import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import LoadingButton from "../../../../components/elements/LoadingButton";
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import {Province} from "../../../../components/elements/Province";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
class SendMessageToUserButton extends React.Component{
    state = {
        province:null,
        cities: null,
        city:null,
        errors:{},
        open:false,
    };

    toggleOpen(){
        this.setState({
            open: !this.state.open,
        })
    }
    handChangeProvince(province){
        if (province === null){
            this.setState({
                province,
                cities:null,
                city:null
            })
        }else{
            this.setState({
                province,
                cities: province.Cities,
            })
        }

    }
    handChangeCity(city){
        this.setState({
            city
        })
    }

    handleSubmit(e){
        let {city} = this.state;
        this.props.handleCity(city.name);
        this.setState({
            open:false
        })
    }
    render(){
        let {province,city,errors,open} = this.state;
        let {user} = this.props;
        return (
            <>
                <Button
                    color="primary"
                    size="small"
                    onClick={() => this.toggleOpen()}
                    startIcon={<AddCircleRoundedIcon/>}
                >
                    {Translate.addCity}
                </Button>

                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {Translate.addCity}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={Province}
                                    required
                                    onChange={(event, value) => this.handChangeProvince(value)}
                                    getOptionLabel={option => option.name}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            required
                                            label={Translate.province}
                                            variant="outlined"
                                            value={province}
                                            fullWidth
                                            autocomplete="off"
                                            error = {!!errors['province']}
                                            helperText={errors['province']}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    options={this.state.cities}
                                    onChange={(event, value) => this.handChangeCity(value)}
                                    getOptionLabel={option => option.name}
                                    value={city}
                                    required
                                    disabled={this.state.cities === null}
                                    renderInput={params => (
                                        <TextField
                                            {...params}
                                            required
                                            autocomplete="off"
                                            label={Translate.city}
                                            variant="outlined"
                                            fullWidth
                                            error = {!!errors['city']}
                                            helperText={errors['city']}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    disabled={!city}
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading={false}
                                >
                                    {Translate.addCity}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SendMessageToUserButton)))));