import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../../components/AppAnimate";
import AppCard from "../../../../components/layouts/components/AppCard";
import Users from "./../components/Users";
import Agents from "./Agents";


class UsersPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.agentsManagement}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={ Translate.agentsManagement}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.agentsManagement}/>
                            }>
                            <Agents/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(UsersPage));
