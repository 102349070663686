import React from "react";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class CurrencyChooser extends React.Component{
    state = {
        anchorEl:null,
        loading:true,
        currencies:null,
        current:!!this.props.current  ? this.props.current : '',
    };
    async componentDidMount(){
        if(!!this.props.current){
            await this.setState({
                current:this.props.current
            })
        }
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {current} = this.state;
        axios.get(`${defaultVariabls.url}/currencies/getAll`, config)
            .then(response => {
                if(!current){
                    let first = true;
                    response.data.map((item) =>{
                        if(first){
                            this.setState({
                                current: item.name
                            })
                            this.props.onChange(item.name)
                            first = false;
                        }
                    })

                }
                this.setState({
                    currencies:response.data,
                    loading:false,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleClick(event){
        this.setState({
            anchorEl:event.currentTarget
        })
    }
    handleClose = () => {
        this.setState({
            anchorEl:null
        })
    };
    handleItem(current){
        this.setState({
            current,
            anchorEl:null
        })
        this.props.onChange(current)
    }
    render(){
        let {anchorEl,currencies,loading,current} = this.state;
    return (
        <div>
            <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                variant="contained"
                onClick={(e) => this.handleClick(e)}
            >
                {
                    loading ?
                        <CircularProgress size={18}/> :
                        current
                }
            </Button>
            {
                !loading &&
                <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={this.handleClose.bind(this)}
                >
                    {
                        currencies.map((item,index) => (
                            <MenuItem key={index} onClick={() => this.handleItem(item.name)}>{item.name}</MenuItem>
                        ))
                    }
                </Menu>
            }
        </div>
    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CurrencyChooser))));