import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Comments from "./Comments";
import {translate} from "../../../../config/lang";


class CommentsPage extends React.Component {
    classes = this.props.classes;

    render(){
        let tr = null;
        let {type} = this.props;
        if(type === 'ad'){
            tr = translate('manageAdsComments')
        }else if(type === "business"){
            tr = translate('manageBusinessesComments')
        }else if(type === "discount"){
            tr = translate('manageDiscountsComments')
        }else if(type === "content"){
            tr = translate('manageArticlesComments')
        }else if(type === "news"){
            tr = translate('manageNewsComments')
        }
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={tr}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={tr}
                            action={
                                <BreadCrumbs singleLevel firstText={tr}/>
                            }>
                            <Comments type={this.props.type}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(CommentsPage));
