import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter,Link} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import ImageGallery from "../fileManager/Components/ImageGallery";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "moment-timezone";
import {makeSlug, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import UserCard from "../../components/elements/UserCard";
import SingleImageChooser from "../fileManager/Components/SingleImageChooser";
import SelectLocationFromMap from "../../components/map/SelectLocationFromMap";
import CountryChooser from "../../components/fields/CountryChooser";
import StateChooser from "../../components/fields/StateChooser";
import Divider from "@material-ui/core/Divider/Divider";
import {hasAccess, updateRedux} from "../../../config/Security";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import MakeEqualDiscount from "./MakeEqualDiscount";
import ViewRelatedComments from "../admin/comments/components/ViewRelatedComments";
import CustomFields from "../advertisements/components/CustomFields";
import MetaDescription from "../fileManager/Components/MetaDescription";
import MuiPhoneNumber from "material-ui-phone-number";
import UserChooser from "../advertisements/components/UserChooser";
import CheckUserCanMakeDiscount from "./components/CheckUserCanMakeDiscount";
import {getTimeZone, makeRound} from "../../../config/values";
import ManageDiscountVouchers from "./vouchers/ManageDiscountVouchers";
import AddVoucherModal from "./vouchers/AddVoucherModal";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
class MakeDiscount extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            meta_title:"",
            description:"",
            reserve_description:"",
            term_of_use:"",
            address:"",
            price:'',
            priceR:'',
            discount_price:'',
            discount_priceR:'',
            phone:'',
            whatsapp:'',
            coins:0,
            expired_at:moment().add(1,'month').format( 'YYYY-MM-DDTHH:mm'),
            expire_after_purchase:moment().add(2,'month').format( 'YYYY-MM-DDTHH:mm'),
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        errors:{},
        categories:[],
        categoriesName:[],
        gallery: [],
        loading: true,
        currency: 'AED',
        SubmitLoading: false,
        publish: true,
        confirmed: false,
        is_best: false,
        real_confirmed: false,
        slug: null,
        id:this.props.match.params.id,
        editMode:false,
        owner_id:null,
        owner:null,
        requested_to_delete:0,
        editor_input_text:'',
        updated_at:null,
        created_at:null,
        expired_at:null,
        expired:0,
        expired_this_week:0,
        activePhoneNumbers:1,
        no_index:1,
        phoneNumberFilled:false,
        activeEmail:1,
        emailFilled:false,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        likes_count:0,
        dislikes_count:0,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        parent_category:null,
        custom_fields:null,
        allowed_comment:0,
        item:null,
        equal:null,
        percent:0,
        related_user:null,
        level:1,
        total_vouchers:0,
        purchased_vouchers:0,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            });
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/discount/get/${this.state.id}`, config)
                .then(response => {
                    if(response.data.lang !== this.props.locale){
                        if(!!response.data.equal_id){
                            this.setState({
                                loading:true
                            })
                            window.location.replace(`${defaultVariabls.React}/edit/discount/${response.data.equal_id}`);

                        }
                    }
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description: !!response.data.description ? response.data.description : '',
                            meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                            reserve_description: !!response.data.reserve_description ? response.data.reserve_description : '',
                            term_of_use: !!response.data.term_of_use ? response.data.term_of_use : '',
                            address: !!response.data.address ? response.data.address : '',
                            price:!!response.data.price ? response.data.price : '',
                            priceR:!!response.data.price ? response.data.price : '',
                            discount_price:!!response.data.discount_price ? response.data.discount_price : '',
                            discount_priceR:!!response.data.discount_price ? response.data.discount_price : '',
                            phone:!!response.data.phone ? response.data.phone : '',
                            whatsapp:!!response.data.whatsapp ? response.data.whatsapp : '',
                            coins:!!response.data.coins ? response.data.coins : 0,
                            expired_at: !!response.data.expired_at ? response.data.expired_at : moment().add(1,'month').format( 'YYYY-MM-DDTHH:mm'),
                            expire_after_purchase: !!response.data.expire_after_purchase ? response.data.expire_after_purchase : moment().add(2,'month').format( 'YYYY-MM-DDTHH:mm'),
                        },
                        thumbnail: response.data.thumbnail,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        confirmed: response.data.confirmed,
                        no_index: response.data.no_index,
                        is_best: response.data.is_best,
                        percent: response.data.percent,
                        real_confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        currency: response.data.currency,
                        owner_id: response.data.user_id,
                        owner: response.data.owner,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        expired: parseInt(response.data.expired),
                        expired_this_week: parseInt(response.data.expired_this_week),
                        requested_to_delete: parseInt(response.data.requested_to_delete),
                        lat:!!response.data.lat ? parseFloat(response.data.lat) : '',
                        lng:!!response.data.lng ? parseFloat(response.data.lng) : '',
                        country:!!response.data.country ? response.data.country : null,
                        state:!!response.data.state ? response.data.state : null,
                        server_country:!!response.data.country ? response.data.country : null,
                        server_state:!!response.data.state ? response.data.state : null,
                        likes_count:parseInt(response.data.likes_count),
                        dislikes_count:parseInt(response.data.dislikes_count),
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                        comments:parseInt(response.data.comments_count),
                        lang:response.data.lang,
                        custom_fields:response.data.custom_fields,
                        allowed_comment:parseInt(response.data.allowed_comment),
                        discount:response.data,
                    })
                    let categoriesTemp=[];
                    let categoriesNameTemp=[];
                    let galleryTemp=[];
                    let parent_category = null;
                    let total_vouchers = 0;
                    let purchased_vouchers = 0;
                    response.data.vouchers.map(item => {
                        total_vouchers++;
                        if(item.status !== 'not_purchased'){
                            purchased_vouchers++;
                        }
                    })
                    response.data.categories.map(item => {
                        if(!parent_category){
                            parent_category = item.id;
                        }
                        categoriesTemp.push(item.id)
                        categoriesNameTemp.push(item.name)
                        return true
                    })
                    response.data.gallery.map(item =>{
                        galleryTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    if(!!response.data.phone_2){
                        if(!!response.data.phone_3){
                            this.setState({
                                activePhoneNumbers:3
                            })
                        }else{
                            this.setState({
                                activePhoneNumbers:2
                            })
                        }
                    }
                    if(!!response.data.email_2){
                        if(!!response.data.email_3){
                            this.setState({
                                activeEmail:3
                            })
                        }else{
                            this.setState({
                                activeEmail:2
                            })
                        }
                    }
                    this.setState({
                        categories: categoriesTemp,
                        categoriesName: categoriesNameTemp,
                        gallery: galleryTemp,
                        parent_category,
                        item:response.data,
                        equal:response.data.equal,
                        total_vouchers,
                        purchased_vouchers,
                        level: response.data.status === "waiting_for_vouchers" ? 2 : 1,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error.response)
                    /*this.props.history.push('/');
                    this.props.enqueueSnackbar(translate('pageNotFound'), {
                        variant: 'error',
                    });*/
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields,percent} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
            if(!!fields.discount_priceR)
                percent = Math.max(0, makeRound((fields.priceR - fields.discount_priceR)*100/fields.priceR));
        }else if (target.name === "discount_price") {
            fields.discount_priceR = target.value.replace(/,/ig, '');
            if(!!fields.priceR)
                percent = Math.max(0, makeRound((fields.priceR - fields.discount_priceR)*100/fields.priceR));
            if(parseInt(fields.discount_priceR) >= parseInt(fields.priceR)){
                fields.discount_price = '';
                fields.discount_priceR = '';
                percent = ''
            }
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
            percent
        });
    }

    handleValidation(callback){
        let { fields,percent,categories,lat,confirmed } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }
        //name
        if(!fields.priceR){
            formIsValid = false;
            errors['price'] = translate('emptyField');
        }
        if(percent > 100){
            formIsValid = false;
            errors['percent'] = translate('wrongPercent');
        }
        //name
        if(!fields.discount_priceR){
            formIsValid = false;
            errors['discount_price'] = translate('emptyField');
        }
        if(!!fields.phone){
            if(fields.phone.length >= 18){
                formIsValid = false;
                errors['phone'] = translate('phoneIsWrong');
            }
        }
        if(categories.length === 0){
            formIsValid = false;
            errors['category'] = translate('emptyField');
        }
        if(!!confirmed){
            //Expire time
            let expire_at = moment(fields.expired_at,'YYYY-MM-DDTHH:mm');
            let expire_after_purchase = moment(fields.expire_after_purchase,'YYYY-MM-DDTHH:mm');
            if(moment().isAfter(expire_at)){
                formIsValid = false;
                errors['expired_at'] = translate('dateShouldBeAfterToday');
            }
            if(expire_at.isAfter(expire_after_purchase)){
                formIsValid = false;
                errors['expire_after_purchase'] = translate('dateShouldBeAfterExpire');
            }
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 500) {
                formIsValid = false;
                errors['description'] = translate('maxLength500');
            }
        }
        //Description
        if(fields.term_of_use !== null ) {
            if (fields.term_of_use.length > 500) {
                formIsValid = false;
                errors['term_of_use'] = translate('maxLength500');
            }
        }
        //Description
        if(fields.reserve_description !== null ) {
            if (fields.reserve_description.length > 1000) {
                formIsValid = false;
                errors['reserve_description'] = translate('maxLength1000');
            }
        }
        //address
        if(fields.address !== null ) {
            if (fields.address.length > 150) {
                formIsValid = false;
                errors['address'] = translate('maxLength150');
            }
        }
        /*if(!country){
            formIsValid = false;
            errors['country'] = translate('emptyField');
        }
        if(!state){
            formIsValid = false;
            errors['state'] = translate('emptyField');
        }
        */
        if(!lat){
            formIsValid = false;
            errors['geocode'] = translate('pleaseChooseYourLocation');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,content,no_index,thumbnail,custom_fields,percent,related_user,allowed_comment,editMode,gallery,categories,is_best,confirmed,currency,lat,lng,country,state} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('meta_title', fields.meta_title);
        data.append('html', content.HTML);
        data.append('lang', this.props.locale);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('related_user', related_user ? related_user.id : 0);
        data.append('description', fields.description);
        data.append('reserve_description', fields.reserve_description);
        data.append('term_of_use', fields.term_of_use);
        data.append('address', fields.address);
        data.append('phone', fields.phone);
        data.append('whatsapp', fields.whatsapp);
        data.append('coins', fields.coins);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('currency', currency);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('no_index',no_index ? 1 : 0);
        data.append('is_best',is_best ? 1 : 0);
        data.append('price', fields.priceR);
        data.append('discount_price', fields.discount_priceR);
        data.append('expired_at', fields.expired_at);
        data.append('expire_after_purchase', fields.expire_after_purchase);
        data.append('percent', percent);
        data.append('lat', lat);
        data.append('lng', lng);
        data.append('country_id', !!country ? country.id : '');
        data.append('state_id', !!state ? state.id : '' );
        data.append('allowed_comment', parseInt(allowed_comment) );
        data.append('categories', JSON.stringify(categories));
        data.append('custom_fields', JSON.stringify(custom_fields));
        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/discount/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('discountEdited') : translate('discountAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                updateRedux();

                if(!this.state.editMode){
                    this.props.history.push(`/edit/discount/${response.data.id}`);
                }else{
                    this.loadData();
                }
                window.scrollTo({
                    top: 0,
                    behavior: "smooth"
                })
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar(editMode ?  translate('discountEditingFailed') : translate('discountAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        let {parent_category} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }else if(parent_category !== categories[0]){
            this.setState({
                parent_category: categories[0]
            })
        }

        this.setState({categories})
    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeBest(){
        this.setState({
            is_best: !this.state.is_best,
        })
    }
    async handleRequestToDelete(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/discount/request_delete/${this.state.id}`, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('requestedToDelete'), {
                    variant: 'success',
                });
                this.setState({
                   requested_to_delete:1,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }
    handleChangeWa(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleDeleteDiscount(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/discount`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteDiscount'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/discounts')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteDiscountFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleChangeCurrency(currency){
        this.setState({
            currency
        })
    }
    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone.replace("+0","+98");
        errors[name] = '';
        this.setState({
            fields,
            errors
        })

    }

    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    handleChangePercent(e){
        let percent = e.target.value;
        let fields = this.state.fields;
        let price = this.state.fields.priceR;
        if(!!price){
            fields['discount_priceR'] = price - (price*percent/100);
            fields['discount_price'] = price - (price*percent/100);
        }
        if(parseInt(percent) === 0){
            fields.discount_price = '';
            fields.discount_priceR = '';
            percent = ''
        }
        this.setState({
            percent,
            fields
        })
    }
    render(){
        let {fields,currency,item,equal,country,percent,loading,server_country,server_state,errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,is_best,confirmed,lat,lng} = this.state;
        const {user} = this.props;

        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editDiscount') :  translate('makeDiscount')}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} lg={11}>
                                {
                                    (user.role.type !== "admin" && !loading) &&
                                    <CheckUserCanMakeDiscount edit={!!editMode}/>
                                }
                                {
                                    (!!editMode && this.state.level ===2) &&
                                    <AddVoucherModal
                                        discount={this.state.discount}
                                        reload={() => this.loadData()}
                                        title={translate('makeVouchers')}
                                    />
                                }
                                <Grid container spacing={2} justifyContent="center">
                                    <>
                                        {
                                            (!!editMode && !loading) &&
                                            <>
                                                <Grid item xs={12}>
                                                    {
                                                        !! user.role.permission.manageAdvertisements ?
                                                            <>
                                                                <Alert
                                                                    severity="info"
                                                                    action={
                                                                        <FormControlLabel
                                                                            style={{marginRight:10}}
                                                                            control={
                                                                                <Switch
                                                                                    checked={confirmed}
                                                                                    onChange={this.handleChangeConfirmed.bind(this)}
                                                                                    value={confirmed}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                        />
                                                                    }
                                                                >
                                                                    {translate('confirmationStatusDescription')}
                                                                </Alert>
                                                                <Alert
                                                                    style={{marginTop:15}}
                                                                    severity="info"
                                                                    action={
                                                                        <FormControlLabel
                                                                            style={{marginRight:10}}
                                                                            control={
                                                                                <Switch
                                                                                    checked={is_best}
                                                                                    onChange={this.handleChangeBest.bind(this)}
                                                                                    value={is_best}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={is_best ? translate('yes') : translate('no')}
                                                                        />
                                                                    }
                                                                >
                                                                    {translate('isThisDiscountBest')}
                                                                </Alert>
                                                            </>
                                                            :
                                                            confirmed ?
                                                                <Alert severity="success" variant="filled">
                                                                    <Typography  className={this.classes.alertText}>{translate('yourDiscountIsActive')}</Typography>
                                                                </Alert>
                                                                :
                                                                <Alert severity="warning">
                                                                    <Typography className={this.classes.alertText}>{translate('yourDiscountIsInchecking')}</Typography>
                                                                </Alert>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <AppCard style={{height:"100%"}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                {
                                                                    !!real_confirmed ?
                                                                        <>
                                                                            <Button
                                                                                component="a"
                                                                                target="_blank"
                                                                                size="small"
                                                                                startIcon={<PageviewRoundedIcon/>}
                                                                                href={makeSlug(`discount/${slug}`,this.state.lang)}
                                                                                color="primary"
                                                                            >
                                                                                {translate('viewDiscount')}
                                                                            </Button>
                                                                        </> :
                                                                        <>
                                                                            <Button
                                                                                component="a"
                                                                                target="_blank"
                                                                                size="small"
                                                                                variant="contained"
                                                                                startIcon={<PageviewRoundedIcon/>}
                                                                                href={makeSlug(`discount/${slug}`,this.state.lang)}
                                                                                color="primary"
                                                                            >
                                                                                {translate('viewPreview')}
                                                                            </Button>

                                                                        </>
                                                                }
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography  className='date-holder'>
                                                                    <span>{translate('todayVisitsCount')}</span>
                                                                    <strong>
                                                                        {this.state.today_visits}
                                                                    </strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography  className='date-holder'>
                                                                    <span>{translate('totalVisitsCount')}</span>
                                                                    <strong>
                                                                        {this.state.visits}
                                                                    </strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography  className='date-holder'>
                                                                    <span>{translate('totalCommentsCount')} <ViewRelatedComments type="discount" item_id={item.id}/></span>
                                                                    <strong>
                                                                        {this.state.comments}
                                                                    </strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <AppCard style={{height:"100%"}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left" style={{position:"relative"}}>
                                                                    {translate('discountInfo')}

                                                                    <div style={{position:"absolute",top:0,right:0,display:"flex",alignItems:"center",fontSize:12}}>
                                                                        <StarRateRoundedIcon style={{marginRight:2}} color="primary"/>
                                                                        <strong className={this.classes.primaryColor} style={{marginRight:2,fontSize:14}}>{item.average_rating} </strong> / 5
                                                                    </div>
                                                                </SubTitle>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography paragraph className='date-holder' style={{marginTop: "0px !important"}}>

                                                                    <span>{translate('discount_expired_at')}</span>
                                                                    <strong >
                                                                        {
                                                                            (this.state.expired === 0 && this.state.expired_this_week === 0) ?
                                                                                `${moment.tz(this.state.fields.expired_at,'UTC').tz(getTimeZone()).fromNow(true)}` :
                                                                                <span className="expired">{translate('expired')}</span>
                                                                        }
                                                                    </strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography  className='date-holder'>
                                                                            <span>
                                                                            {translate('updated_at')}
                                                                        </span>
                                                                    <strong>
                                                                        {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                    </strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography  className='date-holder'>
                                                                    <span>{translate('created_at')}</span>
                                                                    <strong>
                                                                        {`${moment.tz(this.state.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                    </strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <AppCard style={{height:"100%"}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left" style={{position:"relative"}}>
                                                                    {translate('vouchersInfo')}
                                                                    <div style={{position:"absolute",top:0,right:0,display:"flex",alignItems:"center",fontSize:12}}>
                                                                        <Button
                                                                            component={Link}
                                                                            size="small"
                                                                            to={'/wallet/view'}
                                                                            color="primary"
                                                                        >
                                                                            {translate('withdrawMoney')}
                                                                        </Button>
                                                                    </div>
                                                                </SubTitle>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <Typography paragraph className='date-holder' style={{marginTop: "0px !important"}}>
                                                                    <span>{translate('total_vouchers')}</span>
                                                                    <strong className={this.classes.alertText}>
                                                                        {this.state.total_vouchers}
                                                                    </strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography  className='date-holder'>
                                                                        <span>
                                                                            {translate('purchased_vouchers')}
                                                                        </span>
                                                                    <strong>
                                                                        {this.state.purchased_vouchers}
                                                                    </strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography  className='date-holder'>
                                                                    <span>{translate('not_purchased_vouchers')}</span>
                                                                    <strong>
                                                                        {this.state.total_vouchers - this.state.purchased_vouchers }
                                                                    </strong>
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                            </>

                                        }
                                        {
                                            (user.role.type === "admin" && !loading) &&
                                            <Grid item xs={12}>
                                                <Alert
                                                    severity="info"
                                                    action={
                                                        <FormControlLabel
                                                            style={{marginRight:10}}
                                                            control={
                                                                <Switch
                                                                    checked={this.state.allowed_comment}
                                                                    onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                                    value={this.state.allowed_comment}
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                        />
                                                    }
                                                >
                                                    {translate('allowed_comments')}
                                                </Alert>
                                            </Grid>
                                        }
                                        {
                                            (!!editMode) &&
                                            <>
                                                {
                                                    this.state.loading ? null :
                                                        <Grid item xs={12}>
                                                            <AppCard
                                                                className={this.classes.equalAppCard}

                                                            >
                                                                <div
                                                                    className={this.classes.equalHolder}
                                                                >
                                                                    <SubTitle align="left">{translate('equalItem')}</SubTitle>
                                                                    <MakeEqualDiscount
                                                                        equal_id={!!equal ? equal.id : null}
                                                                        item={item}
                                                                        reload={() => this.loadData()}
                                                                    />
                                                                </div>

                                                            </AppCard>
                                                        </Grid>
                                                }
                                            </>
                                        }
                                        {
                                            (!editMode || user.role.type === "admin"|| !real_confirmed) ?
                                            <>
                                                <Grid item xs={12}>
                                                    <AppCard
                                                        title={editMode ? translate('editDiscount') :  translate('makeDiscount')}
                                                        action={
                                                            <BreadCrumbs singleLevel firstText={editMode ? translate('editDiscount') :  translate('makeDiscount')}/>
                                                        }>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>

                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            required
                                                                            fullWidth
                                                                            name="name"
                                                                            variant="outlined"
                                                                            label={translate('discountName')}
                                                                            type="text"
                                                                            id="name"
                                                                            className={this.classes.inputCtl}
                                                                            value={fields.name}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            error={!!errors['name']}
                                                                            helperText={errors['name']}
                                                                        />
                                                                    </Grid>

                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            fullWidth
                                                                            name="expired_at"
                                                                            variant="outlined"
                                                                            label={translate('discount_expire_time')}
                                                                            id="datetime-local"
                                                                            type="datetime-local"
                                                                            onChange={this.handleChange.bind(this)}
                                                                            value={fields.expired_at}
                                                                            InputLabelProps={{
                                                                                shrink: true,
                                                                            }}
                                                                            error={!!errors['expired_at']}
                                                                            helperText={!!errors['expired_at'] ? errors['expired_at'] :  moment.tz(fields.expired_at,'UTC').tz(getTimeZone()).fromNow()}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={4}>
                                                                        <CurrencyTextField
                                                                            margin="normal"
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            minimumValue={0}
                                                                            currencySymbol={currency}
                                                                            decimalPlaces={2}
                                                                            value={fields.price}
                                                                            id="price"
                                                                            label={translate('priceDiscount')}
                                                                            name="price"
                                                                            onChange={this.handleChange.bind(this)}
                                                                            error={!!errors['price']}
                                                                            helperText={errors['price']}
                                                                            className={this.classes.currencyTextField}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4} md={2}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            value={percent}
                                                                            fullWidth
                                                                            id="percent"
                                                                            required
                                                                            margin="normal"
                                                                            label={translate('discount_percent')}
                                                                            name="percent"
                                                                            minimumValue={0}
                                                                            autoComplete="percent"
                                                                            onChange={this.handleChangePercent.bind(this)}
                                                                            error = {!!errors['percent']}
                                                                            helperText={errors['percent']}
                                                                            InputProps={{
                                                                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={8} md={6}>
                                                                        <CurrencyTextField
                                                                            variant="outlined"
                                                                            fullWidth
                                                                            required
                                                                            minimumValue={0}
                                                                            currencySymbol={currency}
                                                                            decimalPlaces={2}
                                                                            margin="normal"
                                                                            value={fields.discount_price}
                                                                            id="discount_price"
                                                                            label={translate('discount_price')}
                                                                            name="discount_price"
                                                                            onChange={this.handleChange.bind(this)}
                                                                            error = {!!errors['discount_price']}
                                                                            helperText={errors['discount_price']}
                                                                            className={this.classes.currencyTextField}

                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            multiline
                                                                            fullWidth
                                                                            name="description"
                                                                            label={translate('seoDescription')}
                                                                            type="text"
                                                                            id="description"
                                                                            minRows={5}
                                                                            className={this.classes.inputCtl}
                                                                            value={fields.description}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            onClick={this.handleClickDescription.bind(this)}
                                                                            error={!!errors['description']}
                                                                            helperText={errors['description']}
                                                                        />
                                                                        <FieldCounterWithoutBar length={fields.description.length} max={500}/>

                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <MuiPhoneNumber
                                                                            fullWidth
                                                                            label={translate('phoneNumber')}
                                                                            name="phone"
                                                                            dir="ltr"
                                                                            variant="outlined"
                                                                            disabled={!hasAccess('has_phone')}
                                                                            onChange={(phone) => this.handleChangeWa(phone,"phone")}
                                                                            disableAreaCodes
                                                                            defaultCountry={'ae'}
                                                                            value={fields.phone}
                                                                            error = {!!errors['phone']}
                                                                            helperText={errors['phone']}
                                                                        />
                                                                        {
                                                                            hasAccess('has_phone') &&
                                                                            <YourPlanDoesntSupportThis visible={!hasAccess('has_phone')}/>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <MuiPhoneNumber
                                                                            fullWidth
                                                                            label={translate('whatsappLink')}
                                                                            placeholder={translate('whatsappPlaceHolder')}
                                                                            name="phone_3"
                                                                            dir="ltr"
                                                                            variant="outlined"
                                                                            onChange={(whatsapp) => this.handleChangeWa(whatsapp,"whatsapp")}
                                                                            disableAreaCodes
                                                                            defaultCountry={'ae'}
                                                                            value={fields.whatsapp}
                                                                            disabled={!hasAccess('has_phone')}
                                                                            error = {!!errors['whatsapp']}
                                                                            helperText={errors['whatsapp']}
                                                                        />
                                                                        {
                                                                            hasAccess('has_phone') &&
                                                                            <YourPlanDoesntSupportThis visible={!hasAccess('has_phone')}/>
                                                                        }
                                                                    </Grid>

                                                                </Grid>
                                                        }
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <AppCard style={{height:"100%"}}>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <LinearCategoryHolder
                                                                            required
                                                                            outPut={this.handleCategoryHolder.bind(this)}
                                                                            inPut={categoriesName}
                                                                            error={!!errors['category']}
                                                                            editMode={categoriesName.length === 0 ? false : editMode}
                                                                        />
                                                                        {
                                                                            !!errors['category'] ?
                                                                                <Typography className="errorText" color="error" variant="body2">
                                                                                    {errors['category']}
                                                                                </Typography>
                                                                                : null
                                                                        }
                                                                    </Grid>
                                                                    {
                                                                        !!editMode && !!categoriesName.length &&
                                                                        <Grid item xs={12}>
                                                                            <Button
                                                                                size="small"
                                                                                variant="outlined"
                                                                                onClick={() => this.changeCategories()}
                                                                            >
                                                                                {translate('changeCategories')}
                                                                            </Button>
                                                                        </Grid>
                                                                    }

                                                                </Grid>
                                                        }
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <AppCard style={{height:'100%'}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left">{translate('term_of_use')}</SubTitle>
                                                            </Grid>
                                                            {
                                                                this.state.loading ? <AppCardLoaderFields/> :
                                                                    <>
                                                                        {
                                                                            !!this.state.parent_category &&
                                                                            <Grid item xs={12}>
                                                                                <TextField
                                                                                    fullWidth
                                                                                    name="expire_after_purchase"
                                                                                    variant="outlined"
                                                                                    label={translate('expire_after_purchase')}
                                                                                    type="datetime-local"
                                                                                    onChange={this.handleChange.bind(this)}
                                                                                    value={fields.expire_after_purchase}
                                                                                    InputLabelProps={{
                                                                                        shrink: true,
                                                                                    }}
                                                                                    error={!!errors['expire_after_purchase']}
                                                                                    helperText={!!errors['expire_after_purchase'] ? errors['expire_after_purchase'] :  moment(fields.expire_after_purchase).locale('en').fromNow()}
                                                                                />
                                                                            </Grid>
                                                                        }

                                                                        <Grid item xs={12}>
                                                                            <CustomFields
                                                                                type={"discount"}
                                                                                position_type="discount_custom_fields"
                                                                                input={this.state.custom_fields}
                                                                                category_id={this.state.parent_category}
                                                                                handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                                            />
                                                                        </Grid>
                                                                    </>
                                                            }
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12} md={3}>
                                                    <AppCard>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <SubTitle align="center">{translate('primaryDiscountImage')}</SubTitle>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <SingleImageChooser
                                                                            primary
                                                                            onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                                            initial={thumbnail}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <MetaDescription width={800} height={600} size={"10MB"}/>
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12} md={9}>
                                                    <AppCard style={{height:"100%"}}>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <SubTitle align="left">{translate('discountGallery')}</SubTitle>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <ImageGallery type={"image"} onChange={this.handleGalleryChange.bind(this)} initial={gallery}/>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <MetaDescription width={800} height={600} size={"10MB"}/>
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AppCard style={{height:"100%"}}>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <SubTitle align='left'>
                                                                            {translate('addressAndLocation')}
                                                                        </SubTitle>
                                                                    </Grid>
                                                                    {
                                                                        !!server_country ?
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Typography>
                                                                                        {server_country.name}  {!!server_state ? "/ "+server_state.name : ""}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Grid item xs={12} md={6}>
                                                                                    <CountryChooser
                                                                                        error={errors['country']}
                                                                                        handleCountry={(country) => this.setState({country})}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={6}>
                                                                                    <StateChooser
                                                                                        country={country}
                                                                                        error={errors['state']}
                                                                                        handleState={(state) => this.setState({state})}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                    }
                                                                    <Grid item xs={12}>
                                                                        <SelectLocationFromMap
                                                                            init_geocode={(!!lat && !!lng) ? {lat:lat,lng:lng} : ""}
                                                                            geocode={(lat,lng) => this.setState({lat,lng})}
                                                                            setAddress={(address) => this.handleAddress(address)}
                                                                            country={country}
                                                                            state={this.state.state}
                                                                        />
                                                                        {
                                                                            !!errors["geocode"] &&
                                                                            <Alert severity="error">
                                                                                <Typography className={this.classes.alertText}>
                                                                                    {errors["geocode"]}
                                                                                </Typography>
                                                                            </Alert>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            multiline
                                                                            fullWidth
                                                                            name="address"
                                                                            label={translate('address')}
                                                                            type="text"
                                                                            minRows="2"
                                                                            value={fields.address}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            error={!!errors['address']}
                                                                            helperText={errors['address']}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <FieldCounterWithoutBar length={fields.address.length} max={150}/>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                        }
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AppCard>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12}>
                                                                        <SubTitle align="left">{translate('discountDescription')}</SubTitle>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <YourPlanDoesntSupportThis visible={!hasAccess('has_html')}/>

                                                                        <RichEditor
                                                                            inputText={editor_input_text}
                                                                            ref={this.EditorChild}
                                                                            Output={this.handleOutPut.bind(this)}
                                                                            input={content.RAW}
                                                                            minHeight={300}
                                                                            readOnly={!hasAccess('has_html')}
                                                                        />
                                                                    </Grid>
                                                                    {/*<Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            multiline
                                                            fullWidth
                                                            name="term_of_use"
                                                            label={translate('term_of_use')}
                                                            type="text"
                                                            minRows={5}
                                                            value={fields.term_of_use}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['term_of_use']}
                                                            helperText={errors['term_of_use']}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FieldCounterWithoutBar length={fields.term_of_use.length} max={500}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>*/}
                                                                </Grid>
                                                        }
                                                    </AppCard>
                                                </Grid>
                                            </> :
                                                <>
                                                    {
                                                        loading &&
                                                            <Grid item xs={12}>
                                                                <AppCard>
                                                                    <AppCardLoaderFields/>
                                                                </AppCard>
                                                            </Grid>
                                                    }
                                                </>
                                        }
                                        {
                                            (!!user.role.permission.manageShops && !editMode) &&
                                            <>
                                                <Grid item xs={12}>
                                                    <AppCard>
                                                        <SubTitle align="left">
                                                            {translate('relatedUserBusiness')}
                                                        </SubTitle>
                                                        <UserChooser
                                                            outPut={(res) => this.setState({
                                                                related_user:res
                                                            })
                                                            }
                                                        />
                                                        <Typography>{translate('inCaseOfNotChoosingUserDiscount')}</Typography>
                                                    </AppCard>
                                                </Grid>
                                            </>


                                        }
                                        {
                                            (!!editMode && !this.state.loading) &&
                                            <ManageDiscountVouchers
                                                discount={this.state.discount}
                                                editMode={true}
                                                reload={() => this.loadData()}
                                            />
                                        }
                                        {
                                            (!! user.role.permission.manageShops && !this.state.loading) &&
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={fields.coins}
                                                                fullWidth
                                                                type="number"
                                                                margin="normal"
                                                                label={translate('discount_coins')}
                                                                name="coins"
                                                                minimumValue={0}
                                                                autoComplete="coins"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['coins']}
                                                                helperText={errors['coins']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                multiline
                                                                fullWidth
                                                                name="reserve_description"
                                                                label={translate('reserve_description')}
                                                                type="text"
                                                                minRows={5}
                                                                className={this.classes.inputCtl}
                                                                value={fields.reserve_description}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['reserve_description']}
                                                                helperText={errors['reserve_description']}
                                                            />
                                                            <FieldCounterWithoutBar length={fields.reserve_description.length} max={1000}/>

                                                        </Grid>
                                                    </Grid>

                                                </AppCard>
                                            </Grid>
                                        }
                                        {
                                            (!!user.role.permission.manageShops) &&
                                            <Grid item xs={12}>
                                                <AppCard style={{height:'100%'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormControlLabel
                                                                    control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                                    label={translate('makePageNoIndex')}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                margin="normal"
                                                                name="meta_title"
                                                                variant="outlined"
                                                                label={translate('meta_title')}
                                                                value={fields.meta_title}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['meta_title']}
                                                                helperText={errors['meta_title']}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                        }
                                        {
                                            (!! user.role.permission.manageShops && !!editMode && !this.state.loading) &&
                                            <Grid item xs={12}>
                                                <UserCard title={translate('creator')} id={owner.id}/>
                                            </Grid>
                                        }
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                style={{minWidth:200}}
                                                onClick = {this.handleSubmit.bind(this)}
                                                loading = {SubmitLoading}
                                            >
                                                {editMode ? translate('editDiscount') :  translate('makeDiscount')}
                                            </LoadingButton>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {
                                                !!editMode ?
                                                    user.id === this.state.owner_id && !user.role.permission.manageAdvertisements && !this.state.requested_to_delete &&
                                                    <Button
                                                        startIcon={<DeleteRoundedIcon/>}
                                                        style={{color:"#ff5451"}}
                                                        onClick={() => this.handleRequestToDelete()}
                                                    >
                                                        {translate('requestToDeleteDiscount')}
                                                    </Button>
                                                    : null
                                            }
                                            {
                                                !!editMode ?
                                                    !!user.role.permission.manageAdvertisements &&
                                                    <GetConfirmationText
                                                        variant="outlined"
                                                        style={{color: "#f44336",backgroundColor:"#fff"}}
                                                        tooltip={translate('removeDiscount')}
                                                        handleAction={() => this.handleDeleteDiscount()}
                                                    >
                                                        {translate('removeDiscount')}
                                                    </GetConfirmationText>
                                                    : null
                                            }
                                        </Grid>
                                    </>
                                </Grid>
                            </Grid>

                        </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeDiscount))));