import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import {updateRedux} from "../../../../config/Security";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import FileChooser from "../../fileManager/Components/FileChooser";
import Typography from "@material-ui/core/Typography/Typography";
import UserCard from "../../../components/elements/UserCard";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import SubTitleLight from "../../../components/elements/SubTitleLight";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {Province} from "../../../components/elements/Province";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import Rating from "@material-ui/lab/Rating/Rating";
import Button from "@material-ui/core/Button/Button";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
class MakeShop extends React.Component {
    classes = this.props.classes;
    state={
        fields: {
            name: '',
            bio:'',
            description:'',
            address:'',
            postal_code:'',
            phone:'',
            financial_code:'',
            melli_code:'',
            commission:'',
        },
        rating:4.5,
        province:null,
        cities: null,
        city:null,
        certificates:[],
        errors:{},
        loading:this.props.edit,
        SubmitLoading:false,
        policy_confirmation:false,
        editMode:this.props.edit,
        editModeP:this.props.edit,
        logo: 3,
        cover: 4,
        confirmed: 0,
        is_global: 0,
        shop_id:this.props.shop_id,
        owner_id:null,
    };
    handleLogoImage(id){
        this.setState({
            logo: id
        })
    }
    handleCoverImage(id){
        this.setState({
            cover: id
        })
    }
    async componentDidMount(){

        if(!!this.state.editMode){
            await this.loadData();
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/shop/get/${this.state.shop_id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        name:response.data.name,
                        bio:!!response.data.bio ? response.data.bio : '',
                        commission:!!response.data.commission ? response.data.commission : '',
                        description:!!response.data.description ? response.data.description : '',
                        address:!!response.data.address ? response.data.address : '',
                        postal_code:!!response.data.postal_code ? response.data.postal_code : '',
                        phone:!!response.data.phone ? response.data.phone : '',
                        financial_code:!!response.data.financial_code ? response.data.financial_code : '',
                        melli_code:!!response.data.melli_code ? response.data.melli_code : '',
                    },
                    rating: response.data.average_rating,
                    province: response.data.province,
                    city: response.data.city,
                    certificates:response.data.certificates,
                    logo: response.data.logo.id,
                    cover: response.data.cover.id,
                    confirmed: response.data.confirmed,
                    is_global: response.data.is_global,
                    loading:false,
                    owner_id:response.data.user_id
                })
            })
            .catch(error => {
                if(error.response.status === 404){
                    this.props.enqueueSnackbar(Translate.dataFetchingError, {
                        variant: 'error',
                    });
                }
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,

        });
    }
    handleValidation(callback){
        let { fields,policy_confirmation,province,city,editMode } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }else if(!validator.isByteLength(fields.name,{min:0, max:255})){
            formIsValid = false;
            errors['name'] = Translate.maxLength255;
        }

        //Bio
        if(fields.bio !== null ) {
            if (fields.bio.length > 50) {
                formIsValid = false;
                errors['bio'] = Translate.maxLength50;
            }
        }
        //policy_confirmation
        if(!editMode){
            if(!policy_confirmation ) {
                formIsValid = false;
                errors['policy_confirmation'] = Translate.pleaseCheckTheBox;
            }
        }

        //Commission
        if(fields.commission !== null ) {
            if (parseInt(fields.commission) > 100 || parseInt(fields.commission) < 1) {
                formIsValid = false;
                errors['commission'] = Translate.between0to100;
            }
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        //province
        if(!province) {
            formIsValid = false;
            errors['province'] = Translate.emptyField;
        }
        //city
        if(!city) {
            formIsValid = false;
            errors['city'] = Translate.emptyField;
        }
        //address
        if(!fields.address) {
            formIsValid = false;
            errors['address'] = Translate.emptyField;
        }
        //phone
        if(!fields.phone) {
            formIsValid = false;
            errors['phone'] = Translate.emptyField;
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasErrorPleaseCheckThem, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,logo,cover,rating,shop_id,confirmed,is_global,certificates,province,city} = this.state;
        let {user} = this.props;
        let data = new FormData();
        let certificates_id = [];
        if(!!certificates){
            certificates.map((cartificate)=>{
                certificates_id.push(cartificate.id);
            })
        }
        data.append('name', fields.name);
        data.append('bio', fields.bio);
        data.append('description', fields.description);
        data.append('commission', fields.commission);
        data.append('id', shop_id);
        data.append('average_rating', rating);

        data.append('user_id', this.props.user.id);
        data.append('logo', logo);
        data.append('confirmed', confirmed ? 1 : 0);
        data.append('is_global', is_global ? 1 : 0);
        data.append('cover', cover);
        data.append('certificates', JSON.stringify(certificates_id));
        data.append('province', province);
        data.append('city', city);
        data.append('address', fields.address);
        data.append('postal_code', fields.postal_code);
        data.append('financial_code', fields.financial_code);
        data.append('phone', fields.phone);
        data.append('melli_code', fields.melli_code);
        if (city !== null ){
            if(city.name === undefined){
                data.append('city', city);
            }else {
                data.append('city', city.name);
            }
        }
        if (province !== null ){
            if(province.name === undefined){
                data.append('province', province);
            }else{
                data.append('province', province.name);
            }
        }
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/shop/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                updateRedux();
                if(!this.state.editMode){
                    if(!!user.role.permission.manageShops){
                        this.props.history.push(`/manage/shops`);
                    }else{
                        window.open(`${defaultVariabls.React}/my-shop`,'_self');
                    }
                }

            })

            .catch(error => {
                if(error.response.status === 401){
                    this.props.enqueueSnackbar(Translate.someFieldsHasErrorPleaseCheckThem, {
                        variant: 'warning',
                    });
                    let errors = this.state.errors;
                    errors['name'] = Translate[error.response.data.name];
                    this.setState({
                        errors
                    })
                }if(error.response.status === 403){
                    if(error.response.data === "first_confirm_user"){
                        this.props.enqueueSnackbar(Translate.firstConfirmUser, {
                            variant: 'error',
                        });
                        this.setState({
                            confirmed:0
                        })
                    }
                }else{
                    this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                        variant: 'error',
                    });
                }
                console.log(error.response);

                this.setState({
                    SubmitLoading:false
                });

            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeIsGlobal(){
        this.setState({
            is_global: !this.state.is_global,
        })
    }
    handChangeProvince(province){
        if (province === null){
            this.setState({
                province,
                cities:null,
                city:null
            })
        }else{
            this.setState({
                province,
                cities: province.Cities,
            })
        }

    }
    handChangeCity(city){
        this.setState({
            city
        })
    }
    render() {
        const {errors,logo,rating,policy_confirmation,province,city,fields,SubmitLoading,editMode,editModeP,confirmed,is_global,certificates,owner_id} = this.state;
        const {user} = this.props;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <form autoComplete="off" role="presentation">
                    <input autoComplete="false" name="hidden" type="text" style={{display:"none"}}/>
                    <Grid container  spacing={2} justify="center" >
                        <CssBaseline/>
                        <Grid item xs={12} md={11} lg={10}  style={{marginTop:20}}>
                            <Grid container spacing={2} direction="row" justify="center"  alignItems="flex-end">
                                <Grid item xs={12}>
                                {
                                    !editMode ?
                                            <Alert severity="info">
                                                <AlertTitle>{Translate.welcomeShopCreatorTitle}</AlertTitle>
                                                {Translate.WelcomeShopCreatorText} <strong>{Translate.thankYou}</strong>
                                            </Alert>
                                        :
                                        <>
                                        {
                                            !! user.role.permission.manageShops ?
                                                <>
                                                    <Alert
                                                        severity="info"
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={confirmed}
                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                        value={confirmed}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                            />
                                                        }
                                                    >
                                                        {Translate.confirmationStatus}
                                                    </Alert>
                                                    <Alert
                                                        className="mt-15"
                                                        severity="info"
                                                        action={
                                                            <FormControlLabel
                                                                style={{marginRight:10}}
                                                                control={
                                                                    <Switch
                                                                        checked={is_global}
                                                                        onChange={this.handleChangeIsGlobal.bind(this)}
                                                                        value={is_global}
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={is_global ? Translate.yes : Translate.no}
                                                            />
                                                        }
                                                    >
                                                        {Translate.isShopGlobal}
                                                    </Alert>
                                                    <Grid container spacing={2} alignItems="center" className="mt-15">
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                className="mt-15"
                                                                variant="outlined"
                                                                value={fields.commission}
                                                                label={Translate.commission}
                                                                name="commission"
                                                                type="number"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['commission']}
                                                                helperText={errors['commission']}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            %
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <Typography>{Translate.shopTotalRating}:</Typography>
                                                            <Rating
                                                                name="rating"
                                                                precision={0.5}
                                                                value={rating}
                                                                onChange={(event, newValue) => {
                                                                    this.setState({
                                                                        rating:newValue,
                                                                    })
                                                                }}
                                                            />
                                                            <Typography className="litle_description_text">{Translate.productTotalRatingDesc}</Typography>
                                                        </Grid>
                                                    </Grid>
                                                    </>
                                                :
                                            confirmed ?
                                               <Alert  severity="success">
                                                   {Translate.yourShopIsActive}
                                               </Alert>
                                               :
                                               <Alert severity="warning">
                                                   {Translate.yourShopIsInchecking}
                                               </Alert>
                                        }
                                        </>

                                }
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <SubTitleLight>{Translate.logo}</SubTitleLight>
                                    <Thumbnail logo
                                               handleThumbImage={this.handleLogoImage.bind(this)}
                                               initial={logo} size={170}/>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                required
                                                value={fields.name}
                                                fullWidth
                                                id="name"
                                                label={Translate.shopName}
                                                name="name"
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['name']}
                                                helperText={errors['name']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                variant="outlined"
                                                value={fields.bio}
                                                fullWidth
                                                id="bio"
                                                label={Translate.bio}
                                                name="bio"
                                                autoComplete="bio"
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['bio']}
                                                helperText={errors['bio']}
                                                InputProps={{
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <FieldCounterWithoutBar length={fields.bio.length} max={50}/>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.description}
                                        fullWidth
                                        id="description"
                                        label={Translate.shopDescription}
                                        name="description"
                                        multiline
                                        minRows={3}
                                        maxRows={5}
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['description']}
                                        helperText={errors['description']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {
                                        editModeP ?
                                            <TextField
                                                required
                                                autoComplete="do-not-autofill"
                                                label={Translate.province}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                value={province}
                                            />
                                            :
                                            <Autocomplete
                                                options={Province}
                                                required
                                                autoComplete="do-not-autofill"
                                                onChange={(event, value) => this.handChangeProvince(value)}
                                                getOptionLabel={option => option.name}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        label={Translate.province}
                                                        variant="outlined"
                                                        value={province}
                                                        fullWidth
                                                        autoComplete="do-not-autofill"
                                                        error = {!!errors['province']}
                                                        helperText={errors['province']}
                                                    />
                                                )}
                                            />
                                    }

                                </Grid>
                                <Grid item xs={12} md={6} style={{position:"relative"}}>
                                    {
                                        editModeP ?
                                            <TextField
                                                required
                                                autocomplete="off"
                                                label={Translate.city}
                                                variant="outlined"
                                                fullWidth
                                                disabled
                                                value={city}
                                            />
                                            :
                                            <Autocomplete
                                                options={this.state.cities}
                                                onChange={(event, value) => this.handChangeCity(value)}
                                                getOptionLabel={option => option.name}
                                                value={city}
                                                required
                                                disabled={this.state.cities === null}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        required
                                                        autocomplete="off"
                                                        label={Translate.city}
                                                        variant="outlined"
                                                        fullWidth
                                                        error = {!!errors['city']}
                                                        helperText={errors['city']}
                                                    />
                                                )}
                                            />
                                    }
                                    {
                                        (!!user.role.permission.manageShops && editModeP) &&
                                            <div style={{position:"absolute",left:12,zIndex:999,top:13}}>
                                            <Tooltip title={Translate.changeProvinceAndCity}>
                                            <IconButton
                                                color="primary"
                                                onClick={() => this.setState({
                                                    province:null,
                                                    city:null,
                                                    editModeP:false,
                                                })}
                                            >
                                                <EditRoundedIcon/>
                                            </IconButton>
                                            </Tooltip>
                                            </div>
                                    }
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.address}
                                        fullWidth
                                        required
                                        label={Translate.address}
                                        name="address"
                                        minRows={4}
                                        maxRows={5}
                                        multiline
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['address']}
                                        helperText={errors['address']}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <FieldCounterWithoutBar length={fields.address.length} max={500}/>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />

                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.postal_code}
                                        fullWidth
                                        label={Translate.postal_code}
                                        name="postal_code"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['postal_code']}
                                        helperText={errors['postal_code']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.phone}
                                        fullWidth
                                        required
                                        label={Translate.phone}
                                        name="phone"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['phone']}
                                        helperText={errors['phone']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.financial_code}
                                        fullWidth
                                        label={Translate.financial_code}
                                        name="financial_code"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['financial_code']}
                                        helperText={errors['financial_code']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        value={fields.melli_code}
                                        fullWidth
                                        label={Translate.melli_code_compnay}
                                        name="melli_code"
                                        type="number"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['melli_code']}
                                        helperText={errors['melli_code']}
                                    />
                                </Grid>
                                {
                                    !editMode &&
                                    <Grid item xs={12}>
                                        <FormControl required error={!!errors['policy_confirmation']} component="fieldset">
                                            <FormControlLabel
                                                control={<Checkbox checked={policy_confirmation} onChange={() => this.setState({policy_confirmation:!policy_confirmation})} />}
                                                label={
                                                    <Typography align="center" className="privacy_policy text-center">
                                                        {Translate.withCreateShop}
                                                        {" "}
                                                        <a target="_blank" href={`${defaultVariabls.website}/seller-contract`} >
                                                            {Translate.shopRules}
                                                        </a>
                                                        {" "}
                                                        {Translate.privacyPolicyConfirmationShop}
                                                    </Typography>
                                                }
                                            />
                                            {
                                                !!errors['policy_confirmation'] &&
                                                <FormHelperText>{errors['policy_confirmation']}</FormHelperText>

                                            }
                                        </FormControl>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <FileChooser
                                        title={Translate.certificationFile}
                                        onChange={(certificates) =>this.setState({certificates})}
                                        initial={certificates}
                                        max={3}
                                    />
                                    {
                                        !!errors['certificates'] &&
                                            <Typography className="error-text">{errors['certificates']}</Typography>
                                    }
                                </Grid>
                                {
                                    !! user.role.permission.manageShops && !!editMode &&
                                    <Grid item xs={12}>
                                            <UserCard title={Translate.shopOwner} id={owner_id}/>
                                    </Grid>
                                }

                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                    >
                                        {editMode ? Translate.setDetails  : Translate.makeShop}
                                    </LoadingButton>

                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </form>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeShop))));