import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../../components/AppAnimate";
import AppCard from "../../../../components/layouts/components/AppCard";
import SlideShowsManager from "./SlideShowsManager";
import {translate} from "../../../../../config/lang";
import ImageGallery from "../../../fileManager/Components/ImageGallery";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router";
import {updateRedux} from "../../../../../config/Security";
import LoadingButton from "../../../../components/elements/LoadingButton";
import HomeBannersManager from "../home_banners/HomeBannersManager";
import Alert from "@material-ui/lab/Alert/Alert";


class SlideShowsPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('manageSlideShows')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <Alert severity="info" variant="outlined">
                            {translate('toViewContentsInOtherLanguageToggleLanguage')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('manageSlideShows')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('manageSlideShows')}/>
                            }>
                            <SlideShowsManager
                                type={"home"}
                                defined_width={1300}
                                defined_height={400}
                            />
                        </AppCard>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('manageHomeBanners')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('manageHomeBanners')}/>
                            }>
                            <Grid container spacing={2}>
                                <HomeBannersManager
                                    defined_width={1920}
                                    defined_height={200}
                                />
                            </Grid>
                        </AppCard>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('manageDiscountsSlider')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('manageDiscountsSlider')}/>
                            }>
                            <Grid container spacing={2}>
                                <SlideShowsManager
                                    type={"discount"}
                                    defined_width={1300}
                                    defined_height={400}
                                />
                            </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SlideShowsPage))));
