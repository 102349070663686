import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../../components/elements/FieldCounter";
import {connect, setStore} from "trim-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Button from "@material-ui/core/Button/Button";
import {makeSlug, toggleLocale, translate} from "../../../../config/lang";
import Typography from "@material-ui/core/Typography/Typography";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import AppBar from "@material-ui/core/AppBar/AppBar";
import CloseIcon from '@material-ui/icons/Close';
import AppCard from "../../../components/layouts/components/AppCard";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";
import SubTitle from "../../../components/elements/SubTitle";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class CategoryEqualCard extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            description:"",
            icon:"",
            ad_meta_title:"",
            business_meta_title:"",
            discount_meta_title:"",
            business_description:"",
            discount_description:"",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: 1,
        errors:{},
        categories:[],
        defaultCategory:null,
        slug:null,
        loading: true,
        SubmitLoading: false,
        confirmed: true,
        hide_products: true,
        parentId:null,
        id:this.props.equal_id,
        editMode:false,
        category:null,
        item:this.props.item,
        modal:false,
        fake_locale:this.props.fake_locale,

    };
    classes = this.props.classes;
    async componentDidMount(){
        if(!!this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }
        await this.loadParents();
    }
    async loadParents(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/categories/quickViewForChooser/${this.props.fake_locale}`, config)
                .then(response => {
                    let categories = response.data.filter((item) => {
                        return item.id !== parseInt(this.id)
                    });
                    categories.map((item,index) =>{
                        return item.id === this.state.parentId ? this.setState({
                            defaultCategory: index,
                        }) : null
                    })
                    this.setState({
                        categories: response.data,
                        loading:false,
                        SubmitLoading:false
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false,
                        SubmitLoading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false,
                SubmitLoading:false
            });
            this.setState({
                loading:false,
                SubmitLoading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/category/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description: !!response.data.description ? response.data.description : '',
                            icon: !!response.data.icon ? response.data.icon : '',
                            business_description: !!response.data.business_description ? response.data.business_description : '',
                            discount_description: !!response.data.discount_description ? response.data.discount_description : '',
                            ad_meta_title: !!response.data.ad_meta_title ? response.data.ad_meta_title : '',
                            business_meta_title: !!response.data.business_meta_title ? response.data.business_meta_title : '',
                            discount_meta_title: !!response.data.discount_meta_title ? response.data.discount_meta_title : '',
                        },
                        parentId: response.data.parent_id,
                        confirmed: response.data.confirmed,
                        hide_products: response.data.hide_products,
                        slug: response.data.slug,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        equal: response.data.equal,
                        category: response.data,
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        //Description
        if(fields.business_description !== null ) {
            if (fields.business_description.length > 150) {
                formIsValid = false;
                errors['business_description'] = translate('maxLength150');
            }
        }
        //Description
        if(fields.discount_description !== null ) {
            if (fields.discount_description.length > 150) {
                formIsValid = false;
                errors['discount_description'] = translate('maxLength150');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal,
            loading:true
        })
        await this.loadParents();
    }
    handleRequest(){
        let { fields,thumbnail,content,id,editMode,confirmed,hide_products,parentId,icon,item} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('ad_meta_title', fields.ad_meta_title);
        data.append('business_meta_title', fields.business_meta_title);
        data.append('discount_meta_title', fields.discount_meta_title);
        data.append('business_description', fields.business_description);
        data.append('discount_description', fields.discount_description);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('equal_id', item.id);
        data.append('description', fields.description);
        data.append('icon', fields.icon);
        data.append('thumbnail', thumbnail);
        data.append('parent_id', parentId);
        data.append('lang', this.props.fake_locale);
        data.append('confirmed',  confirmed ? 1 : 0);
        data.append('hide_products',  hide_products ? 1 : 0);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/category/make-equal`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false,
                });
                this.props.reload();
                this.toggleModal();

            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('categoryPublishedBefore'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeHideProducts(){
        this.setState({
            hide_products: !this.state.hide_products,
        })
    }
    handleParent(value){
        if(value === null){
            this.setState({
                parentId:''
            })
        }else{
            this.setState({
                parentId:value.id
            })
        }
    }
    render(){
        let {fields,id, errors,SubmitLoading,loading,content,editMode,confirmed,equal,category,slug,categories,parentId} = this.state;
        return (
            <>
                {
                    !!category ?
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{category.name}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    onClick={() => this.toggleModal()}
                                >
                                    {translate('edit')}
                                </Button>
                            </Grid>
                        </Grid> :
                        <>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>{translate('noEqual')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    color="primary"
                                    disabled={loading}
                                    onClick={() => this.toggleModal()}
                                >
                                    {translate('addEqual')}
                                </Button>
                            </Grid>
                        </Grid>
                        </>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editCategory') : translate('makeCategory')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        {
                            this.state.loading ?
                                <AppCardLoaderFields/>:
                                <Grid container spacing={2} justify="center" alignItems="center">
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            action={
                                                <FormControlLabel
                                                    style={{marginRight:10}}
                                                    control={
                                                        <Switch
                                                            checked={confirmed}
                                                            onChange={this.handleChangeConfirmed.bind(this)}
                                                            value={confirmed}
                                                            color="primary"
                                                        />
                                                    }
                                                    label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                />
                                            }
                                        >
                                            {translate('confirmationStatusDescription')}
                                        </Alert>
                                    </Grid>
                                    {
                                        !!editMode && !!confirmed ?
                                            <Grid item xs={12}>
                                                <Button
                                                    component="a"
                                                    target="_blank"
                                                    size="small"
                                                    startIcon={<PageviewRoundedIcon/>}
                                                    href={makeSlug(`ads/${slug}`,this.props.fake_locale)}
                                                    color="primary"
                                                >
                                                    {translate('viewCategory')}
                                                </Button>
                                            </Grid>
                                            : null
                                    }
                                    <Grid xs={12}>
                                        <AppCard>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        variant="outlined"
                                                        name="name"
                                                        label={translate('categoryName')}
                                                        type="text"
                                                        id="name"
                                                        className={this.classes.inputCtlEqual}

                                                        value={fields.name}
                                                        onChange={this.handleChange.bind(this)}
                                                        error = {!!errors['name']}
                                                        helperText={errors['name']}
                                                    />
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <RichEditor ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <AppCard style={{height:'100%'}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="ad_meta_title"
                                                                    variant="outlined"
                                                                    label={translate('ad_meta_title')}
                                                                    value={fields.ad_meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['ad_meta_title']}
                                                                    helperText={errors['ad_meta_title']}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    multiline
                                                                    fullWidth
                                                                    name="description"
                                                                    label={translate('ad_description')}
                                                                    type="text"
                                                                    id="description"
                                                                    className={this.classes.inputCtl}
                                                                    rows="4"
                                                                    value={fields.description}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    onClick={this.handleClickDescription.bind(this)}
                                                                    error = {!!errors['description']}
                                                                    helperText={errors['description']}
                                                                />
                                                                <FieldCounter length={fields.description.length} max={150}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="business_meta_title"
                                                                    variant="outlined"
                                                                    label={translate('business_meta_title')}
                                                                    value={fields.business_meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['business_meta_title']}
                                                                    helperText={errors['business_meta_title']}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    multiline
                                                                    fullWidth
                                                                    name="business_description"
                                                                    label={translate('business_description')}
                                                                    type="text"
                                                                    className={this.classes.inputCtl}
                                                                    rows="4"
                                                                    value={fields.business_description}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error = {!!errors['business_description']}
                                                                    helperText={errors['business_description']}
                                                                />
                                                                <FieldCounter length={fields.business_description.length} max={150}/>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="discount_meta_title"
                                                                    variant="outlined"
                                                                    label={translate('discount_meta_title')}
                                                                    value={fields.discount_meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['discount_meta_title']}
                                                                    helperText={errors['discount_meta_title']}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    variant="outlined"
                                                                    margin="normal"
                                                                    multiline
                                                                    fullWidth
                                                                    name="discount_description"
                                                                    label={translate('discount_description')}
                                                                    type="text"
                                                                    className={this.classes.inputCtl}
                                                                    rows="4"
                                                                    value={fields.discount_description}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error = {!!errors['discount_description']}
                                                                    helperText={errors['discount_description']}
                                                                />
                                                                <FieldCounter length={fields.discount_description.length} max={150}/>
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {editMode ? translate('editCategory') : translate('makeCategory')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                    {
                                        (!!editMode && !loading) &&
                                        <Grid item xs={12}>
                                            <Alert severity="warning" variant="standard" style={{marginBottom:10}}>
                                                <Typography style={{direction:this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                    {translate('ifYouWantToSeeMoreInfoClick')}
                                                    {" "}
                                                    <Button
                                                        size="small"
                                                        color="primary"
                                                        component='a'
                                                        href={`${defaultVariabls.React}/edit/category/${this.state.id}?lang=${this.props.fake_locale}`}
                                                    >
                                                        {translate('clickHere')}
                                                    </Button>
                                                </Typography>
                                                <Typography style={{fontSize:11}}>
                                                    {translate('attentionText')}
                                                </Typography>
                                            </Alert>
                                        </Grid>
                                    }
                                </Grid>
                        }
                    </DialogContent>
                </Dialog>
            </>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
    fake_locale: state.fake_locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CategoryEqualCard))));