import {translate} from "../lang";
import {getStore} from "trim-redux";
import {defaultVariabls} from "../index";
function getChevronEnd(){
    let locale = getStore('locale');
    return locale === "en" ? "chevron_right" : "chevron_right"
}
export const getRoutes = function () {
    return [
        {
            id: 'dashboard',
            title: translate('dashboard'),
            type: 'item',
            icon: 'dashboard',
            url: '/dashboard',
        },
        {
            id: 'tickets',
            title: translate('tickets'),
            type: 'item',
            icon: 'chat',
            url: '/tickets',
            roles: ['user'],
            feature:"tickets",
        },
        {
            id: 'business',
            title: translate('myBusiness'),
            type: 'item',
            icon: 'business',
            url: '/business',
            roles: ['admin','support','supplier','user'],
            restrict:'has_business_page'
        },
        {
            id: 'orders',
            title: translate('orders'),
            type: 'group',
            roles: ['user'],
            children: [
                {
                    id: 'ordersList',
                    title: translate('ordersList'),
                    type: 'item',
                    icon: 'list',
                    url: '/my-orders',
                },
                {
                    id: 'businessVouchers',
                    title: translate('buyersOrdersList'),
                    type: 'item',
                    restrict:'has_discount',
                    icon: 'insert_chart',
                    url: '/purchased-discounts',
                },
                {
                    id: 'myTransactions',
                    title: translate('myTransactions'),
                    type: 'item',
                    icon: 'receipt_long',
                    url: '/wallet/view',
                },

            ],
        },
        {
            id: 'advertisement',
            title: translate('advertisements'),
            type: 'group',
            roles: ['user'],
            restrict:'has_ad',
            children: [
                {
                    id: 'advertisementsList',
                    title: translate('myAdvertisements'),
                    type: 'item',
                    icon: 'list',
                    url: '/my-advertisements',
                },
                {
                    id: 'makeAdvertisement',
                    title: translate('makeAdvertisement'),
                    icon: 'edit_note',
                    type: 'item',
                    url: '/create/advertisement',
                },
            ],
        },
        {
            id: 'discount',
            title: translate('discounts'),
            type: 'group',
            roles: ['user'],
            restrict:'has_discount',
            children: [

                {
                    id: 'discountsList',
                    title: translate('myDiscounts'),
                    type: 'item',
                    icon: 'qr_code_scanner',
                    url: '/my-discounts',
                },
                {
                    id: 'makeDiscount',
                    title: translate('makeDiscount'),
                    icon: 'edit_note',
                    type: 'item',
                    url: '/create/discount',
                },
            ],
        },

        {
            id: 'site_settings',
            title: translate('siteSettings'),
            type: 'collapse',
            icon: 'web',
            permission: 'managePayments',
            children: [
                {
                    id: 'siteInformation',
                    title: translate('siteInformation'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/setting/information',
                    exact:true
                },
                {
                    id: 'manageContacts',
                    title: translate('siteContactInformation'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/setting/contact',
                    exact:true
                },
                {
                    id: 'manageSlideShows',
                    title: translate('manageAllSlideShows'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/settings/slideshows',
                    exact:true
                },
                {
                    id: 'manageBanners',
                    title: translate('manageBanners'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/settings/banners',
                    exact:true
                },
                {
                    id: 'manageFields',
                    title: translate('manageFields'),
                    type: 'item',
                    url: '/manage/fields',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageCTAs',
                    title: translate('manageCTAs'),
                    type: 'item',
                    url: '/manage/ctas',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],

        },
        {
            id: 'palns',
            title: translate('plans'),
            type: 'collapse',
            icon: 'verified',
            permission: 'managePayments',
            children: [
                {
                    id: 'managePlanParents',
                    title: translate('managePlanParents'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/plan-parents',
                    exact:true
                },
                {
                    id: 'managePlans',
                    title: translate('managePlans'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/plans',
                    exact:true
                },
                {
                    id: 'purchaseAds',
                    title: translate('purchase_ads_page'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/purchase-page',
                    exact:true
                },
            ],
        },
        {
            id: 'manageCategories',
            title: translate('categories'),
            type: 'item',
            url: '/manage/categories',
            icon: 'category',
            permission: 'managePayments',
            exact:true
        },
        {
            id: 'manageBusinesses',
            title: translate('manageBusinesses'),
            type: 'item',
            url: '/manage/businesses',
            icon:"business",
            permission: 'manageAdvertisements',
            exact:true
        },
        {
            id: 'advertisements',
            title: translate('manageAdvertisements'),
            type: 'item',
            url: '/manage/advertisements',
            icon: 'list',
            exact:true,
            permission: 'manageAdvertisements',

        },

        {
            id: 'manageDiscounts',
            title: translate('manageDiscounts'),
            type: 'item',
            url: '/manage/discounts',
            icon:"qr_code_scanner",
            permission: 'manageAdvertisements',
            exact:true
        },
        {
            id: 'manageComments',
            title: translate('manageComments'),
            type: 'collapse',
            icon: 'rate_review',
            permission: 'manageAdvertisements',
            children: [
                {
                    id: 'manageAdsComments',
                    title: translate('manageAdsComments'),
                    type: 'item',
                    url: '/manage/comments/ad',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageBusinessesComments',
                    title: translate('manageBusinessesComments'),
                    type: 'item',
                    url: '/manage/comments/business',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageDiscountsComments',
                    title: translate('manageDiscountsComments'),
                    type: 'item',
                    url: '/manage/comments/discount',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageArticlesComments',
                    title: translate('manageArticlesComments'),
                    type: 'item',
                    url: '/manage/comments/article',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageNewsComments',
                    title: translate('manageNewsComments'),
                    type: 'item',
                    url: '/manage/comments/news',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'manageOrders',
            title: translate('manageOrders'),
            type: 'collapse',
            icon: 'receipt',
            permission: 'manageOrders',
            children: [
                {
                    id: 'manageOrders',
                    title: translate('ordersListManage'),
                    type: 'item',
                    url: '/manage/orders',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'usersManagement',
            title: translate('usersManagement'),
            type: 'collapse',
            icon: 'group',
            permission: 'manageSettings',
            children: [
                {
                    id: 'allUsers',
                    title: translate('allUsers'),
                    type: 'item',
                    url: '/manage/users/view/all',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'verifiedUsers',
                    title: translate('verifiedUsers'),
                    type: 'item',
                    url: '/manage/verified-users',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'usersActivity',
                    title: translate('usersActivity'),
                    type: 'item',
                    url: '/manage/activities',
                    exact:true,
                    icon: getChevronEnd(),
                    feature:"activity_log"
                },
                {
                    id: 'manageNewsLetter',
                    title: translate('newsletterSubscribers'),
                    type: 'item',
                    url: '/manage/subscribers',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'paymentManagement',
            title: translate('paymentManagement'),
            type: 'collapse',
            icon: 'badge',
            permission: 'managePayments',
            feature:"wallet",
            children: [
                {
                    id: 'manageVerifications',
                    title: translate('manageVerifications'),
                    type: 'item',
                    url: '/manage/verifications',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageWallets',
                    title: translate('manageWallets'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/wallets',
                    exact:true,
                },

            ],
        },
        {
            id: 'manageMessages',
            title: translate('manageMessages'),
            type: 'collapse',
            icon: 'chat',
            permission: 'manageSettings',
            feature:"tickets",
            children: [
                {
                    id: 'manageDepartments',
                    title: translate('manageTickets'),
                    type: 'item',
                    url: '/manage/tickets',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageDepartments',
                    title: translate('referToMe'),
                    type: 'item',
                    url: '/tickets',
                    icon: getChevronEnd(),
                    exact:true
                },
                {
                    id: 'manageDepartments',
                    title: translate('manageDepartments'),
                    type: 'item',
                    url: '/manage/departments',
                    icon: getChevronEnd(),
                    exact:true
                },

            ]
        },
        {
            id: 'managePages',
            title: translate('managePages'),
            type: 'item',
            icon: 'description',
            url: '/manage/pages',
            permission: 'manageSettings',
        },
        {
            id: 'manageContact',
            title: translate('manageContact'),
            type: 'item',
            url: '/manage/contacts',
            icon: 'contact_mail',
            permission: 'manageSettings',
            exact:true
        },
        {
            id: 'manageFAQ',
            title: translate('manageFAQ'),
            type: 'item',
            url: '/manage/faq',
            icon: 'quiz',
            permission: 'manageSettings',
            exact:true
        },
        {
            id: 'blog',
            title: translate('articles'),
            type: 'collapse',
            icon: 'article',
            feature:"blog",
            roles: ['admin'],
            children: [
                {
                    id: 'blogCategories',
                    title: translate('manageBlogCategories'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/blog-categories',
                    exact:true,
                },
                {
                    id: 'contents',
                    title: translate('contentsList'),
                    type: 'item',
                    url: '/manage/contents',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'blog',
            title: translate('news'),
            type: 'collapse',
            icon: 'newspaper',
            feature:"blog",
            roles: ['admin'],
            children: [
                {
                    id: 'newsCategories',
                    title: translate('manageNewsCategories'),
                    type: 'item',
                    icon: getChevronEnd(),
                    url: '/manage/news-categories',
                    exact:true,
                },
                {
                    id: 'news',
                    title: translate('newsList'),
                    type: 'item',
                    url: '/manage/news',
                    icon: getChevronEnd(),
                    exact:true
                },
            ],
        },
        {
            id: 'myDetails',
            title: translate('myDetails'),
            type: 'group',
            icon: 'person',
            children: [
                {
                    id: 'settings',
                    title: translate('account'),
                    type: 'item',
                    icon: 'badge',
                    url: '/account/details',
                },
                {
                    id: 'password',
                    title: translate('managePassword'),
                    icon: 'password',
                    type: 'item',
                    url: '/account/password',
                },
            ],
        },
        {
            id: 'fileManager',
            title: translate('fileManager'),
            type: 'item',
            icon: 'image',
            url: '/file-manager',
            roles: ['admin','support','supplier','product_manager'],
            permission: "fileManagerPage",
        },
    ]
}

