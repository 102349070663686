import {getStore, setStore} from "trim-redux";
import {FA} from "./fa";
import {EN} from "./en";
import {defaultVariabls} from "../index";

export function translate(string, lang = "") {
    if(lang === "")
        lang = getStore('fake_locale');
    if(!FA[string]) console.log(string);
    if (lang === "fa"){
        return FA[string];
    }else return EN[string];

}

export function makeSlug(slug,lang = null) {
    let locale;
    if(lang === null){
        locale = getStore('locale');
    }else{
        locale = lang;
    }

    let url = `${defaultVariabls.website}/`;
    if(locale !== "fa"){
        url += locale+ "/"
    }
    return url+slug;
}
export async function toggleLocale() {
    let fake_locale = getStore('fake_locale');
    if(fake_locale === "en"){
        await setStore({
            fake_locale: "fa"
        })
    }else{
        await setStore({
            fake_locale: "en"
        })
    }
}
export async function fixLocale() {
    let locale = getStore('locale');
    await setStore({
        fake_locale: locale
    })
}