import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../config/Translate";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

class Pricing extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                title: Translate.thumbnail,
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            { title: Translate.productName, field: 'name',filterPlaceholder: Translate.search},
            { title: Translate.shopName, field: 'shop',filterPlaceholder: Translate.search},

            {
                title: Translate.lastUpdate,
                editable: 'never',
                field: 'updated_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.updated_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.updated_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/pricing/product/${rowData.id}`}
                        endIcon={<ChevronLeftRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {Translate.pricingOnly}
                    </Button>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/my-products-for-pricing/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        thumbnail: `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                                        name: row.name,
                                                        id: row.id,
                                                        shop: row.shop.name,
                                                        confirmed: row.confirmed,
                                                        updated_at:row.updated_at
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                }}
                            />
                        </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Pricing)))));