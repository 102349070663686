import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../../config/design/styles";
import { withSnackbar } from 'notistack';
import {connect} from "trim-redux";
import FileManagerDialog from "../../../fileManager/Components/FileManagerDialog";
import AttachFileIcon from '@material-ui/icons/AttachFile';
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../../../config/lang";

class SendFileInChatButton extends React.Component{
    state = {
        FileManagerPending: false,
    };
    classes = this.props.classes;
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }
    setImage = (data) => {
        this.setState({
            image: data.thumbSource
        })
        this.props.sendFile(data.id)
        this.toggleOperation();
    }
    render(){
        let {FileManagerPending} = this.state;
        return (
            <>
                <Button
                    size="small"
                    color="primary"
                    variant="outlined"
                    style={{marginLeft:10}}
                    onClick={this.toggleOperation}
                    disabled={this.props.disabled}
                    startIcon={<AttachFileIcon/>}
                >
                    {translate('sendFile')}
                </Button>

                {
                    FileManagerPending ?
                        <FileManagerDialog
                            fileType="all"
                            onlyFile
                             cancleOperation={this.toggleOperation}
                             setImage={this.setImage}
                        /> : null
                }
            </>
        );
    }
}

const mstp = state => ({
});
export default withSnackbar(withStyles(Styles)(connect(mstp)(SendFileInChatButton)));