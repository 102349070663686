import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ManageUserVerificationDialog from "./components/ManageUserVerificationDialog";
import {translate} from "../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import {getTimeZone} from "../../../../config/values";

class ManageVerifications extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'user',
                editable: 'never',
                filtering: false,
                title: translate('userName'),
            },
            {
                field: 'type',
                editable: 'never',
                filtering: false,
                title: translate('payment_type'),
                render: rowData => translate(rowData.type)
            },
            {
                field: 'confirmed',
                editable: 'never',
                filtering: false,
                title: translate('confirmationStatus'),
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    ,
                    0:
                        <Typography style={{color:"#BF8230"}}>{translate('unConfirmed')}</Typography>
                },
            },
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                sorting:false,
                field: 'activity',
                render: rowData =>
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.handleRowClick(rowData)}
                            endIcon={<ChevronRightRoundedIcon/>}
                            size={"small"}
                            style={{margin:5}}
                        >
                            {translate('view')}
                        </Button>
                    </>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        openModal:false,
        id:null,
    };
    loadData(){
        this.setState({
            openModal:false,
            id:null,
        })
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRowClick(data){
        this.setState({
            openModal:true,
            id: data.id,
        })
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={<> </>}
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/users/getAllVerifications?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        id: row.id,
                                                        user: row.user.first_name+" "+row.user.last_name,
                                                        confirmed: row.user.verified,
                                                        type:row.type,
                                                        created_at: row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                }}
                            />
                            <ManageUserVerificationDialog open={this.state.openModal} id={this.state.id} reload={this.loadData.bind(this)}/>

                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManageVerifications)))));