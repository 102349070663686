import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect, getStore} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {makeSlug, translate} from "../../../../config/lang";
import ConfirmationNumberIcon from "@material-ui/icons/ConfirmationNumber";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import SubTitle from "../../../components/elements/SubTitle";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment-timezone";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Grid from "@material-ui/core/Grid/Grid";
import InfoTwoToneIcon from "@material-ui/icons/InfoTwoTone";
import CheckCircleTwoToneIcon from "@material-ui/icons/CheckCircleTwoTone";
import AccessAlarmRoundedIcon from "@material-ui/icons/AccessAlarmRounded";
import {defaultVariabls} from "../../../../config";
import {getTimeZone} from "../../../../config/values";
class SeeVouchersButton extends React.Component{
    state = {
        vouchers:this.props.vouchers,
        open:false,
    };
    classes = this.props.classes;

    toggleOpen(type){
        this.setState({
            open: !this.state.open,
        })
    }
    render(){
        let {vouchers,open} = this.state;
        return (
            <>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="outlined"
                    color="primary"
                    onClick={(e) => this.toggleOpen('system')}
                    startIcon={<ConfirmationNumberIcon/>}
                    size={"small"}
                >
                    {translate('viewVouchers')}
                </Button>

                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",right:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {translate('viewVouchers')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <SubTitle align="left">
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="a"
                                        href={makeSlug(`vouchers/${this.props.id}`)}
                                    >
                                        {translate('getAllVouchers')}
                                    </Button>
                                </SubTitle>
                            </Grid>
                            {
                                vouchers.map((voucher,index)=>(
                                    <Grid item xs={12} sm={6} md={4} key={index}>
                                        <Card
                                            style={{
                                                boxShadow:"none",
                                                border:"1px solid #eee",
                                                marginBottom:15,
                                                background: voucher.status === "not_use" ? getStore( 'mode') === "dark" ? "#413f34" :"#fffaec" : getStore( 'mode') === "dark" ? "#363b2c" :"#f4ffe1",
                                            }}
                                        >
                                            <CardContent>
                                                <Typography className={this.classes.voucher_text}>
                                                    <AccessAlarmRoundedIcon fontSize="small" style={{marginRight:5}}/>
                                                    <span className="expired_at">
                                                    {
                                                        !!voucher.expired_at ?
                                                            <Typography className={this.classes.dateTable}>
                                                                {moment.tz(voucher.expired_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')} - {moment.tz(voucher.expired_at,'UTC').tz(getTimeZone()).fromNow( true)}
                                                            </Typography>
                                                            :
                                                            translate('withoutExpire')
                                                    }
                                                </span>
                                                </Typography>
                                                <Typography
                                                    style={{
                                                        display:"flex",
                                                        alignItems:"center",
                                                        fontSize:12,
                                                        marginTop:10,

                                                    }}
                                                >
                                                    {
                                                        voucher.status === "not_use" ?
                                                            <InfoTwoToneIcon fontSize="small" style={{color:"#ffc963",marginRight:5}}/>
                                                            :
                                                            <CheckCircleTwoToneIcon fontSize="small" style={{color:"#62c562",marginRight:5}}/>
                                                    }
                                                    <span className="status">
                                                    {translate(voucher.status)}
                                                </span>
                                                </Typography>
                                                <div style={{textAlign:"center",marginTop:10}}>
                                                    <Button
                                                        color="primary"
                                                        size="small"
                                                        variant="outlined"
                                                        startIcon={<ConfirmationNumberIcon/>}
                                                        component="a"
                                                        /*href={voucher.status === "not_use" ? "#" :makeSlug(`voucher/${voucher.unique_key}`)}*/
                                                        href={makeSlug(`voucher/${voucher.unique_key}`)}
                                                        /*disabled={voucher.status === "not_use"}*/
                                                    >
                                                        {translate('getVoucher')}
                                                    </Button>
                                                </div>
                                            </CardContent>


                                        </Card>
                                    </Grid>
                                ))
                            }
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SeeVouchersButton)))));