import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import {setStore, getStore, connect} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import {secret} from "../../../config/Security";
import Box from "@material-ui/core/Box/Box";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "../../components/elements/LoadingButton";
import HeaderTags from "../../components/elements/HeaderTags";
import AuthLayout from "../../components/layouts/AuthLayout";
import PassField from "../../components/fields/PassField";
import queryString from "query-string";
import Button from "@material-ui/core/Button/Button";
import Feature from "../../components/elements/Feature";
import LockOpenTwoToneIcon from '@material-ui/icons/LockOpenTwoTone';
import Hidden from "@material-ui/core/Hidden/Hidden";
import {translate} from "../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
class Login extends React.Component {
    constructor(props){
        super(props);

        const values = queryString.parse(this.props.location.search)
        let redirect = values.r;
        if(!!redirect){
            if(this.props.isAuth) {
                this.handleLogout();
            }
        }else{
            if(this.props.isAuth){
                this.props.history.push("/");
            }
        }
        this.state={
            fields: {
                username:'',
                password:''
            },
            errors:{},
            SubmitLoading:false,
            success:false,
            anchorEl:null,
            login_type:"email",
            login_type_persist:false,
            redirect: !!redirect ? redirect : null
        };
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
    }
    handleChange(e){
        let {fields,errors,login_type_persist} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        if(target.name === "username" && !login_type_persist){
            var regExp = /[a-zA-Z]/g;
            if(regExp.test(target.value) || !target.value){
                this.setState({
                    login_type:"email"
                })
            } else {
                this.setState({
                    login_type:"mobile"
                })
            }
        }
        this.setState({fields});
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //Username
        if(validator.isEmpty(fields.username)){
            formIsValid = false;
            errors['username'] = translate('emptyEmailOrMobile');
        }
        //Password
        if(validator.isEmpty(fields.password)){
            formIsValid = false;
            errors['password'] = translate('emptyPassword');
        }
        this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    async handleRequest(){

        const {username, password} = this.state.fields;

        let data = new FormData();
        data.append('secret', secret);
        data.append('username', username);
        data.append('password', password);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/login`, data, config)
            .then(response =>{
                localStorage.setItem('ACT',response.data.access_token);
                localStorage.setItem('RET',response.data.refresh_token);
            })
            .catch(error =>{
                console.log(error.response)
                this.setState({
                    SubmitLoading:false

                })
                this.props.enqueueSnackbar(translate('loginError'), {
                    variant: 'error',
                });
            });
        const loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin/${getStore('locale')}`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });
                    this.setState({
                        SubmitLoading:false,
                        success:true
                    })
                }).catch(error => {
                    console.log(error.response)
                    setStore({
                        isAuth : false,
                        user : null,
                    });
                    this.setState({
                        SubmitLoading:false

                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                SubmitLoading:false
            })
        }
        if (getStore().isAuth){
            let code = getStore().user.login_token;
            if(this.state.redirect){
                localStorage.setItem("external_redirect",this.state.redirect);
            }
            let token = localStorage.getItem('ACT');
            let lang = localStorage.getItem('locale');
            let mode = localStorage.getItem('mode');
            if(!mode){
                mode = '';
            }
            let {iframe} = this.props;
            if(iframe){
                await window.parent.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }else{
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeMobile(mobile){
        let {fields} = this.state;
        fields.username = mobile.replace("+0", "+98");
        this.setState({
            fields
        })
    }
    clickOnRegisterButton(){
        let {iframe} = this.props;
        if(iframe){
            window.top.location.replace(`${defaultVariabls.React}/register`);
        }else{
            this.props.history.push(`/register`);
        }
    }
    render() {
        const {classes,iframe} = this.props;
        const {errors,SubmitLoading,success,login_type} = this.state;
        return (
                <AuthLayout iframe={iframe}>
                    <HeaderTags title={translate('login')}/>

                    <Grid item md={10} lg={9} >
                        <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                            <strong className={classes.auth_title}>
                                <LockOpenTwoToneIcon className={classes.me10} color="secondary"/>
                                {translate('login')}
                                </strong>
                        </Box>
                        <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                            <Grid container spacing={0} justify="center" alignItems='center'>
                                <Grid item xs={12}>
                                    {
                                        login_type === "email" ?
                                            <TextField
                                                required
                                                fullWidth
                                                variant="outlined"
                                                id="username"
                                                label={translate('emailOrMobile')}
                                                name="username"
                                                autoComplete="username"
                                                onChange={this.handleChange.bind(this)}
                                                autoFocus
                                                error = {!!errors['username']}
                                                helperText={errors['username']}
                                                InputProps={!!this.state.fields.username ? {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <Button
                                                                size="small"
                                                                variant="outlined"
                                                                style={{fontSize:11}}
                                                                onClick={() => this.setState({
                                                                    login_type:"mobile",
                                                                    login_type_persist:true
                                                                })}
                                                            >
                                                                {translate('changeToMobile')}
                                                            </Button>
                                                        </InputAdornment>
                                                    ),
                                                } : null}
                                            />
                                            :
                                            <>
                                                <MuiPhoneNumber
                                                    required
                                                    fullWidth
                                                    label={translate('emailOrMobile')}
                                                    name="username"
                                                    margin="normal"
                                                    dir="ltr"
                                                    variant="outlined"
                                                    onChange={this.handleChangeMobile.bind(this)}
                                                    autoFocus
                                                    disableAreaCodes
                                                    defaultCountry={'ae'}
                                                    error = {!!errors['username']}
                                                    helperText={errors['username']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <Button
                                                                    variant="outlined"
                                                                    style={{fontSize:11}}
                                                                    size="small"
                                                                    onClick={() => this.setState({
                                                                        login_type:"email"
                                                                    })}
                                                                >
                                                                    {translate('changeToEmail')}
                                                                </Button>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />

                                            </>

                                    }

                                </Grid>
                                <Grid item xs={12}>
                                    <PassField
                                        margin="normal"
                                        required
                                        variant="outlined"
                                        fullWidth
                                        name="password"
                                        label={translate('password')}
                                        placeholder={translate('password')}
                                        type="password"
                                        id="password"
                                        onChange={this.handleChange.bind(this)}
                                        autoComplete="current-password"
                                        error = {!!errors['password']}
                                        helperText={errors['password']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        style={{width:"100%"}}
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                        success = {success}
                                    >
                                        {translate('login')}
                                    </LoadingButton>
                                </Grid>
                                <Feature check="token_login">
                                    <Grid item xs={12}>
                                        <Button style={{width:'100%'}} component={Link} to={iframe ? "/iframe/token-login" : "/token-login"} color="primary" variant="outlined">
                                            <strong>
                                                {translate('loginWithToken')}
                                            </strong>
                                        </Button>
                                    </Grid>
                                </Feature>
                                <Grid item xs={6} md={6} className={classes.textLeft} style={{marginTop:15}}>
                                    <Typography >
                                        <Button size={"small"}  color="primary"  onClick={() => this.clickOnRegisterButton()}>
                                            <strong>
                                                {translate('doRegister')}
                                            </strong>
                                        </Button>
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} md={6} className={classes.textRight}>
                                    <Button size={"small"} component={Link} to={iframe ? "/iframe/forget-email" : "/forget-email"} >
                                        {translate('forgetPass')}
                                    </Button>
                                </Grid>

                            </Grid>
                        </form>
                    </Grid>
                </AuthLayout>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Login))));