import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import ImageGallery from "../fileManager/Components/ImageGallery";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "moment-timezone";
import {makeSlug, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import UserCard from "../../components/elements/UserCard";
import MuiPhoneNumber from "material-ui-phone-number";
import SingleImageChooser from "../fileManager/Components/SingleImageChooser";
import SelectLocationFromMap from "../../components/map/SelectLocationFromMap";
import CountryChooser from "../../components/fields/CountryChooser";
import StateChooser from "../../components/fields/StateChooser";
import Divider from "@material-ui/core/Divider/Divider";
import CheckUserCanAdBusiness from "./components/CheckUserCanAdBusiness";
import {hasAccess, updateRedux} from "../../../config/Security";
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import ViewRelatedComments from "../admin/comments/components/ViewRelatedComments";
import MakeEqualBusiness from "./MakeEqualBusiness";
import CustomFields from "../advertisements/components/CustomFields";
import ChipInput from "material-ui-chip-input";
import MetaDescription from "../fileManager/Components/MetaDescription";
import BusinessUserChooser from "./components/BusinessUserChooser";
import {getTimeZone} from "../../../config/values";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import FormControl from "@material-ui/core/FormControl/FormControl";

class MakeBusiness extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:'',
            address:'',
            phone:'',
            year:'',
            total_employees:'',
            bio:'',
            meta_title:'',
        },
        standards:[],
        logo: null,
        thumbnail: null,
        errors:{},
        banners: [],
        videos: [],
        loading: true,
        SubmitLoading: false,
        confirmed: false,
        real_confirmed: false,
        custom_fields:null,
        slug: null,
        user_id:this.props.user_id,
        related_user:null,
        editMode:false,
        owner_id:null,
        owner:null,
        updated_at:null,
        created_at:null,
        lat:'',
        lng:'',
        country:'',
        state:'',
        server_country:'',
        server_state:'',
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        allowed_comment:0,
        is_verified: false,
        is_discount_provider: false,
        item:null,
        equal:null,
        id:0,
        no_index:0,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.user_id){
            this.setState({
                editMode:true
            });
            this.loadData();
        }else{
            if(!this.props.manage){
                this.setState({
                    fields:{
                        name:this.props.user.business_name,
                        address:!!this.props.user.address ? this.props.user.address : '',
                        phone:this.props.user.phone,
                        bio:!!this.props.user.business_bio ? this.props.user.business_bio : '',
                    },
                    lat:this.props.user.lat,
                    lng:this.props.user.lng,
                    logo:this.props.user.logo,
                    country:this.props.user.country,
                    state:this.props.user.state,
                    server_country:this.props.user.country,
                    server_state:this.props.user.state,
                })
            }
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.user_id !== this.props.user_id){
            this.setState({
                user_id: this.props.user_id
            })
        }
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        let {locale,by_id} = this.props;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/business/${!!by_id ? "getById" : "get"}/${this.state.user_id}${!!by_id ? '' : "/"+locale}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            year: !!response.data.year ? response.data.year : '',
                            total_employees: !!response.data.total_employees ? response.data.total_employees : '',
                            address: !!response.data.address ? response.data.address : '',
                            phone: !!response.data.phone ? response.data.phone : '',
                            bio: !!response.data.bio ? response.data.bio : '',
                            meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                        },
                        standards: !!response.data.standards ? response.data.standards.split(',') : [],
                        logo: response.data.logo,
                        thumbnail: response.data.thumbnail,
                        confirmed: response.data.confirmed,
                        is_discount_provider: response.data.is_discount_provider,
                        is_verified: response.data.is_verified,
                        real_confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        owner_id: response.data.user_id,
                        id: response.data.id,
                        owner: response.data.owner,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        lat:!!response.data.lat ? parseFloat(response.data.lat) : '',
                        lng:!!response.data.lng ? parseFloat(response.data.lng) : '',
                        country:!!response.data.country ? response.data.country : null,
                        state:!!response.data.state ? response.data.state : null,
                        server_country:!!response.data.country ? response.data.country : null,
                        server_state:!!response.data.state ? response.data.state : null,
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                        comments:parseInt(response.data.comments_count),
                        lang:response.data.lang,
                        custom_fields:response.data.custom_fields,
                        allowed_comment:parseInt(response.data.allowed_comment),
                        editMode:true,
                        no_index:response.data.no_index,
                    })
                    let bannerTemp=[];
                    response.data.banners.map(item =>{
                        bannerTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    let videosTemp=[];
                    response.data.videos.map(item =>{
                        videosTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })

                    this.setState({
                        banners: bannerTemp,
                        videos: videosTemp,
                        item:response.data,
                        equal:response.data.equal,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error.response)
                    /*this.props.history.push('/');
                    this.props.enqueueSnackbar(translate('pageNotFound'), {
                        variant: 'error',
                    });*/
                    this.setState({
                        loading:false,
                        editMode:false,
                        id:0
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "year" || target.name === "total_employees"){
            if(parseInt(target.value) > 0){
                fields[target.name] = Math.abs(parseInt(target.value));
            }else{
                fields[target.name] = '';
            }
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
        });
    }

    handleValidation(callback){
        let { fields,country,state,lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //address
        if(fields.address !== null ) {
            if (fields.address.length > 150) {
                formIsValid = false;
                errors['address'] = translate('maxLength150');
            }
        }
        //address
        if(fields.bio !== null ) {
            if (fields.bio.length > 70) {
                formIsValid = false;
                errors['bio'] = translate('maxLength70');
            }
        }
        /*if(!country){
            formIsValid = false;
            errors['country'] = translate('emptyField');
        }
        if(!state){
            formIsValid = false;
            errors['state'] = translate('emptyField');
        }
        */
        if(!lat){
            formIsValid = false;
            errors['geocode'] = translate('pleaseChooseYourLocation');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,no_index,allowed_comment,custom_fields,editMode,related_user,standards,confirmed,is_verified,is_discount_provider,lat,lng,country,state,banners,videos,logo,thumbnail} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('no_index', no_index ? 1 : 0);
        data.append('meta_title', fields.meta_title);
        data.append('lang', this.props.locale);
        data.append('id', id ? id : 0);
        data.append('related_user', related_user ? related_user.id : 0);
        data.append('address', fields.address);
        data.append('bio', fields.bio);
        data.append('year', fields.year);
        data.append('total_employees', fields.total_employees);
        data.append('standards', standards.toString());
        data.append('logo', !!logo ? logo.id : 3);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('is_discount_provider',is_discount_provider ? 1 : 0);
        data.append('is_verified',is_verified ? 1 : 0);
        data.append('phone', fields.phone);
        data.append('lat', lat);
        data.append('lng', lng);
        data.append('country_id', !!country ? country.id : '');
        data.append('state_id', !!state ? state.id : '' );
        data.append('allowed_comment', parseInt(allowed_comment) );
        data.append('custom_fields', JSON.stringify(custom_fields));
        if(banners !== null){
            let banners_ids = [];
            banners.map((row) =>{
                banners_ids.push(row.id);
                return true
            })
            banners = JSON.stringify(banners_ids);
        }
        data.append('banners', banners);

        if(videos !== null){
            let videos_ids = [];
            videos.map((row) =>{
                videos_ids.push(row.id);
                return true
            })
            videos = JSON.stringify(videos_ids);
        }
        data.append('videos', videos);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/business/make`, data, config)
            .then(async response => {
                this.props.enqueueSnackbar(editMode ? translate('businessEdited') : translate('businessAdded'), {
                    variant: 'success',
                });
                await this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                await updateRedux();
                if(!!user.role.permission.manageAdvertisements){
                    this.props.history.push(`/manage/businesses`);
                }else{
                    await this.loadData();
                    await this.props.history.push(`/business`);
                }
                /*if(!this.state.editMode){
                    if(!!user.role.permission.manageAdvertisements){
                        this.props.history.push(`/manage/businesses`);
                    }else{
                        await this.loadData();
                        await this.props.history.push(`/business`);
                    }
                }else{
                    await this.loadData();
                }*/
            })
            .catch(error => {
                console.log(error.response)
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('multipleBusinessAddingFailed'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('businessEditingFailed') : translate('businessAddingFailed'), {
                        variant: 'error',
                    });
                }


                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleBannersChange(data){
        this.setState({
            banners: data,
        })
    }
    handleVideosChange(data){
        this.setState({
            videos: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeDiscountProvider(){
        this.setState({
            is_discount_provider: !this.state.is_discount_provider,
        })
    }
    handleChangeVerified(){
        this.setState({
            is_verified: !this.state.is_verified,
        })
    }

    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone.replace("+0","+98");
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    async handleAddChip(item){
        let {standards} = this.state;
        if(standards.length < 3){
            standards.push(item);
            this.setState({
                standards
            })
        }else{
            this.setState({
                standards
            })
        }

    }
    async handleDeleteChip(chip, index){
        let {standards} = this.state;
        standards.splice(index, 1);
        this.setState({
            standards
        })
        this.setState({
            standards
        })
    }
    handleDeleteBusiness(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/business`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteBusiness'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/businesses')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteBusinessFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
        let {fields,item,equal,country,loading,server_country,server_state,errors,slug,SubmitLoading,owner,logo,thumbnail,banners,videos,editMode,real_confirmed,confirmed,is_verified,is_discount_provider,lat,lng} = this.state;
        const {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editBusiness') :  translate('makeBusiness')}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item  xs={12} lg={11}>
                                <BreadCrumbs singleLevel firstText={editMode ? translate('editBusiness') :  translate('makeBusiness')}/>
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justify="center">
                                    {
                                        (user.role.type !== "admin" && !loading) &&
                                        <CheckUserCanAdBusiness edit={!!editMode}/>
                                    }

                                    {
                                        (!!editMode && !loading) &&
                                        <>
                                            <Grid item xs={12}>
                                                {
                                                    !! user.role.permission.manageAdvertisements ?
                                                        <>
                                                            <Alert
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={confirmed}
                                                                                onChange={this.handleChangeConfirmed.bind(this)}
                                                                                value={confirmed}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('confirmationStatusDescription')}
                                                            </Alert>
                                                            <Alert
                                                                style={{marginTop:15}}
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={is_verified}
                                                                                onChange={this.handleChangeVerified.bind(this)}
                                                                                value={is_verified}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={is_verified ? translate('yes') : translate('no')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('isVerifiedDescription')}
                                                            </Alert>
                                                            <Alert
                                                                style={{marginTop:15}}
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={is_discount_provider}
                                                                                onChange={this.handleChangeDiscountProvider.bind(this)}
                                                                                value={is_discount_provider}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={is_discount_provider ? translate('yes') : translate('no')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('isDiscountProviderDescription')}
                                                            </Alert>
                                                        </>
                                                        :
                                                        confirmed ?
                                                            <Alert severity="success" variant="filled">
                                                                <Typography  className={this.classes.alertText}>{translate('yourBusinessIsActive')}</Typography>
                                                            </Alert>
                                                            :
                                                            <Alert severity="warning">
                                                                <Typography className={this.classes.alertText}>{translate('yourBusinessIsInchecking')}</Typography>
                                                            </Alert>
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <AppCard style={{height:"100%"}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            {
                                                                !!real_confirmed ?
                                                                    <>
                                                                        <Button
                                                                            component="a"
                                                                            target="_blank"
                                                                            size="small"
                                                                            startIcon={<PageviewRoundedIcon/>}
                                                                            href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                            color="primary"
                                                                        >
                                                                            {translate('viewBusiness')}
                                                                        </Button>
                                                                    </> :
                                                                    <>
                                                                        <Button
                                                                            component="a"
                                                                            target="_blank"
                                                                            size="small"
                                                                            variant="contained"
                                                                            startIcon={<PageviewRoundedIcon/>}
                                                                            href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                            color="primary"
                                                                        >
                                                                            {translate('viewPreview')}
                                                                        </Button>

                                                                    </>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('todayVisitsCount')}</span>
                                                                <strong>
                                                                    {this.state.today_visits}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('totalVisitsCount')}</span>
                                                                <strong>
                                                                    {this.state.visits}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('totalCommentsCount')} <ViewRelatedComments type="business" item_id={item.id}/></span>
                                                                <strong>
                                                                    {this.state.comments}
                                                                </strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <AppCard style={{height:"100%"}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left" style={{position:"relative"}}>
                                                                {translate('pageInfo')}

                                                                <div style={{position:"absolute",top:0,right:0,display:"flex",alignItems:"center",fontSize:12}}>
                                                                        <StarRateRoundedIcon style={{marginRight:2}} color="primary"/>
                                                                        <strong className={this.classes.primaryColor} style={{marginRight:2,fontSize:14}}>{item.average_rating} </strong> / 5
                                                                </div>
                                                            </SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography  className='date-holder'>
                                                                <span>
                                                                {translate('updated_at')}
                                                            </span>
                                                                <strong>
                                                                    {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                </strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                        </>

                                    }
                                    {
                                        (user.role.type === "admin" && !loading) &&
                                        <Grid item xs={12}>
                                            <Alert
                                                severity="info"
                                                action={
                                                    <FormControlLabel
                                                        style={{marginRight:10}}
                                                        control={
                                                            <Switch
                                                                checked={this.state.allowed_comment}
                                                                onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                                value={this.state.allowed_comment}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                    />
                                                }
                                            >
                                                {translate('allowed_comments')}
                                            </Alert>
                                        </Grid>
                                    }
                                    {
                                        !!editMode &&
                                        <>
                                            {
                                                this.state.loading ? null :
                                                <Grid item xs={12}>
                                                    <AppCard
                                                        className={this.classes.equalAppCard}
                                                    >
                                                        <div
                                                            className={this.classes.equalHolder}
                                                        >
                                                            <SubTitle align="left">{translate('equalItem')}</SubTitle>
                                                            <MakeEqualBusiness
                                                                equal_id={!!equal ? equal.id : null}
                                                                item={item}
                                                                reload={() => this.loadData()}
                                                            />
                                                        </div>
                                                    </AppCard>
                                                </Grid>
                                            }
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <AppCard
                                            title={editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                            >
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} justifyContent="center"  style={{marginBottom:10}}>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                required
                                                                fullWidth
                                                                name="name"
                                                                disabled={user.role.type !=="admin" && !!confirmed}
                                                                variant="outlined"
                                                                label={translate('businessName')}
                                                                type="text"
                                                                id="name"
                                                                className={this.classes.inputCtl}
                                                                value={fields.name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['name']}
                                                                helperText={errors['name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="bio"
                                                                variant="outlined"
                                                                label={translate('businessBio')}
                                                                type="text"
                                                                className={this.classes.inputCtl}
                                                                value={fields.bio}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['bio']}
                                                                helperText={errors['bio']}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            <FieldCounterWithoutBar length={fields.bio.length} max={70}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="year"
                                                                margin="normal"
                                                                variant="outlined"
                                                                label={translate('foundingYear')}
                                                                type="number"
                                                                disabled={user.role.type !=="admin" && !!confirmed}
                                                                value={fields.year}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['year']}
                                                                helperText={errors['year']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                margin="normal"
                                                                name="total_employees"
                                                                variant="outlined"
                                                                label={translate('total_employees')}
                                                                type="number"
                                                                value={fields.total_employees}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['total_employees']}
                                                                helperText={errors['total_employees']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <ChipInput
                                                                value={this.state.standards}
                                                                fullWidth
                                                                disabled={user.role.type !=="admin" && !!confirmed}
                                                                label={translate('standards')}
                                                                placeholder={translate('standardsPlaceHolder')}
                                                                variant={"outlined"}
                                                                onAdd={(chip) => this.handleAddChip(chip)}
                                                                onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                            />
                                                            <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                                {translate('pressEnterToMakeEachOption')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <MuiPhoneNumber
                                                                fullWidth
                                                                label={translate('phoneNumber')}
                                                                name="phone"
                                                                dir="ltr"
                                                                disabled={user.role.type !=="admin" && !!confirmed}
                                                                variant="outlined"
                                                                onChange={(phone) => this.handleChangePhone(phone,"phone")}
                                                                disableAreaCodes
                                                                defaultCountry={'ae'}
                                                                value={fields.phone}
                                                                error = {!!errors['phone']}
                                                                helperText={errors['phone']}
                                                            />

                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="center">{translate('businessLogo')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SingleImageChooser
                                                                primary
                                                                logo
                                                                onChange={(logo) => this.handleLogoImage(logo)}
                                                                initial={logo}
                                                                disabled={user.role.type !=="admin" && !!confirmed}

                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MetaDescription width={800} height={800} size={"10MB"}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('businessBanners')}</SubTitle>
                                                        </Grid>
                                                        {
                                                            !hasAccess('has_banner') ?
                                                            <Grid item xs={12}>
                                                                <YourPlanDoesntSupportThis visible={!hasAccess('has_banner')}/>
                                                            </Grid> :
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <ImageGallery
                                                                            type={"image"}
                                                                            onChange={this.handleBannersChange.bind(this)}
                                                                            initial={banners}
                                                                            defined_width={1000}
                                                                            defined_height={500}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <MetaDescription width={1000} height={500} size={"10MB"}/>
                                                                    </Grid>
                                                                </>
                                                        }

                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="center">{translate('primaryBusinessImage')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SingleImageChooser
                                                                primary
                                                                onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                                initial={thumbnail}
                                                            />
                                                            <Typography style={{marginTop:10,fontSize:12,textAlign:"center"}}>
                                                                {translate('business_thumbnail_desc')}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MetaDescription width={800} height={600} size={"10MB"}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('businessVideos')}</SubTitle>
                                                        </Grid>
                                                        {
                                                            !hasAccess('has_video') ?
                                                                <Grid item xs={12}>
                                                                    <YourPlanDoesntSupportThis visible={!hasAccess('has_video')}/>
                                                                </Grid> :
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <ImageGallery type="video" onChange={this.handleVideosChange.bind(this)} initial={videos}/>
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <MetaDescription width={800} height={600} video_size={"100MB"}/>
                                                                    </Grid>
                                                                </>
                                                        }

                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align='left'>
                                                                {translate('addressAndLocation')}
                                                            </SubTitle>
                                                        </Grid>
                                                        {
                                                            !hasAccess('has_contact') ?
                                                                <Grid item xs={12}>
                                                                    <YourPlanDoesntSupportThis visible={!hasAccess('has_contact')}/>
                                                                </Grid> :
                                                                <>
                                                                    {
                                                                        !!server_country ?
                                                                            <>
                                                                                <Grid item xs={12}>
                                                                                    <Typography>
                                                                                        {server_country.name} {!!server_state ? "/ "+server_state.name : ""}
                                                                                    </Typography>
                                                                                </Grid>
                                                                            </>
                                                                            :
                                                                            <>
                                                                                <Grid item xs={12} md={6}>
                                                                                    <CountryChooser
                                                                                        error={errors['country']}
                                                                                        handleCountry={(country) => this.setState({country})}
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={12} md={6}>
                                                                                    <StateChooser
                                                                                        country={country}
                                                                                        error={errors['state']}
                                                                                        handleState={(state) => this.setState({state})}
                                                                                    />
                                                                                </Grid>
                                                                            </>
                                                                    }
                                                                    <Grid item xs={12}>
                                                                        <SelectLocationFromMap
                                                                            disabled={user.role.type !=="admin" && !!confirmed}
                                                                            init_geocode={(!!lat && !!lng) ? {lat:lat,lng:lng} : ""}
                                                                            geocode={(lat,lng) => this.setState({lat,lng})}
                                                                            setAddress={(address) => this.handleAddress(address)}
                                                                            country={country}
                                                                            state={this.state.state}
                                                                        />
                                                                        {
                                                                            !!errors["geocode"] &&
                                                                            <Alert severity="error">
                                                                                <Typography className={this.classes.alertText}>
                                                                                    {errors["geocode"]}
                                                                                </Typography>
                                                                            </Alert>
                                                                        }
                                                                    </Grid>
                                                                    <Grid item xs={12}>
                                                                        <TextField
                                                                            variant="outlined"
                                                                            multiline
                                                                            fullWidth
                                                                            disabled={user.role.type !=="admin" && !!confirmed}
                                                                            name="address"
                                                                            label={translate('address')}
                                                                            type="text"
                                                                            minRows="2"
                                                                            value={fields.address}
                                                                            onChange={this.handleChange.bind(this)}
                                                                            error={!!errors['address']}
                                                                            helperText={errors['address']}
                                                                            InputProps={{
                                                                                endAdornment: (
                                                                                    <InputAdornment position="end">
                                                                                        <FieldCounterWithoutBar length={fields.address.length} max={150}/>
                                                                                    </InputAdornment>
                                                                                ),
                                                                            }}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                        }
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard style={{height:'100%'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('aboutBusiness')}</SubTitle>
                                                </Grid>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <Grid item xs={12}>
                                                            {
                                                                !hasAccess('has_business_about') ?
                                                                    <YourPlanDoesntSupportThis visible={!hasAccess('has_business_about')}/>
                                                                    :
                                                                    <CustomFields
                                                                        type={"business"}
                                                                        position_type="business_custom_fields"
                                                                        input={this.state.custom_fields}
                                                                        handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                                    />
                                                            }

                                                        </Grid>
                                                }
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                    {
                                        (!!user.role.permission.manageShops) &&
                                        <Grid item xs={12}>
                                            <AppCard style={{height:'100%'}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                                label={translate('makePageNoIndex')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            margin="normal"
                                                            name="meta_title"
                                                            variant="outlined"
                                                            label={translate('meta_title')}
                                                            value={fields.meta_title}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['meta_title']}
                                                            helperText={errors['meta_title']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                    }

                                    {
                                        (!! user.role.permission.manageShops && !!editMode && !this.state.loading) &&
                                        <Grid item xs={12}>
                                            <UserCard title={translate('creator')} id={owner.id}/>
                                        </Grid>
                                    }
                                    {
                                        (!!user.role.permission.manageShops  && !editMode) &&
                                        <Grid item xs={12}>
                                            <AppCard style={{height:'100%'}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('relatedUser')}</SubTitle>
                                                    </Grid>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid item xs={12}>
                                                                <BusinessUserChooser
                                                                    outPut={(res) => this.setState({
                                                                        related_user:res
                                                                    })
                                                                    }
                                                                />
                                                            </Grid>
                                                    }
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            style={{minWidth:200}}
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                        </LoadingButton>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            !!editMode ?
                                                !!user.role.permission.manageAdvertisements &&
                                                <GetConfirmationText
                                                    variant="outlined"
                                                    style={{color: "#f44336",backgroundColor:"#fff"}}
                                                    tooltip={translate('removeBusiness')}
                                                    handleAction={() => this.handleDeleteBusiness()}
                                                >
                                                    {translate('removeBusiness')}
                                                </GetConfirmationText>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeBusiness))));