import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, getStore, setStore} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {updateRedux} from "../../../../config/Security";
import Typography from "@material-ui/core/Typography/Typography";
import {makeSlug, translate} from "../../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import CountryChooser from "../../../components/fields/CountryChooser";
import StateChooser from "../../../components/fields/StateChooser";
import SelectLocationFromMap from "../../../components/map/SelectLocationFromMap";
import ParentCategoryChooser from "../../admin/categories/components/ParentCategoryChooser";
import moment from "moment";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import SingleImageChooser from "../../fileManager/Components/SingleImageChooser";
import SubTitleLight from "../../../components/elements/SubTitleLight";
import Button from "@material-ui/core/Button/Button";
import SingleFileChooserNew from "../../fileManager/Components/SingleFileChooserNew";
class details extends React.Component {
    classes = this.props.classes
    state={
        fields: {
        },
        avatar:null,
        logo:null,
        trade_license:null,
        emirates_card:null,
        lock:true,
        errors:{},
        loading:true,
        SubmitLoading:false,
        confirmed:'',
        is_verified: false,
        checked: false,
        is_discount_provider: false,
        mobile_confirmed:false,
        melli_card:null,
        faults:'',
        current_user:null,
        no_license:false,

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    fields:{

                    },
                    loading:false,
                    logo: response.data.logo,
                    faults: response.data.faults,
                    emirates_card: response.data.emirates_card,
                    trade_license: response.data.trade_license,
                    is_discount_provider: response.data.is_discount_provider,
                    is_verified: response.data.is_verified,
                    current_user: response.data,
                    no_license: response.data.no_license,

                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
        });
    }
    handleValidation(callback){
        let { fields,country,trade_license,emirates_card,logo,checked,current_user } = this.state;
        let {user} = this.props;
        let errors = {};
        let formIsValid = true;
        if(!trade_license){
            formIsValid = false;
            errors['trade_license'] = translate('pleaseChooseAFile');
        }
        if(!emirates_card){
            formIsValid = false;
            errors['emirates_card'] = translate('pleaseChooseAFile');
        }
        if(!logo && current_user.personality === "company"){
            formIsValid = false;
            errors['logo'] = translate('pleaseChooseAFile');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
            console.log(errors);
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {logo,emirates_card,trade_license,no_license} = this.state;
        let data = new FormData();

        data.append('logo', !!logo ? logo.id : '');
        data.append('emirates_card', !!emirates_card ? emirates_card.id : '');
        data.append('trade_license', !!trade_license ? trade_license.id : '');
        data.append('no_license', !!no_license ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user_business/set_file/${manage ? user_id : this.props.user.id}`, data, config)
            .then(async response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                await  updateRedux();
                await this.props.reload();
                await this.loadData();
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 422){
                    this.props.enqueueSnackbar(translate('anotherUserHasThisInfo'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:true,
                    })
                    this.loadData();
                }else{
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin/${getStore('locale')}`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: this.state.confirmed === "confirmed" ? "pending" : "confirmed",
        })
    }
    loginToUserAccount(){
        let {mobile_confirmed} = this.state;
        if(!!mobile_confirmed){
            this.setState({
                loading:true
            })
            let loginToken = localStorage.getItem('ACT');
            let {user_id} = this.props;
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/loginToUserAccount/${user_id}`, config)
                .then(async response => {
                    await localStorage.setItem('ADMIN_ACT',localStorage.getItem('ACT'));
                    await localStorage.setItem('ACT',response.data.accessToken);
                    await updateRedux();
                    let user = await getStore('user')
                    let username = user.email;
                    let login_token = user.login_token;
                    await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${login_token}`);
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.props.enqueueSnackbar(translate('userShouldConfirmMobileFirst'), {
                variant: 'error',
            });
        }

    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        fields.mobile = mobile.replace("+0","+98");
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    handleChangePhone(phone){
        let {fields,errors} = this.state;
        fields.phone = phone.replace("+0","+98");
        errors.phone = '';
        this.setState({
            fields,
            errors
        })
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleLogoEmiratesCard(emirates_card){
        this.setState({
            emirates_card
        })
    }
    handleTradeLicense(trade_license){
        this.setState({
            trade_license
        })
    }
    handleChangeDiscountProvider(){
        this.setState({
            is_discount_provider: !this.state.is_discount_provider,
        })
    }
    handleChangeVerified(){
        this.setState({
            is_verified: !this.state.is_verified,
        })
    }
    handleChangeCat(category){
        this.setState({
            category
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })

    }
    render() {
        const {errors,fields,lock,SubmitLoading,no_license,current_user,logo,trade_license,loading,emirates_card} = this.state;
        let {purchase,user,manage,user_id,primary_color} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('step3')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard title={translate('step3')}>
                            {
                                loading ? <AppCardLoaderFields/> :
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} md={11} lg={10}>
                                            <Grid container  spacing={2} justifyContent="center" alignItems="flex-start">
                                                <CssBaseline/>
                                                {
                                                    !!this.state.faults &&
                                                    <Grid item xs={12}>
                                                        <Typography className={"faults small"}>
                                                            {this.state.faults}
                                                        </Typography>
                                                    </Grid>
                                                }
                                                <Grid item xs={12} md={4}>
                                                    <SubTitleLight required={current_user.personality === "company"}> {translate('businessLogo')}</SubTitleLight>
                                                    <SingleImageChooser
                                                        primary
                                                        logo
                                                        onChange={(logo) => this.handleLogoImage(logo)}
                                                        initial={logo}
                                                    />
                                                    {
                                                        !!errors['logo'] &&
                                                        <Typography className="error-text">{errors['logo']}</Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={4}>
                                                    <SubTitleLight required> {translate('emiratesCard')}</SubTitleLight>
                                                    <SingleImageChooser
                                                        primary
                                                        logo
                                                        onChange={(emirates_card) => this.handleLogoEmiratesCard(emirates_card)}
                                                        initial={emirates_card}
                                                    />
                                                    {
                                                        !!errors['emirates_card'] &&
                                                        <Typography className="error-text">{errors['emirates_card']}</Typography>
                                                    }
                                                </Grid>
                                                <Grid item xs={12} md={4} style={{textAlign:"center"}}>
                                                    <SubTitleLight required> {translate(current_user.personality === "company" ? 'tradeLicense' : 'tradeLicenseContract')}</SubTitleLight>
                                                    {
                                                        !!no_license ?
                                                            <SingleFileChooserNew
                                                                primary
                                                                onChange={(trade_license) => this.handleTradeLicense(trade_license)}
                                                                initial={trade_license}
                                                            />
                                                            :
                                                            <SingleImageChooser
                                                                primary
                                                                logo
                                                                onChange={(trade_license) => this.handleTradeLicense(trade_license)}
                                                                initial={trade_license}
                                                            />
                                                    }
                                                    {
                                                        !!errors['trade_license'] &&
                                                        <Typography className="error-text">{errors['trade_license']}</Typography>
                                                    }
                                                    {
                                                        current_user.personality === "person" &&
                                                        <FormControl>
                                                            <FormControlLabel
                                                                control={<Checkbox checked={no_license} onChange={() => this.setState({no_license:!no_license,trade_license:null})} />}
                                                                label={
                                                                    <Typography className={this.classes.privacy_policy}>
                                                                        {translate('no_license')}
                                                                    </Typography>
                                                                }
                                                            />
                                                        </FormControl>
                                                    }

                                                </Grid>
                                                <Grid item xs={12}>
                                                    {
                                                        !!no_license &&
                                                        <Alert
                                                            severity="info"
                                                            action={
                                                                <Button
                                                                    component="a"
                                                                    href="https://dl.phil.ae/b-contract.pdf"
                                                                    color="secondary"
                                                                    size="small"
                                                                    variant="contained"
                                                                    target="_blank"
                                                                >
                                                                    {translate('downloadContract')}
                                                                </Button>
                                                            }
                                                        >
                                                            {translate('no_license_desc_1')}
                                                            <br/>
                                                            {translate('no_license_desc_2')}
                                                        </Alert>
                                                    }
                                                </Grid>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {translate('saveAndContinue')}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));