import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../config/Translate";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Divider from "@material-ui/core/Divider/Divider";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ModifySinglePricing from "../single_pricing/ModifySinglePricing";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

class ModifyPriceButton extends React.Component{
    state = {
        open: false,
        id:this.props.id,
        run:false,
    };
    componentDidMount(){
        this.setState({
            id:this.props.id,
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.id){
            this.setState({
                id: this.props.id
            })
        }
    }
    async toggleOpen(){
        await this.setState({
            open: !this.state.open
        })
        if(this.state.open === true){
            this.setState({
                run:true
            })
        }else{
            this.setState({
                run:false
            })
        }
    }
    async handleReload(){
        await this.toggleOpen();
        await this.props.reload();
    }
    render(){
        let {open,id,run} = this.state;
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.toggleOpen()}
                    endIcon={<ChevronLeftRoundedIcon/>}
                    size={"small"}
                    style={{marginBottom:5,marginRight:5}}
                >
                    {Translate.edit}
                </Button>
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">
                        {Translate.modifyPricing}
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                    </DialogTitle>
                    <Divider/>
                    <DialogContent>
                        {
                            run &&
                            <ModifySinglePricing
                                modal
                                id={id}
                                reload={() => this.handleReload()}
                                manage={this.props.manage}
                            />
                        }
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
export default ModifyPriceButton;