import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import LoadingButton from "../../../components/elements/LoadingButton";
import {translate} from "../../../../config/lang";
import {WhatsApp} from "@material-ui/icons";
import Typography from "@material-ui/core/Typography";
import {Link} from "react-router-dom";
class DiscountReserveButton extends React.Component{
    classes = this.props.classes
    state = {
        fields:{
            subject:"",
            message:translate('reserveRequestText'),
            priority:"medium",
            department:"",
            order_item:""
        },
        type:"system",
        SubmitLoading:false,
        departments:[],
        orderItems:[],
        loading:true,
        open:false,
        errors:{},
        anchorEl:null,
        has_reserve:false,
        ticket_id:0,
        item:this.props.item,
    };
    handleClickMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null
        })
    };

    componentDidMount(){
        this.loadData()
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/check-has-reserve/${this.state.item.id}`, config)
            .then(response => {
                this.setState({
                    ticket_id: response.data,
                    has_reserve: response.data > 0,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    toggleOpen(type){
        this.setState({
            open: !this.state.open,
            type:type
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,type } = this.state;
        let errors = {};
        let formIsValid = true;


        if(!fields.message) {
            formIsValid = false;
            errors['message'] = translate('message');
        }


        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,item} = this.state;
        let data = new FormData();
        data.append('lang', this.props.locale);
        data.append('message', fields.message);
        data.append('type', "reserve");
        data.append('factor_item_id', item.id);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/ticket/add-reserve`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('ticket_sent'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false,
                    open:false
                });
                this.loadData();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    render(){
        let {item,SubmitLoading,has_reserve,errors,fields,open,ticket_id,orderItems,anchorEl} = this.state;
        let {user} = this.props;
        return (
            <>
                {
                    !!has_reserve ?
                        <Button
                            className="discount_reserve_btn"
                            variant="contained"
                            color="secondary"
                            component={Link}
                            to={`/ticket/${ticket_id}`}
                            size={"small"}
                        >
                            {translate('viewReserveRequest')}
                        </Button>
                        :

                        <Button
                            className="discount_reserve_btn"
                            variant="contained"
                            color="secondary"
                            onClick={() => this.toggleOpen('system')}
                            size={"small"}
                        >
                            {translate('reserveDiscount')}
                        </Button>
                }


                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",right:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        <Button
                            variant="contained"
                            size={"small"}
                            color="primary"
                            component="a"
                            href={this.props.setting.reserve_whatsapp_link}
                            target="_blank"
                            style={{backgroundColor:"#25D366",position:"absolute",right:45,top:12}}
                            startIcon={<WhatsApp/>}
                        >
                            {translate('sendMessageInWhatsapp')}
                        </Button>
                        {translate('reserveDiscount')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography>
                                    {item.discount.reserve_description}
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={fields.message}
                                    fullWidth
                                    label={translate('message')}
                                    name="message"
                                    className={this.classes.inputCtl}
                                    minRows={5}
                                    multiline
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['message']}
                                    helperText={errors['message']}
                                />
                            </Grid>
                            <Grid item xs={12} style={{display:"flex",alignItems:"center",justifyContent:"center",position:"relative"}}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {translate('reserveRequest')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
    setting: state.setting,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(DiscountReserveButton)))));