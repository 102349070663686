import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import {connect} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {currencySymbol, price} from "../../../../config/values";
import Link from "@material-ui/core/Link/Link";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {makeSlug, translate} from "../../../../config/lang";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import axios from "axios";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
class SingleCartItem extends React.Component {

    state = {
        cart:this.props.cart,
        action_loading:false,
    };
    classes = this.props.classes;
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.state.cart !== this.props.cart){
            this.setState({
                cart:this.props.cart,
            })
        }
    }

    handleAction(action){
        this.setState({
            action_loading:true,
        })
        let {cart} = this.state;
        let data = new FormData();
        data.append('action',action);
        data.append('id',cart.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/cart/action`, data, config)
            .then(async response => {
                await this.props.reload();
                await this.setState({
                    action_loading:false,
                })
                if(response.data === "max_limit"){
                    this.props.enqueueSnackbar(translate('youCantAddToCart'), {
                        variant: 'warning',
                    });
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });
    }
    render(){
        let {discount} = this.state.cart;
        let {cart,action_loading} = this.state;

        return (
            <div className={this.classes.singleCartHolder}>
                <div className={this.classes.singleCartInner}>
                    <div style={{display:"flex",alignItems:"center"}}>
                        <IconButton
                            style={{marginRight:10}}
                            size="small"
                            disabled={action_loading}
                            onClick={() => this.handleAction('delete')}
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        <Link href={makeSlug(`discount/${discount.slug}`)} target="_blank">
                            <img
                                src={`${defaultVariabls.uploads}/FileManager/${discount.thumbnail.name}_small.${discount.thumbnail.extension}`}
                                alt="img"
                                className="single-order-items-img"
                            />
                        </Link>
                        <div  className="single_order_items">
                            <Typography style={{marginBottom:10}}>
                                <IconButton size="small"><FeaturedPlayListOutlinedIcon/></IconButton><strong>{discount.name}</strong></Typography>
                            <Typography  style={{marginBottom:10}}><IconButton size="small"><MonetizationOnOutlinedIcon/></IconButton><del style={{color:"#f82e4e"}}>{price(discount.price*cart.quantity)}</del> <strong>{price(discount.discount_price*cart.quantity)+" "+discount.currency}</strong>  </Typography>
                        </div>
                    </div>
                    <div className={this.classes.singleCartControllers}>
                        <IconButton
                            size="small"
                            disabled={action_loading}
                            onClick={() => this.handleAction('plus')}
                        >
                            <AddRoundedIcon/>
                        </IconButton>
                        <span style={{color:this.props.primary_color,padding:10}}>
                            {
                                action_loading ? <CircularProgress size={18}/> : cart.quantity
                            }
                        </span>
                        <IconButton
                            size="small"
                            disabled={action_loading}
                            onClick={() => this.handleAction('minus')}
                        >
                            <RemoveRoundedIcon/>
                        </IconButton>
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
    global_need_verification: state.global_need_verification
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleCartItem))));