import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import {setStore} from "trim-redux";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemAvatar from "@material-ui/core/ListItemAvatar/ListItemAvatar";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import List from "@material-ui/core/List/List";
import Avatar from "@material-ui/core/Avatar/Avatar";
import SubTitle from "../../../../components/elements/SubTitle";
import AddBrandButton from "./AddBrandButton";

class BrandChooser extends React.Component {
    classes = this.props.classes;
    state={
        brands:[],
        brand:this.props.initial,
        loading: true,
    }
    componentDidMount(){
        this.setState({
            brand:this.props.initial
        })
        this.loadData()
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/brands/getAllForChooser`, config)
                .then(response => {
                    this.setState({
                        brands: response.data,
                        loading:false,
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChangeBrand(item){
        let {brand} = this.state;
        if(brand !== item.id){
            this.setState({
                brand:item.id
            });
            this.props.changeBrand(item.id);

        }else{
            this.setState({
                brand:null
            });
            this.props.changeBrand(null);

        }

    }
    render(){
        let {brands,loading,brand} = this.state;
        let {title} = this.props;
        return(
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubTitle align="left">{title}</SubTitle>
                </Grid>
                <Grid item xs={12}>
                    {
                        loading ? null :
                            <List dense style={{maxHeight:340,overflowY:'auto'}}>
                                {
                                    !!brands &&
                                    brands.map((item,index) => (
                                        <ListItem
                                            key={index}
                                            button
                                            onClick={() => this.handleChangeBrand(item)}
                                        >
                                            <ListItemAvatar>
                                                <Avatar
                                                    alt={item.name}
                                                    src={`${defaultVariabls.uploads}/FileManager/${item.logo.name}_small.${item.logo.extension}`}
                                                />
                                            </ListItemAvatar>
                                            <ListItemText primary={item.name} />
                                            <ListItemSecondaryAction>
                                                <Checkbox
                                                    edge="end"
                                                    onChange={() => this.handleChangeBrand(item)}
                                                    checked={brand === item.id}
                                                />
                                            </ListItemSecondaryAction>
                                        </ListItem>
                                    ))
                                }

                            </List>

                    }
                </Grid>
                <Grid item xs={12}>
                    <AddBrandButton reload={() => this.loadData()}/>
                </Grid>
            </Grid>
        )

    }
}
export default withStyles(Styles)(BrandChooser);