import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {getDataTableLocalization} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect, getStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import Button from "@material-ui/core/Button/Button";
import {Link, withRouter} from 'react-router-dom';
import AddBox from "@material-ui/icons/AddBox";
import { withSnackbar } from 'notistack';
import {rolesWithName, RWN, TableCount, TablePage} from "../../../../../config/Permissions";
import moment from "moment-timezone";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../../../config/lang";
import ChevronNextRoundIcon from "../../../../components/elements/ChevronNextRoundIcon";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SubTitle from "../../../../components/elements/SubTitle";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import {getTimeZone} from "../../../../../config/values";


class Users extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    role = this.props.match.params.role;
    state = {
        columns: [
            { title: translate('referral_code'), field: 'referral_code', editable: 'never' },
            {
                title: translate('userName'),
                field: 'name',
                editable: 'never',
                render: rowData => <Typography className={this.classes.dateTable}>{rowData.name} <br/> ({rowData.invite_code})</Typography>
            },
            { title: translate('plan'),
                field: 'plan',
                editable: 'never',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {
                            !!rowData.plan &&
                            <img alt="avatar" src={`${defaultVariabls.uploads}/FileManager/${rowData.plan.parent.thumbnail.name}.${rowData.plan.parent.thumbnail.extension}`} style={{width:30}}/>
                        }
                        <br/>
                        {
                            (!!rowData.plan_usage && !!rowData.plan) ?
                            !!rowData.plan_usage.finishes_at &&
                            <span style={{color:rowData.plan.parent.color}}><strong>{moment.tz(rowData.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong></span> : null
                        }
                    </Typography>
            },
            { title: translate('userType'),
                field: 'step',
                editable: 'never',
                hidden: this.props.verified,
                render:rowData =>
                    <Typography className={this.classes.dateTable}>
                        {
                            rowData.real_role.type === "admin" ?
                                <span>{translate('admin')}</span>
                                :
                                rowData.is_suspended ?
                                    <span style={{color: "#ee0e0e"}}>{translate('suspended')}</span>
                                :
                                rowData.step === 6 ?
                                    <span>{translate('verifiedUser')}</span> :
                                    <span>{translate('normalUser')}</span>
                        }
                    </Typography>
            },
            {
                title: translate('role'),
                field: 'role',
                hidden: this.props.verified,
                lookup: RWN(),
            },
            { title: translate('status'),
                field: 'step',
                editable: 'never',
                lookup: {
                    6 :
                        <Typography className={this.classes.dateTable} style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , 4:
                        <Typography className={this.classes.dateTable}>{translate('pending')}</Typography>
                    , 5:
                        <Typography className={this.classes.dateTable} style={{color: "#ee0e0e"}}>{translate('rejected')}</Typography>
                    , 3:
                        <Typography className={this.classes.dateTable} color="textSecondary">{translate('completingForm')}</Typography>
                },
            },
            {
                title: translate('registerDate'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <>
                        <Button
                            variant="outlined"
                            color="primary"
                            component={Link}
                            to={`/manage/user/${rowData.id}`}
                            endIcon={<ChevronNextRoundIcon/>}
                            size={"small"}
                            style={{margin:5}}
                        >
                            {translate('details')}
                        </Button>
                        <br/>
                        <Button
                            variant="outlined"
                            color="primary"
                            component={Link}
                            to={`/manage/register-user/${rowData.id}`}
                            size={"small"}
                            style={{margin:5}}
                        >
                            {translate('registerInfo')}
                        </Button>
                    </>

            },

        ],
        data: [],
        loading:false,
        role:"all",
        ChangeLoader:false
    };
    componentDidMount(){
        this.loadData();
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            let newRole = this.props.match.params.role;
            await this.setState({
                role:!!newRole ? newRole : "all",
                ChangeLoader:true
            })
            await this.loadData();
        }
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDeleteUser(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/removeUser`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteData'), {
                        variant: 'success',
                    });
                }
                this.loadData();
                return true
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    onEditUser(user) {
        let authUser = getStore().user;
        if(authUser.role.permission.manageSettings){
            let data = new FormData();
            data.append('id', user.id);
            data.append('role', user.role);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/quickEditUser`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(translate('detailsSet'), {
                        variant: 'success',
                    });
                    this.loadData();
                    return true
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    return false
                });
        }else{
            this.props.enqueueSnackbar(translate('youDontHavePermission'), {
                variant: 'error',
            });
            this.loadData();
        }

    }

    async handleChangeRole(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let role = target.value;
        await this.setState({
            role
        });
        this.props.history.push(`/manage/users/view/${role}`)

        this.loadData();
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let {role} = this.state;
        let {verified} = this.props;

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        const data = new FormData();
        data.append('role', role);
        return (
                <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                                <Grid container spacing={2}>
                                    {
                                        !verified &&
                                        <Grid item xs={12}>
                                            <Accordion>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreRoundedIcon/>}
                                                    aria-controls="panel2a-content"
                                                    id="panel2a-header"
                                                >
                                                    <SubTitle align="left" style={{marginBottom: 0}}>
                                                        <IconButton size="small">
                                                            <FilterListRoundedIcon/>
                                                        </IconButton>
                                                        {translate('filters')}
                                                    </SubTitle>
                                                </AccordionSummary>
                                                <AccordionDetails>
                                                    <Grid container spacing={2} alignItems="center" direction="row">
                                                        <Grid item xs={12} md={3}>
                                                            <TextField
                                                                select
                                                                fullWidth
                                                                variant="outlined"
                                                                value={role}
                                                                onChange={this.handleChangeRole.bind(this)}
                                                            >
                                                                {
                                                                    rolesWithName().map((row, index) => (
                                                                        <MenuItem key={index}
                                                                                  value={row.value}>{row.name}</MenuItem>
                                                                    ))
                                                                }
                                                                <MenuItem value='all'>{translate('allRoles')}</MenuItem>
                                                            </TextField>
                                                        </Grid>

                                                    </Grid>
                                                </AccordionDetails>
                                            </Accordion>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <MaterialTable
                                            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                            tableRef={this.tableRef}
                                            icons={tableIcons}
                                            title={
                                                <Link to='/manage/users/add'>
                                                    {
                                                        this.props.width !== "xs" ?
                                                            <Button
                                                                variant="contained"
                                                                color="primary"
                                                                startIcon={<AddBox/>}
                                                            >
                                                                {translate('addUser')}
                                                            </Button>
                                                            :
                                                            <IconButton color="primary">
                                                                <AddBox/>
                                                            </IconButton>
                                                    }
                                                </Link>
                                            }

                                            localization={getDataTableLocalization()}
                                            columns={this.state.columns}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    axios.post(`${defaultVariabls.url}/getUsers/withFilter?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&verified=${!!verified ? 1 : 0}`,data, config)
                                                        .then(response => {
                                                            let data = [];
                                                            // eslint-disable-next-line
                                                            response.data.data.map((row) => {
                                                                data.push({
                                                                    name: row.first_name+" "+row.last_name,
                                                                    email: row.email,
                                                                    is_suspended: row.is_suspended,
                                                                    referral_code: row.referral_code,
                                                                    invite_code: row.invite_code,
                                                                    step: row.step,
                                                                    real_role: row.role,
                                                                    role: !!row.role ? row.role.name : null,
                                                                    url: `${defaultVariabls.uploads}/FileManager/${row.avatar.name}_small.${row.avatar.extension}`,
                                                                    id: row.id,
                                                                    manager: row.manager,
                                                                    plan: row.plan,
                                                                    plan_usage: row.plan_usage,
                                                                    created_at:row.created_at
                                                                })
                                                            });
                                                            resolve({
                                                                data: data,
                                                                page: response.data.current_page - 1,
                                                                totalCount: response.data.total,
                                                            })
                                                        }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                })
                                            }
                                            options={{
                                                pageSize: TablePage,
                                                pageSizeOptions: TableCount,
                                                sorting:false,
                                                draggable:false,
                                            }}

                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            this.onEditUser(newData);
                                                            if (oldData) {
                                                                this.setState(prevState => {
                                                                    const data = [...prevState.data];
                                                                    data[data.indexOf(oldData)] = newData;
                                                                    return { ...prevState, data };
                                                                });
                                                            }
                                                        }, 600);
                                                    }),
                                                onRowDelete: oldData =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            this.onDeleteUser(oldData.id);
                                                            this.setState(prevState => {
                                                                const data = [...prevState.data];
                                                                data.splice(data.indexOf(oldData), 1);
                                                                return { ...prevState, data };
                                                            });
                                                        }, 600);
                                                    }),
                                            }}

                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                    }
                </>
        );
    }
}
const mstp = state => ({
    features: state.features,
    locale: state.locale
});
export default  withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Users)))));

