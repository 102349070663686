import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, getStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Typography from "@material-ui/core/Typography/Typography";
import MakeAttributeModal from "./MakeAttributeModal";

class Variables extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            { title: Translate.attributeName, field: 'name',filterPlaceholder: Translate.search},
            {
                title: Translate.attributeType,
                field: 'type',
                editable: 'never',
                filterPlaceholder: Translate.search,
                render: rowData =>
                    Translate[rowData.type]
            },
            {
                title: Translate.lastUpdate,
                editable: 'never',
                field: 'updated_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.updated_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.updated_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/manage/attribute/${rowData.id}`}
                        endIcon={<ChevronLeftRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {Translate.manageValues}
                    </Button>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/attribute/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.deleteData, {
                        variant: 'success',
                    });
                }
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.loadData();
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    onEdit(rowData) {
        let authUser = getStore().user;
        if(authUser.role.permission.manageShops){
            let data = new FormData();
            data.append('id', rowData.id);
            data.append('name', rowData.name);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/quickEditAttribute`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(Translate.detailsSet, {
                        variant: 'success',
                    });
                    this.loadData();
                    return true
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                        variant: 'error',
                    });
                    return false
                });
        }else{
            this.props.enqueueSnackbar(Translate.youDontHavePermission, {
                variant: 'error',
            });
            this.loadData();
        }

    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={
                                    <MakeAttributeModal reload={() => this.loadData()}/>
                                }

                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/attributes/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        name: row.name,
                                                        id: row.id,
                                                        type: row.type,
                                                        updated_at:row.updated_at
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onEdit(newData);
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Variables)))));