import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {makeSlug, translate} from "../../../config/lang";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import Button from "@material-ui/core/Button/Button";
import SingleVideoChooser from "../fileManager/Components/SingleVideoChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import SingleImageChooser from "../fileManager/Components/SingleImageChooser";
import ImageGallery from "../fileManager/Components/ImageGallery";
import MetaDescription from "../fileManager/Components/MetaDescription";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment-timezone";
import MakeEqualNews from "./MakeEqualNews";
import NewsCategoryHolderPro from "../../components/fields/NewsCategoryHolderPro";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Divider from "@material-ui/core/Divider/Divider";
import {getTimeZone} from "../../../config/values";
import FormControl from "@material-ui/core/FormControl/FormControl";
class MakeNews extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            title:"",
            description:"",
            content_type:"article",
            type:"none",
            source:"",
            source_url:"",
            meta_title:"",
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        should_expire: 0,
        no_index: 0,
        video_id: null,
        errors:{},
        keywords:[],
        categories:[],
        loading: true,
        SubmitLoading: false,
        publish: true,
        is_featured: false,
        id:this.props.match.params.id,
        editMode:false,
        slug:"",
        updated_at:"",
        created_at:"",
        businesses:[],
        gallery: [],
        item:null,
        equal:null,
        visits:0,
        today_visits:0,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            })
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChangePublish(){
        this.setState({
            publish: !this.state.publish,
        })
    }
    handleChangeFeatured(){
        this.setState({
            is_featured: !this.state.is_featured,
        })
    }
    async loadData(){
        await this.setState({
            loading:true,
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/news/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            title: response.data.title,
                            description: !!response.data.description ? response.data.description : '',
                            type: response.data.type,
                            content_type: response.data.content_type,
                            source: !!response.data.source ? response.data.source : '',
                            source_url: !!response.data.source_url ? response.data.source_url : '',
                            meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                        },
                        thumbnail: response.data.thumbnail,
                        video_id: !!response.data.video ? response.data.video.id : 0,
                        slug: response.data.slug,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        publish: !!response.data.publish ? 1 : 0,
                        no_index: !!response.data.no_index ? 1 : 0,
                        should_expire: !!response.data.should_expire ? 1 : 0,
                        businesses: !!response.data.businesses ? response.data.businesses : [],
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                    })
                    let categoriesTemp=[];
                    let keywordsTemp=[];
                    let galleryTemp=[];
                    response.data.gallery.map(item =>{
                        galleryTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    response.data.categories.map(item => {
                        categoriesTemp.push(item.id)
                        return true
                    })
                    response.data.keywords.map(item => {
                        keywordsTemp.push(item.name);
                        return true
                    });
                    this.setState({
                        categories: categoriesTemp,
                        keywords: keywordsTemp,
                        gallery: galleryTemp,
                        equal:response.data.equal,
                        item:response.data,
                        loading: false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //title
        if(validator.isEmpty(fields.title)){
            formIsValid = false;
            errors['title'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    async handleRequest(){
        let { fields,thumbnail,no_index,content,video_id,businesses,gallery,keywords,should_expire,categories,publish,is_featured,id,editMode} = this.state;
        let data = new FormData();
        data.append('title', fields.title);
        data.append('meta_title', fields.meta_title);
        data.append('type', fields.type);
        data.append('content_type', fields.content_type);
        data.append('source', fields.source);
        data.append('source_url', fields.source_url);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('no_index', no_index ? 1 : 0);
        data.append('description', fields.description);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('video_id', video_id);
        data.append('categories', JSON.stringify(categories));
        data.append('keywords', JSON.stringify(keywords));
        data.append('publish', publish ? 1 : 0);
        data.append('should_expire', should_expire ? 1 : 0);
        data.append('is_featured', is_featured ? 1 : 0);
        data.append('lang', this.props.fake_locale);
        let ad_ids = [];
        await businesses.map((ad) => {
            ad_ids.push(ad.id)
        })
        await data.append('businesses',  JSON.stringify(ad_ids));

        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.post(`${defaultVariabls.url}/news/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('contentEdited') : translate('contentAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/news`);

                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('contentEditingFailed') : translate('contentAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        this.setState({categories})
    }
    handleRelated(businesses){
        this.setState({
            businesses
        })
    }
    render(){
        let {fields, errors,SubmitLoading,slug,thumbnail,equal,item,video_id,categories,keywords,content,gallery,editMode,publish,is_featured} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editSingleNews') : translate('makeSingleNews')}>
            {
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={12} md={8} lg={9}>
                                <Grid container spacing={2} justifyContent="center">
                                    {
                                        editMode &&
                                        <Grid item xs={12}>
                                            <Alert
                                                severity="info"
                                                action={
                                                    <FormControlLabel
                                                        style={{marginRight:10}}
                                                        control={
                                                            <Switch
                                                                checked={publish}
                                                                onChange={this.handleChangePublish.bind(this)}
                                                                value={publish}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={publish ? translate('published') : translate('draft')}
                                                    />
                                                }
                                            >
                                                {translate('publishStatus')}
                                            </Alert>

                                        </Grid>
                                    }
                                    {
                                        !!editMode &&
                                        <>
                                            {
                                                this.state.loading ? null :
                                                    <Grid item xs={12}>
                                                        <AppCard
                                                            className={this.classes.equalAppCard}
                                                        >
                                                            <div
                                                                className={this.classes.equalHolder}
                                                            >
                                                                <SubTitle align="left">{translate('equalItem')}</SubTitle>
                                                                <MakeEqualNews
                                                                    equal_id={!!equal ? equal.id : null}
                                                                    item={item}
                                                                    reload={() => this.loadData()}
                                                                />
                                                            </div>
                                                        </AppCard>
                                                    </Grid>
                                            }
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <AppCard
                                        title={editMode ? translate('editSingleNews') : translate('makeSingleNews')}
                                        action={
                                            <BreadCrumbs singleLevel firstText={editMode ? translate('editSingleNews') : translate('makeSingleNews')}/>
                                        }>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2} justify="center" alignItems="center">
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            variant="outlined"
                                                            name="title"
                                                            label={translate('singleNewsTitle')}
                                                            type="text"
                                                            id="title"
                                                            size="small"
                                                            value={fields.title}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['title']}
                                                            helperText={errors['title']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            name="source"
                                                            label={translate('source')}
                                                            type="text"
                                                            size="small"
                                                            value={fields.source}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['source']}
                                                            helperText={errors['source']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            fullWidth
                                                            variant="outlined"
                                                            name="source_url"
                                                            label={translate('source_url')}
                                                            type="text"
                                                            size="small"
                                                            value={fields.source_url}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['source_url']}
                                                            helperText={errors['source_url']}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    checked={this.state.should_expire}
                                                                    onChange={() => this.setState({should_expire: !this.state.should_expire})}
                                                                    name="checkedB"
                                                                    color="primary"
                                                                />
                                                            }
                                                            label={translate('shouldNewsExpire')}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <RichEditor ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            multiline
                                                            fullWidth
                                                            name="description"
                                                            label={translate('seoDescription')}
                                                            placeholder={translate('seoDescription')}
                                                            type="text"
                                                            id="description"
                                                            rows="4"
                                                            value={fields.description}
                                                            onChange={this.handleChange.bind(this)}
                                                            onClick={this.handleClickDescription.bind(this)}
                                                            error = {!!errors['description']}
                                                            helperText={errors['description']}
                                                            InputProps={{
                                                                endAdornment: (
                                                                    <InputAdornment position="end">
                                                                        <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                    </InputAdornment>
                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>

                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('newsGallery')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ImageGallery onChange={this.handleGalleryChange.bind(this)} initial={gallery}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MetaDescription width={800} height={600} size={"10MB"} video_size={"100MB"}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    {/*<Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('keywordsLabel')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Keywords input={keywords}
                                                                      handleChange={(keywords) => this.setState({keywords})}/>
                                                            <Typography
                                                                variant="body2"
                                                                style={{marginTop: 10, fontWeight: 700}}
                                                            >
                                                                {translate('keywordsDescription')}
                                                                <br/>
                                                            </Typography>
                                                            <Typography>
                                                                {translate('pressEnterAfterEachKeyword')}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>*/}
                                    <Grid item xs={12}>
                                        <AppCard style={{height:'100%'}}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12}>
                                                    <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <FormControl component="fieldset">
                                                        <FormControlLabel
                                                            control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                            label={translate('makePageNoIndex')}
                                                        />
                                                    </FormControl>
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        margin="normal"
                                                        name="meta_title"
                                                        variant="outlined"
                                                        label={translate('meta_title')}
                                                        value={fields.meta_title}
                                                        onChange={this.handleChange.bind(this)}
                                                        error={!!errors['meta_title']}
                                                        helperText={errors['meta_title']}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Hidden smDown>
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editSingleNews') : translate('makeSingleNews')}
                                                </LoadingButton>
                                            </Grid>
                                        </Hidden>
                                    </Grid>
                                </Grid>

                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12}>
                                        {
                                            !this.state.loading &&
                                            <>
                                                <Hidden smDown>
                                                    <Grid item xs={12}>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            styleForParent={{marginTop:0}}
                                                            style={{width: "100%"}}
                                                            onClick={this.handleSubmit.bind(this)}
                                                            loading={SubmitLoading}
                                                        >
                                                            {editMode ? translate('editSingleNews') : translate('makeSingleNews')}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Hidden>
                                                {
                                                    !!editMode &&
                                                    <Grid item xs={12} style={{marginTop:0,paddingTop:0}}>
                                                        <AppCard>
                                                            <Button
                                                                component="a"
                                                                target="_blank"
                                                                size="small"
                                                                startIcon={<PageviewRoundedIcon/>}
                                                                href={makeSlug(`news/${slug}`,this.props.locale)}
                                                                color="primary"
                                                            >
                                                                {translate('viewSingleNews')}
                                                            </Button>
                                                            <Typography className='date-holder'>
                                                                    <span>
                                                                        {translate('updated_at')}
                                                                    </span>
                                                                <span>
                                                                        {`${moment.tz(this.state.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                    </span>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('created_at')}</span>
                                                                <span>
                                                                        {`${moment.tz(this.state.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                    </span>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('todayVisitsCount')}</span>
                                                                <strong>
                                                                    {this.state.today_visits}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('totalVisitsCount')}</span>
                                                                <strong>
                                                                    {this.state.visits}
                                                                </strong>
                                                            </Typography>
                                                        </AppCard>

                                                    </Grid>
                                                }
                                            </>
                                        }
                                    </Grid>
                                        <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <SubTitle>{translate('ThumbnailTitle')}</SubTitle>
                                                            <SingleImageChooser
                                                                primary
                                                                onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                                initial={thumbnail}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MetaDescription width={800} height={600} size={"10MB"}/>
                                                        </Grid>
                                                        {/*<Grid item xs={12}>
                                                            <FormControl component="fieldset">
                                                                <FormLabel component="legend">{translate('content_type')}</FormLabel>
                                                                <RadioGroup style={{flexDirection:"row"}} name="content_type" value={fields.content_type} onChange={this.handleChange.bind(this)}>
                                                                    <FormControlLabel value="article" control={<Radio />} label={translate('article')} />
                                                                    <FormControlLabel value="video" control={<Radio />} label={translate('video')} />
                                                                </RadioGroup>
                                                            </FormControl>
                                                        </Grid>*/}
                                                        {
                                                            fields.content_type === "video" &&
                                                            <Grid item xs={12}>
                                                                <SingleVideoChooser
                                                                    initial={video_id}
                                                                    handleVideoChooser={(video_id) => this.setState({video_id})}
                                                                />
                                                            </Grid>
                                                        }
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            this.state.loading ?
                                                <AppCardLoaderFields/>
                                            :
                                                <NewsCategoryHolderPro outPut={this.handleCategoryHolder.bind(this)} inPut={categories}/>
                                        }
                                    </AppCard>
                                </Grid>
                                    <Hidden mdUp>
                                        <Grid item xs={12} style={{paddingTop: 0}}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                style={{width: "100%"}}
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={SubmitLoading}
                                            >
                                                {editMode ? translate('editSingleNews') : translate('makeSingleNews')}
                                            </LoadingButton>
                                        </Grid>
                                    </Hidden>
                            </Grid>
                        </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeNews))));
