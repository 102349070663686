import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {getTimeZone, price, writeOrderType} from "../../../../config/values";
import Button from "@material-ui/core/Button/Button";
import { Link as ReactLink } from "react-router-dom";
import {translate} from "../../../../config/lang";

class UserOrders extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'factor_number',
                editable: 'never',
                filtering: false,
                title: translate('factor_number'),
            },{
                field: 'factor_items',
                editable: 'never',
                filtering: false,
                title: translate('factor_type'),
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {writeOrderType(rowData.factor_items)}
                    </Typography>
            },
            { title: translate('totalCartPrice'),
                field: 'total_price',
                filterPlaceholder: translate('search'),
            },
            { title: translate('status'),
                editable: 'never',
                field: 'status',
                filterPlaceholder: translate('choose'),
                lookup: {
                     "payed":
                        <Typography color="textPrimary">{translate('payed')}</Typography>
                    , "not_payed":
                        <Typography color="textSecondary">{translate('not_payed')}</Typography>
                    , "confirm_payment":
                        <Typography style={{color:"#f82e4e"}}>{translate('confirm_payment')}</Typography>
                },
            },
            { title: translate('created_at_factor'),
                editable: 'never',
                filtering: false,
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            {
                title: translate('activity'),
                field: 'activity',
                render: rowData =>
                    <>
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            component={ReactLink}
                            to={`/order/view/${rowData.id}`}
                        >
                            {translate('view')}
                        </Button>
                        {
                            (rowData.status === "not_payed") &&
                            <Button
                                className="mx-5"
                                onClick={() => this.handleRemoveItem(rowData.id)}
                                size="small"
                                style={{color:"#f82e4e",marginLeft:5}}
                            >
                                {translate('remove')}
                            </Button>
                        }
                    </>
            }
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRemoveItem(id){
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/factor/remove`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('dataRemoved'), {
                    variant: 'success',
                });
                this.loadData();
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('dataRemovingFailed'), {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl" className={!!this.props.widget ? "thin-table" : null}>
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/my-factors/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        total_price: price(row.total_price) +" "+row.currency,
                                                        id: row.id,
                                                        factor_items: row.factor_items,
                                                        factor_number: row.number,
                                                        status: row.status,
                                                        created_at: row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    /*search:false,*/
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserOrders)))));