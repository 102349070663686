import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect, getStore} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {price} from "../../../../config/values";
import Link from "@material-ui/core/Link/Link";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import {makeSlug, translate} from "../../../../config/lang";
import ShowCase from "../../../../files/img/showcase.png";
class SingleOrderItem extends React.Component {

    state = {
        item:this.props.item,
    };
    classes = this.props.classes;

    render(){
        let {showcase} = this.state.item;
        let {item} = this.state;
        return (
            <div style={{border:"1px solid",marginBottom:15,borderRadius:4,borderColor: "#eee",paddingLeft:15,paddingRight:15}}>
                <div style={{display:'flex',alignItems:"center"}}>
                    {
                        !!showcase &&
                        <Link href={makeSlug(`buy-plan`)} target="_blank">
                            <img
                                src={ShowCase}
                                alt="img"
                                className="single-order-items-img"
                            />
                        </Link>
                    }
                    <div className="single_order_items">
                        {
                            !!showcase &&
                            <Typography style={{marginBottom:10}}>
                                <IconButton disabled size="small"><FeaturedPlayListOutlinedIcon/></IconButton><strong>{translate('buyAd')}</strong></Typography>
                        }
                        <Typography  style={{marginBottom:10}}><IconButton disabled size="small"><MonetizationOnOutlinedIcon/></IconButton>
                            <strong>{price((!!item.discount_price ? item.discount_price : item.price )*item.quantity)+" "+item.currency}</strong>
                            <span style={{color:this.props.primary_color,fontSize:11}}> (x{item.quantity})</span>
                        </Typography>
                    </div>
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleOrderItem))));