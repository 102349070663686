import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import AppCard from "../../../components/layouts/components/AppCard";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import AppAnimate from "../../../components/AppAnimate";
import Button from "@material-ui/core/Button/Button";
import {Link} from 'react-router-dom';
import TocRoundedIcon from '@material-ui/icons/TocRounded';
import Hidden from "@material-ui/core/Hidden/Hidden";
import {makeSlug, translate} from "../../../../config/lang";
import Title from "../../../components/elements/Title";
import {defaultVariabls} from "../../../../config";
import StorefrontRoundedIcon from '@material-ui/icons/StorefrontRounded';
class ViewYourOrders extends React.Component {
    classes = this.props.classes;
    state= {
        hide:false
    }
    render(){
        let {user} = this.props;
        return (
            <>
                {
                 !this.state.hide ?
                     <AppCard style={{position:"relative"}}>
                         <Grid container alignItems="center" justify="center">
                             <Hidden mdDown>
                             <Grid item xs={12} md={3} lg={2} >
                                 <Link to="/create/product">
                                     <img src="/assets/images/cart.png" style={{maxWidth:100}}  alt="View Orders"/>
                                 </Link>
                             </Grid>
                             </Hidden>
                             <Grid item xs={12} md={6} lg={8}>
                                 <Grid container spacing={1} alignItems="center" justify="center">
                                     <Grid item xs={12}>
                                         <Title align="left">{translate('viewOrders')}</Title>
                                     </Grid>
                                     <Grid item xs={12}>
                                         <Typography className={this.classes.condir}>{translate('viewOrdersDetails')}</Typography>

                                     </Grid>
                                 </Grid>
                             </Grid>
                             <Grid item xs={12} md={3} lg={2}>
                                 <Grid container spacing={1} alignItems="center" justify="center">
                                     <Grid item xs={12}>
                                         <Button
                                             component={Link}
                                             style={{marginTop:"10px !important",whiteSpace:"nowrap",width:"100%"}}
                                             to="/my-orders"
                                             color="primary"
                                             startIcon={<TocRoundedIcon/>}
                                             variant="outlined"
                                         >
                                             {translate('ordersList')}
                                         </Button>
                                         <br/>
                                         <Button
                                             component={'a'}
                                             style={{marginTop:10,whiteSpace:"nowrap",width:"100%"}}
                                             href={makeSlug('buy-plan')}
                                             color="primary"
                                             startIcon={<StorefrontRoundedIcon/>}
                                             variant="outlined"
                                         >
                                             {translate('purchaseAd')}
                                         </Button>
                                     </Grid>
                                 </Grid>

                             </Grid>
                         </Grid>
                     </AppCard>
                     : null
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ViewYourOrders))));