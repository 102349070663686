import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {DataTableLocalization, getDataTableLocalization, Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import Container from "@material-ui/core/Container/Container";
import {connect, getStore} from "trim-redux";
import {translate} from "../../../../config/lang";
import {getTimeZone} from "../../../../config/values";


class ManageDepartments extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef();
        console.log(getStore('admins'));
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    state = {
        columns: [
            { title: translate('related_admin'), field: 'admin' , lookup: getStore('admins') },
            { title: translate('department'), field: 'department'},
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
        ],
        data: [],
        loading:false,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onAdd(result) {
        if(result.admin === undefined || result.department === undefined){
            this.props.enqueueSnackbar(translate('addRedirectFailed'), {
                variant: 'error',
            });
        }else{
            let data = new FormData();
            data.append('user_id', result.admin);
            data.append('name', result.department);
            data.append('lang', this.props.locale);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/department/add`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(translate('detailsSet'), {
                        variant: 'success',
                    });
                    this.loadData();
                    return true

                })
                .catch(error => {
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    this.loadData();
                    return false
                });
        }

    }
    onEdit(result) {
        let data = new FormData();
        data.append('id', result.id);
        data.append('user_id', result.admin);
        data.append('name', result.department);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/department/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/department/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteData'), {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('youCantDeleteThisDepartment'), {
                        variant: 'error',
                    });
                    this.loadData();
                }else{
                    this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                        variant: 'error',
                    });
                    this.loadData();
                    return false
                }
            });
    }
    async onDeleteSelected(data){
        await data.map((row) =>(
            this.onDelete(row.id,false)

        ));
        await this.loadData();
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                icons={tableIcons}
                                title={translate('departments')}
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/departments/quickView?page=${query.page + 1}&per_page=${query.pageSize}&lang=${this.props.locale}`, config)
                                            .then(response => {
                                                console.log(response.data)
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        admin: row.user_id,
                                                        id:row.id,
                                                        department:row.name,
                                                        created_at:row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error)
                                        })
                                    })
                                }
                                options={{
                                    selection: true,
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    search:false,
                                }}
                                actions={[
                                    {
                                        tooltip: translate('deleteSelectedUsers'),
                                        icon: tableIcons.Delete,
                                        onClick: (evt, data) => this.onDeleteSelected(data)
                                    },
                                ]}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.onAdd(newData);
                                                resolve();
                                            }, 1000);
                                        }),
                                    onRowUpdate: async (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onEdit(newData);
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return {...prevState, data};
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return {...prevState, data};
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>
        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManageDepartments))));
