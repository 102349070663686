import React from "react";
import {getStore} from "trim-redux";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';

export default function ArrowBackIcon() {
    const locale = getStore('locale');
    return (
        <>
        {
            locale === "fa" ?
                <ArrowBackRoundedIcon/> : <ArrowBackRoundedIcon/>
        }
        </>
    );
}