import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Button from "@material-ui/core/Button/Button";
import {makeSlug, toggleLocale, translate} from "../../../config/lang";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {hasAccess} from "../../../config/Security";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import CheckUserCanAdBusiness from "./components/CheckUserCanAdBusiness";
import MuiPhoneNumber from "material-ui-phone-number";
import ChipInput from "material-ui-chip-input";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import SubTitle from "../../components/elements/SubTitle";
import CustomFields from "../advertisements/components/CustomFields";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";
import {truncate} from "../../../config/values";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class MakeEqualBusiness extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            address:"",
            phone:'',
            year:'',
            bio:'',
            total_employees:'',
            meta_title:'',
        },
        standards:[],
        logo: null,
        thumbnail: null,
        errors:{},
        banners: [],
        videos: [],
        loading: true,
        SubmitLoading: false,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.equal_id,
        editMode:false,
        owner_id:null,
        owner:null,
        updated_at:null,
        created_at:null,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.fake_locale,
        allowed_comment:0,
        is_verified: false,
        is_discount_provider: false,
        item:this.props.item,
        modal:false,
        business:null,
        custom_fields:null,

    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id: this.props.equal_id,
            loading:true
        })
        if(this.state.id){
            await this.setState({
                editMode:true
            });
            await this.loadData();
        }else{
            await this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id,
                loading:true
            })
            if(!!this.state.id && !!this.props.equal_id){
                this.setState({
                    editMode:true
                });
                this.loadData();
            }
        }
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true,
        })
        if(!this.state.id || !this.props.equal_id || (this.props.equal_id !== this.state.id)) {
            this.setState({
                editMode:false,
                loading:false,
                id:null
            })
        }else {
            let loginToken = localStorage.getItem('ACT');
            if(loginToken !== null){
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/business/getById/${this.state.id}`, config)
                    .then(response => {
                        this.setState({
                            fields: {
                                name: response.data.name,
                                year: !!response.data.year ? response.data.year : '',
                                address: !!response.data.address ? response.data.address : '',
                                phone: !!response.data.phone ? response.data.phone : '',
                                bio: !!response.data.bio ? response.data.bio : '',
                                total_employees: !!response.data.total_employees ? response.data.total_employees : '',
                                meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                            },
                            standards: !!response.data.standards ? response.data.standards.split(',') : [],
                            logo: response.data.logo,
                            thumbnail: response.data.thumbnail,
                            confirmed: response.data.confirmed,
                            real_confirmed: response.data.confirmed,
                            slug: response.data.slug,
                            owner_id: response.data.user_id,
                            owner: response.data.owner,
                            updated_at: response.data.updated_at,
                            created_at: response.data.created_at,
                            lat:!!response.data.lat ? parseFloat(response.data.lat) : '',
                            lng:!!response.data.lng ? parseFloat(response.data.lng) : '',
                            country:!!response.data.country ? response.data.country : null,
                            state:!!response.data.state ? response.data.state : null,
                            server_country:!!response.data.country ? response.data.country : null,
                            server_state:!!response.data.state ? response.data.state : null,
                            visits:parseInt(response.data.visits_count),
                            today_visits:parseInt(response.data.today_visits_count),
                            comments:parseInt(response.data.comments_count),
                            lang:response.data.lang,
                            custom_fields:response.data.custom_fields,
                            allowed_comment:parseInt(response.data.allowed_comment),
                            is_verified:parseInt(response.data.is_verified),
                            is_discount_provider:parseInt(response.data.is_discount_provider),
                        })
                        let bannerTemp=[];
                        response.data.banners.map(item =>{
                            bannerTemp.push({
                                src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                                link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                            })
                            return true
                        })
                        let videosTemp=[];
                        response.data.videos.map(item =>{
                            videosTemp.push({
                                src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                                link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                            })
                            return true
                        })

                        this.setState({
                            banners: bannerTemp,
                            videos: videosTemp,
                            business:response.data,
                            equal:response.data.equal,
                            loading: false
                        })
                    }).catch(error => {
                        console.log(error.response)
                        /*this.props.history.push('/');
                        this.props.enqueueSnackbar(translate('pageNotFound'), {
                            variant: 'error',
                        });*/
                        this.setState({
                            loading:false
                        })
                    })
            }else {
                setStore({
                    isAuth : false,
                    user : null,
                });
                this.setState({
                    loading:false
                })
            }
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "year" || target.name === "total_employees"){
            if(parseInt(target.value) > 0){
                fields[target.name] = Math.abs(parseInt(target.value));
            }else{
                fields[target.name] = '';
            }
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
        });
    }

    handleValidation(callback){
        let { fields,country,state,lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        if(fields.bio !== null ) {
            if (fields.bio.length > 70) {
                formIsValid = false;
                errors['bio'] = translate('maxLength70');
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal
        })
    }
    handleRequest(){
        let { id,fields,standards,editMode,confirmed,item,custom_fields,is_verified,is_discount_provider,allowed_comment} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('meta_title', fields.meta_title);
        data.append('lang', this.props.fake_locale);
        data.append('id', id ? id : 0);
        data.append('address', fields.address);
        data.append('bio', fields.bio);
        data.append('year', fields.year);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('phone', fields.phone);
        data.append('equal_id', item.id);
        data.append('total_employees', fields.total_employees);
        data.append('standards', standards.toString());
        data.append('custom_fields', JSON.stringify(custom_fields));
        data.append('is_discount_provider',is_discount_provider ? 1 : 0);
        data.append('is_verified',is_verified ? 1 : 0);
        data.append('allowed_comment', parseInt(allowed_comment) );
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/business/make-equal`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('businessEdited') : translate('businessAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.loadData();
                this.toggleModal();
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('businessEditingFailed') : translate('businessAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeDiscountProvider(){
        this.setState({
            is_discount_provider: !this.state.is_discount_provider,
        })
    }
    handleChangeVerified(){
        this.setState({
            is_verified: !this.state.is_verified,
        })
    }
    handleChangePhone(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone.replace("+0","+98");
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    async handleAddChip(item){
        let {standards} = this.state;
        if(standards.length < 3){
            standards.push(item);
            this.setState({
                standards
            })
        }else{
            this.setState({
                standards
            })
        }

    }
    async handleDeleteChip(chip, index){
        let {standards} = this.state;
        standards.splice(index, 1);
        this.setState({
            standards
        })
        this.setState({
            standards
        })
    }
    render(){
        let {fields,is_discount_provider,is_verified,item,equal,business,modal,country,parent_category,loading,dislikes_count,likes_count,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,lat,lng} = this.state;
        const {user,list} = this.props;
        return (
            <>
                {
                    !!business ?
                        <Button
                            color={!!list ? 'default' : "primary"}
                            variant={!!list ? 'text' : "outlined"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<EditRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}

                        >
                            {!!list ? truncate(business.name,8) : business.name }
                        </Button>
                        :
                        <Button
                            color={!!list ? 'default' : "primary"}
                            variant={!!list ? 'outlined' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<AddRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}

                        >
                            {translate('addEqual')}
                        </Button>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editBusiness') :  translate('makeBusiness')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editBusiness') :  translate('makeBusiness')}>
                            {
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} lg={11}>
                                        <Grid container spacing={2} justify="center">
                                            {
                                                (user.role.type !== "admin" && !loading) &&
                                                <CheckUserCanAdBusiness edit={!!editMode}/>
                                            }
                                            {/*{
                                                (!!editMode && !loading) &&
                                                <>
                                                    <Grid item xs={12}>
                                                        {
                                                            !! user.role.permission.manageAdvertisements ?
                                                                <>
                                                                    <Alert
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={confirmed}
                                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                                        value={confirmed}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('confirmationStatusDescription')}
                                                                    </Alert>
                                                                </>
                                                                :
                                                                confirmed ?
                                                                    <Alert severity="success" variant="filled">
                                                                        <Typography  className={this.classes.alertText}>{translate('yourBusinessIsActive')}</Typography>
                                                                    </Alert>
                                                                    :
                                                                    <Alert severity="warning">
                                                                        <Typography className={this.classes.alertText}>{translate('yourBusinessIsInchecking')}</Typography>
                                                                    </Alert>
                                                        }
                                                    </Grid>
                                                </>
                                            }*/}
                                            {
                                                (!!editMode && !loading) &&
                                                <>
                                                    <Grid item xs={12}>
                                                        {
                                                            !!real_confirmed ?
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                        color="primary"
                                                                    >
                                                                        {translate('viewBusiness')}
                                                                    </Button>
                                                                </> :
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        variant="contained"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`business/${slug}`,this.state.lang)}
                                                                        color="primary"
                                                                    >
                                                                        {translate('viewPreview')}
                                                                    </Button>

                                                                </>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <AppCard
                                                    title={editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                                    action={
                                                        <BreadCrumbs singleLevel firstText={editMode ? translate('editBusiness') :  translate('makeBusiness')}/>
                                                    }>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} justifyContent="center" style={{marginBottom:10}}>

                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        required
                                                                        fullWidth
                                                                        name="name"
                                                                        variant="outlined"
                                                                        className={this.classes.inputCtlEqual}
                                                                        label={translate('businessName')}
                                                                        type="text"
                                                                        id="name"
                                                                        value={fields.name}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['name']}
                                                                        helperText={errors['name']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        name="bio"
                                                                        variant="outlined"
                                                                        className={this.classes.inputCtlEqual}
                                                                        label={translate('businessBio')}
                                                                        type="text"
                                                                        value={fields.bio}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['bio']}
                                                                        helperText={errors['bio']}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <FieldCounterWithoutBar length={fields.bio.length} max={70}/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        name="year"
                                                                        variant="outlined"
                                                                        label={translate('foundingYear')}
                                                                        type="number"
                                                                        margin="normal"
                                                                        value={fields.year}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['year']}
                                                                        helperText={errors['year']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        fullWidth
                                                                        margin="normal"
                                                                        name="total_employees"
                                                                        variant="outlined"
                                                                        label={translate('total_employees')}
                                                                        type="number"
                                                                        value={fields.total_employees}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['total_employees']}
                                                                        helperText={errors['total_employees']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <ChipInput
                                                                        value={this.state.standards}
                                                                        fullWidth
                                                                        label={translate('standards')}
                                                                        placeholder={translate('standardsPlaceHolder')}
                                                                        variant={"outlined"}
                                                                        onAdd={(chip) => this.handleAddChip(chip)}
                                                                        onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                                    />
                                                                    <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                                        {translate('pressEnterToMakeEachOption')}
                                                                    </Typography>
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <MuiPhoneNumber
                                                                        fullWidth
                                                                        label={translate('phoneNumber')}
                                                                        name="phone"
                                                                        dir="ltr"
                                                                        variant="outlined"
                                                                        disabled={!hasAccess('has_phone')}
                                                                        onChange={(phone) => this.handleChangePhone(phone,"phone")}
                                                                        disableAreaCodes
                                                                        defaultCountry={'ae'}
                                                                        value={fields.phone}
                                                                        error = {!!errors['phone']}
                                                                        helperText={errors['phone']}
                                                                    />
                                                                    {
                                                                        hasAccess('has_phone') &&
                                                                        <YourPlanDoesntSupportThis visible={!hasAccess('has_phone')}/>
                                                                    }
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppCard style={{height:'100%'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('aboutBusiness')}</SubTitle>
                                                        </Grid>
                                                        {
                                                            this.state.loading ? <AppCardLoaderFields/> :
                                                                <Grid item xs={12}>
                                                                    <CustomFields
                                                                        equal
                                                                        type={"business"}
                                                                        position_type="business_custom_fields"
                                                                        input={this.state.custom_fields}
                                                                        handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                                    />
                                                                </Grid>
                                                        }
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                            {
                                                (!!user.role.permission.manageShops) &&
                                                <Grid item xs={12}>
                                                    <AppCard style={{height:'100%'}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="meta_title"
                                                                    variant="outlined"
                                                                    label={translate('meta_title')}
                                                                    value={fields.meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['meta_title']}
                                                                    helperText={errors['meta_title']}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                            }
                                            {/*{
                                                (!!editMode && !loading) &&
                                                <>
                                                    <Grid item xs={12}>
                                                        {
                                                            !! user.role.permission.manageAdvertisements &&
                                                                <>
                                                                    <Alert
                                                                        style={{marginTop:15}}
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={is_verified}
                                                                                        onChange={this.handleChangeVerified.bind(this)}
                                                                                        value={is_verified}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={is_verified ? translate('yes') : translate('no')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('isVerifiedDescription')}
                                                                    </Alert>
                                                                    <Alert
                                                                        style={{marginTop:15}}
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={is_discount_provider}
                                                                                        onChange={this.handleChangeDiscountProvider.bind(this)}
                                                                                        value={is_discount_provider}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={is_discount_provider ? translate('yes') : translate('no')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('isDiscountProviderDescription')}
                                                                    </Alert>
                                                                    <Alert
                                                                        style={{marginTop:15}}
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={this.state.allowed_comment}
                                                                                        onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                                                        value={this.state.allowed_comment}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('allowed_comments')}
                                                                    </Alert>
                                                                </>
                                                        }
                                                    </Grid>
                                                </>
                                            }*/}
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{minWidth:200}}
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editBusiness') :  translate('makeBusiness')}
                                                </LoadingButton>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Alert severity="warning" variant="standard" style={{marginBottom:10}}>
                                                    <Typography style={{direction:this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                        {translate('ifYouWantToSeeMoreInfoClick')}
                                                        {" "}
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            component='a'
                                                            href={
                                                                this.props.user.role.type === "admin" ?
                                                                    `${defaultVariabls.React}/edit/business/id/${this.state.id}?lang=${this.props.fake_locale}`
                                                                    :
                                                                    `${defaultVariabls.React}/business?lang=${this.props.fake_locale}`
                                                            }
                                                        >
                                                            {translate('clickHere')}
                                                        </Button>
                                                    </Typography>
                                                    <Typography style={{fontSize:11}}>
                                                        {translate('attentionText')}
                                                    </Typography>
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                </Grid>

                            }
                        </AppAnimate>
                    </DialogContent>

                </Dialog>
            </>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeEqualBusiness))));