import React from 'react';
import {Input, InputLabel, MenuItem, withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect, getStore, setStore} from "trim-redux";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import Typography from "@material-ui/core/Typography/Typography";
import {defaultVariabls} from "../../../../config";
import axios from "axios";
import Grid from "@material-ui/core/Grid/Grid";
import {translate} from "../../../../config/lang";
import TextField from "@material-ui/core/TextField/TextField";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Select from "@material-ui/core/Select";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import UnitChooser from "./UnitChooser";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";

class CustomFields extends React.Component{
    state= {
        type: this.props.type,
        position_type: this.props.position_type,
        business_type: this.props.business_type,
        category_id: this.props.category_id,
        fields: [],
        results:[],
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            business_type: this.props.business_type,
            category_id: this.props.category_id,
        })
        if(this.props.type === "business"){
            await this.loadData();
        }
        if(!!this.props.business_type || this.props.category_id){
            await this.loadData();
        }
    }
    async componentDidUpdate(prevProps){
        if(this.state.business_type !== this.props.business_type){
            await this.setState({
                business_type: this.props.business_type,
                fields: [],
            })
            await this.loadData();
        }
        if(this.state.category_id !== this.props.category_id){
            await this.setState({
                category_id: this.props.category_id,
                fields: [],
            })
            await this.loadData();
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {type,position_type,business_type,category_id} = this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let bt = '';
            if(!!business_type){
                if(!!business_type.id){
                    bt = business_type.id
                }
            }
            let ci = '';
            if(!!category_id){
                if(!!category_id.id){
                    ci = category_id.id
                }else{
                    ci = category_id;
                }
            }
            await axios.get(`${defaultVariabls.url}/fields/getByType/${type}/${position_type}?category_id=${ci}`, config)
                .then(async response => {
                    let result = [];
                    if(!!this.props.input){
                        let find = 0 ;
                        await response.data.map((item) => {
                            find = 0;
                            this.props.input.map((res) => {
                                if(res.field_id === item.id){
                                    find = 1;
                                    if(item.field_type === "multi_select"){
                                        result.push({
                                            val:res.val.split(","),
                                            unit:res.unit
                                        })
                                    }else{
                                        result.push({
                                            val:res.val,
                                            unit:res.unit
                                        })
                                    }

                                }
                            })
                            if(find === 0){
                                result.push(null)
                            }
                        })
                    }
                    await this.setState({
                        fields:response.data,
                        loading: false,
                        results: result
                    })
                    await this.props.handleCustomFields(result)

                    return true
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleChangeField(index,val){
        let {results} = this.state;
        let res;
        if(val === "clear"){
            val = ''
        }
        if(!!results[index]){

            if(!!results[index].unit){
                res = {
                    val: val,
                    unit: results[index].unit,
                }
            }else{
                res = {
                    val: val,
                    unit: null,
                }
            }
        }else{
            res = {
                val: val,
                unit: null,
            }
        }
        results[index] = res;

        await this.setState({
            results
        });
        await this.props.handleCustomFields(results)
    }
    async handleChangeUnit(index,unit){
        let {results} = this.state;
        if(!!results[index]){
            if(!!results[index].val){
                results[index].unit = unit
            }else{
                let res = {
                    val: null,
                    unit: unit,
                }
                results[index] = res;
            }
        }else{
            let res = {
                val: null,
                unit: unit,
            }
            results[index] = res;
        }

        await this.setState({
            results
        });
        await this.props.handleCustomFields(results)
    }
    render(){
        let{type,business_type,category_id,fields,results} = this.state;
        let {fake_locale,equal} = this.props;
        return (
            <Grid container spacing={2}>
                {
                    (type === "advertisement" && !category_id && !equal) &&
                    <Grid item xs={12}>
                        <Typography className={this.classes.condir}>{translate('pleasChooseCategoryFirst')}</Typography>
                    </Grid>
                }
                {
                    (type === "discount" && !category_id && !equal) &&
                    <Grid item xs={12}>
                        <Typography className={this.classes.condir}>{translate('pleasChooseCategoryFirst')}</Typography>
                    </Grid>
                }
                {
                    fields.map((field,index)=>(
                        <Grid item xs={12}>
                            {
                                (field.field_type === "select") ?
                                    <FormControl
                                        fullwidth
                                        variant="outlined"
                                        required={!!parseInt(field.required)}
                                        style={{width:"100%"}}
                                    >
                                        <InputLabel style={{marginBottom:4}}>{fake_locale === "en" ? field.english_name : field.name}</InputLabel>
                                        <Select
                                            variant="outlined"
                                            value={!!results[index] ?  results[index].val : []}
                                            onChange={(e) => this.handleChangeField(index,e.target.value)}
                                            label={fake_locale === "en" ? field.english_name : field.name}
                                        >
                                            {
                                                fake_locale === "en" ?
                                                    !!field.english_options &&
                                                    field.english_options.split(",").map((option,index)=>(
                                                        <MenuItem key={index} value={option}>{option}</MenuItem>
                                                    )) :
                                                    !!field.options &&
                                                    field.options.split(",").map((option,index)=>(
                                                        <MenuItem key={index} value={option}>
                                                            {option}
                                                        </MenuItem>
                                                    ))
                                            }
                                            {
                                                !!results[index] &&
                                                    <MenuItem  value={"clear"} style={{opacity:0.8}}>
                                                        {translate('removeSelect')}
                                                    </MenuItem>
                                            }
                                        </Select>
                                    </FormControl>
                                    :
                                field.field_type === "multi_select" ?
                                    <FormControl
                                        fullwidth
                                        variant="outlined"
                                        required={!!parseInt(field.required)}
                                        style={{width:"100%"}}
                                    >
                                        <InputLabel style={{marginBottom:4}}>{fake_locale === "en" ? field.english_name : field.name}</InputLabel>
                                        <Select
                                            variant="outlined"
                                            value={!!results[index] ?  results[index].val : []}
                                            multiple="multi_select"
                                            onChange={(e) => this.handleChangeField(index,e.target.value)}
                                            label={fake_locale === "en" ? field.english_name : field.name}
                                            renderValue={(selected) => selected.join(', ')}
                                        >
                                            {
                                                fake_locale === "en" ?
                                                    !!field.english_options &&
                                                    field.english_options.split(",").map((option,i)=>(
                                                        <MenuItem key={i} value={option}>
                                                            <Checkbox checked={!!results[index] ? results[index].val.indexOf(option) > -1 : false}/>
                                                            <ListItemText primary={option} />
                                                        </MenuItem>
                                                    )) :
                                                    !!field.options &&
                                                    field.options.split(",").map((option,i)=>(
                                                        <MenuItem key={i} value={option}>
                                                            <Checkbox checked={!!results[index] ? results[index].val.indexOf(option) > -1 : false}/>
                                                            <ListItemText primary={option} />
                                                        </MenuItem>
                                                    ))
                                            }
                                        </Select>
                                    </FormControl>
                                    :
                                    <TextField
                                        key={index}
                                        variant="outlined"
                                        fullWidth
                                        required={!!parseInt(field.required)}
                                        multiline={field.field_type === "long_text"}
                                        minRows={3}
                                        label={fake_locale === "en" ? field.english_name : field.name}
                                        type={field.field_type}
                                        style={(!field.units && field.field_type !== "number") ? {direction:fake_locale === "en" ? "ltr" : "rtl"} : null}
                                        value={!!results[index] ? results[index].val : ''}
                                        helperText={fake_locale === "en" ? field.english_description : field.description}
                                        onChange={(e) => this.handleChangeField(index,e.target.value)}
                                        InputProps={fake_locale === "en" ? !!field.english_units && {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <UnitChooser
                                                        current={!!results[index] ? !!results[index].unit ?  results[index].unit : '' : ''}
                                                        units={field.english_units}
                                                        onChange={(unit) => this.handleChangeUnit(index,unit)}
                                                    />
                                                </InputAdornment>
                                            ),
                                        } : !!field.units && {
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <UnitChooser
                                                        current={!!results[index] ? !!results[index].unit ?  results[index].unit : '' : ''}
                                                        units={field.units}
                                                        onChange={(unit) => this.handleChangeUnit(index,unit)}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}

                                    />
                            }

                        </Grid>
                    ))
                }

            </Grid>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
    fake_locale: state.fake_locale,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CustomFields))));