import {makeStyles} from '@material-ui/core/styles';
import {getStore} from "trim-redux";

const useStyles = makeStyles((theme) => {
    return {
        navItem: {
            height: 40,
            marginTop: 6,
            marginBottom: 2,
            cursor: 'pointer',
            textDecoration: 'none !important',
            paddingLeft: 24 ,
            '&.nav-item-header': {
                textTransform: 'uppercase',
            },
            '&.active': {
                backgroundColor: getStore('mode') === "dark" ? "rgba(66,66,66,.7)" :  "rgba(77,2,103,.14)",
                pointerEvents: 'none',
                transition: '.15s cubic-bezier(0.4,0.0,0.2,1)',
                borderLeft:"5px solid #4d0267",
                '& .nav-item-text': {
                    color: theme.palette.primary.main,
                },
                '& .nav-item-icon': {
                    color: theme.palette.primary.main,
                },
            },

            '&:hover, &:focus': {
                '& .nav-item-icon-arrow': {
                    color: theme.palette.primary.main
                },
            },
            '& .nav-item-icon': {
                color: getStore('mode') === "dark" ? "#ddd" : "#333333",
            },
            '& .nav-item-text': {
                color: getStore('mode') === "dark" ? "#ddd" : "#333333",
            },

        },
        listIcon: {
            fontSize: 22,
            marginTop: 7
        },
        listItemText: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
        },
    };
});
export default useStyles;

