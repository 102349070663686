import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import {Translate} from "../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import Thumbnail from "../fileManager/Components/Thumbnail";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Keywords from "../../components/fields/keywords"
import Grid from "@material-ui/core/Grid/Grid";
import FieldCounter from "../../components/elements/FieldCounter";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import CheckForHavingShop from "../../components/elements/CheckForHavingShop";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import VariablesChooser from "../../components/fields/VariablesChooser/index";
import ImageGallery from "../fileManager/Components/ImageGallery";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import Advantages from "./components/Advantages";
import Disadvantages from "./components/Disadvantages";
import BrandChooser from "../admin/brands/components/BrandChooser";
import Hidden from "@material-ui/core/Hidden/Hidden";
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {currencySymbol} from "../../../config/values";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Rating from "@material-ui/lab/Rating/Rating";
import StarRoundedIcon from '@material-ui/icons/StarRounded';
import Feature from "../../components/elements/Feature";
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "jalali-moment";

class MakeProduct extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            english_name:"",
            description:"",
            shop_id:this.props.user.role.type === "admin" ? this.props.primary_shop.id : this.props.user.shops.length ? this.props.user.shops[0].id : '',
            shop_name:'',
            price:'',
            priceR:'',
            discount_price:'',
            discount_priceR:'',
            warranty:"",
            inventory:null,
            transfer_time:"",
            commission:'',
            weight:'',
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        rating:4.5,
        advantages:[],
        disadvantages:[],
        thumbnail: 1,
        brand_id: 0,
        editor_input_text: '',
        is_not_original: 0,
        errors:{},
        keywords:[],
        categories:[],
        categoriesName:[],
        variables:[],
        variables_res:[],
        gallery: [],
        loading: true,
        SubmitLoading: false,
        publish: true,
        confirmed: false,
        featured: false,
        slug: null,
        id:this.props.match.params.id,
        editMode:false,
        percent:'',
        owner_id:null,
        requested_to_delete:0,
        updated_at:null,
        created_at:null,
    };
    classes = this.props.classes;
    componentDidMount(){

        if(this.state.id){
            this.setState({
                editMode:true
            })
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    handleChangePercent(e){
        let percent = e.target.value;
        let fields = this.state.fields;
        let price = this.state.fields.priceR;
        if(!!price){
            fields['discount_priceR'] = price - (price*percent/100);
            fields['discount_price'] = price - (price*percent/100);
        }
        if(parseInt(percent) === 0){
            fields.discount_price = '';
            fields.discount_priceR = '';
            percent = ''
        }
        this.setState({
            percent,
            fields
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/product/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            english_name: response.data.english_name === null ? "" : response.data.english_name ,
                            description: !!response.data.description ? response.data.description : '',
                            commission: !!response.data.commission ? response.data.commission : '',
                            weight: !!response.data.weight ? response.data.weight : '',
                            shop_id: response.data.shop_id,
                            shop_name: response.data.shop.name,
                            price:!!response.data.pricing ? response.data.pricing.price : '',
                            priceR:!!response.data.pricing ? response.data.pricing.price : '',
                            discount_price: !!response.data.pricing ? response.data.pricing.discount_price === null ? "" : response.data.pricing.discount_price : '',
                            discount_priceR: !!response.data.pricing ? response.data.pricing.discount_price === null ? "" : response.data.pricing.discount_price : '',
                            warranty:!!response.data.pricing ? response.data.pricing.warranty : '',
                            inventory:!!response.data.pricing ? response.data.pricing.inventory : null,
                            transfer_time:!!response.data.pricing ? response.data.pricing.transfer_time : '',
                        },
                        thumbnail: response.data.thumbnail.id,
                        brand_id: response.data.brand_id,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        rating: response.data.average_rating,
                        publish: response.data.publish,
                        confirmed: response.data.confirmed,
                        featured: response.data.featured,
                        percent : !!response.data.pricing ? response.data.pricing.discount_price > 0 ? Math.max(0, Math.floor((response.data.pricing.price - response.data.pricing.discount_price)*100/response.data.pricing.price)) : '' : '',
                        variables_res: response.data.variables,
                        slug: response.data.slug,
                        is_not_original: parseInt(response.data.is_not_original),
                        owner_id: response.data.user_id,
                        updated_at: response.data.updated_at,
                        created_at: response.data.created_at,
                        requested_to_delete: parseInt(response.data.requested_to_delete)
                    })
                    let categoriesTemp=[];
                    let categoriesNameTemp=[];
                    let keywordsTemp=[];
                    let galleryTemp=[];
                    let advantageTemp =[];
                    let disadvantageTemp =[];
                    response.data.advantages.map(item => {
                        advantageTemp.push(item.name);
                        return true
                    });
                    response.data.disadvantages.map(item => {
                        disadvantageTemp.push(item.name);
                        return true
                    });
                    response.data.categories.map(item => {
                        categoriesTemp.push(item.id)
                        categoriesNameTemp.push(item.name)
                        return true
                    })
                    response.data.keywords.map(item => {
                        keywordsTemp.push(item.name);
                        return true
                    });
                    response.data.gallery.map(item =>{
                        galleryTemp.push({
                            src: `${defaultVariabls.uploads}/FileManager/${item.name}_small.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                        })
                        return true
                    })
                    this.setState({
                        categories: categoriesTemp,
                        categoriesName: categoriesNameTemp,
                        keywords: keywordsTemp,
                        gallery: galleryTemp,
                        advantages: advantageTemp,
                        disadvantages: disadvantageTemp,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error)
                    this.props.history.push('/');
                    this.props.enqueueSnackbar(Translate.pageNotFound, {
                        variant: 'error',
                    });
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields,percent} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
            if(!!fields.discount_priceR)
                percent = Math.max(0, Math.floor((fields.priceR - fields.discount_priceR)*100/fields.priceR));

        }else if (target.name === "discount_price") {
            fields.discount_priceR = target.value.replace(/,/ig, '');
            if(!!fields.priceR)
                percent = Math.max(0, Math.floor((fields.priceR - fields.discount_priceR)*100/fields.priceR));
            if(parseInt(fields.discount_priceR) >= parseInt(fields.priceR)){
                fields.discount_price = '';
                fields.discount_priceR = '';
                percent = ''
            }
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
            percent

        });
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }
        //Commission
        if(fields.commission !== null ) {
            if (parseInt(fields.commission) > 100 || parseInt(fields.commission) < 1) {
                formIsValid = false;
                errors['commission'] = Translate.between0to100;
            }
        }
        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }

        if(fields.discount_priceR !== null){
            if(fields.discount_priceR > 0){
                if(fields.discount_priceR > fields.priceR){
                    formIsValid = false;
                    errors['discount_price'] = Translate.discountMoreThanPrice;
                }
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,content,rating,commission,weight,featured,brand_id,thumbnail,advantages,disadvantages,is_not_original,variables,editMode,gallery,categories,keywords,confirmed,publish} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('english_name', fields.english_name);
        data.append('html', content.HTML);
        data.append('raw', content.RAW);
        data.append('shop_id', fields.shop_id);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('average_rating', rating);
        data.append('thumbnail', thumbnail);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('publish', publish ? 1 : 0);
        data.append('featured', featured ? 1 : 0);
        data.append('is_not_original', is_not_original ? 1 : 0);
        data.append('brand_id', !!brand_id ? brand_id : null);
        data.append('price', fields.priceR);
        data.append('discount_price', fields.discount_priceR);
        data.append('warranty', fields.warranty);
        data.append('inventory', fields.inventory);
        data.append('commission', fields.commission);
        data.append('weight', fields.weight);
        data.append('transfer_time', fields.transfer_time);
        data.append('advantages',JSON.stringify(advantages));
        data.append('disadvantages',JSON.stringify(disadvantages));
        data.append('variables', JSON.stringify(variables));
        data.append('categories', JSON.stringify(categories));
        data.append('keywords', JSON.stringify(keywords));
        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/product/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.contentEdited : Translate.contentAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                if(!this.state.editMode){
                    if(!!user.role.permission.manageShops){
                        this.props.history.push(`/manage/products`);
                    }else{
                        this.props.history.push(`/my-products`);
                    }
                }
            })
            .catch(error => {
                console.log(error.response)
                if(error.response.status === 403){
                    if(error.response.data === "first_confirm_shop"){
                        this.props.enqueueSnackbar(Translate.firstConfirmShop, {
                            variant: 'error',
                        });
                        this.setState({
                            confirmed:0
                        })
                    }
                }else{
                    this.props.enqueueSnackbar(editMode ?  Translate.contentEditingFailed : Translate.contentAddingFailed, {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        this.setState({categories})
    }
    handleVariablesHolder(variables){
        this.setState({variables})
    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeFeatured(){
        this.setState({
            featured: !this.state.featured,
        })
    }
    async handleRequestToDelete(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/product/request_delete/${this.state.id}`, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.requestedToDelete, {
                    variant: 'success',
                });
                this.setState({
                   requested_to_delete:1,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }
    handleDeleteProduct(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/product`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.deleteProduct, {
                    variant: 'success',
                });
                this.props.history.push('/manage/products')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.deleteProductFailed, {
                    variant: 'error',
                });
                return false
            });
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    render(){
        let {fields,brand_id,percent,rating,featured, errors,advantages,disadvantages,is_not_original,gallery,slug,SubmitLoading,keywords,thumbnail,categories,categoriesName,content,editMode,confirmed,editor_input_text} = this.state;
        const {user,primary_shop} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? Translate.editProduct : Translate.makeProduct}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} md={8} lg={9}>
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12}>
                                        <CheckForHavingShop new={!editMode}/>
                                        <AppCard
                                            title={editMode ? Translate.editProduct : Translate.makeProduct}
                                            action={
                                                <BreadCrumbs singleLevel firstText={editMode ? Translate.editProduct : Translate.makeProduct}/>
                                            }>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>
                                                        <Grid item xs={12}>
                                                            {
                                                                !editMode ?
                                                                    null
                                                                    :
                                                                    <>
                                                                        {
                                                                            !! user.role.permission.manageShops ?
                                                                                <>
                                                                                    <Alert
                                                                                        severity="info"
                                                                                        action={
                                                                                            <FormControlLabel
                                                                                                style={{marginRight:10}}
                                                                                                control={
                                                                                                    <Switch
                                                                                                        checked={confirmed}
                                                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                                                        value={confirmed}
                                                                                                        color="primary"
                                                                                                    />
                                                                                                }
                                                                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                                                            />
                                                                                        }
                                                                                    >
                                                                                        {Translate.confirmationStatusDescription}
                                                                                    </Alert>
                                                                                </>
                                                                                :
                                                                                confirmed ?
                                                                                    <Alert severity="success">
                                                                                        {Translate.yourProductIsActive}
                                                                                    </Alert>
                                                                                    :
                                                                                    <Alert severity="warning">
                                                                                        {Translate.yourProductIsInchecking}
                                                                                    </Alert>
                                                                        }
                                                                    </>

                                                            }
                                                        </Grid>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                margin="normal"
                                                                required
                                                                size="small"
                                                                fullWidth
                                                                name="name"
                                                                variant="outlined"
                                                                label={Translate.productName}
                                                                placeholder={Translate.productName}
                                                                type="text"
                                                                id="name"
                                                                value={fields.name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['name']}
                                                                helperText={errors['name']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                margin="normal"
                                                                fullWidth
                                                                size="small"
                                                                name="english_name"
                                                                variant="outlined"
                                                                label={Translate.productEnglishName}
                                                                placeholder={Translate.productEnglishName}
                                                                type="text"
                                                                value={fields.english_name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['english_name']}
                                                                helperText={errors['english_name']}
                                                            />
                                                        </Grid>
                                                        {/*<Grid item xs={12}>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={is_not_original}
                                                                        onChange={() => this.setState({is_not_original:!is_not_original})}
                                                                        name="is_not_original"
                                                                        color="primary"
                                                                    />
                                                                }
                                                                label={Translate.ProductIsNotOriginal}
                                                            />
                                                        </Grid>*/}
                                                        <Grid item xs={12} md={6} >
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                currencySymbol={currencySymbol}
                                                                decimalPlaces={0}
                                                                value={fields.price}
                                                                id="price"
                                                                label={Translate.price}
                                                                name="price"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['price']}
                                                                helperText={errors['price']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={4} md={2}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={percent}
                                                                fullWidth
                                                                id="percent"
                                                                size="small"
                                                                label={Translate.discount_percent}
                                                                name="percent"
                                                                autoComplete="percent"
                                                                onChange={this.handleChangePercent.bind(this)}
                                                                error = {!!errors['percent']}
                                                                helperText={errors['percent']}
                                                                InputProps={{
                                                                    startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={8} md={4}>
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                fullWidth
                                                                size="small"
                                                                currencySymbol={currencySymbol}
                                                                decimalPlaces={0}
                                                                value={fields.discount_price}
                                                                id="discount_price"
                                                                label={Translate.discount_price}
                                                                name="discount_price"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['discount_price']}
                                                                helperText={errors['discount_price']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={fields.inventory}
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                label={Translate.inventory}
                                                                placeholder={Translate.inventoryDesc}
                                                                name="inventory"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['inventory']}
                                                                helperText={!!errors['inventory'] ? errors['inventory'] : Translate.inventoryHelperText}
                                                            />
                                                        </Grid>
                                                        {/*<Grid item xs={12} md={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={fields.warranty}
                                                                fullWidth
                                                                size="small"
                                                                label={Translate.warranty}
                                                                name="warranty"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['warranty']}
                                                                helperText={errors['warranty']}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={fields.transfer_time}
                                                                fullWidth
                                                                size="small"
                                                                label={Translate.transfer_time}
                                                                name="transfer_time"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['transfer_time']}
                                                                helperText={errors['transfer_time']}
                                                            />
                                                        </Grid>*/}
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                variant="outlined"
                                                                value={fields.weight}
                                                                fullWidth
                                                                size="small"
                                                                type="number"
                                                                label={Translate.weight}
                                                                name="weight"
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['weight']}
                                                                helperText={!!errors['weight'] ? errors['weight'] : Translate.weightHelper}
                                                                InputProps={{
                                                                    startAdornment: (
                                                                        <InputAdornment position="start">
                                                                            {Translate.kilogram}
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Feature check="product_advantages">
                                        <Grid item xs={12}>
                                            <AppCard>
                                                {
                                                    this.state.loading ? <AppCardLoaderFields/> :
                                                        <Grid Container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={12} md={6}>
                                                                        <SubTitle color="secondary"
                                                                            align="left">{Translate.productAdvantages}</SubTitle>
                                                                        <Advantages
                                                                            input={advantages.length === 0 ? null : advantages}
                                                                            handleAdvantages={(advantages) => this.setState({advantages})}/>
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <SubTitle color="error"
                                                                                  align="left">{Translate.productDisadvantages}</SubTitle>
                                                                        <Disadvantages
                                                                            input={disadvantages.length === 0 ? null : disadvantages}
                                                                            handleDisadvantages={(disadvantages) => this.setState({disadvantages})}/>
                                                                    </Grid>
                                                                </Grid>
                                                            </Grid>
                                                        </Grid>
                                                }
                                            </AppCard>
                                        </Grid>
                                    </Feature>
                                    <Grid item xs={12} md={6}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <LinearCategoryHolder
                                                                outPut={this.handleCategoryHolder.bind(this)}
                                                                inPut={categoriesName} editMode={categoriesName.length === 0 ? false : editMode}/>
                                                        </Grid>
                                                        {
                                                            !!editMode && !!categoriesName.length &&
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    size="small"
                                                                    variant="outlined"
                                                                    onClick={() => this.changeCategories()}
                                                                >
                                                                    {Translate.changeCategories}
                                                                    </Button>
                                                            </Grid>
                                                        }

                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <VariablesChooser
                                                                outPut={this.handleVariablesHolder.bind(this)}
                                                                categories={categories}
                                                                input={this.state.variables_res}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{Translate.productDescription}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <RichEditor inputText={editor_input_text} ref={this.EditorChild} Output={this.handleOutPut.bind(this)} input={content.RAW}/>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            margin="normal"
                                                            multiline
                                                            fullWidth
                                                            name="description"
                                                            label={Translate.seoDescription}
                                                            placeholder={Translate.seoDescription}
                                                            type="text"
                                                            id="description"
                                                            rows="4"
                                                            value={fields.description}
                                                            onChange={this.handleChange.bind(this)}
                                                            onClick={this.handleClickDescription.bind(this)}
                                                            error={!!errors['description']}
                                                            helperText={errors['description']}
                                                        />
                                                        <FieldCounter length={fields.description.length} max={150}/>
                                                    </Grid>
                                                </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{Translate.keywordsLabel}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Keywords input={keywords}
                                                                      handleChange={(keywords) => this.setState({keywords})}/>
                                                            <Typography
                                                                variant="body2"
                                                                style={{marginTop: 10, fontWeight: 700}}
                                                            >
                                                                {Translate.keywordsDescription}
                                                                <br/>
                                                            </Typography>
                                                            <Typography>
                                                                {Translate.pressEnterAfterEachKeyword}
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Hidden smDown>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                onClick = {this.handleSubmit.bind(this)}
                                                loading = {SubmitLoading}
                                            >
                                                {editMode ? Translate.editProduct : Translate.makeProduct}
                                            </LoadingButton>
                                        </Grid>
                                    </Hidden>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={4} lg={3}>
                                <Grid container spacing={2} alignItems="center" justify="center">
                                    <Hidden smDown>
                                        <Grid item xs={12} style={{paddingTop: 0}}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                styleForParent={{marginBottom:10}}
                                                style={{width: "100%"}}
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={SubmitLoading}
                                            >
                                                {editMode ? Translate.editProduct : Translate.makeProduct}
                                            </LoadingButton>
                                        </Grid>
                                    </Hidden>
                                    {
                                        (!!editMode && !!confirmed) &&
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <>
                                                                <Button
                                                                    component="a"
                                                                    target="_blank"
                                                                    size="small"
                                                                    startIcon={<PageviewRoundedIcon/>}
                                                                    href={`${defaultVariabls.website}/product/${slug}`}
                                                                    color="primary"
                                                                >
                                                                    {Translate.viewProduct}
                                                                </Button>
                                                                <Typography className='date-holder'>
                                                                    <span>
                                                                        {Translate.updated_at}
                                                                    </span>
                                                                    <span>
                                                                        {`${moment(this.state.updated_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                                                                    </span>
                                                                </Typography>
                                                                <Typography  className='date-holder'>
                                                                    <span>{Translate.created_at}</span>
                                                                    <span>
                                                                            {`${moment(this.state.created_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                                                                    </span>
                                                                </Typography>

                                                            </>
                                                    }
                                                </AppCard>
                                            </Grid>
                                    }
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} alignItems="center">
                                                        <Grid item xs={12}>
                                                            <SubTitle>{Translate.ThumbnailTitle}</SubTitle>
                                                            <Thumbnail rounded ref={this.ThumbChild}
                                                                       handleThumbImage={this.handleThumbImage.bind(this)}
                                                                       initial={thumbnail}/>
                                                            <Typography className="thumbnail_description">{Translate.thumbnailSize}</Typography>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SubTitle>{Translate.gallery}</SubTitle>
                                                            <ImageGallery onChange={this.handleGalleryChange.bind(this)} initial={gallery}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Feature check="brands">
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <BrandChooser
                                                        title={Translate.brand}
                                                        initial={brand_id}
                                                        changeBrand={(brand_id) => this.setState({brand_id})}
                                                    />
                                            }

                                        </AppCard>
                                    </Grid>
                                    </Feature>
                                    <Feature check="marketplace">
                                    <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12} style={{paddingBottom: 0}}>
                                                        <SubTitle align="left">{Translate.relatedShop}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        {
                                                            editMode ?
                                                                <TextField
                                                                    margin="normal"
                                                                    required
                                                                    type="text"
                                                                    fullWidth
                                                                    disabled
                                                                    label={Translate.shopName}
                                                                    value={fields.shop_name}
                                                                    variant="outlined"
                                                                /> :
                                                                <TextField
                                                                    select
                                                                    margin="normal"
                                                                    required
                                                                    type="text"
                                                                    fullWidth
                                                                    label={Translate.chooseShop}
                                                                    name="shop_id"
                                                                    value={fields.shop_id}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    variant="outlined"
                                                                    error={!!errors['shop_id']}
                                                                    helperText={!!errors['shop_id'] ? errors['shop_id'] : Translate.chooseShopHelperText}
                                                                >
                                                                    {
                                                                        user.role.type === "admin" &&
                                                                            !!primary_shop &&
                                                                        <MenuItem value={primary_shop.id}>
                                                                            {primary_shop.name}
                                                                        </MenuItem>
                                                                    }
                                                                    {user.shops.map((option) => (
                                                                        <MenuItem key={option.id} value={option.id}>
                                                                            {option.name}
                                                                        </MenuItem>
                                                                    ))}
                                                                </TextField>
                                                        }
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                    </Feature>
                                    {
                                        !!user.role.permission.manageShops ?
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} alignItems="center">
                                                                <Grid item xs={12}>
                                                                    <SubTitle align="left">{Translate.managementSetting}</SubTitle>
                                                                </Grid>
                                                                <Feature check="marketplace">
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        value={fields.commission}
                                                                        fullWidth
                                                                        label={Translate.commission}
                                                                        name="commission"
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error = {!!errors['commission']}
                                                                        helperText={errors['commission']}
                                                                        InputProps={{
                                                                            startAdornment: (
                                                                                <InputAdornment position="start">
                                                                                    %
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                </Feature>
                                                                <Grid item xs={12}>
                                                                    <Typography>{Translate.productTotalRating}:</Typography>
                                                                    <Rating
                                                                        name="rating"
                                                                        precision={0.5}
                                                                        value={rating}
                                                                        onChange={(event, newValue) => {
                                                                            this.setState({
                                                                                rating:newValue,
                                                                            })
                                                                        }}
                                                                    />
                                                                    <Typography className="litle_description_text">{Translate.productTotalRatingDesc}</Typography>
                                                                </Grid>
                                                                <Feature check="featured_products">
                                                                <Grid item xs={12}>
                                                                    <Alert
                                                                        severity="info"
                                                                        variant="outlined"
                                                                        icon={<StarRoundedIcon fontSize="small" />}
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={featured}
                                                                                        onChange={this.handleChangeFeatured.bind(this)}
                                                                                        value={featured}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={featured ? Translate.yes : Translate.no}
                                                                            />
                                                                        }
                                                                    >
                                                                        <Typography style={{fontSize:13}}>{Translate.featuredProduct}</Typography>
                                                                    </Alert>
                                                                </Grid>
                                                                </Feature>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            : null
                                    }
                                    <Hidden mdUp>
                                        <Grid item xs={12} style={{paddingTop: 0}}>
                                            <LoadingButton
                                                variant="contained"
                                                color="primary"
                                                styleForParent={{marginBottom:10}}
                                                style={{width: "100%"}}
                                                onClick={this.handleSubmit.bind(this)}
                                                loading={SubmitLoading}
                                            >
                                                {editMode ? Translate.editProduct : Translate.makeProduct}
                                            </LoadingButton>
                                            {
                                                !!editMode && !!confirmed ?
                                                    <Button
                                                        component="a"
                                                        target="_blank"
                                                        size="small"
                                                        startIcon={<PageviewRoundedIcon/>}
                                                        href={`${defaultVariabls.website}/product/${slug}`}
                                                        color="primary"
                                                    >
                                                        {Translate.viewProduct}
                                                    </Button>
                                                    : null
                                            }
                                        </Grid>
                                    </Hidden>
                                    <Grid item xs={12}>
                                    {
                                        !!editMode ?
                                            user.id === this.state.owner_id && !user.role.permission.manageShops && !this.state.requested_to_delete &&
                                                <Button
                                                    startIcon={<DeleteRoundedIcon/>}
                                                    style={{color:"#ff5451",marginTop:20}}
                                                    onClick={() => this.handleRequestToDelete()}
                                                >
                                                    {Translate.requestToDeleteProduct}
                                                </Button>
                                            : null
                                    }
                                    {
                                        !!editMode ?
                                            !!user.role.permission.manageShops &&
                                            <GetConfirmationText
                                                variant="outlined"
                                                style={{color: "#f44336",width:"100%",backgroundColor:"#fff"}}
                                                tooltip={Translate.removeProduct}
                                                handleAction={() => this.handleDeleteProduct()}
                                            >
                                                {Translate.removeProduct}
                                            </GetConfirmationText>
                                            : null
                                    }
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeProduct))));