import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import SubmitStepInfo from "./steps/SubmitStepInfo";
import SubmitStepFiles from "./steps/SubmitStepFiles";
import WaitingStep from "./steps/WaitingStep";
import StepsHeader from "./icons/StepsHeader";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import ValidateUserRegisterForm from "./steps/ValidateUserRegisterForm";
import AllDoneStep from "./steps/AllDoneStep";
import CheckFaultsStep from "./steps/CheckFaultsStep";
import Alert from "@material-ui/lab/Alert/Alert";
import {translate} from "../../../config/lang";
import UserCard from "../../components/elements/UserCard";
import {Translate} from "../../../config/Translate";

class RegisterStepsPage extends React.Component {
    classes = this.props.classes;
    state = {
        step_number: 2,
        loading:true,
    }
    componentDidMount() {
        this.loadData();
    }
    loadData(){
        this.setState({
            loading:true
        })
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        })
        let {user,manage} = this.props;
        let user_id = null;
        if(!!manage){
            let id = this.props.match.params.id
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/user/get/${id}`, config)
                .then(response => {
                    this.setState({
                        step_number: response.data.step,
                        loading:false,
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.setState({
                step_number:user.step,
                loading:false,
            })
        }
    }
    render(){
        let {user,manage,create,setting} = this.props;
        let user_id = null;
        if(!!manage){
            user_id = this.props.match.params.id
        }else{
            user_id = !!user ? user.id : null;
        }
        let {step_number,loading} = this.state;
        return (
            <>
                {
                    loading ?
                        <AppCardLoaderFields/> :
                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} lg={11}>
                                <a href={setting.banner.link}>
                                    <img
                                        style={{width:"100%",borderRadius:4}}
                                        src={`${defaultVariabls.uploads}/FileManager/${setting.banner.thumbnail.name}.${setting.banner.thumbnail.extension}`}/>
                                </a>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {
                                !!manage &&
                                <Grid item xs={12} lg={11}>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} lg={11}>
                                            <Alert severity="info" variant="filled">
                                                {translate('youAreViewingUserRegisterInfo')}
                                            </Alert>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12} lg={11}>
                                        <StepsHeader step_number={step_number}/>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} lg={11}>
                                {
                                    step_number === 2 ?
                                        <SubmitStepInfo
                                            manage={manage}
                                            user_id={user_id}
                                            reload={() => this.loadData()}
                                        /> :
                                        step_number === 3 ?
                                            <SubmitStepFiles
                                                manage={manage}
                                                user_id={user_id}
                                                reload={() => this.loadData()}
                                            /> :
                                            step_number === 4 ?
                                                <>
                                                {
                                                    !!manage ?
                                                        <ValidateUserRegisterForm
                                                            manage={manage}
                                                            user_id={user_id}
                                                            reload={() => this.loadData()}
                                                        />
                                                        :
                                                        <WaitingStep
                                                            manage={manage}
                                                            user_id={user_id}
                                                            reload={() => this.loadData()}
                                                        />
                                                }
                                                </>
                                            : step_number === 5 ?
                                                <>
                                                    {
                                                        !!manage ?
                                                        <ValidateUserRegisterForm
                                                            manage={manage}
                                                            user_id={user_id}
                                                            reload={() => this.loadData()}
                                                        />
                                                            :
                                                            <CheckFaultsStep
                                                                manage={manage}
                                                                user_id={user_id}
                                                                reload={() => this.loadData()}
                                                            />
                                                    }
                                                </>
                                                :
                                                step_number === 6 ?
                                                    <>

                                                        {
                                                            !!manage ?
                                                            <ValidateUserRegisterForm
                                                                done
                                                                manage={manage}
                                                                user_id={user_id}
                                                                reload={() => this.loadData()}
                                                            />
                                                                :
                                                                <AllDoneStep
                                                                    manage={manage}
                                                                    user_id={user_id}
                                                                    reload={() => this.loadData()}
                                                                />
                                                        }
                                                    </>
                                                : null
                                }
                            </Grid>
                            {
                                !!manage &&
                                <Grid item xs={12} lg={11}>
                                    <Grid container spacing={2} justifyContent="center">
                                        <Grid item xs={12} lg={11}>
                                            <UserCard title={translate('userDetail')} id={user_id}/>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            }
                        </Grid>
                }


            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
    setting: state.setting,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(RegisterStepsPage))));
