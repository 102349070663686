import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BackupIcon from '@material-ui/icons/Backup';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import axios from "axios/index";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {translate} from "../../../../config/lang";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import LoadingButton from "../../../components/elements/LoadingButton";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ArrowBackIcon from "../../../components/elements/ArrowBackIcon";
import YouTubeIcon from '@material-ui/icons/YouTube';
import {setStore} from "trim-redux";
import AddFile from "./AddFile";
import SingleImageChooser from "./SingleImageChooser";
import Typography from "@material-ui/core/Typography/Typography";
import SingleImageUploader from "./SingleImageUploader";
class VideoThumbnailChooser extends React.Component {
    state = {
        loading:false,
        SubmitLoading:false,
        url:'',
        error:'',
        data:this.props.file

    }

    classes = this.props.classes;
    handleClickOpen(){
        this.setState({
            open:true,
        })
    }
    handleClickClose(){
        this.setState({
            open:false,
        })
    }
    async handleSubmit(file = false){
        if(!!file){
            if(!!file.id){
                let data = new FormData();

                data.append('video_thumbnail',file.id);
                data.append('video_id',this.state.data.id);

                let loginToken = localStorage.getItem('ACT');
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                axios.post(`${defaultVariabls.url}/file/add-preview`, data, config)
                    .then(response => {
                        this.props.onChange(`${defaultVariabls.uploads}/FileManager/${response.data.name}_s_thumb.${response.data.extension}`);
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.props.enqueueSnackbar(translate('submitErrorFile'), {
                            variant: 'error',
                        });
                    });
            }else{
                await this.props.onChange();
            }
        }
    }
    render(){
        let {SubmitLoading,data} = this.state;
        return(
            <>
                <Dialog fullWidth maxWidth="sm" open={true} onClose={() => this.handleClickClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate('addPreviewToVideo')}</DialogTitle>
                    <DialogContent style={{textAlign:"center"}}>
                        <video style={{maxWidth:"100%"}}  controls>
                            <source src={`${defaultVariabls.uploads}/FileManager/${data.name}.${data.extension}`} type="video/mp4" />
                                Your browser does not support the video tag.
                        </video>
                        <Typography style={{marginTop:20,marginBottom:10,textAlign:"center"}} className={this.classes.condir}>{translate('doYouWantToAddThumbnail')}</Typography>
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={!data}
                            onClick={() => this.handleSubmit(true)}
                            loading={SubmitLoading}
                        >
                            {translate('addVideoWithoutPreview')}
                        </LoadingButton>
                        <SingleImageUploader
                            simple
                            title={translate('addPreviewImage')}
                            handlePreview={(file) => this.handleSubmit(file)}
                        />
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(VideoThumbnailChooser)));