import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect, setStore} from "trim-redux";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {FieldSkeleton} from "../../../components/Skeletons/FieldSkeleton";
import {translate} from "../../../../config/lang";


class DiscountChooser extends React.Component {
    state = {
        discounts: [],
        loading:true,
        selected:null,
        owner_id:this.props.owner_id
    };
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            selected: this.props.selected
        })
        this.loadData()
    }
    async componentDidUpdate(newProps){
        if(this.props.selected !== this.state.selected){
            await this.setState({
                selected: this.props.selected

            })
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        let {owner_id} =this.state;
        if(!owner_id){
            owner_id = this.props.user.id
        }
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let {locale} = this.props;
            await axios.get(`${defaultVariabls.url}/discounts/quickViewForChooser/${locale}/${owner_id}`, config)
                .then(response => {
                    this.setState({
                        discounts: response.data,
                    })
                    this.loadDefault();
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    handChange(value){
        this.setState({
            output:value,
        })
        this.props.outPut(value);
    }

    render(){
        let {discounts,selected} = this.state;
        return (
            <React.Fragment>
                {
                    this.state.loading ?
                        <FieldSkeleton/>
                        :
                        discounts !== undefined ?
                            <Autocomplete
                                options={discounts}
                                className="autoCompleteHolder"
                                onChange={(event, value) => this.handChange(value)}
                                getOptionLabel={option => option.name}
                                renderInput={params => (
                                    <TextField
                                        {...params}
                                        label={!!this.props.label ? this.props.label : translate('chooseADiscount')}
                                        variant="outlined"
                                        fullWidth
                                    />
                                )}
                            /> :
                            translate('noItem')
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    locale: state.locale,
    user: state.user,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(DiscountChooser))));
