import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography/Typography";
import {defaultVariabls} from "../../../../config";
import FormControl from "@material-ui/core/FormControl/FormControl";
import LoadingButton from "../../../components/elements/LoadingButton";
import axios from "axios";
import Grid from "@material-ui/core/Grid/Grid";
import {updateRedux} from "../../../../config/Security";
import {translate} from "../../../../config/lang";
import SubTitle from "../../../components/elements/SubTitle";
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
class UserDashboardConfirmation extends React.Component{
    open = this.props.new;
    is_agent = this.props.user.role.type === "normal";
    state= {
        dialogOpen: (!!this.is_agent && !this.props.user.agreement_confirmed),
        checked:false,
        SubmitLoading:false,
    };
    classes = this.props.classes;
    handleRequest(){
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user_agreement/confirmed`, config)
            .then(response => {
                this.setState({
                    SubmitLoading:false,
                    dialogOpen:false,
                })
                updateRedux();
            })
            .catch(error => {
                console.log(error)
                console.log(error.response)
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleRequest();
    }
    render(){
        let{checked,SubmitLoading} = this.state;
        return (
            <>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({dialogOpen:false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title" className={this.classes.alertText}>
                        <SubTitle >{translate('welcomeToPanel')}</SubTitle>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} style={{textAlign:"center"}}>
                                <ErrorOutlineRoundedIcon style={{fontSize:60,color:"#ab2a2a"}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Typography className={this.classes.textFloat}>{translate('pleaseConfirmTerms')}</Typography>
                            </Grid>
                            <Grid item xs={12} className={this.classes.textFloat}>
                                <FormControl required  component="fieldset">
                                    <FormControlLabel
                                        control={<Checkbox checked={checked} onChange={() => this.setState({checked:!checked})} />}
                                        label={
                                            <Typography className={this.classes.privacy_policy}>
                                                {translate('with')}
                                                {" "}
                                                <a target="_blank" href={`${defaultVariabls.website}/terms-and-conditions`} >
                                                    {translate('agreement')}
                                                </a>
                                                {" "}
                                                {translate('rulesConfirmation')}
                                            </Typography>
                                        }
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    disabled={!checked}
                                    onClick={this.handleSubmit.bind(this)}
                                    loading={SubmitLoading}
                                >
                                    {translate('save')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserDashboardConfirmation))));