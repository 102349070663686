import React from 'react';
import {connect, getStore, setStore} from "trim-redux";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import LanguageTwoToneIcon from '@material-ui/icons/LanguageTwoTone';
import {translate} from "../../../config/lang";
import {withRouter} from "react-router-dom";
import {defaultVariabls} from "../../../config";
class LanguageSwitcher extends React.Component {
    state = {
        anchorEl : null
    }

    handleClick = (event) => {
        this.setState({
            anchorEl:event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            anchorEl:null
        })
    };
    async toggleLanguage(){
        let current = getStore('locale');
        let locale = 'fa'
        if(current === "fa"){
            locale = 'en'
        }
        /*setStore({
            locale
        });*/
        if(locale === "fa"){
            localStorage.setItem('locale','fa');
            window.location.replace(`${defaultVariabls.React}${this.props.location.pathname}`);
        }else{
            localStorage.setItem('locale','en');
            window.location.replace(`${defaultVariabls.React}${this.props.location.pathname}`);
        }
    }
    render(){
        let {locale,style,className,menu} = this.props;
        let {anchorEl} = this.state;
        return (
            <div style={style} className={className}>
                <Button
                    aria-controls="Language"
                    aria-haspopup="true"
                    style={!!menu ? {color:"#fefefe"} : null}
                    onClick={() => this.toggleLanguage()}
                    startIcon={<LanguageTwoToneIcon color={!menu ? "primary" : 'inherit'}/>}
                >
                    {translate(locale === "fa" ? "en" : 'fa')}
                </Button>
                {/*<Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={() => this.handleClose()}
                >
                    <MenuItem onClick={() => this.changeLanguage('fa')}>{translate('en')}</MenuItem>
                    <MenuItem onClick={() => this.changeLanguage('en')}>{translate('fa')}</MenuItem>
                </Menu>*/}
            </div>

        );
    }
}
const mstp = state => ({
    locale: state.locale,
});
export default withRouter(connect(mstp)(LanguageSwitcher));