import React, {Component} from 'react';
import Box from "@material-ui/core/Box/Box";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {connect} from "trim-redux";
import {Styles} from "../../../../../config/design/styles";
class Emojies extends Component {
    classes = this.props.classes;

    addEmoji = emoji => {
        this.props.addEmoji(emoji);
    };
    render() {
        return (
            <Box className="emojies_holder">
                <IconButton size="small" onClick={() => this.addEmoji("👌")}>
                    <span role="img" aria-label="nice">👌</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("❤️")}>
                    <span role="img" aria-label="nice">❤️</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🌸")}>
                    <span role="img" aria-label="nice">🌸</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🌹")}>
                    <span role="img" aria-label="nice">🌹</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("👍")}>
                    <span role="img" aria-label="nice">👍</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😂")}>
                    <span role="img" aria-label="nice">😂</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😊")}>
                    <span role="img" aria-label="nice">😊</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😐")}>
                    <span role="img" aria-label="nice">😐</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🙁")}>
                    <span role="img" aria-label="nice">🙁</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("👆")}>
                    <span role="img" aria-label="nice">👆</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("👇")}>
                    <span role="img" aria-label="nice">👇</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🙏")}>
                    <span role="img" aria-label="nice">🙏</span>
                </IconButton>

                <IconButton size="small" onClick={() => this.addEmoji("😳")}>
                    <span role="img" aria-label="nice">😳</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🤔")}>
                    <span role="img" aria-label="nice">🤔</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😎")}>
                    <span role="img" aria-label="nice">😎</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🧐")}>
                    <span role="img" aria-label="nice">🧐</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😅")}>
                    <span role="img" aria-label="nice">😅</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😀")}>
                    <span role="img" aria-label="nice">😀</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😁")}>
                    <span role="img" aria-label="nice">😁</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🙂")}>
                    <span role="img" aria-label="nice">🙂</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😔")}>
                    <span role="img" aria-label="nice">😔</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😠")}>
                    <span role="img" aria-label="nice">😠</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("😢")}>
                    <span role="img" aria-label="nice">😢</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("👏")}>
                    <span role="img" aria-label="nice">👏</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("🤝")}>
                    <span role="img" aria-label="nice">🤝</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("👎")}>
                    <span role="img" aria-label="nice">👎</span>
                </IconButton>
                <IconButton size="small" onClick={() => this.addEmoji("✌️")}>
                    <span role="img" aria-label="nice">✌️</span>
                </IconButton>
            </Box>
        );
    }
}

const mstp = state => ({
    user: state.user,
    Translate: state.Translate,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Emojies))));
