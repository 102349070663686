import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {secret} from "../../../../../config/Security";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {rolesWithName} from '../../../../../config/Permissions'
import MuiPhoneNumber from "material-ui-phone-number";
import {translate} from "../../../../../config/lang";
import AppCard from "../../../../components/layouts/components/AppCard";
import SubTitleLight from "../../../../components/elements/SubTitleLight";
import moment from "moment-timezone";
import SubTitle from "../../../../components/elements/SubTitle";
import Divider from "@material-ui/core/Divider/Divider";
import Typography from "@material-ui/core/Typography";
import {getMonths, getTimeZone} from "../../../../../config/values";
import PlanChooser from "./PlanChooser";
import UserSuspension from "./UserSuspension";
import FormControl from "@material-ui/core/FormControl/FormControl";
import {FormLabel} from "@material-ui/core";
import RadioGroup from "@material-ui/core/RadioGroup/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Radio from "@material-ui/core/Radio/Radio";
class GetPlanToUser extends React.Component {
    classes = this.props.classes
    state={
        plans:[],
        loading:true,
        SubmitLoading:false,
        user_id:this.props.user_id,
        ad_count:0,
        user:null,
        plan_id:null,
        reserve:"reserve",
    }
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${user_id}`, config)
            .then(response => {
                let user_has_plan = false;
                if(!!response.data.plan){
                    if(response.data.plan.parent.type === "premium"){
                        user_has_plan = true;
                    }

                }
                this.setState({
                    user:response.data,
                    loading:false,
                    user_has_plan

                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }

    handleRequest(){
        this.setState({
            SubmitLoading:true
        });
        const {plan_id,user_id,ad_count,reserve} = this.state;
        let data = new FormData();
        if(!ad_count && !plan_id){
            this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                variant: 'error',
            });
        }else{
            data.append('user_id', user_id);
            data.append('plan_id', plan_id);
            data.append('ad_count', ad_count);
            data.append('reserve', reserve);
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/user/setPlan`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(translate('detailsSet'), {
                        variant: 'success',
                    });
                    this.setState({
                        SubmitLoading:false
                    });
                    this.props.history.push(`/manage/users/view/all`);
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    this.setState({
                        SubmitLoading:false
                    });
                });
        }


    }

    handleChangeReserve(e){
        this.setState({
            reserve: e.target.value
        })
    }
    render() {
        const {errors,user,SubmitLoading,ad_count,reserve,plan_id,user_has_plan} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <Grid container spacing={2} justify="center">
                    {
                        user.role.type !== "admin" ?
                            <Grid item xs={12} md={11} lg={10}>
                                <Grid container  spacing={2} justify="center" alignItems="center">
                                    <CssBaseline/>
                                    {
                                        !!user.plan_id &&
                                        <>
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} className="my-plan-container">
                                                            <img
                                                                src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`} />
                                                            <SubTitleLight style={{color:user.plan.parent.color}}>{this.props.locale === "en" ? user.plan.parent.english_name : user.plan.parent.name}</SubTitleLight>
                                                            {
                                                                !!user.plan_usage.finishes_at &&
                                                                <span style={{color:user.plan.parent.color}}>{translate('planValidity')} <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong></span>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <AppCard style={{height:"100%"}}>
                                                                <SubTitle align="left" style={{position:"relative",marginBottom:33}}>
                                                                    {translate('planInfo')}
                                                                </SubTitle>
                                                                <Typography className='date-holder'>
                                                                    <span>{translate('monthlyAdCount')}</span>
                                                                    <strong className={this.classes.primary_color}>{parseInt(user.ad_count) - parseInt(user.plan_usage.ad_count)}</strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography className='date-holder'>
                                                                    <span>{translate('monthlyEditCount')}</span>
                                                                    <strong className={this.classes.primary_color}>{parseInt(user.edit_count) - parseInt(user.plan_usage.edit_count)}</strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography className='date-holder'>
                                                                    <span>{translate('philmag')}</span>
                                                                    <strong className={this.classes.primary_color}>{parseInt(user.plan.philmag)}</strong>
                                                                </Typography>
                                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                <Typography className='date-holder'>
                                                                    <span>{translate('photography')}</span>
                                                                    <strong className={this.classes.primary_color}>{parseInt(user.plan.photography)}</strong>
                                                                </Typography>
                                                            </AppCard>
                                                        </Grid>
                                                        {
                                                            !!user.reserved_plan_id &&
                                                            <Grid item xs={12}>
                                                                <AppCard>
                                                                    <SubTitle align="left">
                                                                        {translate('reserved_plan')}
                                                                    </SubTitle>
                                                                    <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                    <Typography style={{color:user.reserved_plan.parent.color,display:"flex",alignItems:"center"}}>
                                                                        <img style={{width:24,marginRight:10}} src={`${defaultVariabls.uploads}/FileManager/${user.reserved_plan.parent.thumbnail.name}.${user.reserved_plan.parent.thumbnail.extension}`} />
                                                                        <span>{this.props.locale === "en" ?  user.reserved_plan.parent.english_name : user.reserved_plan.parent.name} ({getMonths(user.reserved_plan.duration)} Months)</span>
                                                                    </Typography>
                                                                    <Divider style={{marginTop:10,marginBottom:10}}/>
                                                                    <Typography className='date-holder'>
                                                                        <span>{translate('staringDate')}</span>
                                                                        <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong>
                                                                    </Typography>
                                                                </AppCard>
                                                            </Grid>
                                                        }
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <SubTitle align={"left"}>{translate('givePlanToUser')}</SubTitle>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <PlanChooser handlePlan={(plan_id) => this.setState({plan_id})}/>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            name="ad_count"
                                            label={translate('ad_count_admin')}
                                            type="number"
                                            value={ad_count}
                                            onChange={e => this.setState({
                                                ad_count: parseInt(e.target.value) > 0 ? Math.abs(parseInt(e.target.value)) : 0
                                            })}
                                        />
                                    </Grid>
                                    {
                                        (!!plan_id && !!user_has_plan) &&
                                        <Grid item xs={12}>
                                            <FormControl className="reserve-radio">
                                                <FormLabel>{translate('reserveSituation')}</FormLabel>
                                                <RadioGroup name="reserve" value={reserve} onChange={this.handleChangeReserve.bind(this)}>
                                                    <FormControlLabel value={"reserve"} control={<Radio />} label={translate('do_reserve')} />
                                                    <FormControlLabel value="renew" control={<Radio />} label={translate('do_renew')} />
                                                </RadioGroup>
                                            </FormControl>
                                        </Grid>

                                    }
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleRequest.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {translate('save')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid> :
                            <Grid item xs={12} md={11} lg={10} >
                                <Typography style={{textAlign:"center"}} className={this.classes.condir}>{translate('adminHasFullAccess')}</Typography>
                            </Grid>
                    }
                    {
                        (
                            user.role.type !== "admin" &&
                            (!!user.plan_id || !!user.suspended_plan_id) &&
                            (!!user.suspended_plan_id || user.step === 6)
                        ) &&
                        <Grid item xs={12}  md={11} lg={10}>
                            <UserSuspension user={user}/>
                        </Grid>
                    }

                </Grid>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(GetPlanToUser))));