import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {defaultVariabls} from "../../../../../config/index";
import {connect} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import DeleteButton from "../../../../components/elements/DeleteButton";
import MakeBannerButton from "./Make‌BannerButton";
import {translate} from "../../../../../config/lang";

class SlideShowItem extends React.Component {

    render(){
        let {item} = this.props;
        return (
            <div
                className="slide_show_item"
                style={{backgroundImage: `url("${defaultVariabls.uploads}/FileManager/${item.thumbnail.name}.${item.thumbnail.extension}")`,}}

            >
                <h3>{translate(item.position)}</h3>
                <div className="controller">
                    <MakeBannerButton id={item.id} reload={() => this.props.reload()}/>

                </div>
            </div>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SlideShowItem)))));