import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../../../components/elements/BreadCrumbs";
import AppAnimate from "../../../../../components/AppAnimate";
import AppCard from "../../../../../components/layouts/components/AppCard";
import ManageContactInfo from "./ManageContactInfo";
import CodeEditor from "../../../template/components/CodeEditor";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import LiveHelpRoundedIcon from '@material-ui/icons/LiveHelpRounded';
import {translate} from "../../../../../../config/lang";


class ContactInformationPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('contactInformation')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <Alert severity="info" variant="outlined">
                            {translate('toViewContentsInOtherLanguageToggleLanguage')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} md={11}>
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12}>
                                <AppCard
                                    title={translate('contactInformation')}
                                    action={
                                        <BreadCrumbs singleLevel firstText={translate('contactInformation')}/>
                                    }>
                                    <ManageContactInfo/>
                                </AppCard>
                            </Grid>

                        </Grid>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(ContactInformationPage));
