import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {translate} from "../../../../../config/lang";
import VisibilityRoundedIcon from '@material-ui/icons/VisibilityRounded';
import Comments from "../../comments/Comments";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class ViewRelatedComments extends React.Component{
    state = {
        open: false,
        item_id:this.props.item_id,
        type:this.props.type,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            item_id:this.props.item_id,
            type:this.props.type,
        })
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            await this.setState({
                item_id:this.props.item_id,
                type:this.props.type,
            })
        }
    }
    toggleOpen(){
        this.setState({
            open:!this.state.open
        });
    }
    handleClose = () => {
        this.setState({
            open:false
        });
    };
    render(){
        let {open} = this.state;
        return(
            <>
                <IconButton
                    onClick={() => this.toggleOpen()}
                    color="primary"
                    size="small"
                >
                    <VisibilityRoundedIcon fontSize="small"/>
                </IconButton>
                <Dialog fullWidth={true} maxWidth={"xl"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {translate('manageComments')}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding:20}}>
                        <Comments type={this.state.type} type_id={this.state.item_id}/>
                    </div>
                </Dialog>
            </>
        )
    }
}
export default  withStyles(Styles)(ViewRelatedComments)