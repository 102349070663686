import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import FileManagerDialog from "./FileManagerDialog";
import {defaultVariabls} from "../../../../config";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../config/lang";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ImageTwoToneIcon from '@material-ui/icons/ImageTwoTone';
import Grid from "@material-ui/core/Grid/Grid";
class SingleFileChooser extends React.Component {
    classes = this.props.classes;
    state={
        FileManagerPending: false,
        file: null,
    }
    async componentDidMount(){
        if(!!this.props.initial){
            await this.setState({
                file:this.props.initial
            })
            await this.fileChanges();
        }
        await this.loadData()
    }
    async loadData(){
        await this.setState({
            file:this.props.initial
        })
        await this.fileChanges();
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }

     setData = async (data) => {
        await this.setState({
            file:data
        })       
        
        await this.fileChanges();
        await this.toggleOperation();
    }
    async removeDataSheet(){
        await this.setState({
            file: null
        });
        this.fileChanges();
    }
    async fileChanges(){
        let {file} = this.state;
        await this.props.onChange(file)
    }
    render(){
        let {FileManagerPending,file} = this.state;
        let{title,required,disabled,print,simple,primary,logo} = this.props;

        return(
            <Grid container spacing={2} justify="center">
                <Grid item xs={primary ? 12 : 6} md={primary ? 10 : 3}>
                    {
                        (!!file && !simple) &&
                        <div className={this.classes.singleImageHolder}>
                            <a  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={`${defaultVariabls.uploads}/FileManager/${file.name}.${file.extension}`}>
                                <img src={`${defaultVariabls.uploads}/FileManager/${file.name}${logo ? "_medium" : "_thumbnail"}.${file.extension}`} className="fileChooser-image"/>
                            </a>
                            {
                                !disabled &&
                                <Button
                                    startIcon={<CloseRoundedIcon />}
                                    size="small"
                                    variant="contained"
                                    onClick={() => this.removeDataSheet()}
                                >
                                    {translate('removeImage')}
                                </Button>
                            }
                        </div>
                    }
                    {
                        !disabled && !file &&
                        <Button
                            startIcon={<ImageTwoToneIcon />}
                            color="primary"
                            variant="outlined"
                            className={!simple ? this.classes.addImageBtn : ''}
                            onClick={this.toggleOperation.bind(this)}
                        >

                            {!!this.props.title ? this.props.title : translate('addImage')}
                        </Button>
                    }

                </Grid>
                {
                    FileManagerPending ?
                        <FileManagerDialog
                            preview={this.props.preview}
                            selection
                            logo={this.props.logo}
                            cancleOperation={this.toggleOperation}
                            setData={this.setData}
                            defined_width={this.props.defined_width}
                            defined_height={this.props.defined_height}
                        /> : null
                }
            </Grid>
        )

    }
}
export default withStyles(Styles)(SingleFileChooser);