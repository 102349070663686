import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import {translate} from "../../../config/lang";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router-dom";
import Alert from "@material-ui/lab/Alert/Alert";
import AddTicketReport from "./add_ticket/AddTicketReport";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";


class ReportsPage extends React.Component {
    classes = this.props.classes;
    state ={
        loading:true,
        page:null
    }
    componentDidMount() {
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/page/get/report/${this.props.locale}`, config)
                .then(response => {
                    this.setState({
                        loading:false,
                        page: response.data

                    })
                    this.setState({
                        loading: false
                    })
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {page,loading}= this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('report')}>
                <Grid container spacing={2} justifyContent="center">
                    {
                        !loading &&
                        <>
                            <Grid item xs={12} lg={11}>
                                <img
                                    style={{width:"100%",borderRadius:4}}
                                    src={`${defaultVariabls.uploads}/FileManager/${page.thumbnail.name}.${page.thumbnail.extension}`}/>
                            </Grid>
                        </>
                    }

                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('report')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('report')}/>
                            }>
                            {
                                loading ?
                                    <AppCardLoaderFields/>
                                    :
                                    <>
                                        <Grid container spacing={2} alignItems="center" justifyContent="center">
                                            <Grid item xs={12} lg={11}>
                                                <Alert severity="info">
                                                    {page.description}
                                                </Alert>
                                            </Grid>
                                            <Grid item xs={12} lg={11}>
                                                <AddTicketReport/>
                                            </Grid>
                                        </Grid>
                                    </>
                            }
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ReportsPage))));
