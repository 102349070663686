import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import Button from "@material-ui/core/Button/Button";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import {makeSlug, toggleLocale, translate} from "../../../config/lang";
import CurrencyChooser from "../../components/fields/CurrencyChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {hasAccess, updateRedux} from "../../../config/Security";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import CustomFields from "./components/CustomFields";
import Dialog from "@material-ui/core/Dialog/Dialog";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import AppBar from "@material-ui/core/AppBar/AppBar";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import CloseIcon from '@material-ui/icons/Close';
import CheckUserCanMakeAd from "./components/CheckUserCanMakeAd";
import EditRoundedIcon from "@material-ui/icons/EditRounded";
import AddRoundedIcon from "@material-ui/icons/AddRounded";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";
import {truncate} from "../../../config/values";

class MakeAdvertisement extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            meta_title:"",
            description:"",
            address:"",
            price:'',
            priceR:'',
            phone_1:'',
            phone_2:'',
            phone_3:'',
            email_1:'',
            email_2:'',
            email_3:'',
            website:'',
            whatsapp:'',
            telegram:'',
            instagram:'',
            facebook:'',
            twitter:'',
            youtube:'',
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        errors:{},
        categories:[],
        categoriesName:[],
        gallery: [],
        loading: true,
        currency: '',
        SubmitLoading: false,
        publish: true,
        confirmed: false,
        real_confirmed: false,
        slug: null,
        id:this.props.equal_id,
        editMode:false,
        owner_id:null,
        owner:null,
        requested_to_delete:0,
        editor_input_text:'',
        updated_at:null,
        created_at:null,
        expired_at:null,
        expired:0,
        expired_this_week:0,
        activePhoneNumbers:1,
        phoneNumberFilled:false,
        activeEmail:1,
        emailFilled:false,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        likes_count:0,
        dislikes_count:0,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        parent_category:null,
        custom_fields:null,
        allowed_comment:0,
        item:this.props.item,
        modal:false,
        advertisement:null,
        is_best: false,

    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id: this.props.equal_id,
            loading:true
        })
        if(this.state.id){
            await this.setState({
                editMode:true
            });
            await this.loadData();
        }else{
            if(!!this.props.item.equal_categories){
                await this.setState({
                    categories: this.props.item.equal_categories.ids,
                    categoriesName: this.props.item.equal_categories.names,
                    parent_category: this.props.item.equal_categories.parent,
                });
            }
            await this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.equal_id){
            this.setState({
                id: this.props.equal_id,
                loading:true
            })
            if(!!this.state.id && !!this.props.equal_id){
                this.loadData();
            }
        }
    }
    async toggleModal(){
        await toggleLocale();
        await this.setState({
            modal: !this.state.modal
        })
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true,
        })
        if(!this.state.id || !this.props.equal_id || (this.props.equal_id !== this.state.id)) {
            this.setState({
                editMode:false,
                loading:false,
                id:null
            })
        }else {
            let loginToken = localStorage.getItem('ACT');
            if(loginToken !== null){
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/advertisement/get/${this.state.id}`, config)
                    .then(response => {
                        this.setState({
                            fields: {
                                name: response.data.name,
                                description: !!response.data.description ? response.data.description : '',
                                address: !!response.data.address ? response.data.address : '',
                                price:!!response.data.price ? response.data.price : '',
                                priceR:!!response.data.price ? response.data.price : '',
                                phone_1: !!response.data.phone_1 ? response.data.phone_1 : '',
                                phone_2: !!response.data.phone_2 ? response.data.phone_2 : '',
                                phone_3: !!response.data.phone_3 ? response.data.phone_3 : '',
                                email_1: !!response.data.email_1 ? response.data.email_1 : '',
                                email_2: !!response.data.email_2 ? response.data.email_2 : '',
                                email_3: !!response.data.email_3 ? response.data.email_3 : '',
                                website: !!response.data.website ? response.data.website : '',
                                telegram: !!response.data.telegram ? response.data.telegram : '',
                                whatsapp: !!response.data.whatsapp ? response.data.whatsapp : '',
                                facebook: !!response.data.facebook ? response.data.facebook : '',
                                instagram: !!response.data.instagram ? response.data.instagram : '',
                                twitter: !!response.data.twitter ? response.data.twitter : '',
                                youtube: !!response.data.youtube ? response.data.youtube : '',
                                meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                            },
                            thumbnail: response.data.thumbnail,
                            content: {
                                HTML: response.data.html,
                                RAW: response.data.raw,
                            },
                            confirmed: response.data.confirmed,
                            real_confirmed: response.data.confirmed,
                            slug: response.data.slug,
                            currency: response.data.currency,
                            owner_id: response.data.user_id,
                            owner: response.data.owner,
                            updated_at: response.data.updated_at,
                            created_at: response.data.created_at,
                            expired_at: response.data.expired_at,
                            expired: parseInt(response.data.expired),
                            expired_this_week: parseInt(response.data.expired_this_week),
                            requested_to_delete: parseInt(response.data.requested_to_delete),
                            lat:!!response.data.lat ? parseFloat(response.data.lat) : '',
                            lng:!!response.data.lng ? parseFloat(response.data.lng) : '',
                            country:!!response.data.country ? response.data.country : null,
                            state:!!response.data.state ? response.data.state : null,
                            server_country:!!response.data.country ? response.data.country : null,
                            server_state:!!response.data.state ? response.data.state : null,
                            likes_count:parseInt(response.data.likes_count),
                            dislikes_count:parseInt(response.data.dislikes_count),
                            visits:parseInt(response.data.visits_count),
                            today_visits:parseInt(response.data.today_visits_count),
                            comments:parseInt(response.data.comments_count),
                            lang:response.data.lang,
                            custom_fields:response.data.custom_fields,
                            allowed_comment:parseInt(response.data.allowed_comment),
                            is_best: response.data.is_best,

                        })
                        let categoriesTemp=[];
                        let categoriesNameTemp=[];
                        let galleryTemp=[];
                        let parent_category = null;
                        response.data.categories.map(item => {
                            if(!parent_category){
                                parent_category = item.id;
                            }
                            categoriesTemp.push(item.id)
                            categoriesNameTemp.push(item.name)
                            return true
                        })
                        response.data.gallery.map(item =>{
                            galleryTemp.push({
                                src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                                link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                                id: item.id,
                                name: item.name,
                                type: item.type,
                                preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                            })
                            return true
                        })
                        if(!!response.data.phone_2){
                            if(!!response.data.phone_3){
                                this.setState({
                                    activePhoneNumbers:3
                                })
                            }else{
                                this.setState({
                                    activePhoneNumbers:2
                                })
                            }
                        }
                        if(!!response.data.email_2){
                            if(!!response.data.email_3){
                                this.setState({
                                    activeEmail:3
                                })
                            }else{
                                this.setState({
                                    activeEmail:2
                                })
                            }
                        }
                        this.setState({
                            categories: categoriesTemp,
                            categoriesName: categoriesNameTemp,
                            gallery: galleryTemp,
                            parent_category,
                            advertisement:response.data,
                            equal:response.data.equal,
                            loading: false
                        })
                    }).catch(error => {
                        console.log(error.response)
                        /*this.props.history.push('/');
                        this.props.enqueueSnackbar(translate('pageNotFound'), {
                            variant: 'error',
                        });*/
                        this.setState({
                            loading:false
                        })
                    })
            }else {
                setStore({
                    isAuth : false,
                    user : null,
                });
                this.setState({
                    loading:false
                })
            }
        }
    }
    handleChange(e){
        let {errors,fields,activeEmail} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
        });
        if(target.name === "email_1" ||target.name === "email_2" ||target.name === "email_3"  ){
            if(!!target.value && ((target.name === "email_1" && activeEmail === 1) || (target.name === "email_2" && activeEmail === 2))){
                if(validator.isEmail(target.value)){
                    this.setState({
                        emailFilled: true
                    })
                }
            }else {
                this.setState({
                    emailFilled: false
                })
            }
        }
    }

    handleValidation(callback){
        let { fields,country,state,lat } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        //address
        if(fields.address !== null ) {
            if (fields.address.length > 150) {
                formIsValid = false;
                errors['address'] = translate('maxLength150');
            }
        }
        /*if(!country){
            formIsValid = false;
            errors['country'] = translate('emptyField');
        }
        if(!state){
            formIsValid = false;
            errors['state'] = translate('emptyField');
        }
        */

        if(!!fields.phone_1){
            if(fields.phone_1.length >= 18){
                formIsValid = false;
                errors['phone_1'] = translate('phoneIsWrong');
            }
        }
        if(!!fields.phone_2){
            if(fields.phone_2.length >= 18){
                formIsValid = false;
                errors['phone_2'] = translate('phoneIsWrong');
            }
        }
        if(!!fields.phone_3){
            if(fields.phone_3.length >= 18){
                formIsValid = false;
                errors['phone_3'] = translate('phoneIsWrong');
            }
        }
        if(!!fields.email_1){
            if(!validator.isEmail(fields.email_1)){
                formIsValid = false;
                errors['email_1'] = translate('wrongEmail');
            }
        }
        if(!!fields.email_2){
            if(!validator.isEmail(fields.email_2)){
                formIsValid = false;
                errors['email_2'] = translate('wrongEmail');
            }
        }
        if(!!fields.email_3){
            if(!validator.isEmail(fields.email_3)){
                formIsValid = false;
                errors['email_3'] = translate('wrongEmail');
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,content,item,is_best,thumbnail,custom_fields,allowed_comment,editMode,gallery,categories,confirmed,currency,lat,lng,country,state} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('meta_title', fields.meta_title);
        data.append('html', content.HTML);
        data.append('lang', this.props.fake_locale);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('address', fields.address);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('currency', currency);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('price', fields.priceR);
        data.append('phone_1', fields.phone_1);
        data.append('phone_2', fields.phone_2);
        data.append('phone_3', fields.phone_3);
        data.append('email_1', fields.email_1);
        data.append('email_2', fields.email_2);
        data.append('email_3', fields.email_3);
        data.append('website', fields.website);
        data.append('whatsapp', fields.whatsapp);
        data.append('telegram', fields.telegram);
        data.append('facebook', fields.facebook);
        data.append('instagram', fields.instagram);
        data.append('twitter', fields.twitter);
        data.append('youtube', fields.youtube);
        data.append('lat', lat);
        data.append('lng', lng);
        data.append('country_id', !!country ? country.id : '');
        data.append('state_id', !!state ? state.id : '' );
        data.append('allowed_comment', parseInt(allowed_comment) );
        data.append('equal_id', item.id);
        data.append('is_best',is_best ? 1 : 0);
        data.append('categories', JSON.stringify(categories));
        data.append('custom_fields', JSON.stringify(custom_fields));

        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/advertisement/make-equal`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('adEdited') : translate('adAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.loadData();
                this.toggleModal();
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('adEditingFailed') : translate('adAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        let {parent_category} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }
        this.setState({categories})
    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    async handleRequestToDelete(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/advertisement/request_delete/${this.state.id}`, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('requestedToDelete'), {
                    variant: 'success',
                });
                this.setState({
                   requested_to_delete:1,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }
    handleDeleteAdvertisement(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/advertisement`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteAdvertisement'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/advertisements')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteAdvertisementFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleChangeCurrency(currency){
        this.setState({
            currency
        })
    }
    handleChangePhone(phone,name){
        let {fields,errors,activePhoneNumbers} = this.state;
        fields[name] = phone.replace("+0","+98");
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
        if(!!phone && ((name === "phone_1" && activePhoneNumbers === 1) || (name === "phone_2" && activePhoneNumbers === 2))){
            this.setState({
                phoneNumberFilled: true
            })
        }else {
            this.setState({
                phoneNumberFilled: false
            })
        }
    }
    handleChangeWa(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })
    }
    handleChangeBest(){
        this.setState({
            is_best: !this.state.is_best,
        })
    }
    render(){
        let {fields,currency,is_best,item,equal,advertisement,modal,country,parent_category,loading,dislikes_count,likes_count,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,lat,lng} = this.state;
        const {user,list} = this.props;
        return (
            <>
                {
                    !!advertisement ?
                        <Button
                            color={!!list ? 'default' : "primary"}
                            variant={!!list ? 'text' : "outlined"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<EditRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {!!list ? truncate(advertisement.name,8) : advertisement.name }
                        </Button> :
                        <Button
                            color={!!list ? 'default' : "primary"}
                            variant={!!list ? 'outlined' : "contained"}
                            disabled={loading}
                            size={!!list ? 'small' : "medium"}
                            onClick={() => this.toggleModal()}
                            startIcon={<AddRoundedIcon/>}
                            style={{whiteSpace:"nowrap"}}
                        >
                            {translate('addEqual')}
                        </Button>
                }
                <Dialog
                    open={this.state.modal}
                    maxWidth={"md"}
                    fullWidth
                    onClose={() => this.toggleModal()}
                >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}
                            </Typography>
                            <IconButton color="inherit" onClick={() => this.toggleModal()} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <DialogContent
                        className={this.classes.dialogRoot}
                    >
                        <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}>
                            {
                                <Grid container spacing={2} justify="center">
                                    <Grid item xs={12} lg={11}>
                                        <Grid container spacing={2} justify="center">
                                            {/*{
                                                (user.role.type !== "admin" && !loading) &&
                                                <CheckUserCanMakeAd edit={!!editMode}/>
                                            }*/}
                                            {
                                                (!!editMode && !loading) &&
                                                <>
                                                    {/*<Grid item xs={12}>
                                                        {
                                                            !! user.role.permission.manageAdvertisements ?
                                                                <>
                                                                    <Alert
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={confirmed}
                                                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                                                        value={confirmed}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('confirmationStatusDescription')}
                                                                    </Alert>
                                                                </>
                                                                :
                                                                confirmed ?
                                                                    <Alert severity="success" variant="filled">
                                                                        <Typography  className={this.classes.alertText}>{translate('yourAdvertisementIsActive')}</Typography>
                                                                    </Alert>
                                                                    :
                                                                    <Alert severity="warning">
                                                                        <Typography className={this.classes.alertText}>{translate('yourAdvertisementIsInchecking')}</Typography>
                                                                    </Alert>
                                                        }
                                                    </Grid>*/}
                                                    <Grid item xs={12}>
                                                        {
                                                            !!real_confirmed ?
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`ad/${slug}`,this.state.lang)}
                                                                        color="primary"
                                                                    >
                                                                        {translate('viewAdvertisement')}
                                                                    </Button>
                                                                </> :
                                                                <>
                                                                    <Button
                                                                        component="a"
                                                                        target="_blank"
                                                                        size="small"
                                                                        variant="contained"
                                                                        startIcon={<PageviewRoundedIcon/>}
                                                                        href={makeSlug(`ad/${slug}`,this.state.lang)}
                                                                        color="primary"
                                                                    >
                                                                        {translate('viewPreview')}
                                                                    </Button>

                                                                </>
                                                        }
                                                    </Grid>
                                                </>
                                            }
                                            <Grid item xs={12}>
                                                <AppCard
                                                    title={editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}
                                                    action={
                                                        <BreadCrumbs singleLevel firstText={editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}/>
                                                    }>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>
                                                                <Grid item xs={12} md={6}>
                                                                    <TextField
                                                                        margin="normal"
                                                                        required
                                                                        className={this.classes.inputCtlEqual}
                                                                        fullWidth
                                                                        name="name"
                                                                        variant="outlined"
                                                                        label={translate('advertisementName')}
                                                                        type="text"
                                                                        id="name"
                                                                        value={fields.name}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['name']}
                                                                        helperText={errors['name']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <CurrencyTextField
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        margin="normal"
                                                                        currencySymbol={<CurrencyChooser current={currency}
                                                                                                         onChange={(currency) => this.handleChangeCurrency(currency)}/>}
                                                                        decimalPlaces={2}
                                                                        minimumValue={0}
                                                                        value={fields.price}
                                                                        id="price"
                                                                        label={translate('price')}
                                                                        name="price"
                                                                        onChange={this.handleChange.bind(this)}
                                                                        error={!!errors['price']}
                                                                        helperText={errors['price']}
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <TextField
                                                                        variant="outlined"
                                                                        multiline
                                                                        fullWidth
                                                                        name="description"
                                                                        label={translate('seoDescription')}
                                                                        type="text"
                                                                        id="description"
                                                                        className={this.classes.inputCtlEqual}
                                                                        minRows="4"
                                                                        value={fields.description}
                                                                        onChange={this.handleChange.bind(this)}
                                                                        onClick={this.handleClickDescription.bind(this)}
                                                                        error={!!errors['description']}
                                                                        helperText={errors['description']}
                                                                        InputProps={{
                                                                            endAdornment: (
                                                                                <InputAdornment position="end">
                                                                                    <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                                                </InputAdornment>
                                                                            ),
                                                                        }}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppCard style={{height:'100%'}}>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <>
                                                                <CustomFields
                                                                    type={"advertisement"}
                                                                    position_type="custom_fields"
                                                                    input={this.state.custom_fields}
                                                                    category_id={this.state.parent_category}
                                                                    handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                                    equal
                                                                />
                                                            </>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <AppCard>
                                                    {
                                                        this.state.loading ? <AppCardLoaderFields/> :
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12}>
                                                                    <SubTitle align="left">{translate('advertisementDescription')}</SubTitle>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <YourPlanDoesntSupportThis visible={!hasAccess('has_html')}/>

                                                                    <RichEditor
                                                                        inputText={editor_input_text}
                                                                        ref={this.EditorChild}
                                                                        Output={this.handleOutPut.bind(this)}
                                                                        input={content.RAW}
                                                                        minHeight={300}
                                                                        readOnly={!hasAccess('has_html')}
                                                                    />
                                                                </Grid>
                                                            </Grid>
                                                    }
                                                </AppCard>
                                            </Grid>
                                            {
                                                (!!user.role.permission.manageShops) &&
                                                <Grid item xs={12}>
                                                    <AppCard style={{height:'100%'}}>
                                                        <Grid container spacing={2}>
                                                            <Grid item xs={12}>
                                                                <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                            </Grid>
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    fullWidth
                                                                    margin="normal"
                                                                    name="meta_title"
                                                                    variant="outlined"
                                                                    label={translate('meta_title')}
                                                                    value={fields.meta_title}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error={!!errors['meta_title']}
                                                                    helperText={errors['meta_title']}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                    </AppCard>
                                                </Grid>
                                            }
                                            {/*{
                                                (!!editMode && !loading) &&
                                                <>
                                                    <Grid item xs={12}>
                                                        {
                                                            !! user.role.permission.manageAdvertisements &&
                                                                <>
                                                                    <Alert
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={this.state.allowed_comment}
                                                                                        onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                                                        value={this.state.allowed_comment}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('allowed_comments')}
                                                                    </Alert>
                                                                    <Alert
                                                                        style={{marginTop:15}}
                                                                        severity="info"
                                                                        action={
                                                                            <FormControlLabel
                                                                                style={{marginRight:10}}
                                                                                control={
                                                                                    <Switch
                                                                                        checked={is_best}
                                                                                        onChange={this.handleChangeBest.bind(this)}
                                                                                        value={is_best}
                                                                                        color="primary"
                                                                                    />
                                                                                }
                                                                                label={is_best ? translate('yes') : translate('no')}
                                                                            />
                                                                        }
                                                                    >
                                                                        {translate('isThisAdBest')}
                                                                    </Alert>
                                                                </>
                                                        }
                                                    </Grid>
                                                </>
                                            }*/}
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{minWidth:200}}
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}
                                                </LoadingButton>
                                            </Grid>
                                            {
                                                (!!editMode && !loading) &&
                                                <Grid item xs={12}>
                                                    <Alert severity="warning" variant="standard" style={{marginBottom:10}}>
                                                        <Typography style={{direction:this.props.fake_locale === "en" ? "ltr" : "rtl",}}>
                                                            {translate('ifYouWantToSeeMoreInfoClick')}
                                                            {" "}
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                component='a'
                                                                href={`${defaultVariabls.React}/edit/advertisement/${this.state.id}?lang=${this.props.fake_locale}`}
                                                            >
                                                                {translate('clickHere')}
                                                            </Button>
                                                        </Typography>
                                                        <Typography style={{fontSize:11}}>
                                                            {translate('attentionText')}
                                                        </Typography>
                                                    </Alert>
                                                </Grid>
                                            }
                                        </Grid>
                                    </Grid>

                                </Grid>

                            }
                        </AppAnimate>
                    </DialogContent>

                </Dialog>
            </>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeAdvertisement))));