import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import ProductPricing from "./single_pricing/ProductPricing";
import Typography from "@material-ui/core/Typography/Typography";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import Button from "@material-ui/core/Button/Button";
import withWidth from "@material-ui/core/withWidth/withWidth";

class MakePricing extends React.Component {
    state = {
        loading:true,
        product:null,
        product_id:this.props.match.params.id,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            product_id:this.props.match.params.id,
        })
        await this.loadData();

    }
    componentDidUpdate(prevProps){
        if(this.state.product_id !== this.props.match.params.id){
            this.setState({
                product_id: this.props.match.params.id
            })
        }
    }
    async reload(){
        this.setState({
            loading:true,
            product:null,
            product_id:this.props.match.params.id,
        })
        await this.loadData();

    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/pricing/getByProduct/${this.state.product_id}`, config)
                .then(response => {
                    this.setState({
                        product: response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    render(){
        let {product} = this.state;
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.pricingOnly}>
                {
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <AppCard
                                title={Translate.pricingOnly}
                                action={
                                    <BreadCrumbs  firstText={Translate.productsList} firstLink="/pricing" secondText={Translate.pricingOnly}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            <Grid item xs={12} md={11}>
                                                <div style={{
                                                    display:"flex",
                                                    flexDirection:"row",
                                                    alignItems:"center",
                                                    justifyContent:"space-between",
                                                    border:"1px solid #fbfbfb",
                                                    padding:10,
                                                    borderRadius:4
                                                }}>
                                                <div style={{display:"flex",flexDirection:"row",alignItems:"center"}}>
                                                    <div>
                                                        <img style={{width:75}} src={`${defaultVariabls.uploads}/FileManager/${product.thumbnail.name}_small.${product.thumbnail.extension}`} />
                                                    </div>
                                                    <div style={{marginRight:10}}>
                                                        <Typography>{product.name} {!!product.english_name ? " | "+ product.english_name : ''}</Typography>
                                                        <Typography>{Translate.shop} {product.shop.name}</Typography>
                                                    </div>
                                                </div>
                                                    <div>
                                                        <Button
                                                            size="small"
                                                            color="primary"
                                                            style={{marginLeft:10}}
                                                            startIcon={<PageviewRoundedIcon/>}
                                                            component="a"
                                                            href={`${defaultVariabls.website}/product/${product.slug}`}
                                                        >
                                                            {Translate.viewProduct}
                                                        </Button>
                                                        {
                                                            user.id === product.user_id &&
                                                            <Button
                                                                size="small"
                                                                color="primary"
                                                                variant="contained"
                                                                component={Link}
                                                                to={`/edit/product/${product.id}`}
                                                            >
                                                                {Translate.editProduct}
                                                            </Button>
                                                        }

                                                    </div>
                                                </div>
                                            </Grid>
                                            <Grid item xs={12} md={11}>
                                                <ProductPricing
                                                    product={product}
                                                    product_id={product.id}
                                                    pricing={product.pricing}
                                                    reload={() => this.reload()}
                                                />
                                            </Grid>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakePricing)))));
