import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BreadCrumbs from "../../../../components/elements/BreadCrumbs";
import {Translate} from "../../../../../config/Translate";
import AppCard from "../../../../components/layouts/components/AppCard";
import AppAnimate from "../../../../components/AppAnimate";
import Details from "../../../user/components/Details";
import {withRouter} from "react-router";
import ManageAgent from "./ManageAgent";

class ManageUserPage extends React.Component {
    render() {
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.ManageAgent}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.ManageAgent}
                            action={
                                <BreadCrumbs singleLevel firstText={Translate.ManageAgent}/>
                            }>
                            <ManageAgent manage agent_id={this.props.match.params.id}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
export default withStyles(Styles)(withRouter(ManageUserPage));