import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, getStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, getTimeZone} from "../../../../config/values";
import CurrencyFormat from "react-currency-format";
import Button from "@material-ui/core/Button/Button";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import ViewUserBankInfo from "../users/components/ViewUserBankInfo";
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import CreditCardRoundedIcon from '@material-ui/icons/CreditCardRounded';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {translate} from "../../../../config/lang";
class Wallet extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'type',
                editable: 'never',
                filtering: false,
                title: translate('transactionType'),
                lookup: {
                    "deposit" :
                        <Typography style={{color: "#38bf84"}}><IconButton disabled size="small"><AddRoundedIcon style={{color:"#38bf84"}}/></IconButton> {translate('deposit')}</Typography>
                    , "withdraw":
                        <Typography style={{color:"#BF8230"}}><IconButton disabled size="small"><RemoveRoundedIcon style={{color:"#BF8230"}}/></IconButton> {translate('withdraw')}</Typography>
                },
            },
            { title: translate('amount'),
                field: 'amount',
                editable: 'never',
                filterPlaceholder: translate('search'),
                render: rowData => <CurrencyFormat value={rowData.amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} />
            },
            { title: translate('transactionTrackingCode'),
                field: 'transaction_id',
                filterPlaceholder: translate('search'),
            },
            { title: translate('description'),
                field: 'description',
            },
            { title: translate('status'),
                editable: 'never',
                field: 'status',
                filterPlaceholder: translate('choose'),
                lookup: {
                    "confirmed" :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , "pending":
                        <Typography color="textSecondary">{translate('pending')}</Typography>
                    , "not_payed":
                        <Typography color="textSecondary">{translate('not_payed')}</Typography>
                },
            },
            {
                title: translate('created_at'),
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <>
                        {
                            rowData.status === "not_payed" || rowData.status === "pending" ?
                                <Tooltip arrow title={translate('remove')}>
                                <IconButton
                                    size="small"
                                    onClick={() =>this.handleRemoveItem(rowData.id)}
                                >
                                    <RemoveCircleRoundedIcon size="small"/>
                                </IconButton>
                                </Tooltip>
                                : null
                        }
                        {
                            rowData.status === "pending" ?
                                <Button
                                    onClick={() =>this.handlePayedItem(rowData.id,rowData.refrence)}
                                    startIcon={<CreditCardRoundedIcon size="small"/>}
                                    color="secondary"
                                    size="small"
                                >
                                    {translate('payed')}
                                </Button> : null
                        }
                    </>
            },
        ],
        data: [],
        loading:false,
        user:null,
        bank_info:null,
        amount:null,
        ChangeLoader:false,
        id:this.props.id,

    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleRemoveItem(id){
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/wallet/removeHistory`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('dataRemoved'), {
                    variant: 'success',
                });
                this.loadData();
            })
            .catch(error => {
                this.props.enqueueSnackbar(translate('dataRemovingFailed'), {
                    variant: 'error',
                });

            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    handlePayedItem(id,refrence){
        if(!!refrence) {
            let data = new FormData();
            data.append('id', id);
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/wallet/payed`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(translate('dataAdded'), {
                        variant: 'success',
                    });
                    this.loadData();
                })
                .catch(error => {
                    this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                        variant: 'error',
                    });

                });

            this.setState({
                loading:false,
                SubmitLoading:false
            });
        }else{
            this.props.enqueueSnackbar(translate('addRefrenceIdFirst'), {
                variant: 'warning',
            });
        }


    }
    onEditRow(row) {
        let authUser = getStore().user;
        if(authUser.role.permission.managePayments){
            let data = new FormData();
            data.append('id', row.id);
            data.append('refrence_id', row.refrence);
            data.append('description', row.description);

            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/wallet/history/quickEdit`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(translate('detailsSet'), {
                        variant: 'success',
                    });
                    this.loadData();
                    return true
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                    return false
                });
        }else{
            this.props.enqueueSnackbar(translate('youDontHavePermission'), {
                variant: 'error',
            });
            this.loadData();
        }

    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let{user,amount,bank_info} = this.state;
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={
                                    <Typography>
                                        <strong>{user} {!!bank_info ? <ViewUserBankInfo bank_info={bank_info}/> : null} </strong>
                                        {!!amount ? " ("+translate('balance')+" :" : null} {!!amount ? <CurrencyFormat value={amount} displayType={'text'} thousandSeparator={true}  suffix={" "+currencySymbol} /> : null}{!!amount ? ") ": null}
                                    </Typography>
                                }
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/wallet/getHistory/${this.state.id}?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data[1].data.map((row) => {
                                                    data.push({
                                                        amount: row.amount,
                                                        id: row.id,
                                                        description: row.description,
                                                        status: row.status,
                                                        type: row.type,
                                                        transaction_id: row.transaction_id,
                                                        created_at: row.created_at,
                                                    })
                                                });
                                                this.setState({
                                                    user:response.data[0].user.first_name+" "+response.data[0].user.last_name,
                                                    amount: response.data[0].amount,
                                                    bank_info:response.data[0].user.bank_info,
                                                })
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    defaultExpanded:true,
                                    search:false,
                                }}
                                editable={{
                                    onRowUpdate: (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onEditRow(newData);
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return { ...prevState, data };
                                                    });
                                                }
                                            }, 600);
                                        }),
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Wallet)))));