import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import {translate} from "../../../../config/lang";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import MakeVouchers from "./MakeVouchers";

class AddVoucherButton extends React.Component{
    state = {
        open:false,
    }
    toggleOpen(){
        this.setState({
            open: !this.state.open,
        })
    }

    render(){
        let {open} = this.state;
        let {title,discount,editMode} = this.props;
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.toggleOpen()}
                    startIcon={<AddRoundedIcon/>}
                    size={"small"}
                >
                    {title}
                </Button>
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",right:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {title}
                    </DialogTitle>
                    <DialogContent>
                      <MakeVouchers
                          discount={discount}
                          editMode={editMode}
                          reload={() => this.props.reload()}
                      />
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AddVoucherButton)))));