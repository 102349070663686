import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import {changeURL, truncate} from "../../../../config/values";
import Grid from "@material-ui/core/Grid/Grid";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import TextField from "@material-ui/core/TextField/TextField";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import queryString from "query-string";
import SubTitle from "../../../components/elements/SubTitle";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
class Products extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                sorting:false,
                hidden: this.props.width === "xs",
                title: Translate.thumbnail,
                render: rowData =>
                    <a href={`${defaultVariabls.website}/product/${rowData.slug}`} target="_blank">
                        <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
                    </a>
            },
            {
                title: Translate.productName,
                field: 'name',
                sorting:false,
                filterPlaceholder: Translate.search,
                render: rowData =>
                    <Button
                        size="small"
                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                        component="a"
                        href={`${defaultVariabls.website}/product/${rowData.slug}`}
                        target="_blank"
                    >
                        {truncate(rowData.name,17)}
                    </Button>
            },
            {
                title: Translate.shopName,
                field: 'shop',
                sorting:false,
                filterPlaceholder: Translate.search,
                hidden: !this.props.features.marketplace,
                render: rowData =>
                    truncate(rowData.shop,17)
            },
            { title: Translate.creator, field: 'owner',filterPlaceholder: Translate.search,sorting:false,hidden: this.props.width === "xs",},
            { title: Translate.confirmationStatusFilter,
                sorting:false,
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: Translate.choose,
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{Translate.confirmed}</Typography>
                    , 0:
                        <Typography color="textSecondary">{Translate.unConfirmed}</Typography>
                },
            },
            {
                title: Translate.updateTime,
                editable: 'never',
                sorting:false,
                hidden: this.props.width === "xs",
                field: 'created_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.updated_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.updated_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: Translate.activity,
                editable: 'never',
                sorting:false,
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/edit/product/${rowData.id}`}
                        endIcon={<ChevronLeftRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {Translate.edit}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        status:"all",
        open_filter:false,
        page:0,
        def_page:0,
        per_page:TablePage,
        def_per_page:TablePage,
    };
    async componentDidMount(){
        await this.loadDefaults();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    async loadDefaults(){
        const values = queryString.parse(window.location.search);
        let status = values.status;
        let page = values.page;
        let per_page = values.per_page;

        if(!!status){
            await this.setState({
                status,
                open_filter:true,
            })
        }
        if(!!page){
            await this.setState({
                def_page:page,
            })
        }
        if(!!per_page){
            await this.setState({
                per_page:per_page,
                def_per_page:per_page,
            })
        }
        await this.setState({
            loading:false,
        })
    }
    async handleChangeStatus(e) {
        let target = e.target;
        let status = target.value;
        await this.setState({
            status
        });
        changeURL({status: target.value});
        this.loadData();
    }
    async handleChangePage(page){
        await changeURL({page: page})
        await this.setState({
            page,
        });
    }
    async handleChangeRowsPerPage(per_page){
        await changeURL({per_page: per_page})
        await this.setState({
            per_page,
        });
    }

    render(){
        let {status,open_filter,def_page,per_page,def_per_page} = this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Accordion
                                        style={{backgroundColor: "#f9f9f9"}}
                                    >
                                        <AccordionSummary
                                            expandIcon={<ExpandMoreRoundedIcon />}
                                            aria-controls="panel2a-content"
                                            id="panel2a-header"
                                        >
                                            <SubTitle align="left" style={{marginBottom:0}}>
                                                <IconButton size="small">
                                                    <FilterListRoundedIcon/>
                                                </IconButton>
                                                {Translate.filters}
                                                </SubTitle>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2} alignItems="center" direction="row">
                                                <Grid item xs={12} md={4} lg={3}>
                                                    <TextField
                                                        select
                                                        fullWidth
                                                        size="small"
                                                        label={Translate.confirmationStatusFilter}
                                                        variant="outlined"
                                                        value={status}
                                                        onChange={this.handleChangeStatus.bind(this)}
                                                    >
                                                        <MenuItem value={"confirmed"}>{Translate.confirmed}</MenuItem>
                                                        <MenuItem value={"unconfirmed"}>{Translate.unConfirmed}</MenuItem>
                                                        <MenuItem value='all'>{Translate.all}</MenuItem>
                                                    </TextField>
                                                </Grid>
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>

                                </Grid>
                                <Grid item xs={12}>
                                    <MaterialTable
                                        tableRef={this.tableRef}
                                        icons={tableIcons}
                                        title={
                                            <Link to='/create/product'>
                                                {
                                                    this.props.width !== "xs" ?
                                                        <Button
                                                            variant="contained"
                                                            color="primary"
                                                            startIcon={<AddBox />}
                                                        >
                                                            {Translate.makeProduct}
                                                        </Button> :
                                                        <IconButton color="primary">
                                                            <AddBox />
                                                        </IconButton>
                                                }
                                            </Link>
                                        }
                                        onChangePage={(page) => this.handleChangePage(page)}
                                        onChangeRowsPerPage={(page) => this.handleChangeRowsPerPage(page)}
                                        localization={DataTableLocalization}
                                        columns={this.state.columns}
                                        data={query =>
                                            new Promise((resolve, reject) => {
                                                axios.get(`${defaultVariabls.url}/${this.props.manage ? "products" : "my-products"}/quickView?page=${parseInt(def_page > 0 ? def_page: query.page) + 1}&per_page=${def_per_page > TablePage ? def_per_page : query.pageSize}&search=${query.search}&status=${status}`, config)
                                                    .then(response => {
                                                        let data = [];
                                                        // eslint-disable-next-line
                                                        response.data.data.map((row) => {
                                                            data.push({
                                                                thumbnail: `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                                                name: row.name,
                                                                id: row.id,
                                                                owner: row.owner.first_name + " " + row.owner.last_name,
                                                                shop: row.shop.name,
                                                                confirmed: row.confirmed,
                                                                slug: row.slug,
                                                                updated_at:row.updated_at
                                                            })
                                                        });
                                                        if(def_page > 0){
                                                            this.setState({
                                                                def_page:0,
                                                            })
                                                        }
                                                        resolve({
                                                            data: data,
                                                            page: response.data.current_page - 1,
                                                            totalCount: response.data.total,
                                                        })
                                                    }).catch(error => {
                                                    console.log(error.response)
                                                })
                                            })
                                        }
                                        options={{
                                            draggable: false,
                                            pageSize: per_page,
                                            pageSizeOptions: TableCount,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    user: state.user,
    features: state.features,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Products)))));