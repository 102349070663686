import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import FileManagerDialog from "./FileManagerDialog";
import {defaultVariabls} from "../../../../config";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../config/lang";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import ImageTwoToneIcon from '@material-ui/icons/ImageTwoTone';
import Grid from "@material-ui/core/Grid/Grid";
class SingleFileChooser extends React.Component {
    classes = this.props.classes;


    render(){
        let{width,height,size,video_size,style} = this.props;

        return(
            <div className="metaDescription" style={style}>
                <Typography style={{display:"flex", alignItems:"center",justifyContent:"space-between",fontSize:12,marginBottom:5}}>
                    <span>{translate('imageProperWidthHeight')}</span>
                    <span>{width}x{height} px</span>
                </Typography>
                {
                    !!size &&
                    <Typography style={{display:"flex", alignItems:"center",justifyContent:"space-between",fontSize:12,marginBottom:5}}>
                        <span>{translate('imageProperSize')}</span>
                        <span>{size}</span>
                    </Typography>
                }

                {
                    !!video_size &&
                        <Typography style={{display:"flex", alignItems:"center",justifyContent:"space-between",fontSize:12}}>
                            <span>{translate('videoProperSize')}</span>
                            <span>{video_size}</span>
                        </Typography>
                }
            </div>
        )

    }
}
export default withStyles(Styles)(SingleFileChooser);