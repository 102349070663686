import React from "react";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../config/lang";

class CurrencyChooser extends React.Component{
    state = {
        countries:[],
        selected:null,
        loading:true,
        open:false,
        default_id:null,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/countries/getAll`, config)
            .then(response => {
                this.setState({
                    countries:response.data,
                    loading:false
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleCountry(data){
        this.props.handleCountry(data);
    }
    render(){
        let {countries,loading,default_id,open} = this.state;
        let{required,error} = this.props;
    return (
        <>
            <Autocomplete
                fullWidth
                open={open}
                onOpen={() => {
                    this.setState({open:true})
                }}
                onClose={() => {
                    this.setState({open:false})
                }}
                required={required}
                getOptionLabel={(option) => option.name}
                onChange={(event, value) => this.handleCountry(value)}
                options={countries}
                loading={loading}
                disabled={this.props.disabled}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label={translate('country')}
                        variant="outlined"
                        required={required}
                        InputProps={{
                            ...params.InputProps,
                            endAdornment: (
                                <React.Fragment>
                                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                    {params.InputProps.endAdornment}
                                </React.Fragment>
                            ),
                        }}
                        error={error}
                        helperText={!!error && error}
                    />
                )}
            />
        </>
    );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CurrencyChooser))));