import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";

import {withSnackbar} from "notistack";
import {withRouter} from "react-router";

import {defaultVariabls} from "../../../../config";

import {makeSlug, translate} from "../../../../config/lang";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import Link from "@material-ui/core/Link/Link";

class YourPlanDoesntSupportThis extends React.Component{

    classes = this.props.classes;

    render(){
        let {visible} = this.props;
        return (
            <>
                {
                    !!visible &&
                    <Alert
                        style={{marginTop:10,marginBottom:10}}
                        severity="warning"
                        action={
                            <Button
                                color="inherit"
                                size="small"
                                href={makeSlug('plans')}
                                component={Link}
                            >
                                {translate('viewPlans')}
                            </Button>
                        }
                    >
                        <AlertTitle className={this.classes.condir}><strong>{translate('yourPlanDoesntSupportThis')}</strong></AlertTitle>
                        {translate('yourPlanDoesntSupportThisDesc')}
                    </Alert>
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(YourPlanDoesntSupportThis))));