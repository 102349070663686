import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../config/design/styles";
import {withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import {defaultVariabls} from "../../../config";
import axios from "axios";
import {setStore} from "trim-redux";
import {AlertSkeleton} from "../Skeletons/AlertSkeleton";
import Button from "@material-ui/core/Button/Button";
import DoneAllIcon from '@material-ui/icons/DoneAll';
import InfiniteScroll from 'react-infinite-scroller';
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment-timezone";
import {translate} from "../../../config/lang";
import {getTimeZone} from "../../../config/values";

class UnReadNotificationsInfinity extends React.Component {
    state = {
        notifications: [],
        nextPage:1,
        hasMore: true,
        check: false,

    }
    classes = this.props.classes;
    async handleLoadMore(){

        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null) {
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/notifications/get?page=${this.state.nextPage}`, config)
                .then(response => {
                    const {current_page, last_page, data} = response.data;
                    this.setState(prevState => ({
                        notifications: [...prevState.notifications, ...data],
                        hasMore: current_page  !== last_page,
                        nextPage: current_page + 1,
                        check: true
                    }));
                })
                .catch(error => {
                    console.log(error)
                });
        }
    }
    async makeAsRead(){

        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/notifications/read`, config)
                .then(response => {
                    this.setState({
                        notifications: response.data,
                    })
                    this.props.refresh();
                }).catch(error => {
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    async makeSingleAsRead(id){
        let loginToken = localStorage.getItem('ACT');
        let data = new FormData();
        data.append('id', id);
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.post(`${defaultVariabls.url}/user/notifications/readSingle`,data, config)
                .then(response => {
                    this.props.refresh();
                }).catch(error => {
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    render(){
        let {notifications,hasMore,check} = this.state;
        let skeleton = [];
        for(let i = 0 ; i < 4 ; i++){
            skeleton.push(<AlertSkeleton key={i}/>)
        }
        return (
            <div className="notifications-holder">
                <React.Fragment>
                    {
                        notifications.length > 0 ?
                            <Button
                                color="primary"
                                startIcon={<DoneAllIcon />}
                                onClick={this.makeAsRead.bind(this)}
                            >
                                {translate('markAsRead')}
                            </Button> : null
                    }
                    <InfiniteScroll
                        pageStart={1}
                        loadMore={this.handleLoadMore.bind(this)}
                        hasMore={hasMore}
                        loader={skeleton}
                        useWindow={false}
                    >
                        {
                            notifications.map( (notification,index) => (
                                <div key={index}>
                                    <a  href={`${defaultVariabls.React}${notification.data.link}`} onClick={() => this.makeSingleAsRead(notification.id)}  target="_blank"  rel="noopener noreferrer">
                                        <Alert className={this.classes.NotificationAlert} severity={notification.data.type}>
                                            <AlertTitle>{notification.data.title.length > 0 ? notification.data.title : null}</AlertTitle>
                                            <Typography color="inherit" className={this.classes.condir}>{notification.data.text}</Typography>
                                            <Typography class={this.classes.NotifcationText}>{`${moment.tz(notification.created_at,'UTC').tz(getTimeZone()).fromNow()}`}</Typography>
                                        </Alert>
                                    </a>
                                </div>
                            ))
                        }
                    </InfiniteScroll>
                    {
                        check && notifications.length === 0 ?
                            <Typography color="textSecondary" variant="body2">{translate('noNotifications')}</Typography> : null
                    }
                </React.Fragment>
            </div>
        );
    }

}
export default withSnackbar(withRouter(withStyles(Styles)(UnReadNotificationsInfinity)));