import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import Hidden from "@material-ui/core/Hidden/Hidden";
import {translate} from "../../../../config/lang";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import ChipInput from "material-ui-chip-input";
import Typography from "@material-ui/core/Typography/Typography";

class MakePlan extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            price:0,
            priceR:0,
            discount_price:0,
            discount_priceR:0,
            description:"",
            english_description:"",
            page_title:"",
            english_page_title:"",
        },
        features:[],
        english_features:[],
        errors:{},
        loading: true,
        SubmitLoading: false,
        editMode:false,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.loadData();
    }

    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/purchase_ads/get`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            price:!!response.data.price ? response.data.price : 0,
                            priceR:!!response.data.price ? response.data.price : 0,
                            discount_price:!!response.data.discount_price ? response.data.discount_price : 0,
                            discount_priceR:!!response.data.discount_price ? response.data.discount_price : 0,
                            description: !!response.data.description ? response.data.description : '',
                            english_description: !!response.data.english_description ? response.data.english_description : '',
                            page_title: !!response.data.page_title ? response.data.page_title : '',
                            english_page_title: !!response.data.english_page_title ? response.data.english_page_title : '',
                        },
                        features: !!response.data.features ? response.data.features.split(',') : [],
                        english_features: !!response.data.english_features ? response.data.english_features.split(',') : [],
                        loading:false
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');

        }else if (target.name === "discount_price") {
            fields.discount_priceR = target.value.replace(/,/ig, '');
        }else{
            fields[target.name] = target.value;
            errors[target.name] = "";
        }
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;
        if(!!fields.priceR){
            if(fields.discount_priceR > fields.priceR){
                formIsValid = false;
                errors['price'] = translate('discountMoreThanPrice')
            }
        }


        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,editMode,features,english_features} = this.state;
        let data = new FormData();
        data.append('price',fields.priceR);
        data.append('discount_price',fields.discount_priceR);
        data.append('description', fields.description);
        data.append('english_description', fields.english_description);
        data.append('page_title', fields.page_title);
        data.append('english_page_title', fields.english_page_title);
        data.append('features', features.toString());
        data.append('english_features', english_features.toString());

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/purchase_ads/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });

            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 403){
                    this.props.enqueueSnackbar(translate('categoryPublishedBefore'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeHideProducts(){
        this.setState({
            hide_products: !this.state.hide_products,
        })
    }
    handleParent(value){
        if(value === null){
            this.setState({
                parentId:''
            })
        }else{
            this.setState({
                parentId:value.id
            })
        }
    }
    async handleAddChip(item){
        let {features} = this.state;
        if(features.length < 3){
            features.push(item);
            this.setState({
                features
            })
        }else{
            this.setState({
                features
            })
        }

    }
    async handleDeleteChip(chip, index){
        let {features} = this.state;
        features.splice(index, 1);
        this.setState({
            features
        })
        this.setState({
            features
        })
    }
    async handleAddChipEnglish(item){
        let {english_features} = this.state;
        if(english_features.length < 3){
            english_features.push(item);
            this.setState({
                english_features
            })
        }else{
            this.setState({
                english_features
            })
        }

    }
    async handleDeleteChipEnglish(chip, index){
        let {english_features} = this.state;
        english_features.splice(index, 1);
        this.setState({
            english_features
        })
        this.setState({
            english_features
        })
    }
    render(){
        let {fields, errors,SubmitLoading,thumbnail,parents,editMode,parentId,
            verify_badge, purchase_ad, has_business_page, has_discount, has_teaser, has_introducing_video,
            has_banner_design, has_commercial_banner, has_support, has_distance,has_link
        } = this.state;

        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('purchase_ads_page')}>
                {
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} lg={11}>
                            <AppCard
                                title={translate('purchase_ads_page')}
                                action={
                                    <BreadCrumbs singleLevel firstText={translate('purchase_ads_page')}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} >
                                            <Grid item xs={12} md={6}>
                                                <CurrencyTextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    currencySymbol={"AED"}
                                                    decimalPlaces={2}
                                                    value={fields.price}
                                                    id="price"
                                                    label={translate('price')}
                                                    name="price"
                                                    onChange={this.handleChange.bind(this)}
                                                    error={!!errors['price']}
                                                    helperText={errors['price']}
                                                    className={this.classes.currencyTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <CurrencyTextField
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    currencySymbol={"AED"}
                                                    decimalPlaces={2}
                                                    value={fields.discount_price}
                                                    id="discount_price"
                                                    label={translate('discountPrice')}
                                                    name="discount_price"
                                                    onChange={this.handleChange.bind(this)}
                                                    error={!!errors['discount_price']}
                                                    helperText={errors['discount_price']}
                                                    className={this.classes.currencyTextField}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="page_title"
                                                    label={translate('page_title')}
                                                    type="text"
                                                    value={fields.page_title}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['page_title']}
                                                    helperText={errors['page_title']}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="english_page_title"
                                                    label={translate('english_page_title')}
                                                    type="text"
                                                    value={fields.english_page_title}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['english_page_title']}
                                                    helperText={errors['english_page_title']}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    multiline
                                                    fullWidth
                                                    name="description"
                                                    label={translate('description')}
                                                    placeholder={translate('description')}
                                                    type="text"
                                                    minRows="4"
                                                    value={fields.description}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['description']}
                                                    helperText={errors['description']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.description.length} max={1000}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <ChipInput
                                                    value={this.state.features}
                                                    fullWidth
                                                    label={translate('planFeatures')}
                                                    variant={"outlined"}
                                                    multiline
                                                    minRows={5}
                                                    onAdd={(chip) => this.handleAddChip(chip)}
                                                    onDelete={(chip, index) => this.handleDeleteChip(chip, index)}
                                                />
                                                <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                    {translate('pressEnterToMakeEachOption')}
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    multiline
                                                    fullWidth
                                                    name="english_description"
                                                    label={translate('english_description')}
                                                    placeholder={translate('english_description')}
                                                    type="text"
                                                    minRows="4"
                                                    value={fields.english_description}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['english_description']}
                                                    helperText={errors['english_description']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.english_description.length} max={1000}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12}>
                                                <ChipInput
                                                    value={this.state.english_features}
                                                    fullWidth
                                                    label={translate('planEnglishFeatures')}
                                                    variant={"outlined"}
                                                    multiline
                                                    minRows={5}
                                                    onAdd={(chip) => this.handleAddChipEnglish(chip)}
                                                    onDelete={(chip, index) => this.handleDeleteChipEnglish(chip, index)}
                                                />
                                                <Typography  style={{fontSize:12,marginLeft:10,marginTop:5}}>
                                                    {translate('pressEnterToMakeEachOption')}
                                                </Typography>
                                            </Grid>
                                            <Hidden mdDown>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {translate('saveChanges')}
                                                    </LoadingButton>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>

                    </Grid>
                }
            </AppAnimate>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakePlan))));