import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LowPriorityRoundedIcon from '@material-ui/icons/LowPriorityRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {translate} from "../../../../config/lang";
import CategoriesOrder from "./CategoriesOrder";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";
class OrderNewsCategoriesButton extends React.Component {
    state={
        open:false
    }
    classes = this.props.classes;
    handleClose (){
        this.setState({open:false})
        this.props.reload();
    }
    render(){
        let {open} = this.state;
        return (
            <>
                <Tooltip title={translate('manageCategoriesOrder')} arrow placement="top">
                <IconButton color="primary" variant="contained" onClick={() => this.setState({open:true})}>
                    <LowPriorityRoundedIcon/>
                </IconButton>
                </Tooltip>

                <Dialog maxWidth="sm" fullWidth onClose={this.handleClose.bind(this)} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose.bind(this)}>
                        {translate('manageCategoriesOrder')}
                        <IconButton
                            onClick={() => this.handleClose()}
                            style={{position:"absolute",right:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon/>
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <CategoriesOrder/>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(OrderNewsCategoriesButton)));