import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import Details from "../../user/components/Details";
import {withRouter} from "react-router";
import {makeSlug, translate} from "../../../../config/lang";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import StepsHeader from "../../register_steps/icons/StepsHeader";
import GetPlanToUser from "./components/GetPlanToUser";
import Alert from "@material-ui/lab/Alert/Alert";
import Button from "@material-ui/core/Button/Button";
import {Link} from "react-router-dom";

class ManageUserPage extends React.Component {
    render() {
        let {user} = this.props;
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('editUser')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('editUser')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('editUser')}/>
                            }>
                            <Grid container spacing={2}>
                                {/*<Grid item xs={12}>
                                    <StepsHeader step_number={user.step}/>
                                </Grid>*/}
                                <Grid item xs={12}>
                                    <Details manage user_id={this.props.match.params.id}/>
                                </Grid>
                            </Grid>
                        </AppCard>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <Alert
                            severity="info"
                            action={
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    component={Link}
                                    to={`/manage/register-user/${this.props.match.params.id}`}
                                >
                                    {translate('registerInfo')}
                                </Button>
                            }
                        >
                            {translate('youCanEditUserRegisterInfo')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('userPlanInfo')}
                            >
                        <GetPlanToUser user_id={this.props.match.params.id}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ManageUserPage))));