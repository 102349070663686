import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {getDataTableLocalization, Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect, getStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import Button from "@material-ui/core/Button/Button";
import {Link, withRouter} from 'react-router-dom';
import AddBox from "@material-ui/icons/AddBox";
import { withSnackbar } from 'notistack';
import {rolesWithName, RWN, TableCount, TablePage} from "../../../../../config/Permissions";
import moment from "moment-timezone";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../../../config/lang";
import ChevronNextRoundIcon from "../../../../components/elements/ChevronNextRoundIcon";
import Accordion from "@material-ui/core/Accordion/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary/AccordionSummary";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import SubTitle from "../../../../components/elements/SubTitle";
import AccordionDetails from "@material-ui/core/AccordionDetails/AccordionDetails";
import {getTimeZone} from "../../../../../config/values";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";


class UserSuspension extends React.Component {
    state = {
        user:this.props.user,
        is_suspended: this.props.user.is_suspended,
        loading:false
    }
    handleChange(){
        this.setState({
            loading:true
        })
        let { user} = this.state;
        let data = new FormData();
        data.append('user_id', user.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/suspension`, data, config)
            .then(response => {
                this.setState({
                    loading:false,
                    is_suspended: !this.state.is_suspended,
                })
                window.location.reload(true);
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                })
            });
    }
    render(){
        let {user,is_suspended,loading} = this.state;
        return(
            <Alert
                severity="error"
                style={{marginBottom:10}}
                action={
                    <FormControlLabel
                        style={{marginRight:10}}
                        control={
                            !!loading ?
                            <CircularProgress size={22}/>
                            :
                            <Switch
                                checked={is_suspended}
                                onChange={this.handleChange.bind(this)}
                                value={is_suspended}
                                color="secondary"
                            />
                        }
                        label={is_suspended ? translate('suspended') : ""}
                    />
                }
            >
                <AlertTitle>
                    {translate('userSuspensionStatus')}
                </AlertTitle>
                {translate('userSuspensionStatusDescription')}
            </Alert>
        );
    }
}
const mstp = state => ({
    features: state.features,
    locale: state.locale
});
export default  withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserSuspension)))));

