import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {Translate} from "../../../../config/Translate";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import PricingHelpButton from "./components/PricingHelpButton";
import MakeSinglePricing from "./MakeSinglePricing";
import AttributeChooser from "./components/AttributeChooser";

class ProductPricing extends React.Component {
    classes = this.props.classes;
    state={
        pricing:!!this.props.pricing ? this.props.pricing : [],
        product_id: this.props.product_id,
        loading:true,
        has_attribute:this.props.pricing.length > 1,
        attribute_is_available:true,
        values:[],
        attribute:null,
    };
    async componentDidMount(){
        await this.setState({
            pricing:!!this.props.pricing ? this.props.pricing : [],
            product_id:this.props.product_id,
            has_attribute: this.props.pricing.length > 1
        });
        this.setState({
            attribute_is_available:false
        })
        if(!!this.props.product.categories[0]){
            this.props.product.categories.map((category) => {
                if(!!category.attribute_id){
                    this.setState({
                        attribute_is_available:true
                    })
                }
            })
        }else{
            this.setState({
                attribute_is_available:false
            })
        }
        if(this.props.pricing.length > 0){
            let values = [];
            await this.props.pricing.map((item)=>{
                item.value_id && values.push(item.value)
            })
            if(this.props.pricing.length === 1){
                if(!!this.props.pricing[0].value_id){
                    this.setState({
                        has_attribute:true
                    })
                }
            }
            await this.setState({
                values,
                loading:false,
            })
        }
        else{
            await this.setState({
                loading:false,
                has_attribute:!!this.props.product.variable_pricing_count
            })
        }

    }
    handleValues(values){
        this.setState({
            values
        })
    }
    render() {
        const {pricing,loading,has_attribute,product_id,attribute_is_available,values,attribute} = this.state;
        return (
            <>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} alignItems="center" justify="center">
                            {
                                attribute_is_available &&
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            action={
                                                <PricingHelpButton/>
                                            }
                                        >
                                            <AlertTitle>{has_attribute ? Translate.variablePricing+" ("+Translate.variablePricingDescTitle+")" : Translate.simplePricing +" ("+Translate.simplePricingDescTitle+")"}</AlertTitle>
                                            {Translate.doYouWantToChangePricingType}
                                            <strong style={{marginRight:20}}>
                                                <FormControlLabel
                                                    control={
                                                        <Switch
                                                            checked={has_attribute}
                                                            onChange={() => this.setState({has_attribute:!has_attribute})}
                                                            name="has_attribute"
                                                            color="primary"
                                                        />
                                                    }
                                                    label={has_attribute ? Translate.variablePricing : Translate.simplePricing}
                                                />
                                            </strong>
                                        </Alert>
                                    </Grid>
                            }

                            <Grid item xs={12}>
                                {
                                    has_attribute ?
                                        <>
                                            <AttributeChooser
                                                input={values}
                                                product_id={product_id}
                                                makeAvailable={ (attribute) => this.setState({attribute_is_available:true,attribute})}
                                                handleValues={(vals) => this.handleValues(vals)}
                                            />
                                        {
                                            !!values &&
                                                values.map((val,index) => (
                                                    <MakeSinglePricing
                                                        key={index}
                                                        product_id={product_id}
                                                        value={val}
                                                        attribute={attribute}
                                                        reload={() => this.props.reload()}

                                                    />
                                                ))
                                        }
                                        </>
                                        :
                                        <MakeSinglePricing
                                            product_id={product_id}
                                            value={0}
                                            reload={() => this.props.reload()}
                                        />
                                }
                            </Grid>
                        </Grid>
                }

            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ProductPricing)))));