import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Typography from "@material-ui/core/Typography/Typography";
import {translate} from "../../../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import Button from "@material-ui/core/Button/Button";

class ChooseMobile extends React.Component {
    constructor(props){
        super(props);
        this.state={
            fields: {
                mobile:0,
            },
            errors:{},
            SubmitLoading:false,
        };
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    async handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //mobile
        if(!fields.mobile){
            formIsValid = false;
            errors['mobile'] = translate('emptyField');
        }else if(!validator.isNumeric(fields.mobile) || fields.mobile.length <= 10){
            formIsValid = false;
            errors['mobile'] = translate('wrongMobile');
        }
        await this.setState({errors},() =>{
            if(!formIsValid){
                this.setState({
                    SubmitLoading:false
                })
            }
            return callback(formIsValid)
        });
    }
    async handleRequest(){
        const {mobile} = this.state.fields;
        let data = new FormData();
        data.append('mobile', mobile);
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/forgetPass/setMobile`, data, config)
            .then(response =>{
                this.setState({
                    SubmitLoading:false
                })
                this.props.setUser(response.data);

                this.props.setNextLevel();
            })
            .catch(error =>{
                this.props.enqueueSnackbar(translate("invalidMobileNumber"), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeMobile(mobile){
        let {fields} = this.state;
        fields.mobile = mobile.replace("+0","+98");
        this.setState({
            fields
        })
    }
    render() {
        const {fields,errors,SubmitLoading} = this.state;
        return (
            <form style={{width:'100%'}} noValidate onSubmit={this.handleSubmit.bind(this)}>
                <Grid container spacing={0} justify="center" alignItems='center'>
            <Grid item xs={12}>
                <MuiPhoneNumber
                    required
                    fullWidth
                    id="mobile"
                    label={translate('mobile')}
                    name="mobile"
                    margin="normal"
                    dir="ltr"
                    value={fields.mobile}
                    variant="outlined"
                    onChange={this.handleChangeMobile.bind(this)}
                    autoFocus
                    disableAreaCodes
                    defaultCountry={'ae'}
                    error = {!!errors['mobile']}
                    helperText={errors['mobile']}
                />
                </Grid>
                <Grid item xs={12}>
                    <Typography align="left" color="textSecondary">{translate('recoverPasswordText')}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        type="submit"
                        variant="contained"
                        color="primary"
                        style={{width:"100%"}}
                        onClick = {this.handleSubmit.bind(this)}
                        loading = {SubmitLoading}
                    >
                        {translate("confirm")}
                    </LoadingButton>
                </Grid>
                </Grid>
            </form>

        );

    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ChooseMobile)));