import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import AppAnimate from "../../components/AppAnimate";
import Grid from "@material-ui/core/Grid/Grid";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import UserGuidLine from "./user_components/UserGuidLine";
import AgentOrdersTable from "../admin/users/agents/AgentOrdersTable";
import AppCard from "../../components/layouts/components/AppCard";
import SubTitle from "../../components/elements/SubTitle";
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import Chip from "@material-ui/core/Chip/Chip";
import AgentAnalyticsShort from "./components/AgentAnalyticsShort";
import AgentDashboardConfirmation from "./components/AgentDashboardConfirmation";

class UserDashboard extends React.Component {
    classes = this.props.classes;
    render(){
        let {user} = this.props;
        let cities = !!user.agent.cities ? user.agent.cities.split(',') : [];
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.dashboard}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AgentDashboardConfirmation/>
                           <AppCard>
                               <SubTitle align="left" color="primary">{Translate.agent_panel}</SubTitle>
                               <Typography style={{marginTop:10,marginBottom:10}}>{Translate.agent_panel_desc}</Typography>
                               {
                                   !!cities.length &&
                                       <>
                                           <Divider/>
                                           <Typography style={{marginTop:10,marginBottom:10}}>{Translate.yourCities}:</Typography>
                                           {
                                               cities.map((city,index)=> (
                                                   <Chip
                                                       style={{marginLeft:10,border:"none",borderRadius:5,backgroundColor:"#fafafa"}}
                                                       label={city}
                                                       key={index}
                                                       variant="outlined"
                                                       color="primary"
                                                   />
                                               ))
                                           }
                                       </>
                               }

                           </AppCard>
                    </Grid>
                    <Grid item xs={12}>
                        <AgentAnalyticsShort/>
                    </Grid>
                    <Grid item xs={12}>
                        <AppCard
                            title={Translate.your_related_orders}
                            >
                            <AgentOrdersTable widget agent_id={0}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}

const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserDashboard)))));
