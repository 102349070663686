import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {makeSlug, translate} from "../../../../config/lang";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import Step6 from "../../../../files/img/steps/6.png";
import Typography from "@material-ui/core/Typography";
import Title from "../../../components/elements/Title";
import Button from "@material-ui/core/Button/Button";
import {defaultVariabls} from "../../../../config";
import Alert from "@material-ui/lab/Alert/Alert";
class details extends React.Component {
    classes = this.props.classes

    render() {
        let {manage} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('step6')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="waiting-container">
                                        <Title>{translate('yourInfoIsVerified')}</Title>
                                        <img src={Step6} alt="waiting"/>
                                        <Typography style={{textAlign:"center"}} className={this.classes.condir}>{translate('yourInfoIsVerifiedDesc1')} {this.props.locale === "en" ? this.props.setting.plan_lite.parent.english_name : this.props.setting.plan_lite.parent.name} {translate('yourInfoIsVerifiedDesc2')}</Typography>
                                    </div>
                                </Grid>
                                {
                                    !manage &&
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            action={
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                    component="a"
                                                    href={makeSlug('plans')}
                                                >
                                                    {translate('plansPage')}
                                                </Button>
                                            }
                                        >
                                            {translate('forPurchasingBetterPlansAdsAndReserveGoToPlans')}
                                        </Alert>
                                    </Grid>
                                }

                            </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    setting: state.setting,
    locale: state.locale,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));