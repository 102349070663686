import {green} from "@material-ui/core/colors";

import {getStore} from "trim-redux";
import Auth_bg from '../../files/img/auth_bg.jpg';

const drawerWidth = 220;
export const  Styles = theme =>( {
    paperLoginPage: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding:theme.spacing(3),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    textRight:{
        textAlign:'right',
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
    },
    textLeft:{
        textAlign:'left',
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
    },
    inputCtl:{
        "& input":{
            direction:getStore('fake_locale') === "en" ? "ltr" : "rtl",
        },
        "& textarea":{
            direction:getStore('fake_locale') === "en" ? "ltr" : "rtl",
        },
    },
    inputCtlEqual:{
        "& input":{
            direction:getStore('fake_locale') === "en" ? "rtl" : "ltr",
        },
        "& textarea":{
            direction:getStore('fake_locale') === "en" ? "rtl" : "ltr",
        },
    },
    textFloat:{
        textAlign:getStore('locale') === "en" ? "left" : "right",
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
    },

    requiredSign:{
        color:theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        textAlign:"left"
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    mr10:{
        marginRight:10,
    },
    me10:{
        marginRight:getStore('locale') === "en" ? 10 : 0,
        marginLeft:getStore('locale') === "en" ? 0 : 10,
    },
    ml10:{
      marginLeft:10
    },
    my10:{
      marginLeft:10,
      marginRight:10
    },
    modalCloseButton:{
        position:"absolute",
        right:15,
        top:15
    },
    root: {
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        position: 'relative',
        backgroundColor: getStore('mode') === 'dark' ? "#303030" : "#f3f4f6",
        overflow:'hidden',
        minHeight:"100vh"
    },
    dialogRoot:{
        backgroundColor: getStore('mode') === 'dark' ? "#303030" : "#f3f4f6",

    },
    mainContent: {
        flex: 1,
        display: 'flex',
    },
    WelcomRroot:{
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    toolbar: {
        paddingLeft: 24, // keep right padding when drawer closed
    },
    toolbarIcon: {
        color: "#fff",
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    colorWhite:{
        color:"rgba(255, 255, 255, 0.9)",
        width: "0.9em !important",
        height: "0.9em !important",
    },
    titleIcon: {
        float:"right"
    },
    appBar: {
        backgroundColor:"#2b2b2b",
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        backgroundColor:"#2b2b2b",

    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
    },
    drawerPaper: {
        backgroundColor: "#2b2b2b",
        color: "#fff",
        position: 'fixed',
        whiteSpace: 'nowrap',
        overflowX:"hidden",
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(0),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(8),
        },
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
        flexGrow: 1,
        overflow: 'auto',
        width:"100vw",
        [theme.breakpoints.up('lg')]: {
            width: "77vw",
            overflowX:"hidden"
        },
    },
    contentWithoutSide: {
        flexGrow: 1,
        overflow: 'auto',
        width:"100vw",
        [theme.breakpoints.up('lg')]: {
            width: "90vw",
            overflowX:"hidden"
        },
    },
    rootContainer:{
        display:"flex",
        justifyContent:"center",
        [theme.breakpoints.up('lg')]: {
            marginLeft:'4vw',
        },
    },
    container: {
        paddingTop: theme.spacing(3),
        paddingBottom: theme.spacing(3),
        marginTop:78,
        [theme.breakpoints.up('lg')]: {
            paddingLeft:"0 !important",
            paddingRight:"0 !important",
        },


    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        borderRadius:10,
        overflow: 'auto',
        flexDirection: 'column',
        boxShadow:"0px 0px 10px rgba(82, 82, 82, 0.21)",
    },
    mainPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom:  theme.spacing(1)
    },
    SidebarPaper:{
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        marginBottom:  theme.spacing(1)
    },
    uploadProgress:{
        '& span.success': {
            color: "#4caf50"
        },
    },
    fixedHeight: {
        height: 240,
    },
    nested: {
        paddingLeft: theme.spacing(4),
    },
    DrawerRoot: {
        width: '100%',
        maxWidth: drawerWidth,

    },
    activeItem: {
        color: "#ffffff",
        backgroundColor: "#4c4c4c",
        borderLeft:"5px solid " + theme.palette.primary.light ,
        display:"block",
        textDecoration: "none"
    },
    activeItemDropDown:{
        color: "#ffffff",
        backgroundColor: "#4c4c4c",
        borderLeft:"5px solid " + theme.palette.primary.light ,

    },
    listItemText: {
        textDecoration: "none"
    },
    Tabroot: {
        flexGrow: 1,
        maxWidth: 370,
        overflow:"hidden",
        [theme.breakpoints.down('md')]: {
            maxWidth: 330,
        },
    },
    tabHeader: {
        backgroundColor:theme.palette.background.default,
        '& .Mui-selected': {
            color: theme.palette.primary.main
        },

    },
    ProfileDrawer:{
        backgroundColor: "#424242",
        color: "#fff",
        width:"19rem",
        zIndex:3000000,
        [theme.breakpoints.down('md')]: {
            width: "19rem",
        },
    },
    emptyNotification:{
        border:"1px solid #ddd",
        textAlign:"center",
        padding:10,
        borderRadius:8
    },
    DataTable: {
        backgroundColor:'#ffffff',
        display:"none"
    },
    addButton: {
        textDecoration: "none",
    },

    appBarDialog: {
        position: 'relative',
        zIndex: 99999999,
        backgroundColor:theme.palette.primary.light
    },
    titleDialog: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
    FileManagerInput: {
        display:"none"
    },
    ButtonProgress: {
        fontSize:20
    },
    FMThumbnail: {
        width:50,
        borderRadius: '10%',
        cursor:'pointer'

    },
    sticky: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        position:"sticky",
        top: 100,
    },
    galleryRoot: {
        maxWidth: 150,
        margin:10,
        display:"inline"
    },
    litleUrlShower:{
        fontSize: 11,
        color:theme.palette.primary.light,
        fontWeight:700,
        cursor: "pointer",
        whiteSpace: "pre-wrap",
        wordWrap: "break-word",
    },
    Stepperroot: {
        width: '100%',
        backgroundColor:"#fff",
        padding: theme.spacing(2),
    },
    Stepperbutton: {
        marginRight: theme.spacing(1),
    },
    backStepperbutton: {
        marginRight: theme.spacing(1),
    },
    completed: {
        display: 'inline-block',
    },
    StepperInstructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    messagesScreenRoot: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
    appsContentContainer:{
        display: 'flex',
        flexDirection: 'column',
        overflow:"auto",
        height: `calc(100% - 115px)`,
        [theme.breakpoints.up('sm')]: {
            height: `calc(100% - 60px)`,
        },
        [theme.breakpoints.up('xl')]: {
            height: `calc(100% - 77px)`,
        },
    },
    emojiOpener:{
        position: "absolute",
        bottom: 5,
        right: 5
    },
    messageItemRoot: {
        marginBottom: 22,
        '&:last-child': {
            marginBottom: 0,
        },
        '&.right $messageChatRoot': {
            '& .message-time': {
                marginLeft: 0,
                marginRight: 55,
                textAlign: 'right',
            },
        },
        '&.right $messageChat': {
            flexDirection: 'row-reverse',
            backgroundColor:"#e1baee",
            color: "#495057",
            '& .MuiAvatar-root': {
                marginRight: -65,
                marginLeft: 0,
            },
            '& .message-info': {
                marginLeft: 0,
                display: 'flex',
                alignItems: 'center',
            },
        },
    },
    messageChatRoot: {
        position: 'relative',
    },
    messageTime: {
        fontSize: 12,
        marginLeft: 55,
        marginBottom: -15,
        color: theme.palette.grey[500],
        display: 'block',
    },
    messageChat: {
        display: 'flex',
        borderRadius: 4,
        padding: '5px 15px',
        position: 'relative',
        marginLeft: 35,
        marginRight: 35,
        marginTop: 20,
        marginBottom: 10,
        maxWidth:650,
        minWidth:100,
        backgroundColor: "#f6f6f6",
        '& .MuiAvatar-root': {
            marginLeft: -65,
            marginTop: -5,
        },
        '& .message-info': {
            fontSize: 14,
            minWidth:300,
            marginTop:10,
            paddingRight:20,
            paddingLeft:20,
            marginBottom:10,
            [theme.breakpoints.up('lg')]: {
                fontSize: 16,
            },
        },
    },
    textPointer: {
        cursor: 'pointer',
    },
    GridAsaPaper:{
        backgroundColor: theme.palette.background.paper,
        borderRadius: 10,
        marginTop:15,
        boxShadow:"0px 0px 10px rgba(82, 82, 82, 0.21)",
        padding: 10
    },
    GridAsaPaperTransParent:{
        padding: 10
    },
    MenuItemDef: {
        color: 'rgba(0, 0, 0, 0.54)'
    },
    BreadLink: {
        display: 'flex',
    },
    BreadIcon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    },
    breadCrumb: {
        marginTop: 12,
        [theme.breakpoints.down('sm')]: {
            fontSize:'12px',
        },
    },
    LoadingButtonWrapper: {
        position: 'relative',
        margin: theme.spacing(2,0),
        textAlign:'center'
    },
    buttonSuccess: {
        backgroundColor: green[500],
        '&:hover': {
            backgroundColor: green[700],
        },
    },
    buttonProgress: {
        color: "#ff9f00",
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    LoadingButtonRoot: {
        display: 'flex',
        alignItems: 'center',
    },
    ListRootWithoutHeight: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflowY:"auto",
        height: '100%',
        maxHeight: 300,
        marginBottom: 10,
    },
    ListRootWithHeight: {
        minHeight:300,
        overflowAnchor:"none",
    },
    link:{
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration:"none",
        },
    },
    linkSecondary:{
        '&:hover': {
            textDecoration:"none",
        },
    },
    loginPageLogo:{
        maxHeight:70,
        margin:15
    },
    loginPageContainer:{
        height:"100vh",
        backgroundColor:"#f7f7f7"
    },
    LoginFormGrid:{
        borderRadius:10,
        backgroundColor:'#ffffff',
        padding:"30px !important"
    },
    image: {
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
    },
    mainLogo:{
        maxHeight:50,
        paddingRight:10
    },
    NotificationsHolder:{
        padding:10,
        height:'88%',
        overflow:"auto"
    },
    NotificationAlert: {
        marginTop:10
    },
    small: {
        width: theme.spacing(3),
        height: theme.spacing(3),
    },
    large: {
        width: theme.spacing(6),
        height: theme.spacing(6),
    },
    ListItemWidth:{
        minWidth: "30px !important"
    },
    activeProfileItem:{
        color: "#2b2b2b",
        fontSize: "10px !important",
        backgroundColor:"#f9f9f9",
        borderLeft:"5px solid " + theme.palette.primary.light ,
        '&:hover': {
            color: theme.palette.text.primary,
        }
    },
    CartBox:{
        paddingTop:"18px !important"
    },
    CartBoxWithoutPadding: {
        backgroundColor: "#f9f9f9",
        borderRadius:4,
        paddingTop:"0 !important",
        marginTop:'10px !important',
    },
    Step:{
        cursor:"pointer",
    },
    filterContainer:{
        backgroundColor:"#f6f6f6",
        padding:15,
        borderRadius:4,
        position:'relative',
        marginBottom:20,
    },
    primaryLink:{
        color:theme.palette.primary.main
    },
    ProviderManagerRoot: {
        flexGrow: 1,
        width: '100%',
        backgroundColor: theme.palette.background.paper,
    },
    dashboardTitle : {
        borderBottom: "1px solid #bbb",
        paddingTop:15,
        paddingBottom:5,
        fontWeight: 700 ,
        display: "block",
        fontSize:20
    },
    ListRoot: {
        width: '100%',
        backgroundColor: theme.palette.background.paper,
        overflowY:"auto",
        height: '100%',
        maxHeight: 450,
        marginBottom: 10
    },
    PassVisibilityIcon: {
        position: "absolute !important",
        right: 10,
        top: "50%",
        transform: "translateY(-50%)"
    },
    GridAsaPaperBaby:{
        borderRadius: 5,
        paddingBottom:20,
        paddingTop:10,
    },
    GridAsaPaperLive:{
        backgroundColor: "#fff",
        borderRadius: 7,
        paddingBottom:15,
        boxShadow:"0px 0px 3px rgba(129, 128, 128, 0.15)",

    },
    AnalyticsResult: {
        fontSize: 20,
        fontWeight: 700,
        color: getStore('mode') === "dark" ? "#c7c7c7" : "#3e3c3c",
    },
    SuccessAnalytic:{
        float:"right",
        backgroundColor:getStore('mode') === "dark" ? "rgba(143,143,143,.5)" : "rgba(255, 255, 255, 0.47)",
        borderRadius: 5,
        padding:"2px 8px",
        color: "#5eb94b",

    },
    FlatAnalytic:{
        color: getStore('mode') === "dark" ? "#c7c7c7" : "#2b2b2b",
        backgroundColor:getStore('mode') === "dark" ? "rgba(143,143,143,.5)" : "rgba(233, 233, 233, 0.47)",
        padding:"2px 8px",
        borderRadius: 5,
        float:"right"
    },
    ErrorAnalytic:{
        backgroundColor:getStore('mode') === "dark" ? "rgba(143,143,143,.5)" : "rgba(255, 255, 255, 0.47)",
        color: getStore('mode') === "dark" ? "#8f1919" : "#ab2a2a",
        padding:"2px 8px",
        borderRadius: 5,
        float:"right"
    },
    ErrorAnalyticLive:{
        backgroundColor:getStore('mode') === "dark" ? "#8f1919" : "#ab2a2a",
        color: getStore('mode') === "dark" ? "#8f1919" : "#ab2a2a",
        padding:"2px 8px",
        borderRadius: "50%",
    },
    styledImg: {
        height: 430,
        display: 'inline-block',
        [theme.breakpoints.up('lg')]: {
            paddingRight: 40,
        },
        [theme.breakpoints.up('xl')]: {
            height: 'auto',
        },
    },
    AuthLogoMobile:{
        zIndex:10,
        maxWidth:70,
        marginBottom:10
    },
    authSidebar:{
        width:"40%",
        position:"absolute",
        height:"100%",
        background:"#1d0525",
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        borderTopLeftRadius:20,
        borderBottomLeftRadius:20,
    },
    AuthLogo:{
        maxWidth:150
    },
    textField: {
        width: '100%',
    },
    containerFull: {
        backgroundColor:"#f9f9f9"

    },
    loginContainerText: {
        alignContent: "center"
    },
    loginContainerForm:{
        textAlign: 'center',
        overflow: 'hidden',
        position:'relative',
        minHeight:600,
        zIndex: 0,
        paddingTop:20,
        paddingBottom:20
    },
    auth_bg_section:{
        backgroundColor: 'rgb(30,41,59)',
    },
    headLine: {
        fontWeight:"700 !important",
        fontSize:'20px !important',
        marginBottom: '20px !important',
        color:"#fff",
        textAlign:"center"
    },
    subLine: {
        fontSize:'17px !important',
        margin: '0 25px !important',
        textAlign:"center",
        color:"#e7e7e7",
    },
    AuthCard: {
        width: '100%',
        padding: "0 32px",
        overflow: 'hidden',
    },
    AuthGrid: {
        textAlign: 'center',
    },
    AuthButton: {
        width: '100%',
        height: 44,
    },
    socialLogin: {
        maxWidth: 27,
    },
    pointer: {
        cursor: 'pointer',
    },
    notiBtn: {
        justifyContent: 'flex-start',
        width: '100%',
        height: 56,
        fontSize: 18,
        borderRadius: 0,
        paddingLeft: '1rem',
        paddingRight: '1rem',
        color: "#fefefe",
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
        [theme.breakpoints.up('md')]: {
            justifyContent: 'center',
            width: 'auto',
            color: theme.palette.grey[400],
        },
        [theme.breakpoints.up('lg')]: {
            paddingLeft: '1.5rem',
            paddingRight: '1.5rem',
        },
        [theme.breakpoints.up('xl')]: {
            paddingLeft: '2.5rem',
            paddingRight: '2.5rem',
        },
    },
    notiIcon: {
        fontSize: 28,
        color: "#fefefe",
        [theme.breakpoints.up('xl')]: {
            fontSize: 22,
        },
    },
    walletBtn:{
        borderRadius:0,
        justifyContent: 'flex-start',
        width: '100%',
        height: 56,
        [theme.breakpoints.down('md')]: {
            justifyContent: 'flex-end',

        },
        [theme.breakpoints.up('sm')]: {
            height: 70,
        },
    },
    NotifcationText:{
        color: "#2f2f2f",
        fontWeight:700,
        fontSize:12,
        marginBottom:0,
        textAlign:'right'
    },
    authFormHolder:{
        background: "#fff",
        padding: "20px 40px 40px !important",
        boxShadow: "0 0 10px #ddd",
        borderRadius: 10,
        position:"relative !important",
        [theme.breakpoints.down('sm')]: {
            padding: "10px 20px 20px !important",

        }
    },
    auth_container: {
        textAlign: 'center',
        minHeight: '100vh',
        backgroundColor:getStore('mode') === "dark" ? "#2e2e2e" : "#f3f4f6",
        backgroundSize:"cover",
        backgroundImage:`url("${Auth_bg}")`,
        backgroundPosition:"center",
        backgroundRepeat:'no-repeat',
        marginTop:"0 !important",
        marginBottom:"0 !important",
        display:"flex",
        justifyContent:"center",
        alignItems:'center',
        alignContent:'center'
    },
    auth_container_no_bg: {
        textAlign: 'center',
        minHeight: '100vh',
        backgroundColor:getStore('mode') === "dark" ? "#2e2e2e" : "#f3f4f6",
        backgroundSize:"cover",
        backgroundPosition:"center",
        backgroundRepeat:'no-repeat',
        marginTop:"0 !important",
        marginBottom:"0 !important",
        display:"flex",
        justifyContent:"center",
        alignItems:'center',
        alignContent:'center'
    },
    auth_inner:{
        backgroundColor:theme.palette.background.paper,
        width:"40%",
        borderRadius:4,
        padding:20,
        [theme.breakpoints.down('md')]: {
            width: "95%",
            marginTop:20,
            marginBottom:20
        },
    },
    auth_title:{
        display:"inline-flex",
        alignItems:'center',
        justifyContent:"center",
        borderBottom:"3px solid",
        borderBottomColor:theme.palette.secondary.main,
        paddingBottom:10,
        fontSize:16,
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
        color:getStore('mode') === "dark" ? "#ddd" : "#333",

    },
    auth_logo:{
      width:160,
      marginBottom:20
    },
    auth_container_items:{
        padding:20,
        display:"flex",
        alignItems:"center",
        justifyContent:"center",
        [theme.breakpoints.down('md')]: {
            marginLeft:0,
        },
    },
    loginContainerFormBrd:{
        borderLeft:"1px solid #ddd !important",
        [theme.breakpoints.down('md')]: {
            borderLeft:"none !important",
        }
    },
    primaryColor:{
        color:theme.palette.primary.main
    },
    authLanguageSwitcher:{
        position:"fixed",
        top:15,
        left:15
    },
    authBackButton:{
        position:"fixed",
        top:15,
        right:15
    },
    dateTable:{
        fontSize:"12px !important",
    },
    condir:{
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
        textAlign:"left"
    },
    condirFake:{
        direction:getStore('fake_locale') === "en" ? "ltr" : "rtl",
        textAlign:"left"
    },
    thumbnail_code:{
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
        fontSize: "12px !important",
        textAlign: "center",
        marginTop: "5px !important",
        color: theme.palette.text.secondary
    },
    avatar_badge:{
        backgroundColor: theme.palette.primary.main,
        color: "#fefefe",
    },
    addImageBtn:{
        width: "100%",
        aspectRatio:"4 / 3",
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
        padding:"20px !important",
        '& .MuiButton-label': {
            display:"flex",
            flexDirection:"column",
            justifyContent:"center",
            alignItems:"center",
            '& .MuiButton-startIcon': {
                margin:"0 !important",
                '& .MuiSvgIcon-root': {
                    fontSize:40,
                    margin:"0 0 10px !important",
                },
            },
        },
    },
    uploaderHolder:{
        width:200,
        marginRight:10,
        maxWidth:"calc( 50% - 5px)"
    },
    thumbnailWithPreview:{
        position:"relative",
        '& .MuiSvgIcon-root': {
            position:"absolute",
            top:"50%",
            left:10,
            transform:'translateY(-50%)'
        },
    },
    thumbnailWithPreviewChooser:{
        position:"relative",
        aspectRatio: "4 / 3",
        minHeight:115,
        backgroundColor:"#dbd8f3",
        borderRadius:5,
        width:"100%",
        '& .MuiSvgIcon-root': {
            position:"absolute",
            top:"50%",
            left:"50%",
            transform:'translate(-50%,-50%)'
        },
    },
    galleryItemHolder:{
        display:"block"
    },
    alertText:{
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
    },
    correctText:{
        direction:getStore('locale') === "en" ? "ltr" : "rtl",
        color: getStore('mode') === "dark" ? "#ddd" : "#333",
        "& a":{
            color: getStore('mode') === "dark" ? theme.palette.secondary.main : theme.palette.primary.main
        }
    },
    privacy_policy:{
        fontSize: "12px !important",
        marginBottom: "10px !important",
        marginTop: "10px !important",
        color: getStore('mode') === "dark" ? "#ddd" : "#333",
        "& a":{
            color: getStore('mode') === "dark" ? theme.palette.secondary.main : theme.palette.primary.main
        }
    },
    mapMarker:{
        position:"absolute",
        top:-20,
        left:-40,
        backgroundColor:"#fff",
        borderColor:"#fff",
        whiteSpace:"nowrap",
        '&:after': {
            content: '""',
            width: 0,
            height: 0,
            borderLeft: "10px solid transparent",
            borderRight: "10px solid transparent",
            borderTop: "10px solid #fff",
            position: "absolute",
            bottom: -10,
        },
        '&:hover': {
            backgroundColor:"#fff",
            borderColor:"#fff",
        }
    },
    confirmationButtonButton:{
        marginRight:5,
        marginLeft:5,
        '& .MuiButton-label':{
            justifyContent:"space-between !important"
        }
    },
    currencyTextField:{

        '& input':{
            textAlign:"left"
        }
    },
    singleImageHolder:{
        position:"relative",
        border: "1px solid #ddd",
        borderRadius:5,
        '& button.MuiButton-contained': {
            position: "absolute",
            bottom: 0,
            width: '100%',
            backgroundColor: "#fff !important",
            left:0,
            right:0,
        },
        '& .fileHolder': {
            aspectRatio: 1 ,
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            flexDirection:"column",
            '& svg': {
                fontSize:50
            }
        }
    },
    ratingContainer:{
        display:'inline',
        '& .MuiRating-root': {
            direction:getStore('locale') === "en" ? "ltr" : "rtl !important",
            textAlign:getStore('locale') === "en" ? "left" : "right !important",
        }
    },
    equalAppCard:{
        backgroundColor:getStore('mode') === "dark" ? "#424242" : "#c7badf",

    },
    equalHolder:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        flexWrap:"wrap"
    },
    voucher_text:{
        fontSize:12,
        display:"flex",
        alignItems:"center",
    },
    voucherList:{
        borderRadius:4,
        border: "1px solid"
    },
    singleCartHolder:{
        border:"1px solid #eee",
        marginBottom:15,
        borderRadius:4,
        paddingLeft:15,
        paddingRight:15,
        [theme.breakpoints.down('md')]: {
            paddingLeft:0,
            paddingRight:0,
        }
    },
    singleCartInner:{
        display:"flex",
        alignItems:"center",
        justifyContent:"space-between",
        [theme.breakpoints.down('md')]: {
            flexDirection:"column"
        }
    },
    singleCartControllers:{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        [theme.breakpoints.down('md')]: {
            background: "rgba(131,131,131,0.1)",
            width: "100%"
        }
    },
    coinHolder:{
        background: "#f8e095",
        position:"relative",
        borderRadius: 50,
        textAlign:"center",
        height:38,
        '& img': {
            maxWidth: 48,
            left: 0,
            top:-5,
            position:"absolute",

        },
        '& span': {
            color: "#986e21",
            lineHeight: "38px",
            fontSize: 12,
            marginLeft: 10,
            direction:getStore('locale') === "en" ? "ltr" : "rtl !important",
        }
    },
    orderResult:{
        color:getStore('mode') === "dark" ? "#d2d2d2" : "#000000",
        padding: '0 5px',
    }
});