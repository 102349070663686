import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCountForCat} from "../../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {translate} from "../../../../config/lang";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import {getTimeZone} from "../../../../config/values";
import OrderCategoriesButton from "../categories/components/OrderCategoriesButton";
import OrderBlogCategoriesButton from "./OrderBlogCategoriesButton";

class BlogCategories extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                hidden: this.props.width === "xs",
                title: translate('thumbnail'),
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            { title: translate('categoryName'), field: 'name',filterPlaceholder: translate('search')},
            { title: translate('categoryParentName'), field: 'parent',filterPlaceholder: translate('search')},
            { title: translate('confirmationStatus'),
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: translate('choose'),
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , 0:
                        <Typography color="textSecondary">{translate('unConfirmed')}</Typography>
                },
            },
            {
                title: translate('updateTime'),
                editable: 'never',
                field: 'created_at',
                hidden: this.props.width === "xs",

                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment.tz(rowData.updated_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/edit/blog-category/${rowData.id}`}
                        endIcon={<ChevronRightRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {translate('edit')}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/blog_category/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteData'), {
                        variant: 'success',
                    });
                }
                this.loadData();
                return true
            })

            .catch(error => {
                this.loadData();
                this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={
                                    <>
                                        <OrderBlogCategoriesButton reload={this.loadData.bind(this)}/>
                                        <Link to='/create/blog-category'>
                                            {
                                                this.props.width !== "xs" ?
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        startIcon={<AddBox/>}
                                                    >
                                                        {translate('makeCategory')}
                                                    </Button>
                                                    :
                                                    <IconButton color="primary">
                                                        <AddBox/>
                                                    </IconButton>
                                            }
                                        </Link>
                                    </>

                                }
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/blog_categories/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&lang=${this.props.locale}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        thumbnail: `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`,
                                                        name: row.name,
                                                        id: row.id,
                                                        owner: row.owner.first_name + " " + row.owner.last_name,
                                                        parentId: row.parent_id,
                                                        parent: !!row.parent ? row.parent.name : '',
                                                        confirmed: row.confirmed,
                                                        updated_at: row.updated_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: 5,
                                    pageSizeOptions: TableCountForCat,
                                    defaultExpanded:false,
                                    draggable:false,
                                    sorting:false,
                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(BlogCategories)))));