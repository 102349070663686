import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppAnimate from "../../components/AppAnimate";
import AppCard from "../../components/layouts/components/AppCard";
import Pricing from "./Pricing";
import ModifyPricing from "./ModifyPricing";


class ModifyPricingPage extends React.Component {
    classes = this.props.classes;
    render(){
        let {manage} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={manage ? Translate.managePricing : Translate.modifyPricing}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={manage ? Translate.managePricing : Translate.modifyPricing}
                            action={
                                <BreadCrumbs singleLevel firstText={manage ? Translate.managePricing : Translate.modifyPricing}/>
                            }>
                            <ModifyPricing manage={manage}/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
export default withSnackbar(withStyles(Styles)(ModifyPricingPage));
