import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {connect, getStore, setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import SubTitle from "../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {Translate} from "../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {Link, withRouter} from "react-router-dom";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import {makeSlug, translate} from "../../../../config/lang";
import Typography from "@material-ui/core/Typography/Typography";
import {hasAccess} from "../../../../config/Security";
import withWidth from "@material-ui/core/withWidth/withWidth";
import AppCard from "../../../components/layouts/components/AppCard";

class SellerAnalyticsShort extends React.Component {
    state = {
        has_business: null,
        business_visits: null,
        business: null,
        total_discounts: null,
        total_sales: null,
        total_ads: null,
        ads_visits:null,
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        this.setState({
            loading:true,
        });
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/user-analytics`, config)
                .then(response => {
                    this.setState({
                        has_business: response.data.has_business,
                        business: response.data.business,
                        business_visits: response.data.business_visits,
                        total_discounts: response.data.total_discounts,
                        total_sales: response.data.total_sales,
                        total_ads: response.data.total_ads,
                        ads_visits: response.data.ads_visits,
                        rfqs_visits: response.data.rfqs_visits,
                        loading: false,
                    })
                }).catch(error => {

                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {
            has_business,
            business,
            business_visits,
            total_discounts,
            total_sales,
            total_ads,
            ads_visits,
            loading
        } = this.state;
        let mode = getStore('mode');
        return (
            <Grid container spacing={2} direction="row">
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative"}} direction="row" className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11}>
                                <SubTitle  align="left">
                                    {translate('myBusinessPage')}
                                    {
                                        !!has_business &&
                                        <Button
                                            size="small"
                                            style={{position:"absolute",right:0}}
                                            endIcon={<ChevronRightRoundedIcon/>}
                                            component={'a'}
                                            href={makeSlug('business/'+business.slug)}
                                        >
                                            {translate('viewList')}
                                        </Button>
                                    }
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    !!has_business &&
                                        <List dense>
                                            <ListItem>
                                                <ListItemText
                                                    primary={translate('totalVisitsCount')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <strong style={{fontSize:11}}>{business_visits}</strong>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List>
                                }
                                {
                                    (!has_business && !loading) &&
                                    <Typography style={{fontSize:12,marginLeft:10}}>{translate('createBusinessPageToSee')}</Typography>
                                }
                            </Grid>

                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative"}} direction="row"  className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11}>
                                <SubTitle  align="left">
                                    {translate('myAdvertisements')}
                                    {
                                        !!hasAccess('ad_count') &&
                                        <Button
                                            size="small"
                                            style={{position:"absolute",right:0}}
                                            endIcon={<ChevronRightRoundedIcon/>}
                                            component={Link}
                                            to={'/my-advertisements'}
                                        >
                                            {translate('viewList')}
                                        </Button>
                                    }
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    !!hasAccess('ad_count') ?
                                        <List dense>
                                            <ListItem>
                                                <ListItemText
                                                    primary={translate('numberOfAds')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <strong style={{fontSize:11}}>{total_ads}</strong>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                            <ListItem>
                                                <ListItemText
                                                    primary={translate('totalVisitsCount')}
                                                />
                                                <ListItemSecondaryAction>
                                                    <strong style={{fontSize:11}}>{ads_visits}</strong>
                                                </ListItemSecondaryAction>
                                            </ListItem>
                                        </List> :
                                        <Typography style={{fontSize:12,marginLeft:10}}>{translate('upgradeYourPlanToCreateAds')}</Typography>
                                }
                            </Grid>

                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative"}} direction="row"  className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11}>
                                <SubTitle align="left">
                                    {translate('myDiscounts')}
                                    <Button
                                        size="small"
                                        style={{position:"absolute",right:0}}
                                        endIcon={<ChevronRightRoundedIcon/>}
                                        component={Link}
                                        to={'/my-discounts'}
                                    >
                                        {translate('viewList')}
                                    </Button>
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                <List dense>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('numberOfDiscounts')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{total_discounts}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('totalVouchersSold')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{total_sales}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(SellerAnalyticsShort))));
