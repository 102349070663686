import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {getStore, setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import SubTitle from "../../../../components/elements/SubTitle";
import TrendingUpRoundedIcon from '@material-ui/icons/TrendingUpRounded';
import TrendingDownRoundedIcon from '@material-ui/icons/TrendingDownRounded';
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import TrendingFlatRoundedIcon from '@material-ui/icons/TrendingFlatRounded';
import {translate} from "../../../../../config/lang";
import AppCard from "../../../../components/layouts/components/AppCard";
class TodayAnalytics extends React.Component {
    state = {
        ads: null,
        yesterdayAds: null,
        discounts: null,
        yesterdayDiscounts: null,
        users: null,
        yesterdayUsers: null,
        visitors: null,
        yesterdayVisitors: null,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/today-analytics`, config)
                .then(response => {
                    this.setState({
                        ads: response.data.ads,
                        yesterdayAds: response.data.yesterdayAds,
                        discounts: response.data.discounts,
                        yesterdayDiscounts: response.data.yesterdayDiscounts,
                        users: response.data.users,
                        yesterdayUsers: response.data.yesterdayUsers,
                        visitors: response.data.visitors,
                        yesterdayVisitors:response.data.yesterdayVisitors,
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {ads,yesterdayAds,discounts,yesterdayDiscounts,users,yesterdayUsers,visitors,yesterdayVisitors} = this.state;
        let mode = getStore('mode');
        return (
            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={6} md={3}>
                    <AppCard>
                        <Grid container  direction="row" alignItems="center" justify="center">
                            <Grid item xs={11}>
                                <SubTitle align="left">{translate('newAds')}</SubTitle>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className={this.classes.AnalyticsResult} >{ads === null ? <CircularProgress size={25}/> : ads}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    ads === null ? null :
                                        yesterdayAds > ads ?
                                            <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayAds-ads}</span> </span>
                                            :
                                            yesterdayAds === ads ?
                                                <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{ads-yesterdayAds}</span> </span>
                                                :
                                                <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{ads-yesterdayAds} </span>
                                }
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={6} md={3}>
                    <AppCard>
                        <Grid container  direction="row" alignItems="center" justify="center" >
                            <Grid item xs={11}>
                                <SubTitle  align="left">{translate('newDiscounts')}</SubTitle>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className={this.classes.AnalyticsResult} >{discounts === null ? <CircularProgress size={25}/> : discounts}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    discounts === null ? null :
                                        yesterdayDiscounts > discounts ?
                                            <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayDiscounts-discounts}</span> </span>
                                            :
                                            yesterdayDiscounts === discounts ?
                                                <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{discounts-yesterdayDiscounts}</span> </span>
                                                :
                                                <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{discounts-yesterdayDiscounts} </span>
                                }
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={6} md={3}>
                    <AppCard>
                        <Grid container direction="row" alignItems="center" justify="center" >
                            <Grid item xs={11}>
                                <SubTitle  align="left">{translate('newRegistrations')}</SubTitle>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className={this.classes.AnalyticsResult} >{users === null ? <CircularProgress size={25}/> : users}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    users === null ? null :
                                        yesterdayUsers > users ?
                                            <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayUsers-users}</span> </span>
                                            :
                                            yesterdayUsers === users ?
                                                <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{users-yesterdayUsers}</span> </span>
                                                :
                                                <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{users-yesterdayUsers} </span>
                                }
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={6} md={3}>
                    <AppCard>
                        <Grid container   direction="row" alignItems="center" justify="center" >
                            <Grid item xs={11}>
                                <SubTitle  align="left">{translate('todayAllUnRegisteredUsers')}</SubTitle>
                            </Grid>
                            <Grid item xs={5}>
                                <Typography className={this.classes.AnalyticsResult} >{visitors === null ? <CircularProgress size={25}/> : visitors}</Typography>
                            </Grid>
                            <Grid item xs={6}>
                                {
                                    visitors === null ? null :
                                        yesterdayVisitors > visitors ?
                                            <span className={this.classes.ErrorAnalytic}><TrendingDownRoundedIcon/> <span>-{yesterdayVisitors-visitors}</span> </span>
                                            :
                                            yesterdayVisitors === visitors ?
                                                <span className={this.classes.FlatAnalytic}><TrendingFlatRoundedIcon/> <span>{visitors-yesterdayVisitors}</span> </span>
                                                :
                                                <span className={this.classes.SuccessAnalytic}><TrendingUpRoundedIcon/> +{visitors-yesterdayVisitors} </span>
                                }
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>

            </Grid>
        );
    }
}

export default withStyles(Styles)(TodayAnalytics);