import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import TextField from "@material-ui/core/TextField/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import {Translate} from "../../../../../config/Translate";
import SubTitle from "../../../../components/elements/SubTitle";

class AttributesChooser extends React.Component {
    state = {
        attribute: null,
        loading:true,
        selected: [],
        product_id:this.props.product_id,
        disabled:false,
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            selected: this.props.input,
            product_id:this.props.product_id
        });
        await this.loadData();
    }
    async loadData(){
        let {product_id} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/values/getForProduct/${product_id}`, config)
                .then(response => {
                    this.props.makeAvailable(response.data)
                    this.setState({
                        attribute: response.data,
                        loading:false,

                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false,
                        disabled:true
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async toggleValues(id) {
        let {selected} = this.state;
        let index = selected.indexOf(id);

        if (index === -1) {
            selected.push(id);
        } else {
            selected.splice(index, 1);
        }
        await this.setState({
            selected
        })
    }
    async handleChange(value){
        await this.setState({
            selected:value
        })
        await this.props.handleValues(value);

    }
    render(){
        let {attribute,loading,selected,disabled} = this.state;
        return (
            <>
                {
                    !disabled  &&
                    <div className="attribute-chooser">
                        {
                            loading ?
                                <AppCardLoaderFields/>
                                :
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12}>
                                        <SubTitle align="left">{attribute.name} {Translate.attributeChooserTitle}</SubTitle>
                                    </Grid>
                                    <Grid item xs={12} style={{marginBottom:20}}>
                                        <Autocomplete
                                            multiple
                                            options={attribute.values}
                                            onChange={(event, value) => this.handleChange(value)}
                                            getOptionLabel={option => attribute.type === "color" ? option.description : option.name}
                                            defaultValue={selected}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    autocomplete="off"
                                                    label={attribute.name}
                                                    variant="outlined"
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                        }
                    </div>
                }
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(AttributesChooser)));