import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, getDataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {translate} from "../../../config/lang";
import AddTicketModalAdmin from "./add_ticket/AddTicketModalAdmin";
import {getTimeZone} from "../../../config/values";

class Tickets extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            { title: translate('ticket_sender'),
                field: 'starter',
                filterPlaceholder: translate('search')
            },
            { title: translate('ticket_receiver'),
                field: 'receiver',
                filterPlaceholder: translate('search')
            },
            { title: translate('subject'),
                field: 'subject',
                filterPlaceholder: translate('search')
            },
            { title: translate('requestType'),
                field: 'target',
                render: rowData =>(
                    <>
                        <Typography>
                            {rowData.type === "system" ?
                                translate('supportRequestFromDepartment') :
                                translate('reserveRequest')
                            }
                        </Typography>
                        <Typography style={{fontSize:11,color:"#777",marginTop:5}}>
                            {rowData.type === "reserve" ?
                                "(" + rowData.target.discount.name + ")"
                                :
                                !!rowData.target ?
                                "(" + translate('department') +" "+ rowData.target.name + ")"  : null
                            }
                        </Typography>
                    </>
                )
            },
            { title: translate('priority'),
                field: 'priority',
                filterPlaceholder: translate('search')
            },
            { title: translate('ticket_edited_at'),editable: 'never',filtering: false, field: 'lastUpdate'},
            {
                title: translate('activity'),
                editable: 'never',
                field: 'action',
                render: rowData =>(
                    <>
                        <Button
                            variant="outlined"
                            size="small"
                            style={{marginBottom:5}}
                            startIcon={
                                <ChevronRightRoundedIcon/>
                            }
                            onClick={() => this.props.history.push(`/manage/ticket/${rowData.id}`)}
                        >
                            {translate('view')}
                        </Button>
                    </>
                )
            }
        ],

        data: [],
        loading:false,
        ChangeLoader:false,
        manage:this.props.manage,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()

    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/ticket/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteTicket'), {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteTicketFailed'), {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                this.state.loading ? <AppCardLoaderTable/> :
                    <Container maxWidth="xl">
                        <MaterialTable
                            tableRef={this.tableRef}
                            icons={tableIcons}
                            title={<AddTicketModalAdmin reload={() => this.loadData()}/>}
                            localization={getDataTableLocalization()}
                            columns={this.state.columns}
                            data={query =>
                                new Promise((resolve, reject) => {
                                    axios.get(`${defaultVariabls.url}/${this.props.manage ? "tickets" : "my-tickets"}/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                        .then(response => {
                                            let data = [];
                                            // eslint-disable-next-line
                                            response.data.data.map((row) => {
                                                data.push({
                                                    id: row.id,
                                                    subject: row.type === "reserve" ? translate('reserveRequest') : !!row.subject ? row.subject.length> 25 ? row.subject.slice(0,25)+"..." : row.subject : '',
                                                    receiver: row.receiver.first_name + " " + row.receiver.last_name,
                                                    starter: row.starter.first_name + " " + row.starter.last_name,
                                                    type: row.type,
                                                    target: row.type === "system" ? row.department : row.factor_item,
                                                    priority: translate(row.priority),
                                                    lastUpdate:
                                                        <Typography className={this.classes.dateTable}>
                                                            {`${moment.tz(row.updated_at,'UTC').tz(getTimeZone()).fromNow()}`}
                                                            <br/>
                                                            {`${moment.tz(row.updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                        </Typography>
                                                })
                                            });
                                            resolve({
                                                data: data,
                                                page: response.data.current_page - 1,
                                                totalCount: response.data.total,
                                            })
                                        }).catch(error => {
                                        console.log(error.response)
                                    })
                                })
                            }
                            options={{
                                pageSize: TablePage,
                                pageSizeOptions: TableCount,
                            }}
                            editable={{
                                onRowDelete: oldData =>
                                    new Promise(resolve => {
                                        setTimeout(() => {
                                            resolve();
                                            this.onDelete(oldData.id);
                                            this.setState(prevState => {
                                                const data = [...prevState.data];
                                                data.splice(data.indexOf(oldData), 1);
                                                return {...prevState, data};
                                            });
                                        }, 600);
                                    }),
                            }}

                        />
                    </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Tickets))));