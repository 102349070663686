import React from 'react';
import clsx from 'clsx';
import Box from '@material-ui/core/Box';
import Avatar from '@material-ui/core/Avatar';
import EditIcon from '@material-ui/icons/Edit';
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {connect, getStore} from "trim-redux";
import moment from "moment-timezone";
import {defaultVariabls} from "../../../../../config";
import DescriptionRoundedIcon from '@material-ui/icons/DescriptionRounded';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {Translate} from "../../../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import Button from "@material-ui/core/Button/Button";
import ViewPlanButton from "../../components/ViewPlanButton";
import {getTimeZone} from "../../../../../config/values";
const getMessage = (item, classes) => {
  if (item.type === "text") {
    return (
      <Box component='p' mb={1} mr={1} mt={1} style={{fontSize:13,whiteSpace:"pre-line"}}>
        {item.message}
      </Box>
    );
  } else if (item.type === "file") {
    return (
      <Box display='flex' flexWrap='nowrap'>
        <Box
          component='span'
          className='pointer'
          display='flex'
          flexWrap='nowrap'>
            <DescriptionRoundedIcon style={{fontSize:45,color: "#fff"}}/>
          <Box component='span' display='inline-block' mr={2}>
            <Box style={{fontSize:13}}>{item.file.name}</Box>
            <Box style={{fontSize:12,fontWeight:700}}>{item.file.size} kb</Box>
          </Box>
        </Box>
      </Box>
    );
  }else if (item.type === "plan") {
    return (
      <Box display='flex' flexWrap='nowrap'>
        <Box
          component='span'
          className='pointer'
          display='flex'
          flexWrap='nowrap'>
          <Box component='span' display='inline-block' mr={2}>
              <Typography>{Translate.yourPlan}</Typography>
              <Typography style={{fontWeight:700}}>{item.plan.name}</Typography>
              <ViewPlanButton plan={item.plan}/>
          </Box>
        </Box>
      </Box>
    );
  }
};
const SenderMessageItem = ({
  user,
  item,
  author,
  Translate,
  locale,
  classes,
  manage,
}) => {


  return (
    <Box
      className={clsx(classes.messageItemRoot, 'right')}
      display='flex'
      justifyContent={'flex-end'}>
      <Box className={classes.messageChatRoot}>
        <Box
          className={clsx(classes.messageTime, 'message-time')}
          component='span'>
          {`${moment.tz(item.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')} `}
          <br/>
            {manage ? author.first_name+" "+author.last_name : null}
        </Box>

        <Box display='flex' flexDirection='row' justifyContent='flex-end'>
          {item.type === "file" &&(
            <Box className='pointer' component='span' mt='auto' style={{position:"absolute",right:0,bottom:0}}>
                <IconButton size="small" component="a" target="_blank" rel="noopener noreferrer"  href={`${defaultVariabls.uploads}/FileManager/${item.file.name}.${item.file.extension}`} download={item.file.name}>
                    <GetAppRoundedIcon color="secondary"/>
                </IconButton>
            </Box>
          )}
          <Box className={classes.messageChat} ml='auto'>
              <Avatar
                  src={`${defaultVariabls.uploads}/FileManager/${author.avatar.name}_small.${author.avatar.extension}`}
                  className={classes.profilePic}
              />
            <Box className='message-info' style={{direction: getStore('locale') === "en" ? "ltr" : "rtl"}}>
              {getMessage(item, classes)}

              {item.edited && (
                <Box className={classes.editRoot}>
                  <EditIcon />
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const mstp = state => ({
    user: state.user,
    locale: state.locale,
    Translate: state.Translate,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SenderMessageItem))));

