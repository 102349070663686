import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../config/design/styles";
import {withRouter } from 'react-router-dom';
import Alert from "@material-ui/lab/Alert/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
import {defaultVariabls} from "../../../config";
import axios from "axios";
import {AlertSkeleton} from "../Skeletons/AlertSkeleton";
import InfiniteScroll from "react-infinite-scroller";
import Typography from "@material-ui/core/Typography/Typography";
import moment from "moment-timezone";
import {translate} from "../../../config/lang";
import {getTimeZone} from "../../../config/values";

class ReadNotificationsInfinity extends React.Component {
    state = {
        notifications: [],
        nextPage:1,
        hasMore: true,
        check: false,
    }
    classes = this.props.classes;
    async handleLoadMore(){

        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null) {
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/notifications/getRead?page=${this.state.nextPage}`, config)
                .then(response => {
                    const {current_page, last_page, data} = response.data;
                    this.setState(prevState => ({
                        notifications: [...prevState.notifications, ...data],
                        hasMore: current_page  !== last_page,
                        nextPage: current_page + 1,
                        check: true
                    }));
                })
                .catch(error => {
                });
        }
    }
    render(){
        let {check,hasMore,notifications} = this.state;
        let count = this.props.count;
        let skeleton = [];
            for(let i = 0 ; i < count && i <= 4 ; i++){
                skeleton.push(<AlertSkeleton key={i}/>)
            }
        return (
            <div className="notifications-holder">
                <InfiniteScroll
                    pageStart={1}
                    loadMore={this.handleLoadMore.bind(this)}
                    hasMore={hasMore}
                    loader={skeleton}
                    useWindow={false}
                >
                    {
                        notifications.map( (notification,index) => (
                            <div key={index}>
                                <a  href={`${defaultVariabls.React}${notification.data.link}`} onClick={() => this.makeSingleAsRead(notification.id)}  target="_blank"  rel="noopener noreferrer">
                                    <Alert className={this.classes.NotificationAlert} severity={notification.data.type}>
                                        <AlertTitle>{notification.data.title.length > 0 ? notification.data.title : null}</AlertTitle>
                                        {notification.data.text}
                                        <Typography class={this.classes.NotifcationText}>{`${moment.tz(notification.read_at,'UTC').tz(getTimeZone()).fromNow()}`}</Typography>
                                    </Alert>
                                </a>
                            </div>
                        ))
                    }
                </InfiniteScroll>
                {
                    check && notifications.length === 0 ?
                        <Typography className={this.classes.emptyNotification} color="textSecondary" variant="body2">{translate('noNotifications')}</Typography> : null
                }
            </div>
        );
    }

}
export default withRouter(withStyles(Styles)(ReadNotificationsInfinity));