import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import SubTitle from "../../../components/elements/SubTitle";
import Typography from "@material-ui/core/Typography/Typography";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import {ChromePicker } from 'react-color';
import DeleteButton from "../../../components/elements/DeleteButton";

class MakeValues extends React.Component {
    state = {
        fields:{
            name:"",
            description:"",
        },
        attribute_name:'',
        attribute_type:'',
        values:'',
        errors:{},
        loading: true,
        SubmitLoading: false,
        id:this.props.match.params.id,
    };
    classes = this.props.classes;

    componentDidMount(){
        this.setState({
            id:this.props.match.params.id,
        });
        this.loadData()
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/values/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        values:response.data.values,
                        attribute_name:response.data.name,
                        attribute_type:response.data.type,
                        loading:false
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }
    handleChangeColor(color){
        let {fields}= this.state;
        fields['name'] = color.hex
        this.setState({
            fields
        })
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 50) {
                formIsValid = false;
                errors['description'] = Translate.maxLength50;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,id} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/value/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataAdded, {
                    variant: 'success',
                });
                this.setState({
                    fields:{
                        name:"",
                        description:"",
                    },
                    attribute_name:'',
                    attribute_type:'',
                    values:'',
                    errors:{},
                    loading:true,
                    SubmitLoading: false,

                });
                this.loadData();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleDeleteValue(id){
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/value/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.deleteData, {
                    variant: 'success',
                });
                this.loadData();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    render(){
        let {fields, errors,SubmitLoading,values,attribute_name,attribute_type} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.manageValues}>
                {
                    <Grid container spacing={2} justify="center">
                        <Grid tem xs={12}>
                            <BreadCrumbs  firstText={Translate.manageAttributes} firstLink="/manage/attributes" secondText={Translate.manageValues}/>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppCard
                                title={Translate.manageValues}
                                >
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12}>
                                                <SubTitle align="left">{Translate.attributeName}: {attribute_name}</SubTitle>
                                            </Grid>
                                            {
                                                attribute_type === "color" ?
                                                    <Grid item xs={12}>

                                                        <Typography style={{marginBottom:5}}>{Translate.valueColor}</Typography>
                                                        <ChromePicker
                                                            color={ fields.name }
                                                            onChangeComplete={ this.handleChangeColor.bind(this) }
                                                        />
                                                    </Grid>
                                                    :
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            margin="normal"
                                                            required
                                                            fullWidth
                                                            variant="outlined"
                                                            name="name"
                                                            label={Translate.valueName}
                                                            type="text"
                                                            value={fields.name}
                                                            onChange={this.handleChange.bind(this)}
                                                            error = {!!errors['name']}
                                                            helperText={errors['name']}
                                                        />
                                                    </Grid>
                                            }

                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    name="description"
                                                    label={attribute_type === "color" ? Translate.valueName : Translate.description}
                                                    type="text"
                                                    id="description"
                                                    value={fields.description}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['description']}
                                                    helperText={errors['description']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.description.length} max={50}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {Translate.makeValue}
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <AppCard
                                title={Translate.values}
                            >
                                {
                                    this.state.loading ?
                                        <AppCardLoaderFields/> :
                                        <Grid container>
                                            {
                                                attribute_type === "color" ?
                                                    <Grid item xs={12}>
                                                        {
                                                            values.map((value,index) => (
                                                            <Typography key={index} className="value_item">
                                                                 <span>
                                                                    <span
                                                                        className="colored_badge"
                                                                        style={{background:value.name}}>
                                                                    </span>
                                                                     {value.description}
                                                                 </span>
                                                                <DeleteButton handleDelete={() => this.handleDeleteValue(value.id)}/>
                                                            </Typography>
                                                            ))
                                                        }
                                                    </Grid> :
                                                    <Grid item xs={12}>
                                                        {
                                                            values.map((value,index) => (
                                                                <Typography key={index} className="value_item">
                                                                    <span>
                                                                        {value.name}
                                                                    </span>
                                                                    <DeleteButton handleDelete={() => this.handleDeleteValue(value.id)}/>
                                                                </Typography>
                                                            ))
                                                        }
                                                    </Grid>
                                            }

                                        </Grid>
                                }
                            </AppCard>

                        </Grid>
                    </Grid>

                }
            </AppAnimate>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(MakeValues)));