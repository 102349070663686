import {getStore} from "trim-redux";

export const defaultVariabls = {
    url: "https://phil.ae/api",
    uploads: "https://phil.ae/uploads",
    React:"https://panel.phil.ae",
    website: "https://phil.ae",
    youtube: "https://www.youtube.com/watch?v=",
};
/*export let defaultVariabls = {
    url: "http://localhost:8000/api",
    uploads: "http://localhost:8000/uploads",
    React:"http://localhost:3000",
    website: "http://127.0.0.1:8000",
    youtube: "https://www.youtube.com/watch?v=",
};*/

export function websiteLink($link) {
    let locale = getStore("locale");

    return `${defaultVariabls.website}/${locale === "en"  ? "en/" : ''}${$link}`
}

