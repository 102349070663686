import React from 'react'
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {Translate} from "../../../../config/Translate";

import FileManager from "../FileManager";
import {defaultVariabls} from "../../../../config/index";
import {translate} from "../../../../config/lang";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

class FileManagerDialog extends React.Component{
    state = {
        open: true
    };
    classes = this.props.classes;
    size = this.props.size ? this.props.size: 200;
    img_ = this.props.cover ? "" : this.size > 400 ? "_medium" : "_small";
    image_ = this.props.logo ? "_medium" : this.props.fourtotree ? this.size > 400 ? "_thumbnail" : "_s_thumb": this.img_ ;
    handleSetImage(data,preview = null){
        let res ={
            src:data.source,
            link:data.source_id ? `https://www.youtube.com/watch?v=${data.source_id}` : data.source,
            type:data.type,
            alt:data.alt,
            name:data.name,
            preview:!!preview ? preview :  data.preview,
            id:data.id,
            thumbSource: `${defaultVariabls.uploads}/FileManager/${data.name}${this.image_}.${data.extension}`
        };

        if(data.preview === null && preview !== null){
            data['preview'] = preview
        }
        if(this.props.setData !== undefined){
            this.props.setData(data)
        }else{
            this.props.setImage(res);

        }
    }
    handleSetPreview(data){
        this.props.setData(data)
    }
    handleClose = () => {
        this.setState({
            open:false
        });
        this.props.cancleOperation();
    };
    render(){
        let {open} = this.state;
        return(
            <div>
                <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {translate('chooseMedia')}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding:20}}>
                    <FileManager
                        preview={this.props.preview}
                        modal
                        onlyFile={this.props.onlyFile}
                        selection={this.props.selection === true}
                        multiInsert={this.props.multiInsert === true}
                        fileType={this.props.fileType}
                        handleSetImage={this.handleSetImage.bind(this)}
                        handleSetPreview={this.handleSetPreview.bind(this)}
                        defined_width={this.props.defined_width}
                        defined_height={this.props.defined_height}
                    />
                    </div>
                </Dialog>
            </div>
        )
    }
}
export default  withStyles(Styles)(FileManagerDialog)