import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "moment-timezone";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {getTimeZone, price, truncate} from "../../../../config/values";
import Button from "@material-ui/core/Button/Button";
import { Link as ReactLink } from "react-router-dom";
import {makeSlug, translate} from "../../../../config/lang";
import OpenInNewRoundedIcon from "@material-ui/icons/OpenInNewRounded";
import SeeVouchersButton from "./SeeVouchersButton";

class UserOrders extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                title: translate('discount'),
                field: 'name',
                sorting:false,
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <Button
                        size="small"
                        startIcon={<OpenInNewRoundedIcon color="primary"/>}
                        component="a"
                        href={makeSlug(`discount/${rowData.discount.slug}`,rowData.discount.lang)}
                        target="_blank"
                    >
                        {truncate(rowData.discount.name,17)}
                    </Button>
            },
            {
                field: 'buyer',
                editable: 'never',
                filtering: false,
                title: translate('buyer'),
                render: rowData =>
                    <Typography>
                        {rowData.user.first_name} {rowData.user.last_name}
                    </Typography>
            },
            { title: translate('singular_price'),
                field: 'discount_price',
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <Typography>
                        {price(rowData.discount_price)} {rowData.currency}
                    </Typography>
            },
            { title: translate('total_price'),
                field: 'total_price',
                filterPlaceholder: translate('search'),
                render: rowData =>
                    <Typography>
                        {price(rowData.total_price)} {rowData.currency}
                    </Typography>
            },
            { title: translate('status'),
                editable: 'never',
                field: 'status',
            },
            { title: translate('created_at_factor'),
                editable: 'never',
                filtering: false,
                field: 'created_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).fromNow()}`}
                        <br/>
                        {`${moment.tz(rowData.created_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}`}
                    </Typography>
            },
            { title: translate('voucher_expired_at'),
                editable: 'never',
                filtering: false,
                field: 'expired_at',
                render: rowData =>
                    <Typography className={this.classes.dateTable}>
                        {
                            !!rowData.expired_at ?
                            <>
                                {`${moment.tz(rowData.expired_at,'UTC').tz(getTimeZone()).fromNow()}`}
                                <br/>
                                {`${moment.tz(rowData.expired_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}`}
                            </> :
                                translate('doesntHave')
                        }
                    </Typography>
            },
            {
                title: translate('activity'),
                field: 'activity',
                render: rowData =>
                    <SeeVouchersButton vouchers={rowData.vouchers} id={rowData.id}/>
            }
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl" className={!!this.props.widget ? "thin-table" : null}>
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/business-factor-items/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    let status;
                                                    let vouchers = row.vouchers;
                                                    let all_vouchers_count = vouchers.length;
                                                    if(row.status === "expired"){
                                                        status = translate('expired')
                                                    }else {
                                                        let used_count = 0;
                                                        // eslint-disable-next-line array-callback-return
                                                        vouchers.map((voucher) => {
                                                            if(voucher.status === "used"){
                                                                used_count++;
                                                            }
                                                        })
                                                        if(used_count === all_vouchers_count){
                                                            status = translate('used')
                                                        }else{
                                                            status = used_count+" / "+all_vouchers_count+" Used";
                                                        }
                                                    }
                                                    data.push({
                                                        id: row.id,
                                                        status: status,
                                                        user: row.user,
                                                        price: row.price,
                                                        total_price: row.discount_price * all_vouchers_count,
                                                        percent: row.percent,
                                                        discount_price: row.discount_price,
                                                        discount: row.discount,
                                                        vouchers: row.vouchers,
                                                        currency: row.currency,
                                                        created_at: row.created_at,
                                                        expired_at: row.expired_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    /*search:false,*/
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserOrders)))));