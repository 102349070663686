import React from 'react';
import {Box} from '@material-ui/core';
import {grey} from '@material-ui/core/colors';
import makeStyles from '@material-ui/core/styles/makeStyles';
import {getStore} from "trim-redux";

const useStyles = makeStyles((theme) => ({
  appHeader: {
    height: 70,
      position:"relative",
    display: 'flex',
    alignItems: 'center',
      borderTopRightRadius:6,
      borderTopLeftRadius:6,
    borderBottom: `1px solid ${grey[getStore('mode') === "dark" ? 600 : 300]}`,
    [theme.breakpoints.up('xl')]: {
      height: 77,
    },
  },
  checkboxRoot: {
    marginRight: 8,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const AppsHeader = ({children}) => {
  const classes = useStyles();
  return (
    <Box px={2} py={{xs: 1, xl: 1}} className={classes.appHeader}>
      {children}
    </Box>
  );
};

export default AppsHeader;
AppsHeader.defaultProps = {};
