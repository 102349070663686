import React from 'react';
import {connect} from "trim-redux";

class Permission extends React.Component {
    render (){
        const { children,isAuth,rule,user} = this.props;
        return (
            isAuth ? (
                    user.role.type === 'admin' ?
                        children
                    :
                !!user.plan ?
                (user.plan[rule]) ? (
                    children
                ) : (
                    null
                ): (
                    null
                )
            ) : (
                null
            )
        )
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default connect(mstp)(Permission);
