import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {Link} from "react-router-dom";
import {Translate} from "../../../config/Translate";
class CheckForHavingShop extends React.Component{
    open = this.props.new;
    is_admin = this.props.user.role.type === "admin";
    state= {
        dialogOpen: (!!this.is_admin && !!this.props.primary_shop) ? false : !this.props.user.shops.length && this.open
    };
    classes = this.props.classes;
    render(){

        return (
            <>
                <Dialog
                    open={this.state.dialogOpen}
                    onClose={() => this.setState({dialogOpen:false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {Translate.makeAShopFirst}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {Translate.makeAShopFirstDescription}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button component={Link} to="/my-shop" color="primary">
                            {Translate.myShop}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CheckForHavingShop))));