import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import CurrencyTextField from '@unicef/material-ui-currency-textfield'
import {currencySymbol} from "../../../../config/values";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import withWidth from "@material-ui/core/withWidth/withWidth";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import Typography from "@material-ui/core/Typography/Typography";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";

class MakeSinglePricing extends React.Component {

    state = {
        fields:{
            price:'',
            priceR:'',
            discount_price:'',
            discount_priceR:'',
            warranty:"",
            inventory:null,
            transfer_time:"",
        },
        value_id:null,
        value:this.props.value,
        product:null,
        thumbnail: 1,
        percent: '',
        errors:{},
        loading: true,
        SubmitLoading: false,
        need_verification:false,
        confirmed: true,
        product_id:null,
        pricing_id:this.props.id,
        editMode:false
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            pricing_id:this.props.id,
        })
        await this.loadData();
    }
    handleThumbImage(id){
        this.setState({
            thumbnail: id
        })
    }
    async loadData(){
        let {pricing_id} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/pricing/getById/${pricing_id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            price: response.data.price,
                            priceR: response.data.price,
                            discount_price: response.data.discount_price === null ? "" : response.data.discount_price,
                            discount_priceR: response.data.discount_price === null ? "" : response.data.discount_price,
                            warranty:response.data.warranty,
                            inventory:response.data.inventory,
                            transfer_time:response.data.transfer_time,
                        },
                        editMode:true,
                        loading:false,
                        thumbnail: response.data.thumbnail.id,
                        value_id: !!parseInt(response.data.value_id) ? parseInt(response.data.value_id) : 0,
                        value: response.data.value,
                        confirmed: response.data.confirmed,
                        pricing_id: response.data.id,
                        need_verification: response.data.need_verification,
                        percent : response.data.discount_price > 0 ? Math.max(0, Math.floor((response.data.price - response.data.discount_price)*100/response.data.price)) : '',

                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields,percent} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
            if(!!fields.discount_priceR)
                percent = Math.max(0, Math.floor((fields.priceR - fields.discount_priceR)*100/fields.priceR));

        }else if (target.name === "discount_price") {
            fields.discount_priceR = target.value.replace(/,/ig, '');
            if (!!fields.priceR)
                percent = Math.max(0, Math.floor((fields.priceR - fields.discount_priceR) * 100 / fields.priceR));
            if (parseInt(fields.discount_priceR) >= parseInt(fields.priceR)) {
                fields.discount_price = '';
                fields.discount_priceR = '';
                percent = ''
            }
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";

        this.setState({
            fields,
            percent

        });    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(!fields.inventory){
            formIsValid = false;
            errors['inventory'] = Translate.emptyField;
        }

        //price
        if(!fields.priceR){
            formIsValid = false;
            errors['price'] = Translate.emptyField;
        }else{
            if(fields.discount_priceR !== null){
                if(fields.discount_priceR > 0){
                    if(fields.discount_priceR > fields.priceR){
                        formIsValid = false;
                        errors['discount_price'] = Translate.discountMoreThanPrice;
                    }
                }
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,thumbnail,editMode,confirmed,pricing_id,need_verification} = this.state;
        let data = new FormData();
        data.append('price', fields.priceR);
        data.append('discount_price', fields.discount_priceR);
        data.append('warranty', fields.warranty);
        data.append('inventory', fields.inventory);
        data.append('transfer_time', fields.transfer_time);
        data.append('thumbnail', thumbnail);
        data.append('confirmed',  confirmed ? 1 : 0);
        data.append('need_verification',  need_verification ? 1 : 0);
        data.append('pricing_id',  pricing_id);
        data.append('manage',  this.props.manage);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/pricing/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.dataEdited : Translate.dataAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.reload();

            })
            .catch(error => {
                if(error.response.status === 403){
                    if(error.response.data === "first_confirm_shop"){
                        this.props.enqueueSnackbar(Translate.firstConfirmShop, {
                            variant: 'error',
                        });
                        this.setState({
                            confirmed:0
                        })
                    }
                }else{
                    this.props.enqueueSnackbar(editMode ?  Translate.dataEditingFailed : Translate.dataAddingFailed, {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangePercent(e){
        let percent = e.target.value;
        let fields = this.state.fields;
        let price = this.state.fields.priceR;
        if(!!price){
            fields['discount_priceR'] = price - (price*percent/100);
            fields['discount_price'] = price - (price*percent/100);
        }
        if(parseInt(percent) === 0){
            fields.discount_price = '';
            fields.discount_priceR = '';
            percent = ''
        }
        this.setState({
            percent,
            fields
        })
    }
    handleDelete(){
        let {pricing_id} = this.state;
        let data = new FormData();
        data.append('pricing_id',  pricing_id);
        this.setState({
            loading: false
        });
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/pricing/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.deleteData, {
                    variant: 'success',
                });
                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });
    }
    render(){
        let {fields, errors,percent,SubmitLoading,thumbnail,editMode,confirmed,loading,value} = this.state;
        let {user,modal} = this.props;
        return (
            <div className={`makeSinglePricingHolder ${modal && "modifySinglePricingHolder"}`}>
                {
                    loading ?
                        <div style={{paddingTop:100,paddingBottom:100,textAlign:"center"}}>
                            <CircularProgress size={35}/>
                        </div>
                        :
                        <Grid container spacing={2}  alignItems='center' justifyContent="center">
                            <Grid item xs={12}>
                                {
                                    !!value ?
                                    value.attribute.type === "color" ?
                                            <Typography className="value_item">
                                            <span>
                                                <span
                                                    className="colored_badge"
                                                    style={{background:value.name}}>
                                                </span>
                                                {Translate.pricingFor} {value.attribute.name} {value.description}
                                             </span>
                                            </Typography> :
                                            <Typography className="value_item">
                                                {Translate.pricingFor} {value.attribute.name} {value.name}
                                            </Typography>
                                        : null

                                }
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <Thumbnail
                                    rounded
                                    handleThumbImage={this.handleThumbImage.bind(this)}
                                    initial={thumbnail}
                                />
                            </Grid>
                            <Grid item xs={12} md={9}>
                                <Grid container spacing={2} alignItems='center' justifyContent="center">
                                    <Grid item xs={12}>
                                        {
                                            !editMode ?
                                                null
                                                :
                                                <>
                                                    {
                                                        !! user.role.permission.manageShops ?
                                                            <>
                                                                <Alert
                                                                    severity="info"
                                                                    action={
                                                                        <FormControlLabel
                                                                            style={{marginRight:10}}
                                                                            control={
                                                                                <Switch
                                                                                    checked={confirmed}
                                                                                    onChange={this.handleChangeConfirmed.bind(this)}
                                                                                    value={confirmed}
                                                                                    color="primary"
                                                                                />
                                                                            }
                                                                            label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                                                        />
                                                                    }
                                                                >
                                                                    {Translate.confirmationStatus}
                                                                </Alert>
                                                            </>
                                                            :
                                                            confirmed ?
                                                                <Alert  severity="success">
                                                                    {Translate.yourPricingIsActive}
                                                                </Alert>
                                                                :
                                                                <Alert severity="warning">
                                                                    {Translate.yourPricingIsInchecking}
                                                                </Alert>
                                                    }
                                                </>

                                        }
                                    </Grid>
                                    <Grid item xs={12} md={6} >
                                        <CurrencyTextField
                                            variant="outlined"
                                            required
                                            fullWidth
                                            size="small"
                                            currencySymbol={currencySymbol}
                                            decimalPlaces={0}
                                            value={fields.price}
                                            id="price"
                                            label={Translate.price}
                                            name="price"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['price']}
                                            helperText={errors['price']}
                                        />
                                    </Grid>
                                    <Grid item xs={4} md={2}>
                                        <TextField
                                            variant="outlined"
                                            value={percent}
                                            fullWidth
                                            id="percent"
                                            size="small"
                                            label={Translate.discount_percent}
                                            name="percent"
                                            autoComplete="percent"
                                            onChange={this.handleChangePercent.bind(this)}
                                            error = {!!errors['percent']}
                                            helperText={errors['percent']}
                                            InputProps={{
                                                startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={8} md={4}>
                                        <CurrencyTextField
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            currencySymbol={currencySymbol}
                                            decimalPlaces={0}
                                            value={fields.discount_price}
                                            id="discount_price"
                                            label={Translate.discount_price}
                                            name="discount_price"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['discount_price']}
                                            helperText={errors['discount_price']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            value={fields.inventory}
                                            fullWidth
                                            size="small"
                                            type="number"
                                            label={Translate.inventory}
                                            placeholder={Translate.inventoryDesc}
                                            name="inventory"
                                            required
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['inventory']}
                                            helperText={errors['inventory']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            value={fields.warranty}
                                            fullWidth
                                            size="small"
                                            label={Translate.warranty}
                                            name="warranty"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['warranty']}
                                            helperText={errors['warranty']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <TextField
                                            variant="outlined"
                                            value={fields.transfer_time}
                                            fullWidth
                                            size="small"
                                            label={Translate.transfer_time}
                                            name="transfer_time"
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['transfer_time']}
                                            helperText={errors['transfer_time']}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={this.state.need_verification}
                                                    onChange={(e) => this.setState({need_verification: !this.state.need_verification})}
                                                    name="need_verification"
                                                    color="primary"
                                                />
                                            }
                                            label={
                                                <Tooltip arrow title={Translate.need_verification_description}>
                                                    <Typography>{Translate.need_verification_hint}</Typography>
                                                </Tooltip>}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={2}>
                                        <LoadingButton
                                            styleForParent={{
                                                float: "left",
                                                margin:0
                                            }}
                                            startIcon={<SaveRoundedIcon/>}
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {Translate.save}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                }

            </div>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeSinglePricing)))));