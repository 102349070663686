import React from 'react';
import {connect} from "trim-redux";
import {Translate} from "../../../config/Translate";
import AppCard from "../layouts/components/AppCard";
import Tab from "@material-ui/core/Tab/Tab";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Paper from "@material-ui/core/Paper/Paper";
import WeekOrdersChart from "./components/WeekOrdersChart";
import MonthOrdersChart from "./components/MonthOrdersChart";
import YearOrdersChart from "./components/YearOrdersChart";

class OrdersChart extends  React.Component{
    state = {
        tab : "week",
    }
    handleTabChange = (event, newValue) => {
        this.setState({
            tab: newValue
        })
    };
    render(){
        let {tab} = this.state;
        let {seller} = this.props;
        return (
            <AppCard
                title={Translate.OrdersChart}
            >
                <Paper style={{flexGrow:1}}>
                <Tabs
                    value={tab}
                    onChange={this.handleTabChange}
                    indicatorColor="primary"
                    textColor="primary"
                    centered
                >
                    <Tab label={Translate.thisWeek} value="week"/>
                    <Tab label={Translate.thisMonth} value="month" />
                    <Tab label={Translate.thisYear} value='year' />
                </Tabs>
                </Paper>
                {
                    tab === "week" ?
                        <WeekOrdersChart seller={seller}/> :
                    tab === "month" ?
                        <MonthOrdersChart seller={seller}/>:
                    tab === "year" &&
                        <YearOrdersChart seller={seller}/>

                }
            </AppCard>
        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
    primary_color: state.primary_color,
});
export default connect(mstp)(OrdersChart);
