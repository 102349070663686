import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import RichEditor from "../../components/editor/RichEditor";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../components/elements/LoadingButton";
import SubTitle from "../../components/elements/SubTitle";
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import LinearCategoryHolder from "../../components/fields/LinearCategoryHolder/index";
import ImageGallery from "../fileManager/Components/ImageGallery";
import Button from "@material-ui/core/Button/Button";
import PageviewRoundedIcon from '@material-ui/icons/PageviewRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import GetConfirmationText from "../../components/elements/GetConfirmationText";
import moment from "moment-timezone";
import {makeSlug, translate} from "../../../config/lang";
import CurrencyChooser from "../../components/fields/CurrencyChooser";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../components/elements/FieldCounterWithoutBar";
import UserCard from "../../components/elements/UserCard";
import MuiPhoneNumber from "material-ui-phone-number";
import SingleImageChooser from "../fileManager/Components/SingleImageChooser";
import SelectLocationFromMap from "../../components/map/SelectLocationFromMap";
import CountryChooser from "../../components/fields/CountryChooser";
import StateChooser from "../../components/fields/StateChooser";
import Divider from "@material-ui/core/Divider/Divider";
import CheckUserCanMakeAd from "./components/CheckUserCanMakeAd";
import {hasAccess, updateRedux} from "../../../config/Security";
import YourPlanDoesntSupportThis from "../Dashboard/components/YourPlanDoesntSupportThis";
import CustomFields from "./components/CustomFields";
import StarRateRoundedIcon from '@material-ui/icons/StarRateRounded';
import MakeEqualAdvertisment from "./MakeEqualAdvertisment";
import ViewRelatedComments from "../admin/comments/components/ViewRelatedComments";
import DiscountChooser from "./components/DiscountChooser";
import MetaDescription from "../fileManager/Components/MetaDescription";
import UserChooser from "./components/UserChooser";
import RenewButton from "./components/RenewButton";
import UpdateButton from "./components/UpdateButton";
import {getTimeZone} from "../../../config/values";
import FormControl from "@material-ui/core/FormControl/FormControl";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
class MakeAdvertisement extends React.Component {
    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            name:"",
            description:"",
            address:"",
            price:'',
            priceR:'',
            phone_1:'',
            phone_2:'',
            phone_3:'',
            email_1:'',
            email_2:'',
            email_3:'',
            website:'',
            whatsapp:'',
            telegram:'',
            instagram:'',
            facebook:'',
            twitter:'',
            youtube:'',
            meta_title:'',
        },
        content:{
            HTML: '',
            RAW: null,
            PlainText: ''
        },
        thumbnail: null,
        errors:{},
        categories:[],
        categoriesName:[],
        gallery: [],
        loading: true,
        currency: '',
        SubmitLoading: false,
        publish: true,
        confirmed: false,
        is_best: false,
        real_confirmed: false,
        slug: null,
        id:this.props.match.params.id,
        editMode:false,
        owner_id:null,
        owner:null,
        requested_to_delete:0,
        editor_input_text:'',
        real_updated_at:null,
        created_at:null,
        expired_at:null,
        expired:0,
        expired_this_week:0,
        activePhoneNumbers:1,
        phoneNumberFilled:false,
        activeEmail:1,
        emailFilled:false,
        lat:"",
        lng:"",
        country:null,
        state:null,
        server_country:null,
        server_state:null,
        likes_count:0,
        dislikes_count:0,
        visits:0,
        today_visits:0,
        comments:0,
        lang: this.props.locale,
        parent_category:null,
        custom_fields:null,
        allowed_comment:0,
        no_index:0,
        discount:null,
        item:null,
        equal:null,
        related_user:null,
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.state.id){
            this.setState({
                editMode:true
            });
            this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(thumbnail){
        this.setState({
            thumbnail
        })
    }
    async loadData(){
        this.setState({
            loading:true
        })
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/advertisement/get/${this.state.id}`, config)
                .then(response => {

                    updateRedux();
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description: !!response.data.description ? response.data.description : '',
                            address: !!response.data.address ? response.data.address : '',
                            price:!!response.data.price ? response.data.price : '',
                            priceR:!!response.data.price ? response.data.price : '',
                            phone_1: !!response.data.phone_1 ? response.data.phone_1 : '',
                            phone_2: !!response.data.phone_2 ? response.data.phone_2 : '',
                            phone_3: !!response.data.phone_3 ? response.data.phone_3 : '',
                            email_1: !!response.data.email_1 ? response.data.email_1 : '',
                            email_2: !!response.data.email_2 ? response.data.email_2 : '',
                            email_3: !!response.data.email_3 ? response.data.email_3 : '',
                            website: !!response.data.website ? response.data.website : '',
                            telegram: !!response.data.telegram ? response.data.telegram : '',
                            whatsapp: !!response.data.whatsapp ? response.data.whatsapp : '',
                            facebook: !!response.data.facebook ? response.data.facebook : '',
                            instagram: !!response.data.instagram ? response.data.instagram : '',
                            twitter: !!response.data.twitter ? response.data.twitter : '',
                            youtube: !!response.data.youtube ? response.data.youtube : '',
                            meta_title: !!response.data.meta_title ? response.data.meta_title : '',
                        },
                        thumbnail: response.data.thumbnail,
                        discount: response.data.discount,
                        content: {
                            HTML: response.data.html,
                            RAW: response.data.raw,
                        },
                        confirmed: response.data.confirmed,
                        is_best: response.data.is_best,
                        real_confirmed: response.data.confirmed,
                        slug: response.data.slug,
                        currency: response.data.currency,
                        owner_id: response.data.user_id,
                        owner: response.data.owner,
                        real_updated_at: response.data.real_updated_at,
                        created_at: response.data.created_at,
                        expired_at: response.data.expired_at,
                        expired: parseInt(response.data.expired),
                        expired_this_week: parseInt(response.data.expired_this_week),
                        requested_to_delete: parseInt(response.data.requested_to_delete),
                        lat:!!response.data.lat ? parseFloat(response.data.lat) : '',
                        lng:!!response.data.lng ? parseFloat(response.data.lng) : '',
                        country:!!response.data.country ? response.data.country : null,
                        state:!!response.data.state ? response.data.state : null,
                        server_country:!!response.data.country ? response.data.country : null,
                        server_state:!!response.data.state ? response.data.state : null,
                        likes_count:parseInt(response.data.likes_count),
                        dislikes_count:parseInt(response.data.dislikes_count),
                        visits:parseInt(response.data.visits_count),
                        today_visits:parseInt(response.data.today_visits_count),
                        comments:parseInt(response.data.comments_count),
                        lang:response.data.lang,
                        custom_fields:response.data.custom_fields,
                        allowed_comment:parseInt(response.data.allowed_comment),
                        no_index:parseInt(response.data.no_index),
                    })
                    let categoriesTemp=[];
                    let categoriesNameTemp=[];
                    let galleryTemp=[];
                    let parent_category = null;
                    response.data.categories.map(item => {
                        if(!parent_category){
                            parent_category = item.id;
                        }
                        categoriesTemp.push(item.id)
                        categoriesNameTemp.push(item.name)
                        return true
                    })
                    response.data.gallery.map(item =>{
                        galleryTemp.push({
                            src:`${defaultVariabls.uploads}/FileManager/${item.name}_s_thumb.${item.extension}`,
                            link:!!item.source_id ? `https://www.youtube.com/watch?v=${item.source_id}` : `${defaultVariabls.uploads}/FileManager/${item.name}.${item.extension}`,
                            id: item.id,
                            name: item.name,
                            type: item.type,
                            preview: item.preview_url ? item.preview_url : item.preview_id ? `${defaultVariabls.uploads}/FileManager/${item.preview.name}_s_thumb.${item.preview.extension}` : '',
                        })
                        return true
                    })
                    if(!!response.data.phone_2){
                        if(!!response.data.phone_3){
                            this.setState({
                                activePhoneNumbers:3
                            })
                        }else{
                            this.setState({
                                activePhoneNumbers:2
                            })
                        }
                    }
                    if(!!response.data.email_2){
                        if(!!response.data.email_3){
                            this.setState({
                                activeEmail:3
                            })
                        }else{
                            this.setState({
                                activeEmail:2
                            })
                        }
                    }
                    this.setState({
                        categories: categoriesTemp,
                        categoriesName: categoriesNameTemp,
                        gallery: galleryTemp,
                        parent_category,
                        item:response.data,
                        equal:response.data.equal,
                        loading: false
                    })
                }).catch(error => {
                    console.log(error.response)
                    /*this.props.history.push('/');
                    this.props.enqueueSnackbar(translate('pageNotFound'), {
                        variant: 'error',
                    });*/
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChange(e){
        let {errors,fields,activeEmail} = this.state;
        let target = e.target;
        if(target.name === "price"){
            fields.priceR = target.value.replace(/,/ig, '');
        }else{
            fields[target.name] = target.value;
        }
        errors[target.name] = "";
        this.setState({
            fields,
        });
        if(target.name === "email_1" ||target.name === "email_2" ||target.name === "email_3"  ){
            if(!!target.value && ((target.name === "email_1" && activeEmail === 1) || (target.name === "email_2" && activeEmail === 2))){
                if(validator.isEmail(target.value)){
                    this.setState({
                        emailFilled: true
                    })
                }
            }else {
                this.setState({
                    emailFilled: false
                })
            }
        }
    }

    handleValidation(callback){
        let { fields,lat,categories } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = translate('emptyField');
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = translate('maxLength150');
            }
        }
        //address
        if(fields.address !== null ) {
            if (fields.address.length > 150) {
                formIsValid = false;
                errors['address'] = translate('maxLength150');
            }
        }
        /*if(!country){
            formIsValid = false;
            errors['country'] = translate('emptyField');
        }
        if(!state){
            formIsValid = false;
            errors['state'] = translate('emptyField');
        }
        */
        if(!lat){
            formIsValid = false;
            errors['geocode'] = translate('pleaseChooseYourLocation');
        }
        if(!!fields.phone_1){
            if(fields.phone_1.length >= 18){
                formIsValid = false;
                errors['phone_1'] = translate('phoneIsWrong');
            }
        }
        if(!!fields.phone_2){
            if(fields.phone_2.length >= 18){
                formIsValid = false;
                errors['phone_2'] = translate('phoneIsWrong');
            }
        }
        if(!!fields.phone_3){
            if(fields.phone_3.length >= 18){
                formIsValid = false;
                errors['phone_3'] = translate('phoneIsWrong');
            }
        }
        if(!!fields.email_1){
            if(!validator.isEmail(fields.email_1)){
                formIsValid = false;
                errors['email_1'] = translate('wrongEmail');
            }
        }
        if(!!fields.email_2){
            if(!validator.isEmail(fields.email_2)){
                formIsValid = false;
                errors['email_2'] = translate('wrongEmail');
            }
        }
        if(!!fields.email_3){
            if(!validator.isEmail(fields.email_3)){
                formIsValid = false;
                errors['email_3'] = translate('wrongEmail');
            }
        }
        if(categories.length === 0){
            formIsValid = false;
            errors['category'] = translate('emptyField');
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,fields,content,no_index,thumbnail,custom_fields,discount,allowed_comment,related_user,editMode,gallery,categories,confirmed,is_best,currency,lat,lng,country,state} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('meta_title', fields.meta_title);
        data.append('html', content.HTML);
        data.append('lang', this.props.locale);
        data.append('raw', content.RAW);
        data.append('plainText', content.PlainText);
        data.append('id', id ? id : 0);
        data.append('related_user', related_user ? related_user.id : 0);
        data.append('description', fields.description);
        data.append('address', fields.address);
        data.append('thumbnail', !!thumbnail ? thumbnail.id : 1);
        data.append('currency', currency);
        data.append('confirmed',confirmed ? 1 : 0);
        data.append('no_index',no_index ? 1 : 0);
        data.append('is_best',is_best ? 1 : 0);
        data.append('price', fields.priceR);
        data.append('phone_1', fields.phone_1);
        data.append('phone_2', fields.phone_2);
        data.append('phone_3', fields.phone_3);
        data.append('email_1', fields.email_1);
        data.append('email_2', fields.email_2);
        data.append('email_3', fields.email_3);
        data.append('website', fields.website);
        data.append('whatsapp', fields.whatsapp);
        data.append('telegram', fields.telegram);
        data.append('facebook', fields.facebook);
        data.append('instagram', fields.instagram);
        data.append('twitter', fields.twitter);
        data.append('youtube', fields.youtube);
        data.append('lat', lat);
        data.append('lng', lng);
        data.append('country_id', !!country ? country.id : '');
        data.append('state_id', !!state ? state.id : '' );
        data.append('allowed_comment', parseInt(allowed_comment) );

        data.append('categories', JSON.stringify(categories));
        data.append('custom_fields', JSON.stringify(custom_fields));
        if(!!discount){
            data.append('discount_id', discount.id);
        }
        if(gallery !== null){
            let galery_ids = [];
            gallery.map((row) =>{
                galery_ids.push(row.id);
                return true
            })
            gallery = JSON.stringify(galery_ids);
        }
        data.append('gallery', gallery);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/advertisement/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('adEdited') : translate('adAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                updateRedux();
                if(!!user.role.permission.manageAdvertisements){
                    this.props.history.push(`/manage/advertisements`);
                }else{
                    this.props.history.push(`/my-advertisements`);
                }
                /*if(!this.state.editMode){
                    if(!!user.role.permission.manageAdvertisements){
                        this.props.history.push(`/manage/advertisements`);
                    }else{
                        this.props.history.push(`/my-advertisements`);
                    }
                }else{
                    this.loadData();
                }*/
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('adEditingFailed') : translate('adAddingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleClickDescription(){
        let {fields,content} = this.state;
        if(fields['description'] === ''){
            fields['description'] = content.PlainText === null ? null : content.PlainText.slice(0, 150);
            this.setState({fields})
        }
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }

    handleOutPut(code){
        this.setState({
            content:code
        })
    }
    handleCategoryHolder(categories){
        let {parent_category} = this.state;
        if(parent_category === null){
            this.setState({
                parent_category: categories[0]
            })
        }else if(parent_category !== categories[0]){
            this.setState({
                parent_category: categories[0]
            })
        }
        this.setState({categories})
    }
    handleGalleryChange(data){
        this.setState({
            gallery: data,
        })
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChangeBest(){
        this.setState({
            is_best: !this.state.is_best,
        })
    }
    async handleRequestToDelete(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/advertisement/request_delete/${this.state.id}`, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('requestedToDelete'), {
                    variant: 'success',
                });
                this.setState({
                   requested_to_delete:1,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }
    handleDeleteAdvertisement(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/delete/advertisement`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteAdvertisement'), {
                    variant: 'success',
                });
                this.props.history.push('/manage/advertisements')
                return true
            })

            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('deleteAdvertisementFailed'), {
                    variant: 'error',
                });
                return false
            });
    }
    async changeCategories(){
        await this.setState({
            categoriesName:[],
            categories:[],
            variables:[],
        })
    }
    handleChangeCurrency(currency){
        this.setState({
            currency
        })
    }
    handleChangePhone(phone,name){
        let {fields,errors,activePhoneNumbers} = this.state;
        fields[name] = phone.replace("+0","+98");
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
        if(!!phone && ((name === "phone_1" && activePhoneNumbers === 1) || (name === "phone_2" && activePhoneNumbers === 2))){
            this.setState({
                phoneNumberFilled: true
            })
        }else {
            this.setState({
                phoneNumberFilled: false
            })
        }
    }
    async readBusinessLocation(){
        let {user,locale}  =this.props;
        let {fields} = this.state;
        let business;
        if(locale === "fa"){
            business = !!user.business_fa ? user.business_fa : user.business_en
        }else{
            business = !!user.business_en ? user.business_en : user.business_fa
        }
        if(!!business){
            fields['address'] = business.address;
            let lat = business.lat;
            let lng = business.lng;
            let country = business.country;
            let state = business.state;
            let server_country = business.country;
            let server_state = business.state;
            await this.setState({
                fields,
                lat,
                lng,
                country,
                state,
                server_country,
                server_state,
            })
        }
    }
    handleChangeWa(phone,name){
        let {fields,errors} = this.state;
        fields[name] = phone;
        errors[name] = '';
        this.setState({
            fields,
            errors
        })
    }
    handleAddress(address){
        let {fields} = this.state;
        fields['address'] = address;
        this.setState({
            fields
        })

    }
    render(){
        let {fields,currency,activePhoneNumbers,item,equal,country,parent_category,loading,dislikes_count,likes_count,server_country,server_state,phoneNumberFilled,activeEmail,emailFilled, errors,gallery,slug,SubmitLoading,owner,thumbnail,editor_input_text,categoriesName,content,editMode,real_confirmed,confirmed,is_best,lat,lng} = this.state;
        const {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}>
            {
                        <Grid container spacing={2} justify="center">
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justify="center">
                                    {
                                        (user.role.type !== "admin" && !loading) &&
                                        <CheckUserCanMakeAd edit={!!editMode} confirmed={real_confirmed}/>
                                    }

                                    {
                                        (!!editMode && !loading) &&
                                        <>
                                            <Grid item xs={12}>
                                                {
                                                    !! user.role.permission.manageAdvertisements ?
                                                        <>
                                                            <Alert
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={confirmed}
                                                                                onChange={this.handleChangeConfirmed.bind(this)}
                                                                                value={confirmed}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('confirmationStatusDescription')}
                                                            </Alert>
                                                            <Alert
                                                                style={{marginTop:15}}
                                                                severity="info"
                                                                action={
                                                                    <FormControlLabel
                                                                        style={{marginRight:10}}
                                                                        control={
                                                                            <Switch
                                                                                checked={is_best}
                                                                                onChange={this.handleChangeBest.bind(this)}
                                                                                value={is_best}
                                                                                color="primary"
                                                                            />
                                                                        }
                                                                        label={is_best ? translate('yes') : translate('no')}
                                                                    />
                                                                }
                                                            >
                                                                {translate('isThisAdBest')}
                                                            </Alert>
                                                        </>
                                                        :
                                                        confirmed ?
                                                            <Alert severity="success" variant="filled">
                                                                <Typography  className={this.classes.alertText}>{translate('yourAdvertisementIsActive')}</Typography>
                                                            </Alert>
                                                            :
                                                            <Alert severity="warning">
                                                                <Typography className={this.classes.alertText}>{translate('yourAdvertisementIsInchecking')}</Typography>
                                                            </Alert>
                                                }
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <AppCard style={{height:"100%"}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12} style={{display:"flex",alignItems:"center",justifyContent:"space-between"}}>
                                                            {
                                                                !!real_confirmed ?
                                                                    <>
                                                                        <Button
                                                                            component="a"
                                                                            target="_blank"
                                                                            size="small"
                                                                            startIcon={<PageviewRoundedIcon/>}
                                                                            href={makeSlug(`ad/${slug}`,this.state.lang)}
                                                                            color="primary"
                                                                        >
                                                                            {translate('viewAdvertisement')}
                                                                        </Button>
                                                                    </> :
                                                                    <>
                                                                        <Button
                                                                            component="a"
                                                                            target="_blank"
                                                                            size="small"
                                                                            variant="contained"
                                                                            startIcon={<PageviewRoundedIcon/>}
                                                                            href={makeSlug(`ad/${slug}`,this.state.lang)}
                                                                            color="primary"
                                                                        >
                                                                            {translate('viewPreview')}
                                                                        </Button>

                                                                    </>
                                                            }
                                                            {
                                                                moment(this.state.expired_at) <= moment().add('days',3) ?
                                                                    <RenewButton id={this.state.id} reload={() => this.loadData()} /> :
                                                                    <UpdateButton id={this.state.id} reload={() => this.loadData()} />
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('todayVisitsCount')}</span>
                                                                <strong>
                                                                    {this.state.today_visits}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('totalVisitsCount')}</span>
                                                                <strong>
                                                                    {this.state.visits}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('totalCommentsCount')} <ViewRelatedComments type={"ad"} item_id={item.id}/></span>
                                                                <strong>
                                                                    {this.state.comments}
                                                                </strong>
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                            <Grid item xs={12} md={4}>
                                                <AppCard style={{height:"100%"}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left" style={{position:"relative"}}>
                                                                {translate('adInfo')}

                                                                <div style={{position:"absolute",top:0,right:0,display:"flex",alignItems:"center",fontSize:12}}>
                                                                        <StarRateRoundedIcon style={{marginRight:2}} color="primary"/>
                                                                        <strong className={this.classes.primaryColor} style={{marginRight:2,fontSize:14}}>{item.average_rating} </strong> / 5
                                                                </div>
                                                            </SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Typography paragraph className='date-holder' style={{marginTop: "0px !important"}}>

                                                                <span>{translate('expired_at')}</span>
                                                                <strong >
                                                                    {
                                                                        (this.state.expired === 0 && this.state.expired_this_week === 0) ?
                                                                        `${moment.tz(this.state.expired_at,'UTC').tz(getTimeZone()).fromNow(true)}` :
                                                                            <>
                                                                                <span className="expired">
                                                                                    {translate('expired')}
                                                                                </span>
                                                                            </>

                                                                    }
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>
                                                                {translate('updated_at')}
                                                            </span>
                                                                <strong>
                                                                    {`${moment.tz(this.state.real_updated_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography  className='date-holder'>
                                                                <span>{translate('created_at')}</span>
                                                                <strong>
                                                                    {`${moment.tz(this.state.created_at,'UTC').tz(getTimeZone()).format( 'HH:mm , YYYY/M/D')}`}
                                                                </strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                        </Grid>
                                                    </Grid>
                                                </AppCard>
                                            </Grid>
                                        </>

                                    }
                                    {
                                        (!!editMode && !loading) &&
                                        <Grid item xs={12} md={4}>
                                            <AppCard style={{height:"100%"}}>
                                                <SubTitle align="left" style={{position:"relative",marginBottom:33}}>
                                                    {translate('planInfo')}
                                                    {
                                                        user.role.type !== "admin" &&
                                                        <Button
                                                            color="secondary"
                                                            size="small"
                                                            style={{position:'absolute',top:-5,right:0}}
                                                            variant="contained"
                                                            startIcon={<img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`}  />}
                                                        >
                                                            {this.props.locale === "en" ? user.plan.parent.english_name : user.plan.parent.name}
                                                        </Button>
                                                    }

                                                </SubTitle>

                                                {
                                                    user.role.type !== "admin" ?

                                                        <>
                                                            <Typography className='date-holder'>
                                                                <span>{translate('adValidityPeriod')}</span>
                                                                <strong className={this.classes.alertText}>{user.plan.ad_validity_month} {translate('months')}</strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography className='date-holder'>
                                                                <span>{translate('monthlyAdCount')}</span>
                                                                <strong className={this.classes.primary_color}>{parseInt(user.ad_count) - parseInt(user.plan_usage.ad_count)}</strong>
                                                            </Typography>
                                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                                            <Typography className='date-holder'>
                                                                <span>{translate('monthlyEditCount')}</span>
                                                                <strong className={this.classes.primary_color}>{parseInt(user.edit_count) - parseInt(user.plan_usage.edit_count)}</strong>
                                                            </Typography>
                                                        </>

                                                        :

                                                        <>
                                                            <Typography className={this.classes.condir}>{translate('adminHasFullAccess')}</Typography>
                                                        </>
                                                }
                                            </AppCard>
                                        </Grid>
                                    }
                                    {
                                        (user.role.type === "admin" && !loading) &&
                                        <Grid item xs={12}>
                                            <Alert
                                                severity="info"
                                                action={
                                                    <FormControlLabel
                                                        style={{marginRight:10}}
                                                        control={
                                                            <Switch
                                                                checked={this.state.allowed_comment}
                                                                onChange={() => this.setState({allowed_comment: parseInt(this.state.allowed_comment === 1 ? 0 : 1)})}
                                                                value={this.state.allowed_comment}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={this.state.allowed_comment ? translate('allowed') : translate('not_allowed')}
                                                    />
                                                }
                                            >
                                                {translate('allowed_comments')}
                                            </Alert>
                                        </Grid>
                                    }
                                    {
                                        !!editMode &&
                                        <>
                                            {
                                                this.state.loading ? null :
                                                <Grid item xs={12}>
                                                    <AppCard
                                                        className={this.classes.equalAppCard}
                                                    >
                                                        <div
                                                            className={this.classes.equalHolder}
                                                        >
                                                            <SubTitle align="left">{translate('equalItem')}</SubTitle>
                                                            <MakeEqualAdvertisment
                                                                equal_id={!!equal ? equal.id : null}
                                                                item={item}
                                                                reload={() => this.loadData()}
                                                            />
                                                        </div>
                                                    </AppCard>
                                                </Grid>
                                            }
                                        </>
                                    }
                                    <Grid item xs={12}>
                                        <AppCard
                                            title={editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}
                                            action={
                                                <BreadCrumbs singleLevel firstText={editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}/>
                                            }>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2} justify="center" alignItems="center" style={{marginBottom:10}}>

                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                margin="normal"
                                                                required
                                                                fullWidth
                                                                name="name"
                                                                variant="outlined"
                                                                label={translate('advertisementName')}
                                                                type="text"
                                                                id="name"
                                                                value={fields.name}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['name']}
                                                                helperText={errors['name']}
                                                                className={this.classes.inputCtl}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <CurrencyTextField
                                                                variant="outlined"
                                                                fullWidth
                                                                margin="normal"
                                                                currencySymbol={<CurrencyChooser current={currency}
                                                                                                 onChange={(currency) => this.handleChangeCurrency(currency)}/>}
                                                                decimalPlaces={2}
                                                                value={fields.price}
                                                                id="price"
                                                                minimumValue={0}
                                                                label={translate('price')}
                                                                name="price"
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['price']}
                                                                helperText={errors['price']}
                                                                className={this.classes.currencyTextField}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                multiline
                                                                fullWidth
                                                                name="description"
                                                                label={translate('seoDescription')}
                                                                type="text"
                                                                id="description"
                                                                minRows="4"
                                                                className={this.classes.inputCtl}
                                                                value={fields.description}
                                                                onChange={this.handleChange.bind(this)}
                                                                onClick={this.handleClickDescription.bind(this)}
                                                                error={!!errors['description']}
                                                                helperText={errors['description']}
                                                            />
                                                            <FieldCounterWithoutBar length={fields.description.length} max={150}/>

                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <LinearCategoryHolder
                                                                required
                                                                outPut={this.handleCategoryHolder.bind(this)}
                                                                inPut={categoriesName}
                                                                editMode={categoriesName.length === 0 ? false : editMode}
                                                            />
                                                            {
                                                                !!errors['category'] ?
                                                                    <Typography className="errorText" color="error" variant="body2">
                                                                        {errors['category']}
                                                                    </Typography>
                                                                    : null
                                                            }
                                                        </Grid>
                                                        {
                                                            (!!editMode && !!categoriesName.length && !!user.role.permission.manageAdvertisements) &&
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    size="small"
                                                                    variant="outlined"
                                                                    onClick={() => this.changeCategories()}
                                                                >
                                                                    {translate('changeCategories')}
                                                                </Button>
                                                            </Grid>
                                                        }

                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AppCard style={{height:'100%'}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <>
                                                        <CustomFields
                                                            type={"advertisement"}
                                                            position_type="custom_fields"
                                                            input={this.state.custom_fields}
                                                            category_id={this.state.parent_category}
                                                            handleCustomFields={(custom_fields) => this.setState({custom_fields})}
                                                        />
                                                    </>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('advertisementDescription')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <YourPlanDoesntSupportThis visible={!hasAccess('has_html')}/>

                                                            <RichEditor
                                                                inputText={editor_input_text}
                                                                ref={this.EditorChild}
                                                                Output={this.handleOutPut.bind(this)}
                                                                input={content.RAW}
                                                                minHeight={300}
                                                                readOnly={!hasAccess('has_html')}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={3}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="center">{translate('primaryAdImage')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <SingleImageChooser
                                                                primary
                                                                onChange={(thumbnail) => this.handleThumbImage(thumbnail)}
                                                                initial={thumbnail}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MetaDescription width={800} height={600} size={"10MB"}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={9}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('adsGallery')}</SubTitle>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <ImageGallery onChange={this.handleGalleryChange.bind(this)} initial={gallery}/>
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MetaDescription width={800} height={600} size={"10MB"} video_size={"100MB"}/>
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard style={{height:"100%"}}>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align='left'>
                                                                {translate('addressAndLocation')}
                                                            </SubTitle>
                                                        </Grid>
                                                        {
                                                            (!!user.business && !lat) &&
                                                            <Grid item xs={12}>
                                                                <Button
                                                                    onClick={() => this.readBusinessLocation()}
                                                                    size="small"
                                                                    color="primary"
                                                                    variant="outlined"
                                                                >
                                                                    {translate('readBusinessLocation')}
                                                                </Button>
                                                            </Grid>
                                                        }
                                                        {
                                                            !!server_country ?
                                                                <>
                                                                    <Grid item xs={12}>
                                                                        <Typography>
                                                                            {server_country.name} {!!server_state ? "/ "+server_state.name : ""}
                                                                        </Typography>
                                                                    </Grid>
                                                                </>
                                                                :
                                                                <>
                                                                    <Grid item xs={12} md={6}>
                                                                        <CountryChooser
                                                                            county_id={1}
                                                                            error={errors['country']}
                                                                            handleCountry={(country) => this.setState({country})}
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={12} md={6}>
                                                                        <StateChooser
                                                                            country={country}
                                                                            error={errors['state']}
                                                                            handleState={(state) => this.setState({state})}
                                                                        />
                                                                    </Grid>
                                                                </>
                                                        }
                                                        <Grid item xs={12}>
                                                            <SelectLocationFromMap
                                                                init_geocode={(!!lat && !!lng) ? {lat:lat,lng:lng} : ""}
                                                                geocode={(lat,lng) => this.setState({lat,lng})}
                                                                setAddress={(address) => this.handleAddress(address)}
                                                                country={country}
                                                                state={this.state.state}
                                                            />
                                                            {
                                                                !!errors["geocode"] &&
                                                                    <Alert severity="error">
                                                                        <Typography className={this.classes.alertText}>
                                                                            {errors["geocode"]}
                                                                        </Typography>
                                                                    </Alert>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                variant="outlined"
                                                                multiline
                                                                fullWidth
                                                                name="address"
                                                                label={translate('address')}
                                                                type="text"
                                                                minRows="2"
                                                                value={fields.address}
                                                                onChange={this.handleChange.bind(this)}
                                                                error={!!errors['address']}
                                                                helperText={errors['address']}
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <FieldCounterWithoutBar length={fields.address.length} max={150}/>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}  md={6}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('addContactInformation')}</SubTitle>
                                                        </Grid>
                                                        {
                                                            hasAccess('has_phone') &&
                                                            <Grid item xs={12}>
                                                                <YourPlanDoesntSupportThis visible={!hasAccess('has_phone')}/>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={12}>
                                                            <MuiPhoneNumber
                                                                fullWidth
                                                                label={translate('phoneNumber')}
                                                                name="phone_1"
                                                                dir="ltr"
                                                                variant="outlined"
                                                                disabled={!hasAccess('has_phone')}
                                                                onChange={(phone) => this.handleChangePhone(phone,"phone_1")}
                                                                disableAreaCodes
                                                                defaultCountry={'ae'}
                                                                value={fields.phone_1}
                                                                error = {!!errors['phone_1']}
                                                                helperText={errors['phone_1']}
                                                            />
                                                            {
                                                                activePhoneNumbers >= 2 &&
                                                                <MuiPhoneNumber
                                                                    fullWidth
                                                                    label={translate('phoneNumber')}
                                                                    name="phone_2"
                                                                    dir="ltr"
                                                                    variant="outlined"
                                                                    onChange={(phone) => this.handleChangePhone(phone,"phone_2")}
                                                                    disableAreaCodes
                                                                    defaultCountry={'ae'}
                                                                    disabled={!hasAccess('has_phone')}
                                                                    value={fields.phone_2}
                                                                    error = {!!errors['phone_2']}
                                                                    helperText={errors['phone_2']}
                                                                />
                                                            }
                                                            {
                                                                activePhoneNumbers >= 3 &&
                                                                <MuiPhoneNumber
                                                                    fullWidth
                                                                    label={translate('phoneNumber')}
                                                                    name="phone_3"
                                                                    dir="ltr"
                                                                    variant="outlined"
                                                                    onChange={(phone) => this.handleChangePhone(phone,"phone_3")}
                                                                    disableAreaCodes
                                                                    defaultCountry={'ae'}
                                                                    value={fields.phone_3}
                                                                    disabled={!hasAccess('has_phone')}
                                                                    error = {!!errors['phone_3']}
                                                                    helperText={errors['phone_3']}
                                                                />
                                                            }

                                                            {
                                                                (activePhoneNumbers < 3 && !!phoneNumberFilled) &&
                                                                <Button
                                                                    size="small"
                                                                    color="primary"
                                                                    onClick={() => this.setState({activePhoneNumbers: activePhoneNumbers + 1,phoneNumberFilled:false })}
                                                                >
                                                                    {translate('addPhoneNumber')}
                                                                </Button>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <TextField
                                                                fullWidth
                                                                variant="outlined"
                                                                label={translate('email')}
                                                                name="email_1"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_phone')}
                                                                value={fields.email_1}
                                                                onChange={this.handleChange.bind(this)}
                                                                error = {!!errors['email_1']}
                                                                helperText={errors['email_1']}
                                                            />
                                                            {
                                                                activeEmail >= 2 &&
                                                                <TextField
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label={translate('email')}
                                                                    name="email_2"
                                                                    dir="ltr"
                                                                    disabled={!hasAccess('has_phone')}
                                                                    value={fields.email_2}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error = {!!errors['email_2']}
                                                                    helperText={errors['email_2']}
                                                                />
                                                            }
                                                            {
                                                                activeEmail >= 3 &&
                                                                <TextField
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    label={translate('email')}
                                                                    name="email_3"
                                                                    disabled={!hasAccess('has_phone')}
                                                                    dir="ltr"
                                                                    value={fields.email_3}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error = {!!errors['email_3']}
                                                                    helperText={errors['email_3']}
                                                                />
                                                            }
                                                            {
                                                                (activeEmail < 3 && !!emailFilled) &&
                                                                <Button
                                                                    size="small"
                                                                    color="primary"
                                                                    onClick={() => this.setState({activeEmail: activeEmail + 1,emailFilled:false })}
                                                                >
                                                                    {translate('addEmail')}
                                                                </Button>
                                                            }
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <MuiPhoneNumber
                                                                fullWidth
                                                                label={translate('whatsappLink')}
                                                                placeholder={translate('whatsappPlaceHolder')}
                                                                name="phone_3"
                                                                dir="ltr"
                                                                variant="outlined"
                                                                onChange={(whatsapp) => this.handleChangeWa(whatsapp,"whatsapp")}
                                                                disableAreaCodes
                                                                defaultCountry={'ae'}
                                                                value={fields.whatsapp}
                                                                disabled={!hasAccess('has_phone')}
                                                                error = {!!errors['whatsapp']}
                                                                helperText={errors['whatsapp']}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={6}>
                                        <AppCard>
                                            {
                                                this.state.loading ? <AppCardLoaderFields/> :
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={12}>
                                                            <SubTitle align="left">{translate('relatedLinks')}</SubTitle>
                                                        </Grid>
                                                        {
                                                            hasAccess('has_link') === 0 &&
                                                            <Grid item xs={12}>
                                                                <YourPlanDoesntSupportThis visible={!hasAccess('has_link')}/>
                                                            </Grid>
                                                        }
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="website"
                                                                variant="outlined"
                                                                label={translate('websiteLink')}
                                                                placeholder={translate('websitePlaceHolder')}
                                                                type="url"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_link')}
                                                                value={fields.website}
                                                                onChange={this.handleChange.bind(this)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="facebook"
                                                                variant="outlined"
                                                                label={translate('facebookLink')}
                                                                placeholder={translate('facebookPlaceHolder')}
                                                                type="url"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_link')}
                                                                value={fields.facebook}
                                                                onChange={this.handleChange.bind(this)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="telegram"
                                                                variant="outlined"
                                                                label={translate('telegramLink')}
                                                                placeholder={translate('telegramPlaceHolder')}
                                                                type="url"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_link')}
                                                                value={fields.telegram}
                                                                onChange={this.handleChange.bind(this)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="instagram"
                                                                variant="outlined"
                                                                label={translate('instagramLink')}
                                                                placeholder={translate('instagramPlaceHolder')}
                                                                type="url"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_link')}
                                                                value={fields.instagram}
                                                                onChange={this.handleChange.bind(this)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="twitter"
                                                                variant="outlined"
                                                                label={translate('twitterLink')}
                                                                placeholder={translate('twitterPlaceHolder')}
                                                                type="url"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_link')}
                                                                value={fields.twitter}
                                                                onChange={this.handleChange.bind(this)}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={12} md={6}>
                                                            <TextField
                                                                fullWidth
                                                                name="youtube"
                                                                variant="outlined"
                                                                label={translate('youtubeChanel')}
                                                                placeholder={translate('youtubePlaceHolder')}
                                                                type="url"
                                                                dir="ltr"
                                                                disabled={!hasAccess('has_link')}
                                                                value={fields.youtube}
                                                                onChange={this.handleChange.bind(this)}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                            }
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            <SubTitle align="left">
                                                {translate('relatedDiscount')}
                                            </SubTitle>
                                            <Typography paragraph className={this.classes.condir}>{translate('relatedDiscountDesc')}</Typography>
                                            <DiscountChooser
                                                owner_id={this.state.owner_id}
                                                outPut={(discount) => this.setState({discount})}
                                            />
                                            {
                                                !!this.state.discount &&
                                                <>
                                                <Typography style={{marginTop:15}}>{translate('chosenDiscount')}: <strong>{this.state.discount.name}</strong></Typography>
                                                </>
                                            }
                                        </AppCard>
                                    </Grid>
                                    {
                                        (!!user.role.permission.manageShops && !editMode) &&
                                        <Grid item xs={12}>
                                            <AppCard>
                                                <SubTitle align="left">
                                                    {translate('relatedUserBusiness')}
                                                </SubTitle>
                                                <UserChooser
                                                    outPut={(res) => this.setState({
                                                        related_user:res
                                                    })
                                                    }
                                                />
                                                <Typography>{translate('inCaseOfNotChoosingUser')}</Typography>
                                            </AppCard>
                                        </Grid>
                                    }
                                    {
                                        (!!user.role.permission.manageShops) &&
                                        <Grid item xs={12}>
                                            <AppCard style={{height:'100%'}}>
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{translate('seoDetails')}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <FormControl component="fieldset">
                                                            <FormControlLabel
                                                                control={<Checkbox checked={this.state.no_index} onChange={() => this.setState({no_index:!this.state.no_index})} />}
                                                                label={translate('makePageNoIndex')}
                                                            />
                                                        </FormControl>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            fullWidth
                                                            margin="normal"
                                                            name="meta_title"
                                                            variant="outlined"
                                                            label={translate('meta_title')}
                                                            value={fields.meta_title}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['meta_title']}
                                                            helperText={errors['meta_title']}
                                                        />
                                                    </Grid>
                                                </Grid>
                                            </AppCard>
                                        </Grid>
                                    }
                                    {
                                        (!! user.role.permission.manageShops && !!editMode && !this.state.loading) &&
                                        <Grid item xs={12}>
                                            <UserCard title={translate('creator')} id={owner.id}/>
                                        </Grid>
                                    }
                                    <Grid item xs={12}>
                                        {
                                            (!!this.state.expired || !!this.state.expired_this_week) ?
                                                <div style={{textAlign:"center"}}>
                                                    <RenewButton id={this.state.id} reload={() => this.loadData()} />
                                                </div>
                                                :
                                                <LoadingButton
                                                    variant="contained"
                                                    color="primary"
                                                    style={{minWidth:200}}
                                                    onClick = {this.handleSubmit.bind(this)}
                                                    loading = {SubmitLoading}
                                                >
                                                    {editMode ? translate('editAdvertisement') :  translate('makeAdvertisement')}
                                                </LoadingButton>
                                        }
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            !!editMode ?
                                                user.id === this.state.owner_id && !user.role.permission.manageAdvertisements && !this.state.requested_to_delete &&
                                                <Button
                                                    startIcon={<DeleteRoundedIcon/>}
                                                    style={{color:"#ff5451"}}
                                                    onClick={() => this.handleRequestToDelete()}
                                                >
                                                    {translate('requestToDeleteAd')}
                                                </Button>
                                                : null
                                        }
                                        {
                                            !!editMode ?
                                                !!user.role.permission.manageAdvertisements &&
                                                <GetConfirmationText
                                                    variant="outlined"
                                                    style={{color: "#f44336",backgroundColor:"#fff"}}
                                                    tooltip={translate('removeAd')}
                                                    handleAction={() => this.handleDeleteAdvertisement()}
                                                >
                                                    {translate('removeAd')}
                                                </GetConfirmationText>
                                                : null
                                        }
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>

                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeAdvertisement))));