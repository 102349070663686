import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import UserCard from "../../../../components/elements/UserCard";
import SubTitle from "../../../../components/elements/SubTitle";
import Chip from "@material-ui/core/Chip/Chip";
import Typography from "@material-ui/core/Typography/Typography";
import AddCityButton from "./AddCityButton";
import AgentOrdersTable from "./AgentOrdersTable";
import Divider from "@material-ui/core/Divider/Divider";

class details extends React.Component {
    classes = this.props.classes;
    state={
        fields: {
            sell_commission: '',
            buy_commission: '',
        },
        agent:{},
        user: {},
        cities:[],
        lockEmail:false,
        lockMobile:false,
        errors:{},
        loading:true,
        SubmitLoading:false,
        confirmed:'',
        mobile_confirmed:false,
        melli_card:null,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {agent_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/agent/get/${agent_id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        sell_commission: response.data.sell_commission,
                        buy_commission: response.data.buy_commission,
                    },
                    agent: response.data,
                    user: response.data.user,
                    cities: !!response.data.cities ? response.data.cities.split(",") : [],
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error)
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
        });
    }
    handleValidation(callback){
        let { fields } = this.state;
        let {user} = this.props;
        let errors = {};
        let formIsValid = true;
        if(user.role.type !== "admin"){
            try {
                if(!fields.sell_commission){
                    formIsValid = false;
                    errors['sell_commission'] = Translate.emptyField;
                }

                if(!fields.buy_commission){
                    formIsValid = false;
                    errors['buy_commission'] = Translate.emptyField;
                }
            }catch (e) {
                console.log(e)
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,cities,agent} = this.state;
        let data = new FormData();
        data.append('buy_commission', fields.buy_commission);
        data.append('sell_commission', fields.sell_commission);
        data.append('cities', cities.toString());

        let loginToken = localStorage.getItem('ACT');
        let {agent_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/agent/set/${agent_id}`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.detailsSet, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }

    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleDeleteCity(city){
        let {cities} = this.state;
        cities = cities.filter((item) => item !== city);
        this.setState({
            cities
        })
    }
    addCity(city){
        let {cities} = this.state;
        cities = cities.filter((item) => item !== city);
        cities.push(city);
        this.setState({
            cities
        })
    }
    render() {
        const {errors,fields,agent,user,cities,SubmitLoading,loading} = this.state;
        if (loading){
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={11} lg={10}>
                        <Grid container  spacing={2} justify="center" alignItems="flex-start">
                            <Grid item xs={12}>
                                <UserCard title={Translate.userDetail} id={user.id}/>

                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    value={fields.sell_commission}
                                    fullWidth
                                    type="number"
                                    label={Translate.sell_commission}
                                    name="sell_commission"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['sell_commission']}
                                    helperText={errors['sell_commission']}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    variant="outlined"
                                    margin="normal"
                                    required
                                    value={fields.buy_commission}
                                    fullWidth
                                    type="number"
                                    label={Translate.buy_commission}
                                    name="buy_commission"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['buy_commission']}
                                    helperText={errors['buy_commission']}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <SubTitle align={"left"}>{Translate.agent_cities}</SubTitle>
                                {
                                    !!cities.length ?

                                        cities.map((city,index)=>(
                                            <Chip
                                                style={{marginLeft:10}}
                                                key={index}
                                                variant="outlined"
                                                color="primary"
                                                onDelete={() => {this.handleDeleteCity(city)}}
                                                label={city}
                                            />
                                        ))
                                        :
                                        <Typography>{Translate.noCityFound}</Typography>
                                }
                                <AddCityButton handleCity={(city) => this.addCity(city)}/>
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {Translate.setDetails}
                                </LoadingButton>
                            </Grid>
                            <Grid item xs={12}>
                                <Divider/>
                            </Grid>
                            <Grid item xs={12}>
                                <AgentOrdersTable agent_id={agent.id}/>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));