import React from 'react';
import {XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer,} from 'recharts';
import Line from "recharts/es6/cartesian/Line";
import LineChart from "recharts/es6/chart/LineChart";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect, setStore} from "trim-redux";
import moment from "moment-jalaali";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";

class OrdersChart extends  React.Component{
    state = {
        data :[],
        loading:true,
    }
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');

        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let url = this.props.seller ? "/analytics/orders_chart_seller/month" : "/analytics/orders_chart/month";
            await axios.get(`${defaultVariabls.url}${url}`, config)
                .then(response => {
                    let data = [];
                    for(let i = 29 ; i >= 0 ; i--){
                        let time = moment(new Date() - i * 24 * 3600 * 1000).locale('en').locale('en').format('jYYYY/jM/jD');
                        data.push({
                            name: time,
                            orders: parseInt(response.data[i])
                        })
                    }
                    this.setState({
                        data,
                        loading:false
                    })
                }).catch(error => {
                    console.log(error)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    render(){
        let {data,loading} = this.state;
        return (
            <div style={{height:450,direction:"ltr"}}>
                {
                    loading ?
                        <div style={{position:"relative"}}>
                            <CircularProgress
                                style={{position: "relative", top: 150, marginLeft: "50%"}}/>
                        </div>
                        :
                        <ResponsiveContainer>
                            <LineChart data={data}
                                       margin={{top: 40, right: 50, left: 10, bottom: 0}}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="name"/>
                                <YAxis allowDecimals={false}/>
                                <Tooltip/>
                                <Legend/>
                                <Line type="monotone" stroke={this.props.primary_color} dataKey="orders" strokeWidth={3}/>
                            </LineChart>
                        </ResponsiveContainer>
                }
            </div>
        );
    }
}
const mstp = state => ({
    Translate: state.Translate,
    locale: state.locale,
    primary_color: state.primary_color,
});
export default connect(mstp)(OrdersChart);
