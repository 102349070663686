import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import SubTitle from "../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Translate} from "../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import {currencySymbol, price} from "../../../../config/values";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import {Link} from "react-router-dom";
class OrdersAnalyticsShort extends React.Component {
    state = {
        orders: null,
        done_orders: null,
        sell_commissions: null,
        buy_commissions: null,
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let {beginDate,endDate} = this.state;
        if((!!beginDate && !!endDate) || (!beginDate && !endDate)){
            this.setState({
                loading:true,
            });
            let loginToken = localStorage.getItem('ACT');
            if(loginToken !== null){
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                await axios.get(`${defaultVariabls.url}/agent/order-analytics`, config)
                    .then(response => {
                        this.setState({
                            orders: response.data.orders,
                            done_orders: response.data.done_orders,
                            sell_commissions: response.data.sell_commissions,
                            buy_commissions: response.data.buy_commissions,
                            loading:false,
                        })
                    }).catch(error => {
                        console.log(error)
                        this.setState({
                            loading:false
                        })
                    })
            }else {
                setStore({
                    isAuth : false,
                    user : null,
                    loading:false
                });
                this.setState({
                    loading:false
                })
            }
        }
    }
    classes = this.props.classes;
    render(){
        let {orders,done_orders,sell_commissions,buy_commissions,loading} = this.state;
        return (
            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} md={3}>
                    <Grid container  style={{backgroundColor: "#fff",border:"1px solid #ff935a"}} direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#ff935a",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.allOrders}
                                <Button
                                    size="small"
                                    color="secondary"
                                    endIcon={<ChevronLeftRoundedIcon/>}
                                    component={Link}
                                    to={'/agent'}
                                >
                                    {Translate.viewDetails}
                                </Button>
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:"center",marginTop:20}} className={this.classes.AnalyticsResult} >{loading ? <CircularProgress size={25}/> : orders}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container  style={{backgroundColor: "#fff",border:"1px solid #42b0c9"}} direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#42b0c9",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.payedOrders}
                                <Button
                                    size="small"
                                    color="secondary"
                                    endIcon={<ChevronLeftRoundedIcon/>}
                                    component={Link}
                                    to={'/agent'}
                                >
                                    {Translate.viewDetails}
                                </Button>
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:"center",marginTop:20}} className={this.classes.AnalyticsResult} >{loading ? <CircularProgress size={25}/> : done_orders}</Typography>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container   style={{backgroundColor: "#fff",border:"1px solid #bfa500"}} direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#bfa500",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.sellCommissions}
                                <Button
                                    size="small"
                                    color="secondary"
                                    endIcon={<ChevronLeftRoundedIcon/>}
                                    component={Link}
                                    to={'/agent'}
                                >
                                    {Translate.viewDetails}
                                </Button>
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:"center",marginTop:20}} className={this.classes.AnalyticsResult} >{loading ? <CircularProgress size={25}/> : price(sell_commissions)+" "+currencySymbol}</Typography>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid style={{backgroundColor: "#fff",border:"1px solid #5dc178"}} container   direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#5dc178",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.buyCommissions}
                                <Button
                                    size="small"
                                    color="secondary"
                                    endIcon={<ChevronLeftRoundedIcon/>}
                                    component={Link}
                                    to={'/agent'}
                                >
                                    {Translate.viewDetails}
                                </Button>
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography style={{textAlign:"center",marginTop:20}} className={this.classes.AnalyticsResult} >{loading ? <CircularProgress size={25}/> : price(buy_commissions)+" "+currencySymbol}</Typography>
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
        );
    }
}

export default withStyles(Styles)(OrdersAnalyticsShort);