import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import LinearCategory from "./components/LinearCategory";
import Breadcrumbs from "@material-ui/core/Breadcrumbs/Breadcrumbs";
import Typography from "@material-ui/core/Typography/Typography";
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import SubTitle from "../../elements/SubTitle";
import {translate} from "../../../../config/lang";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
class LiearCategoryHolder extends React.Component {
    state = {
        categories: null,
        loading:true,
        catL1:null,
        catL2:null,
        catL3:null,
        catL4:null,
        catL1_name:null,
        catL2_name:null,
        catL3_name:null,
        catL4_name:null,
        editMode: this.props.editMode,
        restricted:false
    };
    classes = this.props.classes;
    componentDidMount(){
        if(this.props.editMode){
            this.setState({
                catL1_name:this.props.inPut[0],
                catL2_name:this.props.inPut[1],
                catL3_name:this.props.inPut[2],
                catL4_name:this.props.inPut[3],
                editMode: this.props.editMode
            })
        }else{
            let {user} = this.props;
            console.log("hi",user.category);
            if(!!user.category && user.role.type !== "admin"){
                this.handleDone1stLevel(user.category.id,user.category.name);
                this.setState({
                    restricted:true
                })
            }

        }
    }
    componentDidUpdate(prevProps){
        if(this.state.editMode !== this.props.editMode){
            if(this.props.editMode){
                this.setState({
                    catL1_name:this.props.inPut[0],
                    catL2_name:this.props.inPut[1],
                    catL3_name:this.props.inPut[2],
                    catL4_name:this.props.inPut[3],
                    editMode: this.props.editMode
                })
            }
        }
    }


    async handleDone1stLevel(input,value){
            await this.setState(
                {
                    catL1:input,
                    catL1_name: value,
                    catL2:null,
                    catL2_name:null,
                    catL3:null,
                    catL3_name:null,
                    catL4:null,
                    catL4_name:null,
                }
            )
        let {catL1} = this.state;
        let categories = [catL1]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);

    }
    async handleDone2ndLevel(input,value){
        await this.setState({catL2:input,catL2_name:value,catL3:null,catL3_name:null,catL4:null,catL4_name:null})
        let {catL1,catL2} = this.state;
        let categories = [catL1,catL2]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);
    }
    async handleDone(input,value){
         await this.setState(
             {
                 catL3:input,
                 catL3_name:value,
                 catL4:null,
                 catL4_name:null
             })
        let {catL1,catL2,catL3} = this.state;
        let categories = [catL1,catL2,catL3]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);
    }
    async handleDone4thLevel(input,value){
        await this.setState(
            {
                catL4:input,
                catL4_name:value
            })
        let {catL1,catL2,catL3,catL4} = this.state;
        let categories = [catL1,catL2,catL3,catL4]
        await this.setState({
            categories
        })
        await this.props.outPut(categories);
    }
    render(){
        let {catL1,catL2,catL3,catL4,catL1_name,catL2_name,catL3_name,catL4_name,restricted} = this.state;
        let {editMode} = this.props;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <SubTitle align="left">{translate('chooseCategory')}</SubTitle>
                </Grid>
                {
                    !editMode ?
                        <>
                            <Grid item xs={12} md={12}>
                                {
                                    !!restricted ?
                                        <TextField
                                            disabled
                                            label={translate('chooseMainCategory')}
                                            variant="outlined"
                                            fullWidth
                                            value={catL1_name}

                                        />
                                        :
                                        <LinearCategory required={this.props.required} error={this.props.error} level={1} input={catL1} handleChange={this.handleDone1stLevel.bind(this)}/>
                                }
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LinearCategory required={this.props.required} error={this.props.error} level={2} input={catL2} prevLevel={catL1} handleChange={this.handleDone2ndLevel.bind(this)}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LinearCategory required={this.props.required} error={this.props.error} level={3} input={catL3} prevLevel={catL2} handleChange={this.handleDone.bind(this)}/>
                            </Grid>
                            <Grid item xs={12} md={12}>
                                <LinearCategory required={this.props.required} error={this.props.error} level={4} input={catL4} prevLevel={catL3} handleChange={this.handleDone4thLevel.bind(this)}/>
                            </Grid>
                        </> : null
                }

                <Grid item xs={12}>
                    <Breadcrumbs
                        separator={
                            <ArrowForwardRoundedIcon style={{margin:5}} color="secondary" fontSize="small" />
                        }
                        aria-label="breadcrumb"
                    >
                        {
                            catL1_name ?
                                <Typography>
                                    {catL1_name}
                                </Typography>
                            : null
                        }
                        {
                            catL2_name ?
                                <Typography>
                                    {catL2_name}
                                </Typography>
                                : null
                        }
                        {
                            catL3_name ?
                                <Typography>
                                    {catL3_name}
                                </Typography>
                                : null
                        }
                        {
                            catL4_name ?
                                <Typography>
                                    {catL4_name}
                                </Typography>
                                : null
                        }
                    </Breadcrumbs>
                </Grid>
            </Grid>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(LiearCategoryHolder))));
