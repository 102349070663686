import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {Styles} from "../../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import {withRouter} from "react-router-dom";
import {connect} from "trim-redux";
import {Translate} from "../../../../config/Translate";
import AppCard from "../../../components/layouts/components/AppCard";
import Typography from "@material-ui/core/Typography/Typography";
import Title from "../../../components/elements/Title";
import {Link} from 'react-router-dom';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import {defaultVariabls} from "../../../../config";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import {updateRedux} from "../../../../config/Security";
class SellerGuidLine extends React.Component{
    classes = this.props.classes;
    state={

    };
    componentDidMount(){
        updateRedux();
    }
    render(){
        let {user} = this.props;
        return (

            <AppCard>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Title align="left">{Translate.sellerGuidLine}</Title>
                        <Typography paragraph>{Translate.sellerGuidLineDescription}</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard className="guidline-single" component={Link} to="/account/details">
                            <img width={50} src="../assets/images/account.png" alt="account" loading="lazy"/>
                            <Title component={Link} to="/account/details">{Translate.completeYorDetails}</Title>
                            <Typography className="text-center" variant="body2" component={Link} to="/account/details">{user.confirmed === "uncompleted" ? Translate.doCompleteDetails : user.confirmed === "pending" ? Translate.inCheckingDetails : Translate.compeltedDetails}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>

                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard className="guidline-single"  component={Link} to="/my-shop">
                            <img width={50} src="../assets/images/shop.png" alt="resume" loading="lazy"/>
                            <Title>{Translate.createShop}</Title>
                            <Typography variant="body2" component={Link} to="/my-shop">{Translate.manageYourShop}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            !!user.shops[0] ?
                                !!user.shops[0].confirmed ?
                                <Typography component="a" target="_blank" href={`${defaultVariabls.website}/vendor/${user.shops[0].slug}`} className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <CheckCircleRoundedIcon fontSize="small" className="simple-success-icon"/>
                                    </IconButton>
                                    {Translate.viewShop}
                                </Typography>
                                :
                                    <Typography component={Link} to="/my-shop" className="mt-10 simple-error-text" varaint="body2">
                                        {Translate.shopIsPending}
                                    </Typography>

                                : null
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard component={!!user.shops[0] ? Link : 'div'} to="/my-products" className={!!user.shops[0] ? "guidline-single" : "disabled-appCard guidline-single"}>
                            <img width={50} src="../assets/images/product.png" alt="product" loading="lazy"/>
                            <Title >{Translate.productsList}</Title>
                            <Typography  variant="body2">{Translate.addNewProduct}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            !!user.shops[0] ?
                                null:
                                <Typography component={Link} to="/my-shop" className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <ErrorRoundedIcon fontSize="small" className="simple-error-icon"/>
                                    </IconButton>
                                    {Translate.firstCreateShop}
                                </Typography>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <AppCard component={!!user.shops[0] ? Link : 'div'} to="/pricing" className={!!user.shops[0] ? "guidline-single" : "disabled-appCard guidline-single"}>
                            <img width={50} src="../assets/images/price.png" alt="content" loading="lazy"/>
                            <Title>{Translate.pricing}</Title>
                            <Typography variant="body2">{Translate.doPricing}</Typography>
                            <ChevronLeftRoundedIcon className="guidline-arrow" />
                        </AppCard>
                        {
                            !!user.shops[0] ?
                                null:
                                <Typography component={Link} to="/my-shop" className="mt-10 simple-error-text" varaint="body2">
                                    <IconButton size="small">
                                        <ErrorRoundedIcon fontSize="small" className="simple-error-icon"/>
                                    </IconButton>
                                    {Translate.firstCreateShop}
                                </Typography>
                        }
                    </Grid>
                </Grid>
            </AppCard>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(SellerGuidLine))));