import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import Thumbnail from "../../fileManager/Components/Thumbnail";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import SubTitle from "../../../components/elements/SubTitle";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import Hidden from "@material-ui/core/Hidden/Hidden";

class MakeBrand extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();

    }
    state = {
        fields:{
            name:"",
            description:"",
        },
        logo: 1,
        errors:{},
        loading: true,
        SubmitLoading: false,
        id:this.props.match.params.id,
        editMode:false
    };
    classes = this.props.classes;
    async componentDidMount(){
        if(!!this.state.id){
            this.setState({
                editMode:true
            })
            await this.loadData();
        }else{
            this.setState({
                loading:false,
            })
        }
    }
    componentDidUpdate(prevProps){
        if(this.state.id !== this.props.match.params.id){
            this.setState({
                id: this.props.match.params.id
            })
        }
    }
    handleThumbImage(id){
        this.setState({
            logo: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/brand/get/${this.state.id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            description: !!response.data.description ? response.data.description : '',
                        },
                        logo: response.data.logo.id,
                        loading:false,
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,id,editMode,logo} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('logo', logo);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/brand/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.dataEdited : Translate.dataAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
                if(!this.state.editMode){
                    this.props.history.push(`/manage/brands`);
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  Translate.dataEditingFailed : Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    render(){
        let {fields, errors,SubmitLoading,logo,editMode} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={editMode ? Translate.editBrand : Translate.makeBrand}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={8} lg={9}>
                            <AppCard
                                title={editMode ? Translate.editBrand : Translate.makeBrand}
                                action={
                                    <BreadCrumbs singleLevel firstText={editMode ? Translate.editBrand : Translate.makeBrand}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12}>
                                                <TextField
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    variant="outlined"
                                                    name="name"
                                                    label={Translate.brandName}
                                                    type="text"
                                                    id="name"
                                                    value={fields.name}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['name']}
                                                    helperText={errors['name']}
                                                />
                                            </Grid>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    name="description"
                                                    label={Translate.seoDescription}
                                                    placeholder={Translate.seoDescription}
                                                    type="text"
                                                    id="description"
                                                    minRows={3}
                                                    maxRows={5}
                                                    value={fields.description}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['description']}
                                                    helperText={errors['description']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                            <Hidden smDown>
                                                <Grid item xs={12}>
                                                    <LoadingButton
                                                        variant="contained"
                                                        color="primary"
                                                        onClick = {this.handleSubmit.bind(this)}
                                                        loading = {SubmitLoading}
                                                    >
                                                        {editMode ? Translate.editBrand : Translate.makeBrand}
                                                    </LoadingButton>
                                                </Grid>
                                            </Hidden>
                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <AppCard>
                                        {
                                            this.state.loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2} alignItems="center">
                                                    <Grid item xs={12}>
                                                        <SubTitle>{Translate.logo_brand}</SubTitle>
                                                        <Thumbnail rounded ref={this.ThumbChild}
                                                                   handleThumbImage={this.handleThumbImage.bind(this)}
                                                                   initial={logo}/>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                                <Hidden mdUp>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {editMode ? Translate.editBrand : Translate.makeBrand}
                                        </LoadingButton>
                                    </Grid>
                                </Hidden>
                            </Grid>

                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(MakeBrand)));