import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import {Translate} from "../../../../../config/Translate";
import ManageUserVerification from "./ManageUserVerification";
import {translate} from "../../../../../config/lang";
class ManageUserVerificationDialog extends React.Component {
    state={
        open:true,
        id:null
    }
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            open:this.props.open,
            id:this.props.id
        })
    }
    componentDidUpdate(prevProps){
        if(this.state.open !== this.props.open){
            this.setState({
                open: this.props.open,
                id:this.props.id

            })
        }
    }
    handleClose (){
        this.setState({open:false})
        this.props.reload();
    }
    render(){
        let {open,id} = this.state;
        return (
            <>
                <Dialog maxWidth="md" fullWidth onClose={this.handleClose.bind(this)} aria-labelledby="customized-dialog-title" open={open}>
                    <DialogTitle id="customized-dialog-title" onClose={this.handleClose.bind(this)}>
                        {translate('ManageUserVerification')}
                    </DialogTitle>
                    <DialogContent dividers>
                        <ManageUserVerification id={id} />
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(ManageUserVerificationDialog)));