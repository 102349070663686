import React from "react";
import Button from "@material-ui/core/Button/Button";
import Menu from "@material-ui/core/Menu/Menu";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
class UnitChooser extends React.Component{
    state = {
        anchorEl:null,
        loading:false,
        units:this.props.units.split(","),
        current:!!this.props.current  ? this.props.current : this.props.units.split(",")[0],
    };
    async componentDidMount(){
        if(!!this.props.current){
            await this.setState({
                current:this.props.current
            })
        }else{
            this.props.onChange(this.props.units.split(",")[0])

        }
    }
    handleClick(event){
        this.setState({
            anchorEl:event.currentTarget
        })
    }
    handleClose = () => {
        this.setState({
            anchorEl:null
        })
    };
    handleItem(current){
        this.setState({
            current,
            anchorEl:null
        })
        this.props.onChange(current)
    }
    render(){
        let {anchorEl,units,loading,current} = this.state;
        return (
            <div>
                <Button
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    variant="contained"
                    onClick={(e) => this.handleClick(e)}
                    endIcon={<ExpandMoreRoundedIcon/>}
                >
                    {current}
                </Button>
                {
                    !loading &&
                    <Menu
                        id="simple-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={this.handleClose.bind(this)}
                    >
                        {
                            units.map((item,index) => (
                                <MenuItem key={index} onClick={() => this.handleItem(item)}>{item}</MenuItem>
                            ))
                        }
                    </Menu>
                }
            </div>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(UnitChooser))));