import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../config/index';
import {setStore, getStore, connect} from "trim-redux";
import {Link, withRouter} from 'react-router-dom';
import {withSnackbar} from "notistack";
import LoadingButton from "../../components/elements/LoadingButton";
import {secret} from "../../../config/Security";
import HeaderTags from "../../components/elements/HeaderTags";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import FormControl from "@material-ui/core/FormControl/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import {translate} from "../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import PassField from "../../components/fields/PassField";
class Register_old_new extends React.Component {
    constructor(props){
        super(props);
        if(getStore().isAuth){
            this.handleLogout()
        }
        this.state={
            fields: {
                first_name: '',
                last_name: '',
                email:'',
                mobile:'',
                role:'user',
                password:'',
                password_confirmation: ''
            },
            user_id:this.props.user_id,
            errors:{},
            SubmitLoading:false,
            policy_confirmation:false,
            success:false
        };
    }
    componentDidMount(){
        this.setState({
            user_id:this.props.user_id,
        })
    }
    componentDidUpdate(PrevProps){
        if(this.state.user_id !== this.props.user_id){
            this.setState({
                user_id:this.props.user_id
            })
        }
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({fields});
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
    }

    handleValidation(callback){
        let { fields ,policy_confirmation} = this.state;
        let errors = {};
        let formIsValid = true;

        //last_name
        if(validator.isEmpty(fields.last_name)){
            formIsValid = false;
            errors['last_name'] = translate('emptyField');
        }
        //first_name
        if(validator.isEmpty(fields.first_name)){
            formIsValid = false;
            errors['first_name'] = translate('emptyField');
        }
        //policy_confirmation
        if(!policy_confirmation ) {
            formIsValid = false;
            errors['policy_confirmation'] = translate('pleaseCheckTheBox');
        }

        //Email
        if(validator.isEmpty(fields.email)){
            formIsValid = false;
            errors['email'] = translate('emptyEmail');
        }else if(!validator.isEmail(fields.email)){
            formIsValid = false;
            errors['email'] = translate('wrongEmail');
        }

        /*//Password
        if(validator.isEmpty(fields.password)){
            formIsValid = false;
            errors['password'] = translate('emptyPassword');
        }else if(fields.password.length < 4){
            formIsValid = false;
            errors['password'] = translate('weakPassword');
        }
        //Password_confirmation
        if(validator.isEmpty(fields.password_confirmation)){
            formIsValid = false;
            errors['password_confirmation'] = translate('emptyField');
        }else if (fields.password_confirmation !== fields.password){
            formIsValid = false;
            errors['password_confirmation'] = translate('noMatchPassword');
        }*/

        //check Email and Mobile
        let data = new FormData();
        data.append('email', fields.email);
        const loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        const request = async () => {
            if(errors['email'] === undefined){
                await axios.post(`${defaultVariabls.url}/register/checkEmail`, data, config)
                    .then(response => {}).catch(error => {
                        console.log(error.response)
                        formIsValid = false;
                        errors['email'] = translate('existEmail');
                    });
            }
            await this.setState({errors},() =>{
                if(!formIsValid){
                    this.setState({
                        SubmitLoading:false
                    })
                }
                return callback(formIsValid)
            });
        }
        request();
    }
    async handleRequest(){
        const {first_name,last_name,email,mobile, password,password_confirmation,role} = this.state.fields;
        let {user_id} = this.state;
        let data = new FormData();
        data.append('secret', secret);
        data.append('first_name', first_name);
        data.append('last_name', last_name);
        data.append('email', email);
        data.append('lang', this.props.locale);
        data.append('role', 'user');
        data.append('user_id', user_id);
        data.append('password', password);
        data.append('password_confirmation', password_confirmation);
        data.append('avatar', "2");
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        await axios.post(`${defaultVariabls.url}/register`, data, config)
            .then(response =>{
                localStorage.setItem('ACT',response.data.accessToken);
            })
            .catch(error =>{
                console.log(error.response)
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(translate('registerError'), {
                    variant: 'error',
                });
            });
        const loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });
                    this.setState({
                        SubmitLoading:false,
                        success:true
                    })

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
                this.setState({
                    SubmitLoading:false
                })
                this.props.enqueueSnackbar(translate('registerError'), {
                    variant: 'error',
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                SubmitLoading:false
            })
        }
        if (this.props.isAuth){
            let code = this.props.user.login_token;
            let token = localStorage.getItem('ACT');
            let lang = localStorage.getItem('locale');
            let mode = localStorage.getItem('mode');
            if(!mode){
                mode = '';
            }
            let {iframe} = this.props;
            if(iframe){
                await window.parent.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }else{
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        fields.mobile = mobile;
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    render() {
        const {classes,iframe} = this.props;
        const {errors,SubmitLoading,success,policy_confirmation} = this.state;
        return (
            <>
                <HeaderTags title={translate('register')}/>
                    <form className={classes.form} noValidate onSubmit={this.handleSubmit.bind(this)}>
                        <Grid container spacing={2} justify="center" alignItems='center'>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="first_name"
                                    variant="outlined"
                                    label={translate('first_name')}
                                    placeholder={translate('first_name')}
                                    name="first_name"
                                    onChange={this.handleChange.bind(this)}
                                    autoFocus
                                    error = {!!errors['first_name']}
                                    helperText={errors['first_name']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    required
                                    fullWidth
                                    id="last_name"
                                    variant="outlined"
                                    label={translate('last_name')}
                                    placeholder={translate('last_name')}
                                    name="last_name"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['last_name']}
                                    helperText={errors['last_name']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    id="email"
                                    label={translate('email')}
                                    name="email"
                                    dir="ltr"
                                    autoComplete="email"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['email']}
                                    helperText={errors['email']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PassField
                                    required
                                    fullWidth
                                    variant="outlined"
                                    name="password"
                                    label={translate('password')}
                                    type="password"
                                    id="password"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['password']}
                                    helperText={errors['password']}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <PassField
                                    required
                                    variant="outlined"
                                    fullWidth
                                    name="password_confirmation"
                                    label={translate('passwordConfirm')}
                                    placeholder={translate('passwordConfirm')}
                                    type="password"
                                    id="password_confirmation"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['password_confirmation']}
                                    helperText={errors['password_confirmation']}
                                />
                            </Grid>

                                <Grid item xs={12} className={classes.textFloat}>
                                    <FormControl required error={!!errors['policy_confirmation']} component="fieldset">
                                        <FormControlLabel
                                            control={<Checkbox checked={policy_confirmation} onChange={() => this.setState({policy_confirmation:!policy_confirmation})} />}
                                            label={
                                                <Typography className={classes.privacy_policy}>
                                                    {translate('withRegistering')}
                                                    {" "}
                                                    <a target="_blank" href={`${defaultVariabls.website}/terms-and-conditions`} >
                                                        {translate('rules')}
                                                    </a>
                                                    {" "}
                                                    {translate('privacyPolicyConfirmation')}
                                                </Typography>
                                            }
                                        />
                                        {
                                            !!errors['policy_confirmation'] &&
                                            <FormHelperText>{errors['policy_confirmation']}</FormHelperText>

                                        }
                                    </FormControl>
                                </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <LoadingButton
                                type="submit"
                                variant="contained"
                                color="primary"
                                size="large"
                                style={{width:"100%"}}
                                styleForParent={{marginBottom:0}}
                                onClick = {this.handleSubmit.bind(this)}
                                loading = {SubmitLoading}
                                success = {success}
                            >
                                {translate('register')}
                            </LoadingButton>
                        </Grid>
                        <Grid item xs={12} style={{marginTop:15}} className={classes.textFloat}>
                            <Typography className={classes.correctText}>
                                    {translate('haveAccount')}
                                <Button size={"small"} component={Link}  to={iframe ? "/iframe/login" : "/login"}>
                                    <strong>
                                        {translate('doLogin')}
                                    </strong>
                                </Button>
                            </Typography>
                        </Grid>
                    </form>
                </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Register_old_new))));