import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Details from "./components/Details";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCard from "../../components/layouts/components/AppCard";
import AppAnimate from "../../components/AppAnimate";
import {translate} from "../../../config/lang";
import Verification from "./components/Verification";
import MyStepsInfo from "../register_steps/steps/MyStepsInfo";

class UserDetails extends React.Component {
    render() {
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('myAccount')}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate('myAccount')}
                            action={
                                <BreadCrumbs singleLevel firstText={translate('myAccount')}/>
                            }>
                        <Details/>
                        </AppCard>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <Verification/>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <MyStepsInfo/>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
export default withStyles(Styles)(UserDetails);