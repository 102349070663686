import React from 'react';
import { withStyles} from '@material-ui/core/styles';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import axios from 'axios';
import {connect, getStore, setStore} from 'trim-redux'
import {defaultVariabls} from './config';
import Login from "./class/pages/Authentication/Login";
import Loader from "./class/components/elements/Loader";
import {Styles} from "./config/design/styles";
import PrivateRoute from "./class/components/elements/PrivateRoute";
import Layout from "./class/components/layouts/layout";
import Error404 from './class/pages/errors/404';
import FileManagerPage from "./class/pages/fileManager/FileManagerPage";
import MobileVerify from "./class/pages/Authentication/MobileVerify";
import ChangePassword from "./class/pages/user/ChangePassword";
import ForgetPassword from "./class/pages/Authentication/ForgetPassword";
import LoginWithToken from "./class/pages/Authentication/LoginWithToken";
import VerifyEmailPage from "./class/pages/Authentication/VerifyEmailPage";
import Permission from "./class/components/elements/Permission";
import {ErrorBoundary} from 'react-error-boundary'
import IconButton from "@material-ui/core/IconButton/IconButton";
import CachedRoundedIcon from '@material-ui/icons/CachedRounded';
import Snackbar from "@material-ui/core/Snackbar/Snackbar";
import MakeContent from "./class/pages/contents/MakeContent";
import UserDetails from "./class/pages/user/UserDetails";
import MakeShopPage from "./class/pages/shop/MakeShopPage";
import ShopsPage from "./class/pages/shop/ShopsPage";
import MakeProduct from "./class/pages/products/MakeProduct";
import CategoriesPage from "./class/pages/admin/categories/CategoriesPage";
import MakeCategory from "./class/pages/admin/categories/MakeCategory";
import ProductsPage from "./class/pages/products/ProductsPage";
import CommentsPage from "./class/pages/admin/comments/CommentsPage";
import ManageAbout from "./class/pages/admin/pages/ManageAbout";
import ManageTerms from "./class/pages/admin/pages/ManageTerms";
import UsersPage from "./class/pages/admin/users/UsersPage";
import ActivityLog from "./class/pages/admin/users/UsersLogPage";
import AddUserPage from "./class/pages/admin/users/AddUserPage";
import WalletPage from "./class/pages/wallet/WalletPage";
import Verification from "./class/pages/user/components/Verification";
import WalletsPage from "./class/pages/admin/wallets/WalletsPage";
import WalletHistoryPage from "./class/pages/admin/wallets/WalletHistoryPage";
import ManageVerificationsPage from "./class/pages/admin/users/ManageVerificationsPage";
import ManageOrdersPage from "./class/pages/admin/orders/ManageOrdersPage";
import MakeVariable from "./class/pages/admin/variables/MakeVariable";
import VariablesPage from "./class/pages/admin/variables/VariablesPage";
import ManageUserPage from "./class/pages/admin/users/ManageUserPage";
import MyAddressesPage from "./class/pages/user/address/MyAddressesPage";
import AttributesPage from "./class/pages/admin/attributes/AttributesPage";
import MakeValues from "./class/pages/admin/attributes/MakeValues";
import PricingPage from "./class/pages/pricing/PricingPage";
import MakePricing from "./class/pages/pricing/MakePricing";
import ModifyPricingPage from "./class/pages/pricing/ModifyPricingPage";
import BrandsPage from "./class/pages/admin/brands/BrandsPage";
import MakeBrand from "./class/pages/admin/brands/MakeBrand";
import ManagePrivacyPolicy from "./class/pages/admin/pages/ManagePrivacyPolicy";
import ManageSellerTerms from "./class/pages/admin/pages/ManageSellerTerms";
import Wishlist from "./class/pages/user/wishlist/Wishlist";
import SlideShowsPage from "./class/pages/admin/settings/slideshows/SlideShowsPage";
import BannersPage from "./class/pages/admin/settings/banners/BannersPage";
import CheckoutPage from "./class/pages/checkout/CheckoutPage";
import CreateOrder from "./class/pages/checkout/CreateOrder";
import UserOrdersPage from "./class/pages/orders/user_orders/UserOrdersPage";
import ViewOrder from "./class/pages/orders/user_orders/ViewOrder";
import ManageOrder from "./class/pages/admin/orders/ManageOrder";
import Role from "./class/components/elements/Role";
import ShopOrdersPage from "./class/pages/orders/shop_orders/ShopOrdersPage";
import CheckOrder from "./class/pages/orders/shop_orders/CheckOrder";
import SyncAuth from "./class/pages/Authentication/SyncAuth";
import ShopCommentsPage from "./class/pages/admin/shop-comments/ShopCommentsPage";
import AdminDashboard from "./class/pages/admin/analytics/AdminDashboard";
import SellerDashboard from "./class/pages/Dashboard/SellerDashboard";
import OrdersAnalyticsPage from "./class/pages/admin/analytics/OrdersAnalyticsPage";
import ManageContact from "./class/pages/admin/pages/ManagePage";
import UserDashboard from "./class/pages/Dashboard/UserDashboard";
import RedirectsPage from "./class/pages/admin/settings/redirects/RedirectsPage";
import ManageViolations from "./class/pages/admin/pages/ManageViolations";
import TicketsPage from "./class/pages/tickets/TicketsPage";
import Ticket from "./class/pages/tickets/Ticket/index";
import DepartmentsPage from "./class/pages/tickets/departments/DepartmentsPage";
import AgentsPage from "./class/pages/admin/users/agents/AgentsPage";
import ManageAgentPage from "./class/pages/admin/users/agents/ManageAgentPage";
import AgentDashboard from "./class/pages/Dashboard/AgentDashboard";
import AgentOrdersPage from "./class/pages/admin/users/agents/AgentOrdersPage";
import TransferPricingPage from "./class/pages/admin/settings/transfer_pricing/TransferPricingPage";
import ManageAgentTerms from "./class/pages/admin/pages/ManageAgentTerms";
import Feature from "./class/components/elements/Feature";
import ContentsPage from "./class/pages/contents/ContentsPage";
import BlogCategoriesPage from "./class/pages/admin/blog_categories/BlogCategoriesPage";
import MakeBlogCategory from "./class/pages/admin/blog_categories/MakeBlogCategory";
import BlogCommentsPage from "./class/pages/admin/blog-comments/BlogCommentsPage";
import MakePage from "./class/pages/admin/pages/MakePage";
import PagesPage from "./class/pages/admin/pages/PagesPage";
import ManageTemplateWithoutDrag from "./class/pages/admin/template/ManageTemplateWithoutDrag";
import SiteInformationPage from "./class/pages/admin/settings/siteSettings/SiteInformationPage";
import ContactInformationPage from "./class/pages/admin/settings/siteSettings/contact/ContactInformationPage";
import CustomCode from "./class/pages/admin/settings/siteSettings/CustomCode";
import HeaderAndFooter from "./class/pages/admin/template/HeaderAndFooter";
import MakeAdvertisement from "./class/pages/advertisements/MakeAdvertisement";
import AdvertisementsPage from "./class/pages/advertisements/AdvertisementsPage";
import {translate} from "./config/lang";
import FieldsPage from "./class/pages/admin/fields/FieldsPage";
import Logout from "./class/pages/Authentication/Logout";
import BusinessesPage from "./class/pages/businesses/BusinessesPage";
import BusinessPage from "./class/pages/businesses/BusinessPage";
import FAQPage from "./class/pages/admin/faq/FAQPage";
import MakeDiscount from "./class/pages/discounts/MakeDiscount";
import DiscountsPage from "./class/pages/discounts/DiscountsPage";
import BusinessVouchersPage from "./class/pages/orders/business_vouchers/BusinessVouchersPage";
import SubscribersPage from "./class/pages/admin/users/subscribers/SubscribersPage";
import NewsCategoriesPage from "./class/pages/admin/news_categories/NewsCategoriesPage";
import MakeNewsCategory from "./class/pages/admin/news_categories/MakeNewsCategory";
import NewsPage from "./class/pages/news/NewsPage";
import MakeNews from "./class/pages/news/MakeNews";
import RegisterStepsPage from "./class/pages/register_steps/RegisterStepsPage";
import {changeMode} from "./config/values";
import ParentsPage from "./class/pages/admin/plans/plan_parent/ParentsPage";
import MakeParent from "./class/pages/admin/plans/plan_parent/MakeParent";
import PlansPage from "./class/pages/admin/plans/PlansPage";
import MakePlan from "./class/pages/admin/plans/MakePlan";
import PurchaseAdsPage from "./class/pages/admin/plans/PurchaseAdsPage";
import PlanRestriction from "./class/components/elements/PlanRestriction";
import MyPlanPage from "./class/pages/plan/MyPlanPage";
import queryString from "query-string";
import ReportsPage from "./class/pages/tickets/ReportsPage";
import Register from "./class/pages/Authentication/Register";
import ForgetPasswordByEmail from "./class/pages/Authentication/ForgetPasswordByEmail";
import CTAsPage from "./class/pages/admin/ctas/CTAsPage";
import MakeCTA from "./class/pages/admin/ctas/MakeCTA";



class App extends React.Component{
    state ={
        loading:true,
    };
    async componentDidMount(){
        const values = queryString.parse(document.location.search);
        if(!!values.mode){
            await localStorage.setItem('mode',values.mode);
        }
        let lang = values.lang;

        if(!!lang){
            if(lang !== getStore('locale')){
                setStore({
                    lang
                });
                if(lang === "fa"){
                    localStorage.setItem('locale','fa');
                    window.location.reload(true);
                }else{
                    localStorage.setItem('locale','en');
                    window.location.reload(true);
                }
            }
        }
        if(!localStorage.getItem('mode')){
            const hours = new Date().getHours()
            const isDayTime = hours > 6 && hours < 19;
            if(!isDayTime){
                changeMode("dark");
            }
        }else{
            let mode = localStorage.getItem('mode')
            setStore({
                global_loading: true
            })
            setStore({
                mode:mode
            });
            setTimeout(function() {
                setStore({
                    global_loading: false
                })
            }, 200);
        }

        await this.loadData();
        await this.loadSetting();
    }
    async loadSetting(){
        let config = {
            headers: {
                Accept: 'application/json',
            }
        };
        let {locale} = this.props;
        await axios.get(`${defaultVariabls.url}/settings/get_all/${locale}`, config)
            .then(response => {
                setStore({
                    setting:response.data,
                    primary_color:response.data.primary_color,
                    secondary_color:response.data.secondary_color,
                    logo:response.data.site_logo,
                    app_name:response.data.app_name,
                    x_price:response.data.x_price,
                    global_need_verification:parseInt(response.data.global_need_verification),
                    admins:response.data.admins,
                    menu:response.data.menu,
                    about:response.data.about,
                    features:response.data.features,
                });
                this.setState({
                    loading:false,
                })
            }).catch(error => {
                console.log(error.response)
            })
    }

    async loadData(){
        const values = queryString.parse(document.location.search);
        if(!!values.token){
            await localStorage.setItem('ACT',values.token);
            await window.location.replace(`${defaultVariabls.website}/redirect`);
        }
        let loginToken = localStorage.getItem('ACT');
        let {locale} = this.props;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin/${locale}`, config)
                .then(response => {
                    console.log(response.data)
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });
                    if(response.data.mobile_confirmed === 1){
                        let redirect = localStorage.getItem('redirect');
                        if(!!redirect){
                            localStorage.removeItem('redirect');
                            this.props.history.push(redirect);
                        }
                        let external_redirect = localStorage.getItem('external_redirect');
                        if(!!external_redirect){
                            localStorage.removeItem('external_redirect');
                            window.location.replace(external_redirect);
                        }
                    }
                }).catch(error => {
                    console.log(error.response)
                    setStore({
                        isAuth : false,
                        user : null,
                    });
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }

    ErrorFallback({error}) {
        if(!!error){
            return (
                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    open={true}
                    message={translate('errorHappens')}
                    action={
                        <React.Fragment>
                            <IconButton  onClick={() => window.location.reload()}>
                                <CachedRoundedIcon color="secondary"/>
                            </IconButton>
                        </React.Fragment>
                    }
                />
            )
        }

    }
    classes = this.props.classes;
    render(){
        let {user,global_loading} = this.props;
        if(this.state.loading || global_loading){
            return(
                <Loader/>
            )
        }else{
            return(
                            <ErrorBoundary FallbackComponent={this.ErrorFallback}>
                                <Router>
                                    <Switch>
                                        <Redirect exact from='/' to='/dashboard'/>
                                        <Route  path="/notingFound" >
                                            <Error404/>
                                        </Route>
                                        {/*Auth Routes*/}
                                        <Route exact path="/login">
                                            <Login />
                                        </Route>
                                        <Route exact path="/iframe/login">
                                            <Login iframe/>
                                        </Route>
                                        <Route path="/sync-auth">
                                            <SyncAuth />
                                        </Route>
                                        <Route exact path="/register">
                                            <Register />
                                        </Route>
                                        <Route exact path="/iframe/register">
                                            <Register iframe/>
                                        </Route>
                                        <Route exact path="/verifyEmail/:id/:code">
                                            <VerifyEmailPage />
                                        </Route>
                                        <Route exact path="/token-login">
                                            <LoginWithToken />
                                        </Route>
                                        <Route exact path="/iframe/token-login">
                                            <LoginWithToken iframe />
                                        </Route>
                                        <Route exact path="/forget">
                                            <ForgetPassword />
                                        </Route>
                                        <Route exact path="/iframe/forget">
                                            <ForgetPassword iframe/>
                                        </Route>
                                        <Route exact path="/forget-email">
                                            <ForgetPasswordByEmail />
                                        </Route>
                                        <Route exact path="/iframe/forget-email">
                                            <ForgetPasswordByEmail iframe/>
                                        </Route>
                                        <PrivateRoute path="/verify-mobile">
                                            <MobileVerify />
                                        </PrivateRoute>
                                        <Route exact path="/logout">
                                            <Logout/>
                                        </Route>
                                        <Layout>
                                            <PrivateRoute exact path="/dashboard">
                                                {
                                                    !!user ? user.role.type === 'admin' ? <AdminDashboard/> :  <UserDashboard/>  : null
                                                }
                                            </PrivateRoute>
                                            <PrivateRoute path="/steps">
                                                <RegisterStepsPage />
                                            </PrivateRoute>
                                            <PrivateRoute path="/my-plan">
                                                <MyPlanPage />
                                            </PrivateRoute>
                                            <Feature check="tickets">
                                                <PrivateRoute path="/tickets">
                                                    <TicketsPage />
                                                </PrivateRoute>
                                                <PrivateRoute path="/ticket/:id">
                                                    <Ticket/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/report">
                                                    <ReportsPage/>
                                                </PrivateRoute>
                                                <Permission rule="manageSettings">
                                                    <PrivateRoute path="/manage/departments">
                                                        <DepartmentsPage />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/manage/tickets">
                                                        <TicketsPage manage />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/manage/ticket/:id">
                                                        <Ticket manage/>
                                                    </PrivateRoute>

                                                </Permission>
                                            </Feature>
                                            <Permission rule="fileManagerPage">
                                                <PrivateRoute path="/file-manager">
                                                    <FileManagerPage/>
                                                </PrivateRoute>
                                            </Permission>
                                            <PrivateRoute path="/account/password">
                                                <ChangePassword/>
                                            </PrivateRoute>
                                            <Feature check="wishlist">
                                                <PrivateRoute path="/wishlist">
                                                    <Wishlist/>
                                                </PrivateRoute>
                                            </Feature>
                                            <PrivateRoute path="/account/details">
                                                <UserDetails/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/account/address">
                                                <MyAddressesPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/account/verification">
                                                <Verification/>
                                            </PrivateRoute>
                                            <Feature check="wallet">
                                                <PrivateRoute exact path="/wallet/view">
                                                    <WalletPage/>
                                                </PrivateRoute>
                                            </Feature>
                                            <PrivateRoute exact path="/checkout">
                                                <CheckoutPage/>
                                            </PrivateRoute>
                                            {/*<PrivateRoute exact path="/order">
                                                <CreateOrder/>
                                            </PrivateRoute>*/}
                                            <Permission rule="manageSettings">
                                                <Feature check="blog">
                                                    <PrivateRoute exact path="/manage/blog-categories">
                                                        <BlogCategoriesPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/create/blog-category">
                                                        <MakeBlogCategory new/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/edit/blog-category/:id">
                                                        <MakeBlogCategory />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/manage/contents" exact>
                                                        <ContentsPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/create/content" exact>
                                                        <MakeContent new/>
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/edit/content/:id">
                                                        <MakeContent />
                                                    </PrivateRoute>

                                                    <PrivateRoute exact path="/manage/news-categories">
                                                        <NewsCategoriesPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/create/news-category">
                                                        <MakeNewsCategory new/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/edit/news-category/:id">
                                                        <MakeNewsCategory />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/manage/news" exact>
                                                        <NewsPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/create/news" exact>
                                                        <MakeNews new/>
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/edit/news/:id">
                                                        <MakeNews />
                                                    </PrivateRoute>
                                                </Feature>
                                                <PrivateRoute exact path="/manage/comments/ad">
                                                    <CommentsPage type="ad"/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/comments/business">
                                                    <CommentsPage type="business"/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/comments/discount">
                                                    <CommentsPage type="discount"/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/comments/article">
                                                    <CommentsPage type="content"/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/comments/news">
                                                    <CommentsPage type="news"/>
                                                </PrivateRoute>
                                                <Feature check="review_for_shop">
                                                    <PrivateRoute exact path="/manage/shop-comments">
                                                        <ShopCommentsPage/>
                                                    </PrivateRoute>
                                                </Feature>
                                                <PrivateRoute exact path="/manage/pages/about">
                                                    <ManageAbout/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/contacts">
                                                    <ManageContact/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/faq">
                                                    <FAQPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/violations">
                                                    <ManageViolations/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/terms">
                                                    <ManageTerms/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/privacy-policy">
                                                    <ManagePrivacyPolicy/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/seller-terms">
                                                    <ManageSellerTerms/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages/agent-terms">
                                                    <ManageAgentTerms/>
                                                </PrivateRoute>
                                                {/*<PrivateRoute exact path="/settings/translate-front">
                                                    <TranslateFront/>
                                                </PrivateRoute>*/}
                                                <Permission rule="managePayments">
                                                    <PrivateRoute path="/setting/contact">
                                                        <ContactInformationPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/setting/custom-code">
                                                        <CustomCode />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/setting/header-footer">
                                                        <HeaderAndFooter />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/setting/information">
                                                        <SiteInformationPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/settings/slideshows">
                                                        <SlideShowsPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/settings/banners">
                                                        <BannersPage/>
                                                    </PrivateRoute>
                                                    <Feature check="redirects">
                                                        <PrivateRoute exact path="/settings/redirects">
                                                            <RedirectsPage/>
                                                        </PrivateRoute>
                                                    </Feature>
                                                </Permission>

                                                <PrivateRoute exact path="/settings/transfer-pricing">
                                                    <TransferPricingPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/template">
                                                    <ManageTemplateWithoutDrag/>
                                                </PrivateRoute>
                                                <Feature check="agent">
                                                <PrivateRoute exact path="/manage/agents">
                                                    <AgentsPage/>
                                                </PrivateRoute>
                                                </Feature>
                                                <PrivateRoute exact path="/manage/subscribers">
                                                    <SubscribersPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/users/view/:role?">
                                                    <UsersPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/verified-users">
                                                    <UsersPage verified/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/users/add">
                                                    <AddUserPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/user/:id">
                                                    <ManageUserPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/register-user/:id">
                                                    <RegisterStepsPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/agent/:id">
                                                    <ManageAgentPage/>
                                                </PrivateRoute>
                                                <Feature check="activity_log">
                                                    <PrivateRoute exact path="/manage/activities/:query?">
                                                        <ActivityLog/>
                                                    </PrivateRoute>
                                                </Feature>
                                                <PrivateRoute exact path="/analytics/orders">
                                                    <OrdersAnalyticsPage/>
                                                </PrivateRoute>
                                            </Permission>
                                            <Permission rule="manageShops">

                                                <Feature check="marketplace">
                                                    <PrivateRoute exact path="/manage/shops">
                                                        <ShopsPage/>
                                                    </PrivateRoute>
                                                </Feature>
                                                <PrivateRoute exact path="/manage/products">
                                                    <ProductsPage manage/>
                                                </PrivateRoute>

                                                <PrivateRoute exact path="/manage/orders">
                                                    <ManageOrdersPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/order/manage/:id">
                                                    <ManageOrder/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/manage/pricing/:type" exact>
                                                    <ModifyPricingPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/ctas">
                                                    <CTAsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/cta">
                                                    <MakeCTA new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/cta/:id">
                                                    <MakeCTA  />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/pages">
                                                    <PagesPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/page">
                                                    <MakePage new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/page/:id">
                                                    <MakePage  />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/purchase-page">
                                                    <PurchaseAdsPage/>
                                                </PrivateRoute>
                                                <Feature check="brands">
                                                    <PrivateRoute exact path="/manage/brands">
                                                        <BrandsPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/edit/brand/:id">
                                                        <MakeBrand />
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/create/brand">
                                                        <MakeBrand new/>
                                                    </PrivateRoute>
                                                </Feature>
                                                <PrivateRoute exact path="/manage/variables">
                                                    <VariablesPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/variable">
                                                    <MakeVariable new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/variable/:id">
                                                    <MakeVariable />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/attributes">
                                                    <AttributesPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/attribute/:id">
                                                    <MakeValues/>
                                                </PrivateRoute>
                                            </Permission>
                                            <Permission rule="managePayments">
                                                <PrivateRoute exact path="/manage/fields">
                                                    <FieldsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/categories">
                                                    <CategoriesPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/category">
                                                    <MakeCategory new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/category/:id">
                                                    <MakeCategory />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/plan-parents">
                                                    <ParentsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/plan-parent">
                                                    <MakeParent new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/plan-parent/:id">
                                                    <MakeParent />
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/plans">
                                                    <PlansPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/plan">
                                                    <MakePlan new/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/plan/:id">
                                                    <MakePlan />
                                                </PrivateRoute>
                                                <Feature check="wallet">
                                                    <PrivateRoute exact path="/manage/wallets">
                                                        <WalletsPage/>
                                                    </PrivateRoute>
                                                    <PrivateRoute exact path="/manage/wallet/:id">
                                                        <WalletHistoryPage/>
                                                    </PrivateRoute>
                                                </Feature>
                                            </Permission>
                                            <PrivateRoute path="/create/shop">
                                                <MakeShopPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/edit/shop/:id">
                                                <MakeShopPage edit/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/my-shops">
                                                <ShopsPage myShops/>
                                            </PrivateRoute>

                                            <PrivateRoute path="/pricing" exact>
                                                <PricingPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/modify-pricing" exact>
                                                <ModifyPricingPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/pricing/product/:id" exact>
                                                <MakePricing/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/my-products" exact>
                                                <ProductsPage/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/create/product" exact>
                                                <MakeProduct new/>
                                            </PrivateRoute>
                                            <PrivateRoute path="/edit/product/:id">
                                                <MakeProduct />
                                            </PrivateRoute>

                                            <Permission rule="manageAdvertisements">
                                                <PrivateRoute exact path="/manage/verifications">
                                                    <ManageVerificationsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/advertisements">
                                                    <AdvertisementsPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/discounts">
                                                    <DiscountsPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/manage/businesses">
                                                    <BusinessesPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/business/:id">
                                                    <BusinessPage manage/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/edit/business/id/:id">
                                                    <BusinessPage manage by_id/>
                                                </PrivateRoute>
                                                <PrivateRoute exact path="/create/business">
                                                    <BusinessPage create manage/>
                                                </PrivateRoute>
                                            </Permission>
                                            <PlanRestriction rule="has_business_page">
                                                <PrivateRoute path="/business" exact>
                                                    <BusinessPage/>
                                                </PrivateRoute>
                                            </PlanRestriction>
                                            <PlanRestriction rule="has_ad">
                                                <PrivateRoute path="/my-advertisements" exact>
                                                    <AdvertisementsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/create/advertisement" exact>
                                                    <MakeAdvertisement new/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/edit/advertisement/:id">
                                                    <MakeAdvertisement />
                                                </PrivateRoute>
                                            </PlanRestriction>
                                            <PlanRestriction rule="has_discount">
                                                <PrivateRoute path="/my-discounts" exact>
                                                    <DiscountsPage/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/create/discount" exact>
                                                    <MakeDiscount new/>
                                                </PrivateRoute>
                                                <PrivateRoute path="/edit/discount/:id">
                                                    <MakeDiscount />
                                                </PrivateRoute>
                                                <PrivateRoute path="/purchased-discounts">
                                                    <BusinessVouchersPage />
                                                </PrivateRoute>
                                            </PlanRestriction>
                                            <PrivateRoute path="/my-orders">
                                                <UserOrdersPage />
                                            </PrivateRoute>
                                            <PrivateRoute path="/order/view/:id">
                                                <ViewOrder/>
                                            </PrivateRoute>
                                            <Feature check="marketplace">
                                                <Role role="supplier" type="normal">
                                                    <PrivateRoute path="/shop-orders">
                                                        <ShopOrdersPage />
                                                    </PrivateRoute>
                                                    <PrivateRoute path="/order/check/:id">
                                                        <CheckOrder/>
                                                    </PrivateRoute>
                                                </Role>
                                            </Feature>
                                            <Route  path="/404" >
                                                <Error404/>
                                            </Route>
                                        </Layout>
                                        {/*Errors Routes*/}
                                        <Route path="*" component={Error404} status={404}/>
                                    </Switch>
                                </Router>
                            </ErrorBoundary>
            )
        }

    }

}
const mstp = state => ({
    user: state.user,
    isAuth:state.isAuth,
    locale:state.locale,
    global_loading:state.global_loading
});
export default withStyles(Styles)(connect(mstp)(App));
