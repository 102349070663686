import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import ProfileImage from "../../fileManager/Components/ProfileImage";
import LoadingButton from "../../../components/elements/LoadingButton";
import {connect, getStore, setStore} from "trim-redux";
import LockOpenRoundedIcon from '@material-ui/icons/LockOpenRounded';
import Button from "@material-ui/core/Button/Button";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {checkMelliCode, updateRedux} from "../../../../config/Security";
import Typography from "@material-ui/core/Typography/Typography";
import GetConfirmationButton from "../../../components/elements/GetConfirmationButton";
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import SendMessageToUserButton from "../../admin/users/SendMessageToUserButton";
import Feature from "../../../components/elements/Feature";
import {translate} from "../../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../components/elements/FieldCounterWithoutBar";
import SubTitle from "../../../components/elements/SubTitle";
import SingleImageChooser from "../../fileManager/Components/SingleImageChooser";
import MetaDescription from "../../fileManager/Components/MetaDescription";
import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import PhilCoinHolder from "../../Dashboard/components/PhilCoinHolder";
class details extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            email:'',
            mobile:'',
            first_name:'',
            last_name:'',
            business_name:'',
            business_bio:'',
            invite_code:'',
        },
        avatar:null,
        logo:null,
        lock:true,
        errors:{},
        loading:true,
        SubmitLoading:false,
        confirmed:'',
        is_verified: false,
        is_discount_provider: false,
        mobile_confirmed:false,
        melli_card:null,
        coins:0,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/user/get/${manage ? user_id : this.props.user.id}`, config)
            .then(response => {
                this.setState({
                    fields:{
                        name: response.data.name,
                        first_name: response.data.first_name,
                        last_name: response.data.last_name,
                        email:response.data.email,
                        mobile:response.data.mobile,
                        business_name:!!response.data.business_name ? response.data.business_name : '',
                        business_bio:!!response.data.business_bio ? response.data.business_bio : '',
                        invite_code:!!response.data.invite_code ? response.data.invite_code : '',
                    },
                    mobile_confirmed: parseInt(response.data.mobile_confirmed),
                    loading:false,
                    logo: response.data.logo,
                    is_discount_provider: response.data.is_discount_provider,
                    is_verified: response.data.is_verified,
                    coins: response.data.coins,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
        });
    }
    handleValidation(callback){
        let { fields } = this.state;
        let {user} = this.props;
        let errors = {};
        let formIsValid = true;
        if(user.role.type !== "admin"){
            try {
                //first_name
                if(validator.isEmpty(fields.first_name)){
                    formIsValid = false;
                    errors['first_name'] = translate('emptyField');
                }else if(!validator.isByteLength(fields.first_name,{min:0, max:255})){
                    formIsValid = false;
                    errors['first_name'] = translate('maxLength255');
                }
                //last_name
                if(validator.isEmpty(fields.last_name)){
                    formIsValid = false;
                    errors['last_name'] = translate('emptyField');
                }else if(!validator.isByteLength(fields.last_name,{min:0, max:255})){
                    formIsValid = false;
                    errors['last_name'] = translate('maxLength255');
                }

            }catch (e) {
                console.log(e)
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,confirmed,mobile_confirmed,lock,is_verified,is_discount_provider,logo} = this.state;
        let data = new FormData();
        data.append('first_name', fields.first_name);
        data.append('last_name', fields.last_name);
        data.append('business_name', fields.business_name);
        data.append('business_bio', fields.business_bio);
        data.append('is_discount_provider',is_discount_provider ? 1 : 0);
        data.append('is_verified',is_verified ? 1 : 0);
        data.append('logo', !!logo ? logo.id : 1);
        data.append('confirmed', confirmed);
        data.append('mobile_confirmed', !!mobile_confirmed ?  1 : 0);
        if(!lock){
            data.append('mobile', fields.mobile);
            data.append('email', fields.email);
        }
        let loginToken = localStorage.getItem('ACT');
        let {manage,user_id} = this.props;
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/set/${manage ? user_id : this.props.user.id}`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                if(!manage){
                    this.setState({
                        loading:true,
                        lock:true
                    })
                    if(!lock){
                        window.location.reload();
                    }else{
                        updateRedux();
                        this.loadData();
                    }

                }else{
                    this.setState({
                        loading:true,
                        lock:true
                    })
                    this.loadData();
                }
            })
            .catch(error => {
                console.log(error.response);
                if(error.response.status === 422){
                    this.props.enqueueSnackbar(translate('anotherUserHasThisInfo'), {
                        variant: 'error',
                    });
                    this.setState({
                        loading:true,
                    })
                    this.loadData();
                }else{
                    this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                        variant: 'error',
                    });
                }
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    refreshStore(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/checkLogin/${getStore('locale')}`, config)
                .then(response => {
                    setStore({
                        isAuth : true,
                        user : response.data,
                    });

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                });
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: this.state.confirmed === "confirmed" ? "pending" : "confirmed",
        })
    }
    loginToUserAccount(){
        let {mobile_confirmed} = this.state;
        if(!!mobile_confirmed){
            this.setState({
                loading:true
            })
            let loginToken = localStorage.getItem('ACT');
            let {user_id} = this.props;
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.get(`${defaultVariabls.url}/loginToUserAccount/${user_id}`, config)
                .then(async response => {
                    await localStorage.setItem('ADMIN_ACT',localStorage.getItem('ACT'));
                    await localStorage.setItem('ACT',response.data.accessToken);
                    await updateRedux();
                    let user = await getStore('user')
                    let username = user.email;
                    let login_token = user.login_token;
                    await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${username}/${login_token}`);
                })
                .catch(error => {
                    this.setState({
                        loading:false
                    })
                });
        }else{
            this.props.enqueueSnackbar(translate('userShouldConfirmMobileFirst'), {
                variant: 'error',
            });
        }

    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        fields.mobile = mobile.replace("+0","+98");
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    handleLogoImage(logo){
        this.setState({
            logo
        })
    }
    handleChangeDiscountProvider(){
        this.setState({
            is_discount_provider: !this.state.is_discount_provider,
        })
    }
    handleChangeVerified(){
        this.setState({
            is_verified: !this.state.is_verified,
        })
    }
    render() {
        const {errors,fields,lock,SubmitLoading,coins,is_verified,is_discount_provider} = this.state;
        let {purchase,user,manage,user_id,primary_color} = this.props;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <Grid container spacing={2} justify="center">
                    {/*<Grid item xs={12} md={11} lg={10}>
                        {
                            !! user.role.permission.manageAdvertisements &&
                                <>
                                    <Alert
                                        style={{marginTop:15}}
                                        severity="info"
                                        action={
                                            <FormControlLabel
                                                style={{marginRight:10}}
                                                control={
                                                    <Switch
                                                        checked={is_verified}
                                                        onChange={this.handleChangeVerified.bind(this)}
                                                        value={is_verified}
                                                        color="primary"
                                                    />
                                                }
                                                label={is_verified ? translate('yes') : translate('no')}
                                            />
                                        }
                                    >
                                        {translate('isVerifiedDescription')}
                                    </Alert>
                                    <Alert
                                        style={{marginTop:15}}
                                        severity="info"
                                        action={
                                            <FormControlLabel
                                                style={{marginRight:10}}
                                                control={
                                                    <Switch
                                                        checked={is_discount_provider}
                                                        onChange={this.handleChangeDiscountProvider.bind(this)}
                                                        value={is_discount_provider}
                                                        color="primary"
                                                    />
                                                }
                                                label={is_discount_provider ? translate('yes') : translate('no')}
                                            />
                                        }
                                    >
                                        {translate('isDiscountProviderDescription')}
                                    </Alert>
                                </>

                        }
                    </Grid>*/}
                    <Grid item xs={12} md={11} lg={10}>
                    <Grid container  spacing={2} justify="center" alignItems="flex-start">
                        <CssBaseline/>
                        {
                            !!purchase ? null :
                                <Grid item xs={12} md={3} style={{textAlign:"center"}}>
                                    <Grid container spacing={2}>

                                        <Grid item xs={12}>
                                            {
                                                !!user.role.permission.manageSettings &&
                                                <>
                                                    <Feature check="login_to_user_account">
                                                        <Button
                                                            style={{marginBottom:20,marginTop:20,width:"100%"}}
                                                            startIcon={<LockOpenRoundedIcon/>}
                                                            variant="outlined"
                                                            color="primary"
                                                            onClick={() => this.loginToUserAccount()}
                                                        >
                                                            {translate('loginToUserAccount')}
                                                        </Button>
                                                    </Feature>
                                                    <Feature check="admin_message">
                                                        <SendMessageToUserButton user_id={user_id}/>
                                                    </Feature>
                                                </>
                                            }

                                            <ProfileImage manage={this.props.manage} user_id={this.props.user_id}/>

                                            <Typography style={{marginTop:15}}>{translate('invite_code')}: <br/><strong>{fields.invite_code}</strong></Typography>
                                        </Grid>
                                    </Grid>

                                </Grid>
                        }
                        <Grid item xs={12} md={9}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <PhilCoinHolder coins={coins}/>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        value={fields.first_name}
                                        fullWidth
                                        id="first_name"
                                        disabled={user.role.type !=="admin"}
                                        label={translate('first_name')}
                                        name="first_name"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['first_name']}
                                        helperText={errors['first_name']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        disabled={user.role.type !=="admin"}
                                        value={fields.last_name}
                                        fullWidth
                                        id="last_name"
                                        label={translate('last_name')}
                                        name="last_name"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['last_name']}
                                        helperText={errors['last_name']}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <MuiPhoneNumber
                                        required
                                        fullWidth
                                        id="mobile"
                                        label={translate('mobile')}
                                        name="mobile"
                                        margin="normal"
                                        onChange={this.handleChangeMobile.bind(this)}
                                        variant="outlined"
                                        disabled={lock}
                                        disableAreaCodes={lock}
                                        value={fields.mobile}
                                        InputProps={!!user.mobile_confirmed &&{
                                            endAdornment: (
                                                <React.Fragment>
                                                    <Tooltip title="Verified">
                                                        <IconButton color="primary">
                                                            <CheckCircleOutlineIcon/>
                                                        </IconButton>
                                                    </Tooltip>

                                                </React.Fragment>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <TextField
                                        variant="outlined"
                                        margin="normal"
                                        required
                                        value={fields.email}
                                        fullWidth
                                        id="email"
                                        disabled={lock}
                                        label={translate('email')}
                                        name="email"
                                        autoComplete="email"
                                        onChange={this.handleChange.bind(this)}
                                        error = {!!errors['email']}
                                        helperText={errors['email']}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {
                                        !!lock ?
                                            <Button
                                                onClick={() => this.setState({lock:false})}
                                                color="primary"
                                            >
                                                {translate('changeMobileOrEmail')}
                                            </Button>
                                            :
                                            <Typography classes={this.classes.condir}>{translate('changeMobileOrEmailDesc')}</Typography>
                                    }

                                </Grid>
                            </Grid>
                        </Grid>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {translate('setDetails')}
                        </LoadingButton>
                    </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));