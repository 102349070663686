import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import AppCardLoaderFields from "../../../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "../../../../../components/elements/LoadingButton";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";

class HidePriceField extends React.Component {
    state = {
        hide_prices: null,
        loading:true,
        SubmitLoading:false,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/settings/getAll`, config)
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        hide_prices: parseInt(response.data.hide_prices),
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleSubmit(){

        let loginToken = localStorage.getItem('ACT');
        let {hide_prices} =  this.state;
        await  this.setState({
            SubmitLoading:true
        })
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let data = new FormData();
        data.append('hide_prices', parseInt(hide_prices));
        await axios.post(`${defaultVariabls.url}/settings/saveHidePrices`,data, config)
            .then(response => {
                this.setState({
                    SubmitLoading:false
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false,
                    SubmitLoading:false

                })
            })
    }
    render(){
        let {loading,hide_prices,SubmitLoading} = this.state;
        return (
            <React.Fragment>
                {
                    loading ?
                        <AppCardLoaderFields/>
                        :
                        <Grid container spacing={2} className="mt-20" alignItems="center">
                            <Grid item xs={10} md={4}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={hide_prices}
                                            onChange={() => this.setState({hide_prices:!!hide_prices ? 0 : 1})}
                                            name="hide_prices"
                                        />
                                    }
                                    label={hide_prices ? Translate.pricesAreHidden : Translate.pricesAreShown}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {Translate.save}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(HidePriceField)));