import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import {Translate} from "../../../../../config/Translate";
import HelpOutlineRoundedIcon from '@material-ui/icons/HelpOutlineRounded';
import Typography from "@material-ui/core/Typography/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import {getStore} from "trim-redux";
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AlertDialogSlide() {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Button startIcon={<HelpOutlineRoundedIcon/>} color="secondary" variant="outlined" size="small" onClick={handleClickOpen}>
                {Translate.help}
            </Button>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{Translate.pricingDiferences}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        <Typography><strong style={{color: getStore('primary_color')}}>{Translate.simplePricing}:</strong> {Translate.simplePricingDesc}</Typography>
                        <Divider style={{marginTop:20,marginBottom:20}}/>
                        <Typography><strong style={{color: getStore('primary_color')}}>{Translate.variablePricing}:</strong> {Translate.variablePricingDesc}</Typography>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        {Translate.understood}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}