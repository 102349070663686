import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Typography from "@material-ui/core/Typography/Typography";
import validator from "validator";
import EditRoundedIcon from '@material-ui/icons/EditRounded';
import {translate} from "../../../../config/lang";
import MuiPhoneNumber from "material-ui-phone-number";
class ChangeMobileModal extends React.Component{
    state = {
        open: false,
        loading:true,
        submit_loading:false,
        error:false,
        mobile:'',
    };
    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
    }
    handleSend(redirect = false){
        this.setState({
            submit_loading:true
        })
        let{mobile} = this.state;
        let user = !!this.props.prop_user ? this.props.prop_user : this.props.user;

        if(!validator.isNumeric(mobile) || mobile.length <= 11){
           this.setState({
               error: translate('wrongMobile'),
               submit_loading:false
           })
        }else{
            let data = new FormData();
            data.append('mobile', mobile);
            data.append('old_mobile', user.mobile);
            data.append('user_id', user.id);
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/user-out/changeMobile`, data, config)
                .then(response => {
                    this.setState({
                        open: false,
                        loading:false,
                        submit_loading:false,
                        error:false,
                    });
                    this.props.setUser(response.data)
                })
                .catch(error => {
                    if(!!error.response){
                        if(error.response.status === 403){
                            this.props.enqueueSnackbar(translate('existMobile'), {
                                variant: 'error',
                            });
                        }else{
                            this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                                variant: 'error',
                            });
                        }
                    }else{
                        this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                            variant: 'error',
                        });
                    }
                    this.setState({
                        submit_loading:false,
                    });
                });
        }
    }
    handleChangeMobile(mobile){
        this.setState({
            mobile:mobile.replace("+0","+98")
        })
    }
    render(){
        let {mobile,submit_loading,open} = this.state;
        let user = !!this.props.prop_user ? this.props.prop_user : this.props.user;
        const {classes} = this.props;

        return (
            <>
                <Button
                    size="small"
                    color="primary"
                    onClick={() => this.toggleOpen()}
                >
                    {translate('editMobile')}
                </Button>
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            className={classes.modalCloseButton}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {translate('editMobile')}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            <Typography paragraph>
                                {translate('editMobileDesc')}
                                <strong dir="ltr">{user.mobile}</strong>
                                {translate('editMobileDesc2')}
                            </Typography>
                        </DialogContentText>
                        <MuiPhoneNumber
                            fullWidth
                            label={translate('mobile')}
                            name="mobile"
                            margin="normal"
                            value={mobile}
                            dir="ltr"
                            variant="outlined"
                            onChange={this.handleChangeMobile.bind(this)}
                            autoFocus
                            disableAreaCodes
                            defaultCountry={'ae'}
                        />
                    </DialogContent>
                    <DialogActions style={{justifyContent:"flex-end"}}>
                        <Button
                            onClick={() => this.handleSend()}
                            color="primary"
                            variant="outlined"
                            startIcon={submit_loading ? <CircularProgress size={20}/> : <EditRoundedIcon/>}
                        >
                            {translate('save')}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ChangeMobileModal)))));