import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../config/design/styles";
import {withRouter } from 'react-router-dom';
import { withSnackbar } from 'notistack';
import Card from "@material-ui/core/Card/Card";
import CardActionArea from "@material-ui/core/CardActionArea/CardActionArea";
import CardMedia from "@material-ui/core/CardMedia/CardMedia";
import CardContent from "@material-ui/core/CardContent/CardContent";
import Typography from "@material-ui/core/Typography/Typography";
import CardActions from "@material-ui/core/CardActions/CardActions";
import Button from "@material-ui/core/Button/Button";
import {defaultVariabls} from "../../../../config";
import {Translate} from "../../../../config/Translate";
import Link from "@material-ui/core/Link/Link";

class SingleWishlistItem extends React.Component {
    state = {
        product: this.props.product,
    }
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            product: this.props.product,
        })
    }

    render(){
        let{product} = this.state;
        return (
            <Card>
                <CardActionArea>
                    <CardMedia
                        image={`${defaultVariabls.uploads}/FileManager/${product.thumbnail.name}_medium.${product.thumbnail.extension}`}
                        title={product.name}
                        style={{height:150}}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="h2" className="wishlist_title only-one-line">
                            {product.name}
                        </Typography>
                    </CardContent>
                </CardActionArea>
                <CardActions style={{justifyContent:"space-between"}}>
                    <Button
                        size="small"
                        color="primary"
                        component={Link}
                        href={`${defaultVariabls.website}/product/${product.slug}`}
                    >
                        {Translate.viewProduct}
                    </Button>
                </CardActions>
            </Card>
        );
    }

}
export default withSnackbar(withRouter(withStyles(Styles)(SingleWishlistItem)));