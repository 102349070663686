import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function SubTitle(props) {
    return (
        <Typography style={props.style} component="h3" variant="subtitle1" className="subheading" color={props.color} align={props.align ? props.align : "center"} gutterBottom>
            {props.children} {props.required ? " *" : null}
        </Typography>
    );
}
