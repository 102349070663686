import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import AppAnimate from "../../components/AppAnimate";
import Grid from "@material-ui/core/Grid/Grid";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import SellerGuidLine from "./components/SellerGuidLine";
import OrdersChart from "../../components/charts/OrdersChart";
import SellerAnalyticsShort from "./components/SellerAnalyticsShort";
import BestSellingProducts from "./components/BestSellingProducts";


class SellerDashboard extends React.Component {
    classes = this.props.classes;
    render(){
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.dashboard}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                           <SellerGuidLine/>
                    </Grid>
                    <Grid item xs={12}>
                        <SellerAnalyticsShort/>
                    </Grid>
                    <Grid item xs={12}>
                        <BestSellingProducts/>
                    </Grid>
                    <Grid item xs={12}>
                        <OrdersChart seller/>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}

const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SellerDashboard)))));
