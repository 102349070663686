import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import FileManagerDialog from "./FileManagerDialog";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import {defaultVariabls} from "../../../../config";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import SubTitle from "../../../components/elements/SubTitle";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CancelIcon from '@material-ui/icons/Cancel';
import SubTitleLight from "../../../components/elements/SubTitleLight";

class SingleFileChooser extends React.Component {
    classes = this.props.classes;
    state={

        FileManagerPending: false,
        file: null,
    }
    componentDidMount(){
        this.loadData()
    }
    async loadData(){
        await this.setState({
            file:this.props.initial
        })
        await this.fileChanges();
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }

     setData = async (data) => {
        await this.setState({
            file:data
        })       
        
        await this.fileChanges();
        await this.toggleOperation();
    }
    async removeDataSheet(){
        await this.setState({
            file: null
        });
        this.fileChanges();
    }
    async fileChanges(){
        let {file} = this.state;
        await this.props.onChange(file)
    }
    render(){
        let {FileManagerPending,file} = this.state;
        let{title,required,disabled,print,type} = this.props;

        return(
            <React.Fragment>
                <Grid container spacing={2} direction="row" alignItems="center"  style={{minHeight:80}} >
                    <Grid item xs={12} className="mt-20">
                        <SubTitleLight align="left" style={{paddingTop:20,fontWeight:700}}>{title} {!!required &&
                        <span className={this.classes.requiredSign}>*</span>}</SubTitleLight>
                    </Grid>
                    {
                        !!file &&
                        <Grid item xs={12} md={11} style={{marginBottom:15,textAlign:'center'}}>
                            <Card style={{position:"relative"}}>
                                <CardContent>
                                    <a  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={`${defaultVariabls.uploads}/FileManager/${file.name}.${file.extension}`}>
                                        {
                                            file.type === 'image' ? <img src={`${defaultVariabls.uploads}/FileManager/${file.name}.${file.extension}`} className="fileChooser-image"/> : null
                                        }
                                        {
                                            file.type === 'document' ? <DescriptionTwoToneIcon fontSize="large" /> : null
                                        }
                                    </a>

                                    <br />
                                    <Typography>
                                        {/*<a  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={`${defaultVariabls.uploads}/FileManager/${file.name}.${file.extension}`}>
                                            {file.name}
                                        </a>*/}
                                        {
                                            !print &&
                                            <IconButton style={{position:"absolute",top:5,right:5}} size="small" onClick={() => this.removeDataSheet()} aria-label={Translate.removeImage}>
                                                <CancelIcon />
                                            </IconButton>
                                        }

                                    </Typography>
                                </CardContent>
                            </Card>
                        </Grid>
                    }
                    {
                        !disabled && !file && 
                        <Grid item xs={12}>
                            <Button
                                startIcon={<AddBoxRoundedIcon />}
                                color="secondary"
                                variant="outlined"
                                size="small"
                                onClick={this.toggleOperation.bind(this)}
                            >
                                {Translate.chooseFile}
                            </Button>
                        </Grid>
                        
                    }

                </Grid>

                {
                    FileManagerPending ? <FileManagerDialog fileType={!!type ? type : 'all'}  selection cancleOperation={this.toggleOperation} setData={this.setData}/> : null
                }
            </React.Fragment>
        )

    }
}
export default withStyles(Styles)(SingleFileChooser);