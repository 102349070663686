import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../components/elements/LoadingButton";
import AppAnimate from "../../../components/AppAnimate";
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Alert from "@material-ui/lab/Alert/Alert";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";
import {translate} from "../../../../config/lang";
import {MenuItem} from "@material-ui/core";
class Verification extends React.Component {

    constructor(props){
        super(props);
        this.ThumbChild = React.createRef();
        this.EditorChild = React.createRef();
    }
    state = {
        fields:{
            type:"paypal",
            credit_number:"",
            paypal_username:"",
        },
        credit_card_image: null,
        errors:{},
        loading: true,
        SubmitLoading: false,
        editMode: false,
        confirmed: true,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    handleCreditImage(id){
        let {errors} = this.state;
        errors['creditcard_image'] ='';
        this.setState({
            creditcard_image: id
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/user/getVerification`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            type:response.data.type,
                            credit_number:response.data.credit_number,
                            paypal_username:response.data.paypal_username,
                        },
                        credit_card_image: response.data.credit_card,
                        confirmed: parseInt(response.data.user.verified),
                        editMode:true,
                        loading:false
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        if(fields.type === "paypal"){
            if(!fields.paypal_username){
                formIsValid = false;
                errors['paypal_username'] = translate('emptyField');
            }
        }else{
            if(!fields.credit_number){
                formIsValid = false;
                errors['credit_number'] = translate('emptyField');
            }
        }
        
        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,credit_card_image,confirmed} = this.state;
        let data = new FormData();
        data.append('type', fields.type);
        data.append('paypal_username', fields.paypal_username);
        data.append('credit_number', fields.credit_number);
        data.append('creditcard_image', !!credit_card_image ? credit_card_image.id : null);
        data.append('confirmed',  confirmed ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/setVerification`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('verificationDataSets'), {
                    variant: 'success',
                });
                this.setState({
                    loading:true,
                    SubmitLoading: false,
                });
                this.loadData();


            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    render(){
        let {fields, errors,SubmitLoading,confirmed,credit_card_image,editMode} = this.state;
        let {user} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('verificationDetails')}>
                {

                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12}>
                            <AppCard
                                title={translate('verificationDetails')}
                                action={
                                    <BreadCrumbs singleLevel firstText={translate('verificationDetails')}/>
                                }>
                                {
                                    this.state.loading ? <AppCardLoaderFields/> :
                                        <Grid container spacing={2} justify="center" alignItems="center">
                                            <Grid item xs={12} md={11} lg={10} alignItems="center">
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12}>
                                                        {
                                                            !editMode ?
                                                                <Alert
                                                                    severity="info"
                                                                >
                                                                    {translate('verificationDescription')}
                                                                </Alert>
                                                                :
                                                                <>
                                                                    {
                                                                        !! user.role.permission.manageShops ?
                                                                            <>
                                                                                <Alert
                                                                                    severity="info"
                                                                                    action={
                                                                                        <FormControlLabel
                                                                                            style={{marginRight:10}}
                                                                                            control={
                                                                                                <Switch
                                                                                                    checked={confirmed}
                                                                                                    onChange={this.handleChangeConfirmed.bind(this)}
                                                                                                    value={confirmed}
                                                                                                    color="primary"
                                                                                                />
                                                                                            }
                                                                                            label={confirmed ? translate('confirmed') : translate('unConfirmed')}
                                                                                        />
                                                                                    }
                                                                                >
                                                                                    {translate('confirmationStatus')}
                                                                                </Alert>
                                                                            </>
                                                                            :
                                                                            confirmed ?
                                                                                <Alert  severity="success">
                                                                                    {translate('yourBankInfoIsActive')}
                                                                                </Alert>
                                                                                :
                                                                                <Alert severity="warning">
                                                                                    {translate('yourBankInfoIsInchecking')}
                                                                                </Alert>
                                                                    }
                                                                </>

                                                        }
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            name="type"
                                                            label={translate('payment_type')}
                                                            type="text"
                                                            value={fields.type}
                                                            onChange={this.handleChange.bind(this)}
                                                            error={!!errors['type']}
                                                            helperText={errors['type']}
                                                            select
                                                        >
                                                            <MenuItem value={'paypal'}>
                                                                {translate('paypal')}
                                                            </MenuItem>
                                                            <MenuItem value={'credit_card'}>
                                                                {translate('credit_card')}
                                                            </MenuItem>
                                                        </TextField>
                                                    </Grid>
                                                    {
                                                        fields.type === "paypal" ?
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    name="paypal_username"
                                                                    label={translate('paypal_username')}
                                                                    value={fields.paypal_username}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error = {!!errors['paypal_username']}
                                                                    helperText={errors['paypal_username']}
                                                                    className="insta-field"

                                                                />
                                                            </Grid> 
                                                            :
                                                            <Grid item xs={12}>
                                                                <TextField
                                                                    required
                                                                    fullWidth
                                                                    variant="outlined"
                                                                    name="credit_number"
                                                                    type="number"
                                                                    label={translate('credit_number')}
                                                                    value={fields.credit_number}
                                                                    onChange={this.handleChange.bind(this)}
                                                                    error = {!!errors['credit_number']}
                                                                    helperText={errors['credit_number']}
                                                                    className="insta-field"

                                                                />
                                                            </Grid>
                                                    }
                                                    <Grid item xs={12}>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="primary"
                                                            onClick = {this.handleSubmit.bind(this)}
                                                            loading = {SubmitLoading}
                                                        >
                                                            {translate('setDetails')}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                        </Grid>
                                }
                            </AppCard>
                        </Grid>
                    </Grid>
                }
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(Verification))));