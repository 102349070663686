import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../../config/Translate";
import {connect} from "trim-redux";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import FieldCounterWithoutBar from "../../../../components/elements/FieldCounterWithoutBar";
import Hidden from "@material-ui/core/Hidden/Hidden";
import LoadingButton from "../../../../components/elements/LoadingButton";
import SubTitle from "../../../../components/elements/SubTitle";
import Thumbnail from "../../../fileManager/Components/Thumbnail";

class AddBrandButton extends React.Component{
    state = {
        open: false,
        fields:{
            name:"",
            description:"",
        },
        logo: 1,
        errors:{},
        SubmitLoading: false,
        editMode:false

    };

    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }

        //Description
        if(fields.description !== null ) {
            if (fields.description.length > 150) {
                formIsValid = false;
                errors['description'] = Translate.maxLength150;
            }
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.someFieldsHasError, {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,id,editMode,logo} = this.state;
        let data = new FormData();
        data.append('name', fields.name);
        data.append('id', id ? id : 0);
        data.append('description', fields.description);
        data.append('logo', logo);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/brand/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? Translate.dataEdited : Translate.dataAdded, {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false,
                    open:false,
                });
                if(!this.state.editMode){
                    this.props.reload()
                }
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  Translate.dataEditingFailed : Translate.dataAddingFailed, {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleThumbImage(id){
        this.setState({
            logo: id
        })
    }
    render(){
        let {open,logo,fields,errors,SubmitLoading,editMode} = this.state;
        let {large} = this.props;
        return (
            <>
                {
                    large ?
                        <Tooltip arrow placement="top" title={Translate.makeBrand} aria-label={Translate.makeBrand}>
                            <Button
                                color="primary"
                                onClick={() => this.toggleOpen()}
                                size="small"
                                endIcon={<AddBoxRoundedIcon />}
                            >
                                {Translate.addNewBrand}
                            </Button>
                        </Tooltip>
                        :
                        <Tooltip arrow placement="top" title={Translate.makeBrand} aria-label={Translate.makeBrand}>
                            <Button
                                color="primary"
                                onClick={() => this.toggleOpen()}
                                size="small"
                                startIcon={<AddBoxRoundedIcon />}
                            >
                                {Translate.addNewBrand}
                            </Button>
                        </Tooltip>
                }
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="lg"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {Translate.addNewBrand}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2} justify="center">
                            <Grid container spacing={2} justify="center" alignItems="center">
                                <Grid item xs={12} md={3}>
                                    <Thumbnail rounded
                                               handleThumbImage={this.handleThumbImage.bind(this)}
                                               initial={logo}/>
                                </Grid>
                                <Grid item xs={12} md={9}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12}>
                                            <TextField
                                                margin="normal"
                                                required
                                                fullWidth
                                                variant="outlined"
                                                name="name"
                                                label={Translate.brandName}
                                                type="text"
                                                id="name"
                                                value={fields.name}
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['name']}
                                                helperText={errors['name']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid item xs={12}>
                                                <TextField
                                                    variant="outlined"
                                                    multiline
                                                    fullWidth
                                                    name="description"
                                                    label={Translate.seoDescription}
                                                    placeholder={Translate.seoDescription}
                                                    type="text"
                                                    id="description"
                                                    minRows={3}
                                                    maxRows={5}
                                                    value={fields.description}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['description']}
                                                    helperText={errors['description']}
                                                    InputProps={{
                                                        endAdornment: (
                                                            <InputAdornment position="end">
                                                                <FieldCounterWithoutBar length={fields.description.length} max={150}/>
                                                            </InputAdornment>
                                                        ),
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12}>
                                    <LoadingButton
                                        variant="contained"
                                        color="primary"
                                        onClick = {this.handleSubmit.bind(this)}
                                        loading = {SubmitLoading}
                                    >
                                        {Translate.makeBrand}
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AddBrandButton)))));