import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect, getStore} from "trim-redux";
import CoinImg from "../../../../files/img/coin.png"
import {translate} from "../../../../config/lang";

class PhilCoinHolder extends React.Component {
    classes = this.props.classes;

    render(){
        let {coins} = this.props;
        return (
            <div className={this.classes.coinHolder}>
                <img src={CoinImg} alt="coin"/>
                <span dir={getStore('locale') === "en" ? "ltr" : "rtl"}>{translate("yourTotalCoins")}: <strong>{coins}</strong></span>
            </div>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(PhilCoinHolder))));