import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import { withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import UsersLog from "./components/UsersLog";
import {connect} from "trim-redux";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import {translate} from "../../../../config/lang";
class UsersLogPage extends React.Component {
    classes = this.props.classes;
    render(){
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate("usersLog")}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard
                            title={translate("usersLog")}
                            action={
                                <BreadCrumbs singleLevel firstText={translate("usersLog")}/>
                            }>
                            <UsersLog page/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UsersLogPage))));