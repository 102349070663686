import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../config/Translate";
import Typography from "@material-ui/core/Typography/Typography";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "./LoadingButton";
import axios from "axios";
import {defaultVariabls} from "../../../config";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../config/design/styles";

class AddYourVariableIfYouCantFind extends React.Component{
    state= {
        open: false,
        SubmitLoading:false,
        category:this.props.category,
        variable: '',
        error: '',

    }
    async componentDidUpdate(prevProps){
        if(this.state.category !== this.props.category){
            this.setState({
                category: this.props.category
            })
            await this.props.reload();
        }
    }
    async handleSubmit(){
        await this.setState({
            SubmitLoading:true
        })
        let { variable,category} = this.state;
        let data = new FormData();
        data.append('name', variable);
        data.append('category', category);
        data.append('confirmed',0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/variable/makeByUsers`, data, config)
            .then(response => {
                this.setState({
                    variable:'',
                    error:'',
                    SubmitLoading:false,
                    open:false
                })
                this.props.reload();
            })
            .catch(error => {
                if(error.response.status === 401){
                    this.setState({
                        error: Translate.youCantAddMoreVariablesUntileConfirmation
                    })
                }else{
                    this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                        variant: 'error',
                    });
                }

                this.setState({
                    SubmitLoading: false,
                });
            });

    }
    handleChange(e){
        let target = e.target;
        let error = "";
        let variable = target.value;
        this.setState({
            variable,
            error
        });
    }
    render(){
        let {open,variable,error,SubmitLoading}= this.state
        return (
            <>
                <Typography>{/*{Translate.addYourvariableIfYouCatFind}*/}
                    <Button size="small" color="primary" onClick={() => this.setState({open:true})}>
                        {Translate.addVariable}
                    </Button>
                </Typography>

                <Dialog maxWidth='sm' fullWidth open={open} onClose={() => this.setState({open:false})} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{Translate.addVariable}</DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {Translate.addYourvariableIfYouCatFind}
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="normal"
                            fullWidth
                            variant="outlined"
                            required
                            value={variable}
                            label={Translate.variableName}
                            name="variable"
                            onChange={this.handleChange.bind(this)}
                            error = {!!error}
                            helperText={error}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.setState({open:false})} color="primary">
                            {Translate.cancel}
                        </Button>
                        <LoadingButton
                            variant="outlined"
                            color="primary"
                            onClick={this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {Translate.addVariable}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(AddYourVariableIfYouCantFind)));