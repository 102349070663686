import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {connect, setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import SearchRoundedIcon from '@material-ui/icons/SearchRounded';
import SubTitle from "../../components/elements/SubTitle";
import Button from "@material-ui/core/Button/Button";
import AppCardLoaderFields from "../Skeletons/AppCardLoaderFields";
import FieldCounterWithoutBar from "../elements/FieldCounterWithoutBar";
import {translate} from "../../../config/lang";

class NewsCategoryHolderPro extends React.Component {
    state = {
        categories: null,
        categoriesDef: null,
        searchedCategories: null,
        loading:true,
        output:[],
        input:[],
        defaults: [],
        search:"",
        show:false,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.setState({
            input: this.props.inPut
        })
        if(this.props.edit === undefined){
            this.handleShow();
        }
    }
    async handleShow(){
        this.setState({
            show:true
        })
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/news_categories/QuickViewRecursive/${this.props.fake_locale}`, config)
                .then(response => {
                    this.setState({
                        categories: response.data,
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
            await axios.get(`${defaultVariabls.url}/news_categories/view/${this.props.fake_locale}`, config)
                .then(async response => {
                    await this.setState({
                        categoriesDef: response.data,
                    })
                    await this.loadDefaults();
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async loadDefaults(){
        let {categoriesDef,input} = this.state;
        if(input !== undefined){
            let temp =[];
            if(!!categoriesDef){
                await categoriesDef.map((item,index) =>{
                    return this.state.input.indexOf(item.id) > -1 ? temp.push(index) : null
                })
            }
            let defaults = []
            await temp.map(row => {
                defaults.push(categoriesDef[row].id)
                return true
            })
            await this.setState({
                defaults,
                output:defaults,
                loading:false
            })
        }else{
            await this.setState({
                defaults:[],
                loading:false,
            })
        }
    }
    async handChange(value){
        let output = [];
        await value.map(row => {
            output.push(row.id)
            return true
        })
        await this.setState({output})
        await this.props.outPut(output);
    }
    async handleChangeCheckbox(id){
        let {output} = this.state;
        let index = output.indexOf(id)
        if(index > -1){
            output.splice(index, 1);
        }else{
            output.push(id);
        }
        this.props.outPut(output);
        await this.setState({
            output
        })
    }
    handleChangeSearch(e){
        let text = e.target.value;
        if(text === ""){
            this.setState({
                searchedCategories:null
            })
        }else{
            let result = [];
            let categories = this.state.categories;
            categories.map((category) => (
                // eslint-disable-next-line
                category.name.search(text) !== -1 ? result.push(category) :
                    category.children_recursive.map((category2) => (
                        // eslint-disable-next-line
                        category2.name.search(text) !== -1 ? result.push(category) :
                            category2.children_recursive !== undefined ?
                                category2.children_recursive.map((category3) => (
                                    category3.name.search(text) !== -1 ? result.push(category) : null
                                )) : null
                    ))

            ))
            this.setState({
                searchedCategories:result
            })
        }

    }
    render(){
        let {categories,searchedCategories,loading,output,show} = this.state;
        if(searchedCategories !== null){
            categories = searchedCategories
        }
        return (
            <React.Fragment>
                {
                    show ?
                    loading ?
                        <AppCardLoaderFields/>
                        :
                        <Grid container spacing={2} direction="row">
                            <Grid item xs={12} style={{paddingBottom:0}}>
                                <SubTitle style={{marginBottom:0}} align="left">{translate('categories')}</SubTitle>
                            </Grid>
                            <Grid item xs={12} style={{paddingTop:0}}>
                                <TextField
                                    label= {translate('search')}
                                    fullWidth
                                    margin="normal"
                                    variant="outlined"
                                    size="small"
                                    name="search"
                                    InputProps={{
                                        startAdornment: <SearchRoundedIcon />
                                    }}
                                    onChange={this.handleChangeSearch.bind(this)}

                                />
                                {this.state.search}
                                <List dense disablePadding className="categoryHolderPro">
                                    {
                                        categories.map((category,index) => (
                                            <React.Fragment>
                                            <ListItem key={index} dense button onClick={() => this.handleChangeCheckbox(category.id)}>
                                                <ListItemIcon>
                                                    <Checkbox
                                                        edge="start"
                                                        checked={output.indexOf(category.id) !== -1}
                                                        name={category.name}
                                                        disableRipple
                                                    />
                                                </ListItemIcon>
                                                <ListItemText  className="categoryParent"  primary={category.name} />
                                            </ListItem>
                                                {
                                                    category.children_recursive.map((item,index2)=>(
                                                        <React.Fragment>
                                                            <ListItem key={index2} dense button onClick={() => this.handleChangeCheckbox(item.id)}>
                                                                <ListItemIcon>
                                                                    <Checkbox
                                                                        edge="start"
                                                                        checked={output.indexOf(item.id) !== -1}
                                                                        name={item.name}
                                                                        disableRipple
                                                                    />
                                                                </ListItemIcon>
                                                                <ListItemText  primary={"-- " + item.name} />
                                                            </ListItem>
                                                            {
                                                                item.children_recursive !== undefined ?
                                                                    item.children_recursive.map((element,index3)=>(
                                                                    <ListItem key={index3} dense button onClick={() => this.handleChangeCheckbox(element.id)}>
                                                                        <ListItemIcon>
                                                                            <Checkbox
                                                                                edge="start"
                                                                                checked={output.indexOf(element.id) !== -1}
                                                                                name={element.name}
                                                                                disableRipple
                                                                            />
                                                                        </ListItemIcon>
                                                                        <ListItemText  primary={"-- -- " + element.name} />
                                                                    </ListItem>
                                                                )) : null
                                                            }
                                                        </React.Fragment>
                                                    ))
                                                }
                                            </React.Fragment>


                                        ))
                                    }
                                </List>

                            </Grid>
                            <Grid item xs={12}>
                                <FieldCounterWithoutBar length={output.length} max={6}/>
                            </Grid>
                        </Grid> :
                        <Grid container spacing={2} direction="row" className={this.classes.GridAsaPaper} style={{marginTop:40,paddingBottom:20}}>
                            <Grid item xs={12}>
                                <SubTitle style={{marginBottom:0}} align="left">{translate('categories')}</SubTitle>
                                <br/>
                                <Button color="primary" variant="outlined" onClick={this.handleShow.bind(this)}>{translate('edit')}</Button>
                            </Grid>
                        </Grid>

                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    fake_locale: state.fake_locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(NewsCategoryHolderPro))));
