import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../config/Translate";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect, setStore} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import PlaylistAddCheckRoundedIcon from '@material-ui/icons/PlaylistAddCheckRounded';
class ReferenceButton extends React.Component{
    state = {
        open: false,
        loading:true,
        submit_loading:false,
        selected_plan:0,
        plans: [],
        error:false,
    };
    componentDidMount(){
        this.loadData()
    }
    componentDidUpdate(prevProps){
        if(this.state.submit_loading !== this.props.loading){
            this.setState({
                submit_loading: this.props.loading
            })
        }
    }
    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/plans/getAll`, config)
                .then(response => {
                    this.setState({
                        plans: response.data,
                        loading:false
                    })
                }).catch(error => {
                    console.log(error)

                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    handleSend(){
        let {selected_plan} = this.state;
        if(!!selected_plan){
            this.props.sendPlan(selected_plan);
            this.setState({
                open:false
            })
        }else{
            this.setState({
                error:true
            })
        }

    }
    render(){
        let {open,plans,loading,submit_loading} = this.state;
        let {disabled} = this.props;
        return (
            <>
                <Button
                    variant="outlined"
                    color="primary"
                    disabled={disabled}
                    onClick={() => this.toggleOpen()}
                    startIcon={<PlaylistAddCheckRoundedIcon/>}
                    size={"small"}
                >
                    {Translate.sendPlan}
                </Button>
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {Translate.sendPlan}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            {Translate.sendPlanDesc}
                        </DialogContentText>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            select
                            value={this.state.selected_plan}
                            label={Translate.plans}
                            onChange={(e) => this.setState({selected_plan: e.target.value,error:false,})}
                            InputProps={loading &&{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <CircularProgress size={20}/>
                                    </InputAdornment>
                                ),
                            }}
                            error={this.state.error}
                        >
                            <MenuItem value={0}>{Translate.choosePlan}</MenuItem>
                            {
                                !!plans.length ?
                                    plans.map((plan,index)=>(
                                        <MenuItem key={index} value={plan.id}>{plan.name}</MenuItem>
                                    ))
                                    :
                                    <MenuItem value={null}>{Translate.noPlanFound}</MenuItem>

                            }
                        </TextField>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => this.handleSend()}
                            color="primary"
                            variant="outlined"
                            startIcon={submit_loading ? <CircularProgress size={20}/> : <PlaylistAddCheckRoundedIcon/>}
                        >
                            {Translate.sendPlan}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ReferenceButton)))));