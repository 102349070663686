import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {withRouter,Link} from "react-router-dom";
import { withSnackbar } from 'notistack';
import AppAnimate from "../../components/AppAnimate/index";
import AppCard from "../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import Typography from "@material-ui/core/Typography/Typography";
import SubTitle from "../../components/elements/SubTitle";
import {currencySymbol, price} from "../../../config/values";
import Divider from "@material-ui/core/Divider/Divider";
import MyAddresses from "../user/address/MyAddresses";
import TextField from "@material-ui/core/TextField/TextField";
import LocalActivityRoundedIcon from '@material-ui/icons/LocalActivityRounded';
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import CheckRoundIcon from '@material-ui/icons/CheckRounded';
import LoadingButton from "../../components/elements/LoadingButton";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import SingleCartItemForAddress from "./components/SingleCartItemForAddress";
import {translate} from "../../../config/lang";

class CreateOrder extends React.Component {

    state = {
        carts:[],
        loading: true,
        SubmitLoading: false,
        total_price:null,
        total_count:null,
        total_discount:null,
        total_discount_code_price:null,
        transfer_price:0,
        address:null,
        discount:null,
        discount_code:'',
        discount_loading:false,
        no_discount:false,
        check_address_result:[],
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
        window.scrollTo(0, 0)
    }

    async loadData(){
        let {discount,address} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let url = !!discount ? `/get_my_cart/${!!address ? address : 0}/${discount.id}` : `/get_my_cart/${!!address ? address : 0}`;
            await axios.get(`${defaultVariabls.url}${url}`, config)
                .then(response => {
                    if(!response.data.total_price) this.props.history.push('/dashboard');
                    this.setState({
                        carts:response.data.carts,
                        total_price:response.data.total_price,
                        total_discount_code_price:response.data.total_discount_code_price,
                        total_discount:response.data.total_discount,
                        total_count:response.data.total_count,
                        transfer_price:response.data.transfer_price,
                        loading: false,
                    });
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleChangeAddress(address){
        await this.setState({
            address
        })
        await this.checkAddress();
        await this.loadData();
    }
    async checkAddress(){
        let {address} =this.state;
        if(!!address){
            await this.setState({
                check_address_result:[],
            });
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('address', address);
            await axios.post(`${defaultVariabls.url}/check_address`,data, config)
                .then(response => {
                    this.setState({
                        check_address_result:response.data,
                    });
                }).catch(error => {
                    console.log(error.response)
                })
        }

    }
    async checkDiscountCode(){
        let {discount_code} =this.state;
        if(!!discount_code){
            this.setState({
                discount_loading:true
            });
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('discount', discount_code);
            await axios.post(`${defaultVariabls.url}/discount/check`,data, config)
                .then(response => {
                    this.setState({
                        discount:response.data,
                        discount_loading:false,
                        no_discount:false,
                    });
                    this.loadData();
                }).catch(error => {
                    this.setState({
                        discount_loading:false,
                        no_discount:true,
                    })
                    this.loadData();
                })
        }

    }
    async handleRemoveDiscount(){
        await this.setState({
            discount_loading:false,
            no_discount:false,
            discount:null,
            discount_code:'',
        });
       await this.loadData();
    }
    handleRequest(){
        let { address,discount} = this.state;
        let data = new FormData();
        data.append('address',address);
        data.append('discount',!!discount ? discount.id : '');

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/factor/make`, data, config)
            .then(response => {
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.history.push(`/order/view/${response.data.id}`)
            })
            .catch(error => {
                console.log(error.response)
                if(error.response.status === 403){
                    if(error.response.data === "address_error"){
                        this.props.enqueueSnackbar(Translate.addressError, {
                            variant: 'warning',
                        });
                        this.checkAddress();
                    }else if(error.response.data === "user_role"){
                        this.props.enqueueSnackbar(Translate.onlyUsersCanPurchase, {
                            variant: 'error',
                        });
                    }
                }else{
                    this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                        variant: 'error',
                    });
                }

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });
    }
    handleValidation(callback){
        let { address } = this.state;
        let errors = {};
        let formIsValid = true;

        //address
        if(!address){
            formIsValid = false;
        }
        if(!formIsValid){
            this.props.enqueueSnackbar(Translate.PleaseChooseAnAddressToContinue, {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    reload(){
        this.setState({
            loading:true
        })
        this.loadData();
    }
    render(){
        let {SubmitLoading,loading,discount_code,check_address_result,transfer_price,total_price,no_discount,total_discount,total_discount_code_price,discount,total_count,discount_loading} = this.state;
        let {primary_color} = this.props;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={Translate.create_order}>
                    <Grid container spacing={2} justify="center">
                        <Grid item xs={12} md={8} lg={9}>
                            <Grid container spacing={2} justify="center">
                                <Grid item xs={12}>
                                    <AppCard
                                        title={Translate.create_order}
                                        action={
                                            <BreadCrumbs singleLevel firstText={Translate.create_order}/>
                                        }>
                                        {
                                            loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}  alignItems="center" style={{marginBottom:10}}>
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{Translate.pleaseChooseYourAddress}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12}>
                                                        <MyAddresses
                                                            choose
                                                            onChange={(address) => this.handleChangeAddress(address)}
                                                        />
                                                    </Grid>
                                                    {
                                                        !!check_address_result.length &&
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <SubTitle style={{color:'#f82e4e'}} align="left">{Translate.belowProductsHasAddressError}</SubTitle>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    {
                                                                        check_address_result.map((item,index) =>(
                                                                            <SingleCartItemForAddress
                                                                                key={index}
                                                                                cart={item}
                                                                                reload={() => this.reload()}
                                                                            />
                                                                        ))
                                                                    }
                                                                </Grid>
                                                            </>
                                                    }
                                                    <Grid item xs={12}>
                                                        <SubTitle align="left">{Translate.input_your_discount_code}</SubTitle>
                                                    </Grid>
                                                    <Grid item xs={12} md={6}>
                                                        <TextField
                                                            variant="outlined"
                                                            value={discount_code}
                                                            fullWidth
                                                            label={Translate.discount_code}
                                                            name="discount_code"
                                                            onBlur={() => this.checkDiscountCode()}
                                                            onChange={(e) => this.setState({discount_code:e.target.value})}
                                                            helperText={!!no_discount && Translate.no_discount}
                                                            InputProps={{
                                                                startAdornment: (
                                                                    <InputAdornment position="start">
                                                                        {
                                                                            discount_loading ?
                                                                                <CircularProgress size={18}/>
                                                                                :
                                                                                <LocalActivityRoundedIcon color={!!discount ? "secondary" : no_discount ? "error" :  'inherit'}/>
                                                                        }
                                                                    </InputAdornment>
                                                                ),
                                                                endAdornment: (
                                                                    <>
                                                                    {
                                                                        !!discount ?
                                                                        <InputAdornment position="end">
                                                                            <IconButton onClick={() => this.handleRemoveDiscount()}>
                                                                                <CloseRoundedIcon/>
                                                                            </IconButton>
                                                                        </InputAdornment>
                                                                            :
                                                                            !!discount_code.length &&
                                                                            <InputAdornment position="end">
                                                                                <IconButton>
                                                                                    <CheckRoundIcon/>
                                                                                </IconButton>
                                                                            </InputAdornment>
                                                                    }
                                                                    </>

                                                                ),
                                                            }}
                                                        />
                                                    </Grid>
                                                    {
                                                        !!discount &&
                                                        <Grid item xs={12} md={6}>
                                                            {
                                                                !!discount.id &&
                                                                <Typography>{Translate.discount_code} <strong style={{color:primary_color}}>{price(discount.value)} {discount.type === "fixed" ? currencySymbol : '%'}</strong> {Translate.assignedToYou}</Typography>
                                                            }
                                                            {
                                                                <>
                                                                    {
                                                                        !!discount.category_id &&
                                                                        <Typography>{Translate.discount_onlyForThisCategory} <strong style={{color:primary_color}}>{discount.category.name}</strong>  {Translate.discount_end_sentence}</Typography>

                                                                    }
                                                                    {
                                                                        !!discount.shop_id &&
                                                                        <Typography>{Translate.discount_onlyForThisShop} <strong style={{color:primary_color}}>{discount.shop.name}</strong> {Translate.discount_end_sentence}</Typography>

                                                                    }
                                                                </>
                                                            }
                                                        </Grid>
                                                    }
                                                    <Grid item xs={12} style={{display:"flex",justifyContent:"flex-end"}}>
                                                        <Button
                                                            color="primary"
                                                            size="small"
                                                            variant="outlined"
                                                            component={Link}
                                                            to="/checkout"
                                                            startIcon={<ChevronRightRoundedIcon/>}
                                                        >
                                                            {Translate.backToPrevPage}
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4} lg={3}>
                            <Grid container spacing={2} alignItems="center" justify="center" className="sticky-grid">
                                <Grid item xs={12}>
                                    <AppCard
                                        title={Translate.bill}

                                    >
                                        {
                                            loading ? <AppCardLoaderFields/> :
                                                <Grid container spacing={2}>
                                                    <Grid item xs={12} className="mt-15">
                                                        <Typography
                                                            style={{color: "#737373"}}>{Translate.totalPrice} ({total_count}) <span
                                                            className="float-left">{price(total_price + total_discount) + " " + currencySymbol}</span></Typography>
                                                    </Grid>
                                                    {
                                                        !!total_discount_code_price &&
                                                        <Grid item xs={12}>
                                                            <Typography
                                                                style={{color: "#80b96f"}}>{Translate.discount}<span
                                                                className="float-left">{price(total_discount_code_price) + " " + currencySymbol}</span></Typography>
                                                        </Grid>
                                                    }
                                                    {
                                                        !!transfer_price &&
                                                        <Grid item xs={12}>
                                                            <Typography>
                                                                {Translate.transferPrice}
                                                                <span className="float-left">{price(transfer_price) + " " + currencySymbol}</span>
                                                            </Typography>
                                                        </Grid>
                                                    }

                                                    <Grid item xs={12}>
                                                        <Typography>{Translate.totalCartPrice} <strong
                                                            className="float-left">{price(total_price + transfer_price - total_discount_code_price) + " " + currencySymbol}</strong></Typography>
                                                    </Grid>
                                                    {
                                                        !!(total_discount+total_discount_code_price) &&
                                                            <>
                                                                <Grid item xs={12}>
                                                                    <Divider/>
                                                                </Grid>
                                                                <Grid item xs={12}>
                                                                    <Typography color="secondary">{Translate.totalDiscounts} <strong
                                                                        className="float-left">{price(total_discount + total_discount_code_price) + " " + currencySymbol}</strong></Typography>
                                                                </Grid>
                                                            </>
                                                    }
                                                    <Grid item xs={12} className="mt-15">
                                                        <Typography style={{marginBottom:0,fontSize:12,textAlign:"center"}}>
                                                            {translate('tax_notic')}
                                                        </Typography>
                                                        <LoadingButton
                                                            variant="contained"
                                                            color="secondary"
                                                            styleForParent={{marginBottom:10}}
                                                            style={{width: "100%"}}
                                                            loading={SubmitLoading}
                                                            onClick = {this.handleSubmit.bind(this)}
                                                        >
                                                            {Translate.makeOrder}
                                                        </LoadingButton>
                                                    </Grid>
                                                </Grid>
                                        }
                                    </AppCard>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
            </AppAnimate>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(CreateOrder))));