import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import AppAnimate from "../../components/AppAnimate";
import Grid from "@material-ui/core/Grid/Grid";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import UserGuidLine from "./user_components/UserGuidLine";
import UserDashboardConfirmation from "./components/UserDashboardConfirmation";
import {makeSlug, translate} from "../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import {defaultVariabls} from "../../../config";
import Alert from "@material-ui/lab/Alert/Alert";
import AnalyticsShort from "./user_components/AnalyticsShort";
import ViewYourOrders from "./components/ViewYourOrders";
import PhilCoinHolder from "./components/PhilCoinHolder";
import Popover from "@material-ui/core/Popover/Popover";
import InvitationArea from "../../components/layouts/components/UserInfo/InvitationArea";
import {Icon} from "@material-ui/core";


class UserDashboard extends React.Component {
    classes = this.props.classes;
    state = {
        anchorEl:null
    }
    handleClick = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };
    handleClose = () => {
        this.setState({
            anchorEl: null
        })
    };
    render(){
        let {user} = this.props;
        let {anchorEl} = this.state;
        const open = Boolean(anchorEl);
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('dashboard')}>
                <Grid container spacing={2} justify="center">
                    <UserDashboardConfirmation/>
                    <Grid item xs={10} lg={4}>
                        <PhilCoinHolder coins={user.coins}/>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <Alert
                            severity="info"
                            action={
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    component="a"
                                    href={makeSlug('plans')}
                                >
                                    {translate('plansPage')}
                                </Button>
                            }
                        >
                            {translate('forPurchasingBetterPlansAdsAndReserveGoToPlans')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <Alert
                            severity="info"
                            variant="filled"
                            style={{
                                background:"#ebe0ff",
                                color:this.props.primary_color
                            }}
                            icon={
                                <Icon className='material-symbols-outlined' >redeem</Icon>
                            }
                            action={
                            <>
                                <Button
                                    size="small"
                                    color="primary"
                                    variant="contained"
                                    onClick={this.handleClick.bind(this)}
                                >
                                    {translate('sendInvitation')}
                                </Button>
                                <Popover
                                    open={open}
                                    anchorEl={anchorEl}
                                    onClose={this.handleClose.bind(this)}
                                >
                                    <InvitationArea/>
                                </Popover>
                            </>

                            }
                        >
                            {translate('invitationText')}
                        </Alert>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <ViewYourOrders/>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <UserGuidLine/>
                    </Grid>
                    <Grid item xs={12} lg={11}>
                        <AnalyticsShort/>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}

const mstp = state => ({
    user: state.user,
    primary_color: state.primary_color,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(UserDashboard)))));
