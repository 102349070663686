import {create} from "jss";
import rtl from "jss-rtl";
import IranSans from "../../files/fonts/IRANSansWeb.woff2";
import { createTheme } from '@material-ui/core/styles';
import {faIR,enUS} from "@material-ui/core/locale";
import React from "react";
import { StylesProvider, jssPreset } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import {getStore} from "trim-redux";

const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const iranSans = {
    fontFamily: 'IranSans',
    fontStyle: 'normal',
    fontDisplay: 'swap',
    fontWeight: 400,
    src: `
    local('IranSans'),
    local('IranSans'),
    url(${IranSans}) format('woff2')`
};
const breakpoints = createBreakpoints({});
export function RTL(props) {
    return (
        <StylesProvider jss={jss}>
            {props.children}
        </StylesProvider>
    );
}
export const theme = (locale_main) => {

    let mode = getStore('mode');
    return createTheme({
        palette: {
            type: mode,
            primary: {
                main: mode === "dark" ? "#ad55ca" : "#4d0267",
            },
            secondary: {
                main: "#fee800",
            },
            thirdColor: {
                main: '#9bd093'
            },
            fourthColor:{
                main: "#613de9"
            },
            sidebar: {
                bgColor: '#313541',
                textColor: '#808183',
            },
            gray: {
                50: '#fafafa',
                100: '#f7fafc',
                200: '#edf2f7',
                300: '#E0E0E0',
                400: '#c5c6cb',
                500: '#A8A8A8',
                600: '#666666',
                700: '#4a5568',
                800: '#201e21',
                900: '#1a202c',
                A100: '#d5d5d5',
                A200: '#aaaaaa',
                A400: '#303030',
                A700: '#616161',
            },
        },
        status: {
            danger: 'orange',
        },
        divider: 'rgba(0, 0, 0, 0.12)',
        typography: {
            fontFamily: 'IranSans, Tahoma' ,
        },
        overrides: {
            MuiCssBaseline: {
                '@global': {
                    '@font-face': [iranSans],
                },
            },
            MuiTypography: {
                h1: {
                    fontSize: 22,
                },
                h2: {
                    fontSize: 20,
                },
                h3: {
                    fontSize: 18,
                },
                h4: {
                    fontSize: 16,
                },
                h5: {
                    fontSize: 14,
                },
                h6: {
                    fontSize: 14,
                },
                subtitle1: {
                    fontSize: 14,
                },
                subtitle2: {
                    fontSize: 16,
                },
                body1: {
                    fontSize: 14,
                },
                body2: {
                    fontSize: 12,
                },
            },
            MuiToggleButton: {
                root: {
                    borderRadius: 5,
                },
            },
            MuiCardLg: {
                root: {
                    borderRadius:5
                },
            },
            MuiCard: {
                root: {
                    borderRadius: 5,
                    boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
                    '& .MuiCardContent-root:last-child': {
                        paddingBottom: 16,
                    },
                },
            },
            MuiButton: {
                root: {
                    borderRadius: 5,
                    boxShadow: '0px 5px 6px rgba(0, 0, 0, 0.04)',
                    [breakpoints.down('md')]: {
                        paddingTop: '8px !important',
                        paddingBottom: '8px !important',
                    },
                },
            },
/*            MuiInputBase:{
                root:{
                    direction:locale_main === "fa" ? 'rtl' : "ltr"
                }
            }*/
        },
        direction: locale_main === "fa" ? 'rtl' : "ltr",
    }, locale_main === "fa" ? faIR : enUS);
}