import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import {translate} from "../../../../config/lang";
import AppCard from "../../../components/layouts/components/AppCard";
import AppAnimate from "../../../components/AppAnimate";
import Waiting from "../../../../files/img/steps/waiting.png";
import Typography from "@material-ui/core/Typography";
import Title from "../../../components/elements/Title";
class details extends React.Component {
    classes = this.props.classes

    render() {

        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('step4')}>
                <Grid container spacing={2} justifyContent="center">
                    <Grid item xs={12} lg={11}>
                        <AppCard>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <div className="waiting-container">
                                        <Title>{translate('yourInfoIsUnderChecking')}</Title>
                                        <img src={Waiting} alt="waiting"/>
                                        <Typography>{translate('yourInfoIsUnderCheckingDesc')}</Typography>
                                    </div>
                                </Grid>
                            </Grid>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(details))));