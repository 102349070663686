import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import {defaultVariabls} from "../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import {connect} from "trim-redux";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MoneyOffRoundedIcon from '@material-ui/icons/MoneyOffRounded';
import {currencySymbol, price} from "../../../../config/values";
import Link from "@material-ui/core/Link/Link";
import FeaturedPlayListOutlinedIcon from '@material-ui/icons/FeaturedPlayListOutlined';
import StorefrontOutlinedIcon from '@material-ui/icons/StorefrontOutlined';
import LocalShippingOutlinedIcon from '@material-ui/icons/LocalShippingOutlined';
import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined';
import Button from "@material-ui/core/Button/Button";
import axios from "axios";
class SingleCartItem extends React.Component {

    state = {
        cart:this.props.cart,
    };
    classes = this.props.classes;
    async handleDeleteItem(){
        let {cart} =this.state;
        if(!!cart){
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            let data = new FormData();
            data.append('id', cart.id);
            await axios.post(`${defaultVariabls.url}/remove_from_cart`,data, config)
                .then(response => {
                    this.props.reload();
                }).catch(error => {
                    console.log(error.response)
                })
        }
    }
    render(){
        let {pricing,product} = this.state.cart;
        let {cart} = this.state;

        return (
            <div style={{border:"2px solid #f82e4e",marginBottom:10,borderRadius:4,backgroundColor:'#fff0f0'}}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={2} lg={2} className="text-center">
                    <Link href={`${defaultVariabls.website}/product/${product.slug}`} target="_blank">
                    <img
                        src={`${defaultVariabls.uploads}/FileManager/${product.thumbnail.name}_small.${product.thumbnail.extension}`}
                        alt="img"
                        className="single-order-items-img"
                    />
                    </Link>
                </Grid>
                <Grid item xs={6} md={5} lg={5} className="single_order_items">
                    <Typography style={{marginBottom:10}}>
                        <IconButton size="small"><FeaturedPlayListOutlinedIcon/></IconButton>{Translate.product}: <strong>{product.name} {!!pricing.value_id ? " ("+pricing.value.attribute.name+" "+(pricing.value.attribute.type === "color" ? pricing.value.description : pricing.value.name) +") " : ''}</strong></Typography>
                    <Typography  style={{marginBottom:10}}><IconButton size="small"><StorefrontOutlinedIcon/></IconButton>{Translate.shop}: <strong>{pricing.shop.name}</strong></Typography>
                    <Typography  style={{marginBottom:10}}><IconButton size="small"><MonetizationOnOutlinedIcon/></IconButton>{Translate.price}: <strong>{price(pricing.price*cart.quantity)+" "+currencySymbol}</strong> <span style={{color:this.props.primary_color,fontSize:11}}>({cart.quantity+" "+Translate.number})</span></Typography>

                </Grid>
                <Grid item xs={6} md={5} lg={5} className="single_order_items buttons_holder">
                    {
                        !!cart.has_other_seller &&
                            <Button
                                size="small"
                                color="primary"
                                variant="contained"
                                style={{minWidth:160}}
                                component="a"
                                href={`${defaultVariabls.website}/product/${cart.product.slug}`}
                            >
                                {Translate.viewOtherSellers}
                            </Button>
                    }
                    <Button
                        size="small"
                        color="error"
                        variant="outlined"
                        style={{marginTop:10,minWidth:160}}
                        onClick={() => this.handleDeleteItem()}
                    >
                        {Translate.deleteProductFromCart}
                    </Button>
                </Grid>

            </Grid>
            </div>
        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(SingleCartItem))));