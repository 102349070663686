import React from 'react';
import HorizontalGroup from './HorizontalGroup';
import HorizontalCollapse from './HorizontalCollapse';
import HorizontalItem from './HorizontalItem';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import Box from '@material-ui/core/Box';
import topMenuRoutesConfig from '../../../../../config/routes/TopMenuRoutesConfig';
import {getStore} from "trim-redux";

const HorizontalNav = () => {
  let menu = getStore('menu');

  return (
    <List className='navbarNav' style={{color:"rgb(73, 80, 87)"}}>
      {menu.map((item) => (
        <React.Fragment key={item.id}>
            <HorizontalItem item={item} nestedLevel={0} />
        </React.Fragment>
      ))}
    </List>
  );
};

export default HorizontalNav;
