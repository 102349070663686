import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import {Styles} from "../../../../config/design/styles";
import FileManagerDialog from "./FileManagerDialog";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import { withSnackbar } from 'notistack';
import Grid from "@material-ui/core/Grid/Grid";
import Button from "@material-ui/core/Button/Button";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import {translate} from "../../../../config/lang";
import VideoCoverButton from "./VideoCoverButton";
import CloseRoundedIcon from "@material-ui/icons/CloseRounded";

class SingleVideoChooser extends React.Component{
    state = {
        FileManagerPending: false,
        file: null,
        init: this.props.initial
    };
    classes = this.props.classes;
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }
    componentDidMount(){
        this.loadData()
    }

    loadData(){
        let id = this.state.init;
        if(id > 1){
            let data = new FormData();
            data.append('id', id);
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/file/get`, data, config)
                .then(response => {

                    if(!!response.data.preview){
                        response.data['preview'] = `${defaultVariabls.uploads}/FileManager/${response.data.preview.name}_thumbnail.${response.data.preview.extension}`
                    }
                    this.setState({
                        file: response.data,
                        init: id
                    })

                    this.props.handleVideoChooser(response.data.id)
                })
                .catch(error => {
                    this.props.enqueueSnackbar(translate('loadingThumbnailFailed'), {
                        variant: 'error',
                    });
                });
        }
    }
    setData = (data) => {
        this.setState({
            file: data
        })
        this.props.handleVideoChooser(data.id)
        this.toggleOperation();
    }
    async removeDataSheet(){
        await this.setState({
            file: null
        });
        this.props.handleVideoChooser(null)
    }
    handlePreview(res){
        let {file} = this.state;
        file['preview'] = res;
        this.setState({
            file
        })
    }
    render(){
        let {FileManagerPending,file} = this.state;
        return (
            <div style={{textAlign:"center"}}>
                <Grid container direction="row">
                    {
                        file !== null ?
                            <React.Fragment>
                                <Grid item xs={12} style={{marginTop:15,marginBottom:15,position:"relative"}}>
                                    <a  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={file.source_id ? `https://www.youtube.com/watch?v=${file.source_id}` : `${defaultVariabls.uploads}/FileManager/${file.name}.${file.extension}`}>
                                        {
                                            file.type === "video" ?
                                                !!file.preview ?
                                                    <div className={this.classes.thumbnailWithPreviewChooser}>
                                                        <img src={`${file.preview}`} className="fileChooser-image"/>
                                                        <PlayCircleFilledOutlinedIcon style={{fontSize: 60,color: "#fee800",opacity:0.9}}/>
                                                    </div>
                                                    :
                                                    <div className={this.classes.thumbnailWithPreviewChooser}>
                                                        <OndemandVideoRoundedIcon style={{fontSize: 60,color: "#6d6d6d"}}/>
                                                    </div>

                                                :
                                                <div className={this.classes.thumbnailWithPreviewChooser}>
                                                    <img src={`${file.src}`} className="fileChooser-image"/>
                                                </div>
                                        }
                                    </a>
                                    <Button
                                        startIcon={<CloseRoundedIcon />}
                                        size="small"
                                        variant="contained"
                                        style={{width:"100%"}}
                                        onClick={() => this.removeDataSheet()}
                                    >
                                        {translate('removeVideo')}
                                    </Button>
                                    {
                                        file.type === "video" &&
                                        <VideoCoverButton
                                            file={file}
                                            handlePreview={(res) => this.handlePreview(res)}
                                            style={{
                                                bottom: "unset",
                                                top: -14,
                                                backgroundColor: "rgb(255, 246, 160)",
                                                width: "unset",
                                                left: "unset",
                                                fontSize: 11,
                                                padding: "5px 10px",
                                                position:"absolute",
                                                right:0
                                            }}
                                        />
                                    }
                                </Grid>
                            </React.Fragment>
                            : null
                    }

                    <Grid item xs={12}>
                        <Button
                            startIcon={<AddBoxRoundedIcon />}
                            color="primary"
                            variant="outlined"
                            size="small"
                            onClick={this.toggleOperation.bind(this)}
                        >
                            {translate('chooseVideo')}
                        </Button>
                    </Grid>
                </Grid>
                {
                    FileManagerPending ? <FileManagerDialog fileType="video" size={this.props.size} cancleOperation={this.toggleOperation} setData={this.setData}/> : null
                }
            </div>
        );
    }
}
export default withSnackbar(withStyles(Styles)(SingleVideoChooser));
