import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {getDataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {TableCountForCat, TablePage} from "../../../../config/Permissions";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import IconButton from "@material-ui/core/IconButton/IconButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {truncate} from "../../../../config/values";
import OpenInNewRoundedIcon from '@material-ui/icons/OpenInNewRounded';
import {makeSlug, translate} from "../../../../config/lang";

class Plans extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                sorting:false,
                hidden: this.props.width === "xs",
                title: translate('thumbnail'),
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            { title: translate('planName'),
                field: 'name',
                filterPlaceholder: translate('search'),
                sorting:false,
                render: rowData =>
                    this.props.locale === "fa" ?
                    truncate(rowData.name,17) :
                    truncate(rowData.english_name,17)
            },
            { title: translate('durationPlan'),
                field: 'duration',
                filterPlaceholder: translate('search'),
                sorting:false,
                render: rowData =>
                    rowData.duration+" "+translate('days')
            },
            { title: translate('confirmationStatusFilter'),
                sorting:false,
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: translate('choose'),
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{translate('confirmed')}</Typography>
                    , 0:
                        <Typography color="textSecondary">{translate('unConfirmed')}</Typography>
                },
            },
            { title: translate('price'),
                field: 'price',
                filterPlaceholder: translate('search'),
                sorting:false,
                render: rowData =>
                    rowData.price === 0 ? translate('free') :
                        rowData.discount_price !== null ?
                        "AED "+rowData.discount_price :
                        "AED "+rowData.price
            },

            { title: translate('activity'),
                editable: 'never',
                field: 'activity',
                sorting:false,
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/edit/plan/${rowData.id}`}
                        endIcon={this.props.locale === "fa" ? <ChevronRightRoundedIcon/> : <ChevronRightRoundedIcon/>}
                        size={"small"}
                        style={{margin:5}}
                    >
                        {translate('edit')}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/plan/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(translate('deleteData'), {
                        variant: 'success',
                    });
                }
                return true
            })

            .catch(error => {
                if(error.response.status === 404){
                    this.props.enqueueSnackbar(translate('deletePlanFailed'), {
                        variant: 'error',
                    });
                }else{
                    this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                        variant: 'error',
                    });
                }
                this.loadData();
                return false
            });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={
                                    <>
                                    <Link to='/create/plan'>
                                        {
                                            this.props.width !== "xs" ?
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    startIcon={<AddBox/>}
                                                >
                                                    {translate('makePlan')}
                                                </Button>
                                                :
                                                <IconButton color="primary">
                                                    <AddBox/>
                                                </IconButton>
                                        }
                                    </Link>
                                    </>
                                }

                                localization={getDataTableLocalization()}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/plans/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&lang=${this.props.locale}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        thumbnail: `${defaultVariabls.uploads}/FileManager/${row.parent.thumbnail.name}.${row.parent.thumbnail.extension}`,
                                                        name: row.parent.name,
                                                        english_name: row.parent.english_name,
                                                        duration: row.duration,
                                                        price: row.price,
                                                        discount_price: row.discount_price,
                                                        id: row.id,
                                                        confirmed: row.confirmed,
                                                        updated_at: row.updated_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCountForCat,
                                    defaultExpanded:false,
                                    draggable: false,

                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Plans)))));