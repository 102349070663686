import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import {setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import SubTitle from "../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import {Translate} from "../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import {currencySymbol, price} from "../../../../config/values";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import {Link} from "react-router-dom";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton/IconButton";

class SellerAnalyticsShort extends React.Component {
    state = {
        finished_inventory: null,
        finished_inventory_most_purchased: null,
        finishing_inventory: null,
        finished_orders: null,
        today_pending_orders: null,
        pending_orders: null,
        done_orders:null,
        total_products:null,
        total_pending_products:null,
        shop_visits:null,
        products_visits:null,
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        this.setState({
            loading:true,
        });
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/seller-analytics`, config)
                .then(response => {
                    this.setState({
                        finished_inventory: response.data.finished_inventory,
                        finished_inventory_most_purchased: response.data.finished_inventory_most_purchased,
                        finishing_inventory: response.data.finishing_inventory,
                        finished_orders: response.data.finished_orders,
                        today_pending_orders: response.data.today_pending_orders,
                        pending_orders: response.data.pending_orders,
                        done_orders: response.data.done_orders,
                        total_products:response.data.total_products,
                        total_pending_products:response.data.total_pending_products,
                        shop_visits:response.data.shop_visits,
                        products_visits:response.data.products_visits,
                        loading:false,
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {
            finished_inventory,
            finished_inventory_most_purchased,
            finishing_inventory,
            finished_orders,
            today_pending_orders,
            pending_orders,
            done_orders,
            total_products,
            total_pending_products,
            shop_visits,
            products_visits,
            loading
        } = this.state;
        return (
            <Grid container spacing={2} direction="row" justify="center" alignItems="center">
                <Grid item xs={12} md={3}>
                    <Grid container  style={{backgroundColor: "#fff",border:"1px solid #ff935a"}} direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                        <SubTitle style={{color:"#ff935a",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                            {Translate.manageInventory}
                            <Button
                                size="small"
                                color="secondary"
                                endIcon={<ChevronLeftRoundedIcon/>}
                                component={Link}
                                to={'/pricing'}
                            >
                                {Translate.viewDetails}
                            </Button>
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem>
                                    <ListItemText
                                        primary={Translate.finished_inventory}
                                    />
                                    <ListItemSecondaryAction>
                                        {finished_inventory}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={Translate.finishing_inventory}
                                    />
                                    <ListItemSecondaryAction>
                                        {finishing_inventory}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container  style={{backgroundColor: "#fff",border:"1px solid #42b0c9"}} direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#42b0c9",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.manageOrders}
                                <Button
                                    size="small"
                                    color="secondary"
                                    endIcon={<ChevronLeftRoundedIcon/>}
                                    component={Link}
                                    to={'/shop-orders'}
                                >
                                    {Translate.viewDetails}
                                </Button>
                            </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid item xs={12}>
                                <List dense>
                                    <ListItem>
                                        <ListItemText
                                            primary={Translate.pending_orders}
                                        />
                                        <ListItemSecondaryAction>
                                            {pending_orders}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={Translate.done_orders}
                                        />
                                        <ListItemSecondaryAction>
                                            {done_orders}
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid container   style={{backgroundColor: "#fff",border:"1px solid #bfa500"}} direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#bfa500",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.manageProducts}
                                <Button
                                    size="small"
                                    color="secondary"
                                    endIcon={<ChevronLeftRoundedIcon/>}
                                    component={Link}
                                    to={'/my-products'}
                                >
                                    {Translate.viewDetails}
                                </Button>
                                </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem>
                                    <ListItemText
                                        primary={Translate.total_products}
                                    />
                                    <ListItemSecondaryAction>
                                        {total_products}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={Translate.total_pending_products}
                                    />
                                    <ListItemSecondaryAction>
                                        {total_pending_products}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>

                    </Grid>
                </Grid>
                <Grid item xs={12} md={3}>
                    <Grid style={{backgroundColor: "#fff",border:"1px solid #5dc178"}} container   direction="row" alignItems="center" justify="center" className={this.classes.GridAsaPaperBaby}>
                        <Grid item xs={11}>
                            <SubTitle style={{color:"#5dc178",display:"flex",justifyContent:"space-between",alignItems:'center'}} align="left">
                                {Translate.visits}
                                </SubTitle>
                        </Grid>
                        <Grid item xs={12}>
                            <List dense>
                                <ListItem>
                                    <ListItemText
                                        primary={Translate.shop_visits}
                                    />
                                    <ListItemSecondaryAction>
                                        {shop_visits}
                                    </ListItemSecondaryAction>
                                </ListItem>
                                <ListItem>
                                    <ListItemText
                                        primary={Translate.products_visits}
                                    />
                                    <ListItemSecondaryAction>
                                        {products_visits}
                                    </ListItemSecondaryAction>
                                </ListItem>
                            </List>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(Styles)(SellerAnalyticsShort);