import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {setStore} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";
import AppCardLoaderFields from "../../../../../components/Skeletons/AppCardLoaderFields";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import TextField from "@material-ui/core/TextField/TextField";
import LoadingButton from "../../../../../components/elements/LoadingButton";

class CommissionField extends React.Component {
    state = {
        commission: null,
        loading:true,
        SubmitLoading:false,
    };
    classes = this.props.classes;
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/settings/getAll`, config)
                .then(response => {
                    console.log(response.data)
                    this.setState({
                        commission: response.data.commission,
                        loading:false
                    })
                })
                .catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
            this.setState({
                loading:false
            })
        }
    }
    async handleSubmit(){

        let loginToken = localStorage.getItem('ACT');
        let {commission} =  this.state;
        if(commission !== null ) {
            if (parseInt(commission) > 100 || parseInt(commission) < 1) {
                this.props.enqueueSnackbar(Translate.between0to100, {
                    variant: 'warning',
                });
            }else{
                await  this.setState({
                    SubmitLoading:true
                })
                let config = {
                    headers: {
                        Accept: 'application/json',
                        Authorization: `Bearer ${loginToken}`
                    }
                };
                let data = new FormData();
                data.append('commission', commission);
                await axios.post(`${defaultVariabls.url}/settings/saveCommission`,data, config)
                    .then(response => {
                        this.setState({
                            SubmitLoading:false
                        })
                    })
                    .catch(error => {
                        console.log(error.response)
                        this.setState({
                            loading:false,
                            SubmitLoading:false

                        })
                    })
            }
        }
    }
    render(){
        let {loading,commission,SubmitLoading} = this.state;
        return (
            <React.Fragment>
                {
                    loading ?
                        <AppCardLoaderFields/>
                        :
                        <Grid container spacing={2} className="mt-20" alignItems="center">
                            <Grid item xs={10} md={4}>
                                <TextField
                                    className="mt-15"
                                    variant="outlined"
                                    value={commission}
                                    label={Translate.commission}
                                    name="commission"
                                    type="number"
                                    onChange={(e)=> this.setState({
                                        commission : e.target.value,
                                    })}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                %
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid item xs={2}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {Translate.save}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                }
            </React.Fragment>

        );
    }
}

export default withSnackbar(withRouter(withStyles(Styles)(CommissionField)));