import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {DataTableLocalization, Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../../config/Permissions";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import Container from "@material-ui/core/Container/Container";
import Button from "@material-ui/core/Button/Button";
import {currencySymbol, price} from "../../../../../config/values";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {connect} from "trim-redux";


class ManageTransferPricing extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    state = {
        columns: [
            {
                title: Translate.min_weight,
                field: 'min_weight',
                render: rowData =>
                    <Typography>
                        {rowData.min_weight} {Translate.gram}
                    </Typography>

            },
            {
                title: Translate.max_weight,
                field: 'max_weight',
                render: rowData =>
                    <Typography>
                        {rowData.max_weight} {Translate.gram}
                    </Typography>
            },
            {
                title: Translate.price,
                field: 'price',
                render: rowData =>
                    <>
                    <Typography>
                        {rowData.price}X
                    </Typography>
                    <Typography>
                        {Translate.equalsTo} {price(rowData.price*this.props.x_price)} {currencySymbol}
                    </Typography>
                    </>
            }
        ],
        data: [],
        loading:false,
    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onAdd(result) {
        if(result.min_weight === undefined || result.max_weight === undefined || result.price === undefined){
            this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                variant: 'error',
            });
        }else{
            let data = new FormData();
            data.append('min_weight', result.min_weight);
            data.append('max_weight', result.max_weight);
            data.append('price', result.price);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/transfer_price/add`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(Translate.dataAdded, {
                        variant: 'success',
                    });
                    this.loadData();
                    return true

                })
                .catch(error => {
                    if(error.response.status === 403){
                        this.props.enqueueSnackbar(Translate.weightChoosingFailed, {
                            variant: 'error',
                        });
                        this.loadData();
                        return false
                    }else{
                        this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                            variant: 'error',
                        });
                        this.loadData();
                        return false
                    }

                });
        }

    }
    onEdit(result) {
        let data = new FormData();
        data.append('id', result.id);
        data.append('min_weight', result.min_weight);
        data.append('max_weight', result.max_weight);
        data.append('price', result.price);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/transfer_price/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.dataEdited, {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.dataEditingFailed, {
                    variant: 'error',
                });
                this.loadData();
                return false
            });
    }
    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/transfer_price/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.deleteData, {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });
                this.loadData();

                return false
            });
    }
    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            <MaterialTable
                                icons={tableIcons}
                                title={
                                    <Button
                                        component={Link}
                                        to='/manage/settings'
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                    >
                                        {Translate.changeXPrice}
                                    </Button>
                                }
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/transfer_price/quickView?page=${query.page + 1}&per_page=${query.pageSize}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        min_weight: row.min_weight,
                                                        max_weight:row.max_weight,
                                                        price:row.price,
                                                        id:row.id,
                                                        created_at:row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    search:false,
                                }}
                                editable={{
                                    onRowAdd: newData =>
                                        new Promise((resolve, reject) => {
                                            setTimeout(() => {
                                                this.onAdd(newData);
                                                resolve();
                                            }, 1000);
                                        }),
                                    onRowUpdate: async (newData, oldData) =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onEdit(newData);
                                                if (oldData) {
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data[data.indexOf(oldData)] = newData;
                                                        return {...prevState, data};
                                                    });
                                                }
                                            }, 600);
                                        }),
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return {...prevState, data};
                                                });
                                            }, 600);
                                        }),
                                }}

                            />
                        </Container>
                }
            </>
        );
    }
}
const mstp = state => ({
    x_price: state.x_price,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManageTransferPricing)))));