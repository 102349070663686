import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import clsx from "clsx";
import {DataTableLocalization, Translate} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {getStore} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import Button from "@material-ui/core/Button/Button";
import {Link, withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import { TableCount, TablePage} from "../../../../../config/Permissions";
import moment from "jalali-moment";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';


class Agents extends React.Component {
    constructor(props) {
        super(props);
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    fixedHeightPaper = clsx(this.classes.paper, this.classes.fixedHeight);
    role = this.props.match.params.role;
    state = {
        columns: [
            {
                field: 'url',
                editable: 'never',
                title: Translate.image,
                render: rowData => <img alt="avatar" src={rowData.url} className={this.classes.FMThumbnail}/>
            },
            { title: Translate.userName,
                field: 'name',
                editable: 'never',
                render: rowData =>(
                    <>
                        <Typography>
                            {rowData.name}
                        </Typography>
                        <Typography style={{fontSize:11,color:"#777",marginTop:5}}>
                            {"(" + Translate.referrer_code +" "+rowData.referrer_code + ")"}
                        </Typography>
                    </>
                )
            },
            { title: Translate.mobile, field: 'mobile', editable: 'never' },
            { title: Translate.sell_commission, field: 'sell_commission', },
            { title: Translate.buy_commission, field: 'buy_commission',  },
            {
                title: Translate.registerDate,
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.created_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.created_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        component={Link}
                        to={`/manage/agent/${rowData.id}`}
                        endIcon={<ChevronLeftRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {Translate.details}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        role:"all",
        ChangeLoader:false
    };
    componentDidMount(){
        this.loadData();
    }
    async componentDidUpdate(newProps){
        if(newProps !== this.props){
            let newRole = this.props.match.params.role;
            await this.setState({
                role:!!newRole ? newRole : "all",
                ChangeLoader:true
            })
            await this.loadData();
        }
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    onEditUser(agent) {
        let authUser = getStore().user;
        if(authUser.role.permission.manageSettings){
            let data = new FormData();
            data.append('id', agent.id);
            data.append('sell_commission', agent.sell_commission);
            data.append('buy_commission', agent.buy_commission);
            let loginToken = localStorage.getItem('ACT');

            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/quickEditAgent`, data, config)
                .then(response => {
                    this.props.enqueueSnackbar(Translate.detailsSet, {
                        variant: 'success',
                    });
                    this.loadData();
                    return true
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(Translate.detailsSetFailed, {
                        variant: 'error',
                    });
                    return false
                });
        }else{
            this.props.enqueueSnackbar(Translate.youDontHavePermission, {
                variant: 'error',
            });
            this.loadData();
        }
    }

    async handleChangeRole(e) {
        this.setState({
            ChangeLoader:true
        })
        let target = e.target;
        let role = target.value;
        await this.setState({
            role
        });
        this.props.history.push(`/manage/users/view/${role}`)
        this.loadData();
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
                <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <MaterialTable
                                            parentChildData={(row, rows) => rows.find(a => a.id === row.parentId)}
                                            tableRef={this.tableRef}
                                            icons={tableIcons}
                                            title={" "}
                                            localization={DataTableLocalization}
                                            columns={this.state.columns}
                                            data={query =>
                                                new Promise((resolve, reject) => {
                                                    axios.get(`${defaultVariabls.url}/getUsers/agents?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                                        .then(response => {
                                                            let data = [];
                                                            // eslint-disable-next-line
                                                            response.data.data.map((row) => {
                                                                data.push({
                                                                    name: row.user.first_name+" "+row.user.last_name,
                                                                    mobile: row.user.mobile,
                                                                    url: `${defaultVariabls.uploads}/FileManager/${row.user.avatar.name}_small.${row.user.avatar.extension}`,
                                                                    id: row.id,
                                                                    created_at:row.user.created_at,
                                                                    referrer_code:row.referrer_code,
                                                                    sell_commission:row.sell_commission,
                                                                    buy_commission:row.buy_commission,
                                                                    cities:row.cities,

                                                                })
                                                            });
                                                            resolve({
                                                                data: data,
                                                                page: response.data.current_page - 1,
                                                                totalCount: response.data.total,
                                                            })
                                                        }).catch(error => {
                                                        console.log(error.response)
                                                    })
                                                })
                                            }
                                            options={{
                                                pageSize: TablePage,
                                                pageSizeOptions: TableCount,
                                            }}

                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise(resolve => {
                                                        setTimeout(() => {
                                                            resolve();
                                                            this.onEditUser(newData);
                                                            if (oldData) {
                                                                this.setState(prevState => {
                                                                    const data = [...prevState.data];
                                                                    data[data.indexOf(oldData)] = newData;
                                                                    return { ...prevState, data };
                                                                });
                                                            }
                                                        }, 600);
                                                    }),
                                            }}

                                        />
                                    </Grid>
                                </Grid>
                            </Container>
                    }
                </>
        );
    }
}

export default  withWidth()(withSnackbar(withRouter(withStyles(Styles)(Agents))));