import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../config/Translate";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import Grid from "@material-ui/core/Grid/Grid";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import LoadingButton from "../../../components/elements/LoadingButton";
import ChatRoundedIcon from '@material-ui/icons/ChatRounded';
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Paper from "@material-ui/core/Paper/Paper";
import Menu from "@material-ui/core/Menu/Menu";
import ButtonGroup from "@material-ui/core/ButtonGroup/ButtonGroup";
import {translate} from "../../../../config/lang";
class AddTicketModal extends React.Component{
    classes = this.props.classes

    state = {
        fields:{
            subject:"",
            message:"",
            priority:"medium",
            department:"",
            order_item:""
        },
        type:"system",
        SubmitLoading:false,
        departments:[],
        orderItems:[],
        loading:true,
        open:false,
        errors:{},
        anchorEl:null
    };
    handleClickMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null
        })
    };

    componentDidMount(){
        this.loadData()
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/departments/getAllWithOrders?lang=${this.props.locale}`, config)
            .then(response => {
                this.setState({
                    departments: response.data.departments,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    toggleOpen(type){
        this.setState({
            open: !this.state.open,
            type:type
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,type } = this.state;
        let errors = {};
        let formIsValid = true;

        //subject
        if(!fields.subject) {
            formIsValid = false;
            errors['subject'] = translate('emptyField');
        }
        //subject
        if(!fields.message) {
            formIsValid = false;
            errors['message'] = translate('message');
        }
        if(type === "system"){
            //department
            if(!fields.department) {
                formIsValid = false;
                errors['department'] = translate('message');
            }
        }else if(type === "product"){
            //order_item
            if(!fields.order_item) {
                formIsValid = false;
                errors['order_item'] = translate('message');
            }
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields,type} = this.state;
        let data = new FormData();
        data.append('subject', fields.subject);
        data.append('message', fields.message);
        data.append('priority', fields.priority);
        data.append('type', type);
        if(type === "system"){
            data.append('department', fields.department);
        }else{
            data.append('order_item', fields.order_item);
        }
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/ticket/add`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('ticket_sent'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false,
                    open:false
                });
                this.props.reload()

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    render(){
        let {loading,SubmitLoading,departments,errors,fields,open,type,orderItems,anchorEl} = this.state;
        let {user} = this.props;
        return (
            <>
                {
                    user.role.name === "user" ?
                        <>
                            <Button
                                aria-controls="simple-menu"
                                aria-haspopup="true"
                                variant="outlined"
                                color="primary"
                                onClick={(e) => this.toggleOpen('system')}
                                startIcon={<ChatRoundedIcon/>}
                                size={"small"}
                            >
                                {translate('addTicket')}
                            </Button>

                        </>
                        :
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => this.toggleOpen('system')}
                            startIcon={<ChatRoundedIcon/>}
                            size={"small"}
                        >
                            {translate('addTicket')}
                        </Button>
                }

                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="md"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",right:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {translate('addTicket')}
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    margin="normal"
                                    select
                                    name="priority"
                                    value={fields.priority}
                                    label={translate('priority')}
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['priority']}
                                    helperText={errors['priority']}
                                >
                                    <MenuItem value={"low"}>{translate('low')}</MenuItem>
                                    <MenuItem value={"medium"}>{translate('medium')}</MenuItem>
                                    <MenuItem value={"high"}>{translate('high')}</MenuItem>
                                </TextField>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {
                                    type === "system" ?
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            select
                                            name="department"
                                            value={fields.department}
                                            label={translate('department')}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['department']}
                                            helperText={errors['department']}
                                            InputProps={loading &&{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CircularProgress size={20}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        >
                                            {
                                                departments.map((department,index)=> (
                                                    <MenuItem key={index} value={department.id}>{department.name}</MenuItem>
                                                ))
                                            }

                                        </TextField>
                                        :
                                        <TextField
                                            fullWidth
                                            variant="outlined"
                                            margin="normal"
                                            select
                                            name="order_item"
                                            value={fields.order_item}
                                            label={translate('productWShop')}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['order_item']}
                                            helperText={errors['order_item']}
                                            InputProps={loading &&{
                                                startAdornment: (
                                                    <InputAdornment position="start">
                                                        <CircularProgress size={20}/>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        >
                                            {
                                                orderItems.map((item,index)=> (
                                                    <MenuItem key={index} value={item.id}>{item.product.name} <span style={{fontSize:11}}> ({translate('factor')} {item.factor.number}) </span></MenuItem>
                                                ))
                                            }

                                        </TextField>
                                }

                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={fields.subject}
                                    fullWidth
                                    className={this.classes.inputCtl}
                                    label={translate('subject')}
                                    name="subject"
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['subject']}
                                    helperText={errors['subject']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    variant="outlined"
                                    value={fields.message}
                                    fullWidth
                                    label={translate('message')}
                                    name="message"
                                    minRows={5}
                                    className={this.classes.inputCtl}
                                    multiline
                                    onChange={this.handleChange.bind(this)}
                                    error = {!!errors['message']}
                                    helperText={errors['message']}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    onClick = {this.handleSubmit.bind(this)}
                                    loading = {SubmitLoading}
                                >
                                    {translate('addTicket')}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AddTicketModal)))));