import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../config/Translate";
import AppCardLoaderTable from "../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, price} from "../../../config/values";
import ModifyPriceButton from "./components/ModifyPriceButton";
import Link from "@material-ui/core/Link/Link";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";

class ModifyPricing extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'thumbnail',
                editable: 'never',
                filtering: false,
                title: Translate.thumbnail,
                render: rowData => <img alt="avatar" src={rowData.thumbnail} className={this.classes.FMThumbnail}/>
            },
            {
                title: Translate.productName,
                field: 'name',
                filterPlaceholder: Translate.search,
                render: rowData =>
                    <>
                        <Typography>
                            <Link className={this.classes.link} href={`${defaultVariabls.website}/product/${rowData.slug}`} target="_blank">
                                {
                                    !!rowData.value_id  ?
                                        rowData.name+" ("+rowData.value.attribute.name+" "+ (rowData.value.attribute.type === "color" ? rowData.value.description : rowData.value.name) +") " :
                                        rowData.name
                                }
                            </Link>
                        </Typography>
                        <Typography style={{fontSize:12,marginTop:5}}>{Translate.shop} {rowData.shop.name}</Typography>
                    </>
            },
            {
                title: Translate.user,
                field: 'owner',
                filterPlaceholder: Translate.search,
                hidden: !this.props.manage,
                render: rowData =>
                    rowData.owner.first_name+" "+rowData.owner.last_name
            },
            { title: Translate.price, field: 'price',filterPlaceholder: Translate.search},
            { title: Translate.inventory, field: 'inventory',filterPlaceholder: Translate.search},
            { title: Translate.confirmationStatus,
                editable: 'never',
                field: 'confirmed',
                filterPlaceholder: Translate.choose,
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{Translate.confirmed}</Typography>
                    , 0:
                        <Typography color="textSecondary">{Translate.unConfirmed}</Typography>
                },
            },
            {
                title: Translate.lastUpdate,
                editable: 'never',
                field: 'updated_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.updated_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.updated_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },

            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <ModifyPriceButton manage={this.props.manage} id={rowData.id} reload={() => this.loadData()}/>
            },
        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        tab_index:!!this.props.match.params.type ? this.props.match.params.type : "all",

    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    handleTabs = async (event, newValue)=>
    {
        this.props.history.push(`/manage/pricing/${newValue}`);
        await this.setState({
            tab_index:newValue
        })
        await this.loadData()
    }
    onDelete(pricing_id){
        let data = new FormData();
        data.append('pricing_id',  pricing_id);
        this.setState({
            loading: false
        });
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/pricing/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.deleteData, {
                    variant: 'success',
                });
                return true
            })
            .catch(error => {
                console.log(error.response);
                this.loadData();
                this.props.enqueueSnackbar(Translate.deleteDataFailed, {
                    variant: 'error',
                });

                return false
            });
    }

    render(){
        let {manage} = this.props;
        let {tab_index} = this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl">
                            {
                            this.props.manage &&
                            <Tabs
                                value={tab_index}
                                onChange={this.handleTabs.bind(this)}
                                indicatorColor="primary"
                                textColor="primary"
                                centered
                                variant={this.props.width === "xs" ? "scrollable" : ""}
                                scrollButtons="auto"
                            >
                                <Tab value="all" label={Translate.all_pricings} />
                                <Tab value="confirmed" label={Translate.confirmed_pricings} />
                                <Tab value="unconfirmed" label={Translate.unconfirmed_pricings} />
                            </Tabs>
                            }
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/pricing/quickView/${manage?"yes":"no"}?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}&type=${tab_index}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        thumbnail:
                                                            row.thumbnail.id > 1 ?
                                                                `${defaultVariabls.uploads}/FileManager/${row.thumbnail.name}_small.${row.thumbnail.extension}`
                                                                :
                                                                `${defaultVariabls.uploads}/FileManager/${row.product.thumbnail.name}_small.${row.product.thumbnail.extension}`,
                                                        name: row.product.name,
                                                        shop: row.shop,
                                                        slug: row.product.slug,
                                                        owner: row.owner,
                                                        price: price(row.price)+" "+currencySymbol,
                                                        inventory: row.inventory,
                                                        value:row.value,
                                                        value_id:row.value_id,
                                                        id: row.id,
                                                        confirmed: row.confirmed,
                                                        updated_at:row.updated_at
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                }}
                                editable={{
                                    onRowDelete: oldData =>
                                        new Promise(resolve => {
                                            setTimeout(() => {
                                                resolve();
                                                this.onDelete(oldData.id);
                                                this.setState(prevState => {
                                                    const data = [...prevState.data];
                                                    data.splice(data.indexOf(oldData), 1);
                                                    return { ...prevState, data };
                                                });
                                            }, 600);
                                        }),
                                }}
                            />
                        </Container>
                }
            </>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ModifyPricing)))));