import React, {Component} from 'react';
import Box from "@material-ui/core/Box/Box";
import ChatFooter from "./ChatFooter";
import ChatHeader from "./ChatHeader";
import AppsHeader from "./components/AppsHeader";
import AppsFooter from "./components/AppsFooter";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Launcher from "../Messaging/ChatScreen/Launcher";
import Grid from "@material-ui/core/Grid/Grid";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import AppAnimate from "../../../components/AppAnimate";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import {translate} from "../../../../config/lang";
import AppCardLoaderFields from "../../../components/Skeletons/AppCardLoaderFields";

class Ticket extends Component {
    chatContainer = React.createRef();
    constructor() {
        super();
        this.state = {
            messageList: [],
            loading:true,
            first:true,
            contact:{},
            myself:{},
            starter:{},
            receiver:{},
            department:{},
            type:'',
            subject:'',
            priority:'',
            manage: false,
            ticket_id: 0,
            ticket: null,
        };
    }
    async componentDidMount(){
        await this.setState({
            manage:this.props.manage,
            ticket_id: this.props.match.params.id
        })
        await this.loadData();
        await this.scrollToMyRef();
    }
    componentDidUpdate(newProps){
        if(newProps !== this.props){
            this.setState({
                ticket_id :this.props.match.params.id,
                loading:true,
            })
            this.loadData()
        }
    }
    async loadData(){
        this.setState({
            ChangeLoader:true
        })
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        await axios.get(`${defaultVariabls.url}/ticket/${this.state.ticket_id}${this.props.manage ? "/true" : ''}`, config)
            .then(response => {
                let {user} = this.props;
                this.setState({
                    messageList: response.data.tickets,
                    contact: response.data.receiver.id === user.id ? response.data.starter : response.data.receiver,
                    myself: response.data.receiver.id !== user.id ? response.data.starter : response.data.receiver,
                    starter: response.data.starter,
                    receiver: response.data.receiver,
                    priority: translate(response.data.priority),
                    type: response.data.type,
                    department: response.data.department,
                    ticket: response.data,
                    subject: !!response.data.subject ? response.data.subject : '',
                    loading:false,
                    manage:this.props.manage
                })
            }).catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false,
                })
            })
        if(this.state.first){
            await setTimeout(() => {
                this.scrollToMyRef();
            }, 1000);
            this.setState({
                first:false
            })
        }else{
            this.scrollToMyRef();
        }
    }

    scrollToMyRef = () => {
        const scroll =
            this.chatContainer.current.scrollHeight -
            this.chatContainer.current.clientHeight;
        this.chatContainer.current.scrollTo(0, scroll);
    };
    render() {
        let classes = this.props.classes;
        let {loading,messageList,contact,ticket,receiver,type,department,manage,myself,subject,ticket_id} = this.state;
        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('tickets')}>
            <Grid container spacing={2} justify="center" >
                <Grid item xs={12} md={11}>
                    <BreadCrumbs firstLink={"/tickets"} firstText={translate('tickets')} secondText={type === "reserve" ? translate('reserveRequest') : subject}/>
                </Grid>
                <Grid item xs={12} md={11}>
                    <Grid container className={classes.GridAsaPaper} style={{height: "calc( 100vh - 160px )",paddingBottom:2}}>
                        <Grid item xs={12} style={{height:"100%"}}>
                            {
                                !!loading ?
                                    <AppCardLoaderFields/> :
                                    <Box className={classes.messagesScreenRoot}>
                                        <Box display='flex' height={1} flexDirection='column'>
                                            <AppsHeader>
                                                <ChatHeader
                                                    loading={loading}
                                                    contact={contact}
                                                    myself={myself}
                                                    manage={manage}
                                                    title={subject}
                                                    type={type}
                                                    department={department}
                                                    ticket={ticket}
                                                />
                                            </AppsHeader>
                                            <Box
                                                ref={this.chatContainer}
                                                className={classes.appsContentContainer}>
                                                <Launcher
                                                    loading={loading}
                                                    messageList={messageList}
                                                    contact={contact}
                                                    myself={myself}
                                                    manage={manage}
                                                />
                                            </Box>
                                            <AppsFooter mt='auto'>
                                                <ChatFooter
                                                    reload={() => this.loadData()}
                                                    loading={loading}
                                                    receiver={contact.id}
                                                    chatList={ticket_id}
                                                    manage={manage}
                                                />
                                            </AppsFooter>
                                        </Box>
                                    </Box>
                            }
                        </Grid>
                    </Grid>
                </Grid>

            </Grid>
            </AppAnimate>
        )
    }
}
const mstp = state => ({
    user: state.user,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(Ticket))));
