import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import LoadingButton from "../../../../components/elements/LoadingButton";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import {LoadSetting} from "../../../../../config/Security";
import CurrencySetting from "./components/CurrencySetting";
import {translate} from "../../../../../config/lang";
import FAQCategories from "./components/FAQCategories";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox/Checkbox";
import Typography from "@material-ui/core/Typography";
import FormHelperText from "@material-ui/core/FormHelperText/FormHelperText";
import FormControl from "@material-ui/core/FormControl/FormControl";

class SiteSettings extends React.Component {
    classes = this.props.classes;
    state = {
        fields:{
            app_name: this.props.setting.app_name,
            pagination_count: this.props.setting.pagination_count,
            page_count: this.props.setting.page_count,
            news_expiration: this.props.setting.news_expiration,
            ads_expiration: this.props.setting.ads_expiration,
            reserve_whatsapp_link: this.props.setting.reserve_whatsapp_link,
        },
        each_discount_notif: this.props.setting.each_discount_notif,
        daily_discount_notif: this.props.setting.daily_discount_notif,
        site_logo_file:this.props.setting.site_logo_file,
        site_logo_id:this.props.setting.site_logo_file.id,
        favicon_file:this.props.setting.favicon_file,
        favicon_id:this.props.setting.favicon_file.id,
        square_logo_file:this.props.setting.square_logo_file,
        square_logo_id:this.props.setting.square_logo_file.id,
        page_header_file:this.props.setting.page_header_file,
        page_header_id:this.props.setting.page_header_file.id,

        SubmitLoading:false,
        errors:{}
    };
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.app_name)){
            formIsValid = false;
            errors['app_name'] = translate('emptyField');
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    handleRequest(){
        let { fields,site_logo_id,favicon_id,page_header_id,square_logo_id,each_discount_notif,daily_discount_notif} = this.state;
        let data = new FormData();
        data.append('app_name', fields.app_name);
        data.append('pagination_count', parseInt(fields.pagination_count));
        data.append('page_count', parseInt(fields.page_count));
        data.append('news_expiration', parseInt(fields.news_expiration));
        data.append('ads_expiration', parseInt(fields.ads_expiration));
        data.append('reserve_whatsapp_link', fields.reserve_whatsapp_link);
        data.append('each_discount_notif', !!each_discount_notif ? 1 : 0);
        data.append('daily_discount_notif', !!daily_discount_notif ? 1 : 0);
        data.append('site_logo_id', site_logo_id);
        data.append('favicon_id', favicon_id);
        data.append('page_header_id', page_header_id);
        data.append('square_logo_id', square_logo_id);
        data.append('lang', this.props.locale);


        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/settings/save`, data, config)
            .then(async response => {
                await LoadSetting();
                await this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                await this.props.enqueueSnackbar(translate('allChangesSaved'), {
                    variant: 'success',
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    handleChangeFile(field,val){
        if(!!val){
            if(field === "site_logo"){
                this.setState({
                    site_logo_id:val.id,
                    site_logo_file:val
                })
            }
            if(field === "square_logo"){
                this.setState({
                    square_logo_id:val.id,
                    square_logo_file:val
                })
            }
            if(field === "favicon"){
                this.setState({
                    favicon_id:val.id,
                    favicon_file:val
                })
            }
            if(field === "page_header"){
                this.setState({
                    page_header_id:val.id,
                    page_header_file:val
                })
            }
        }

    }
    render(){
        let {errors,fields,SubmitLoading,daily_discount_notif,each_discount_notif,page_header_file,square_logo_file} = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.app_name}
                        fullWidth
                        label={translate('app_name')}
                        name="app_name"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['app_name']}
                        helperText={errors['app_name']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <CurrencySetting/>
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.pagination_count}
                        fullWidth
                        label={translate('pagination_count')}
                        name="pagination_count"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['pagination_count']}
                        helperText={errors['pagination_count']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.page_count}
                        fullWidth
                        label={translate('page_count')}
                        name="pagination_count"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['page_count']}
                        helperText={errors['page_count']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.news_expiration}
                        fullWidth
                        label={translate('news_expiration')}
                        name="news_expiration"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['news_expiration']}
                        helperText={errors['news_expiration']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.ads_expiration}
                        fullWidth
                        label={translate('ads_expiration')}
                        name="ads_expiration"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['ads_expiration']}
                        helperText={errors['ads_expiration']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        value={fields.reserve_whatsapp_link}
                        fullWidth
                        label={translate('reserve_whatsapp_link')}
                        name="reserve_whatsapp_link"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['reserve_whatsapp_link']}
                        helperText={errors['reserve_whatsapp_link']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FAQCategories/>
                </Grid>
                <Grid item xs={12}>
                    <FormControl  component="fieldset">
                        <FormControlLabel
                            control={<Checkbox checked={daily_discount_notif} onChange={() => this.setState({daily_discount_notif:!daily_discount_notif})} />}
                            label={translate('daily_discount_notif')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl  component="fieldset">
                        <FormControlLabel
                            control={<Checkbox checked={each_discount_notif} onChange={() => this.setState({each_discount_notif:!each_discount_notif})} />}
                            label={translate('each_discount_notif')}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        startIcon={<SaveRoundedIcon/>}
                        onClick={this.handleSubmit.bind(this)}
                        loading={SubmitLoading}
                    >
                        {translate('saveChanges')}
                    </LoadingButton>
                </Grid>
            </Grid>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
    about: state.about,
    setting: state.setting,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SiteSettings)))));