import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {makeSlug, translate} from "../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import Dialog from "@material-ui/core/Dialog/Dialog";
import AppBar from "@material-ui/core/AppBar/AppBar";
import Toolbar from "@material-ui/core/Toolbar/Toolbar";
import Typography from "@material-ui/core/Typography/Typography";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseIcon from '@material-ui/icons/Close';
import Slide from "@material-ui/core/Slide/Slide";
import DeleteButton from "../../../components/elements/DeleteButton";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import {defaultVariabls} from "../../../../config";
import Businesses from "../../businesses/parts/Businesses";

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
class RelatedBusinesses extends React.Component {
    classes = this.props.classes;
    state = {
        businesses:this.props.initial,
        open:false,
    }
    handleClose = () => {
        this.setState({
            open:false
        });
    };
    async handleSelect(item){
        let {businesses} = this.state;
        let can_add = true;
        businesses.map(ad => {
            if(ad.id === item.id){
                can_add = false;
            }
        });
        if(can_add){
            await businesses.push(item);
            await this.setState({
                businesses,
                open:false,
            });
            await this.props.handleRelated(businesses)
        }else{
            await this.setState({
                open:false,
            });
        }
    }
    async onDelete(index){
        let {businesses} = this.state;
        await businesses.splice(index, 1);
        await this.setState({
            businesses
        })
        await this.props.handleRelated(businesses)

    }
    render(){
        let {open,businesses} = this.state;
        return (
            <>
                <Grid container spacing={2} alignItems="center">
                    {
                        businesses.map((item,index)=>(
                            <Grid item xs={6} md={3} key={index}>
                                <div
                                    className="business-item"
                                >
                                    <a href={makeSlug(`business/${item.slug}`)}>
                                        <img
                                            src={!!item.logo.id ? `${defaultVariabls.uploads}/FileManager/${item.logo.name}.${item.logo.extension}` : item.logo}
                                        />
                                    </a>
                                    <h3><a href={makeSlug(`business/${item.slug}`)}>{item.name}</a></h3>
                                    <DeleteButton
                                        size="small"
                                        handleDelete={() => this.onDelete(index)}
                                    />
                                </div>
                            </Grid>
                        ))
                    }
                    <Grid item xs={6} md={4}>
                        <Button
                            onClick={() => this.setState({open:true})}
                            color="primary"
                            variant="outlined"
                            size={"small"}
                            style={{marginTop:20,marginBottom:20}}
                        >
                            {translate('addBusiness')}
                        </Button>
                    </Grid>
                </Grid>

                <Dialog fullWidth={true} maxWidth={"lg"} open={open} onClose={this.handleClose} TransitionComponent={Transition} >
                    <AppBar className={this.classes.appBarDialog}>
                        <Toolbar>
                            <Typography variant="h6" className={this.classes.titleDialog}>
                                {translate('chooseBusiness')}
                            </Typography>
                            <IconButton color="inherit" onClick={this.handleClose} aria-label="close">
                                <CloseIcon />
                            </IconButton>
                        </Toolbar>
                    </AppBar>
                    <div style={{padding:20}}>
                        <Businesses
                            select
                            manage
                            onSelect={(item) => this.handleSelect(item)}
                        />
                    </div>

                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    features: state.features,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(RelatedBusinesses)))));
