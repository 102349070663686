import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../config/Translate";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, price} from "../../../../config/values";
import Button from "@material-ui/core/Button/Button";
import { Link as ReactLink } from "react-router-dom";

class ShopOrders extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'factor_number',
                editable: 'never',
                filtering: false,
                title: Translate.factor_number,
            },
            { title: Translate.totalCartPrice,
                field: 'total_price_payed',
                filterPlaceholder: Translate.search,
            },
            { title: Translate.totalUserDiscounts,
                field: 'total_discounts',
                filterPlaceholder: Translate.search,

            },
            { title: Translate.status,
                editable: 'never',
                field: 'status',
                filterPlaceholder: Translate.choose,
                lookup: {
                    "payed_to_shop" :
                        <Typography style={{color: "#38bf84"}}>{Translate.payed_to_shop}</Typography>
                    , "ready":
                        <Typography color="textPrimary">{Translate.ready}</Typography>
                    , "pending":
                        <Typography color="textSecondary">{Translate.pending}</Typography>
                    , "need_verification":
                        <Typography color="textSecondary">{Translate.need_verification}</Typography>
                    , "rejected":
                        <Typography color="textSecondary">{Translate.rejected}</Typography>
                },
            },
            { title: Translate.created_at_factor,
                editable: 'never',
                filtering: false,
                field: 'created_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.created_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.created_at).locale('en').format( 'YYYY/M/D')}`}
                    </Typography>
            },
            {
                title: Translate.activity,
                field: 'activity',
                render: rowData =>
                    <>
                        <Button
                            color="primary"
                            size="small"
                            variant="outlined"
                            component={ReactLink}
                            to={`/order/check/${rowData.id}`}
                        >
                            {Translate.view}
                        </Button>
                    </>
            }
        ],
        data: [],
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }

    render(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl" className={!!this.props.widget ? "thin-table" : null}>
                            <MaterialTable
                                tableRef={this.tableRef}
                                icons={tableIcons}
                                title={" "}
                                localization={DataTableLocalization}
                                columns={this.state.columns}
                                data={query =>
                                    new Promise((resolve, reject) => {
                                        axios.get(`${defaultVariabls.url}/shop-factors/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                            .then(response => {
                                                let data = [];
                                                // eslint-disable-next-line
                                                response.data.data.map((row) => {
                                                    data.push({
                                                        total_price_payed: price(row.orders[0].total_price_payed) +" "+currencySymbol,
                                                        id: row.id,
                                                        factor_number: row.number,
                                                        total_discounts: price(row.orders[0].total_discount_price) +" "+currencySymbol,
                                                        status: row.orders[0].status,
                                                        created_at: row.created_at,
                                                    })
                                                });
                                                resolve({
                                                    data: data,
                                                    page: response.data.current_page - 1,
                                                    totalCount: response.data.total,
                                                })
                                            }).catch(error => {
                                            console.log(error.response)
                                        })
                                    })
                                }
                                options={{
                                    pageSize: TablePage,
                                    pageSizeOptions: TableCount,
                                    /*search:false,*/
                                }}
                            />
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ShopOrders)))));