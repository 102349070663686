import React from "react";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import {connect} from "trim-redux";
import axios from "axios";
import {defaultVariabls} from "../../../../../config";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import TextField from "@material-ui/core/TextField/TextField";
import {translate} from "../../../../../config/lang";

class ParentCategoryChooser extends React.Component{
    state = {
        categories:[],
        selected:!!this.props.selected ? this.props.selected : null,
        loading:true,
        open:false,
    };
    async componentDidMount(){
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/categories/quickViewParents/${this.props.locale}`, config)
            .then(response => {
                this.setState({
                    categories:response.data,
                    loading:false,
                })
            })
            .catch(error => {
                this.setState({
                    loading:false
                })
            });
    }
    handleType(data){
        this.props.handleType(data);
        this.setState({
            selected:data
        })
    }
    render(){
        let {categories,loading,selected,open} = this.state;
        let{required,error,locale,size} = this.props;
        return (
            <>
                <Autocomplete
                    fullWidth
                    open={open}
                    onOpen={() => {
                        this.setState({open:true})
                    }}
                    onClose={() => {
                        this.setState({open:false})
                    }}
                    required={required}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => this.handleType(value)}
                    options={categories}
                    value={selected}
                    loading={loading}
                    size={!!size ? size : ''}
                    disabled={this.props.disabled}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={translate('category')}
                            variant="outlined"
                            required={required}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <React.Fragment>
                                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                                        {params.InputProps.endAdornment}
                                    </React.Fragment>
                                ),
                            }}
                            error={error}
                            helperText={!!error && error}
                        />
                    )}
                />
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,

});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(ParentCategoryChooser))));