import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import Loader from "../../../components/elements/Loader";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, setStore} from "trim-redux";
import { withSnackbar } from 'notistack';
import SubTitle from "../../../components/elements/SubTitle";
import LoadingButton from "../../../components/elements/LoadingButton";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import Title from "../../../components/elements/Title";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Permission from "../../../components/elements/Permission";
import Rating from "@material-ui/lab/Rating/Rating";
import Typography from "@material-ui/core/Typography/Typography";
import HeaderTags from "../../../components/elements/HeaderTags";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Button from "@material-ui/core/Button/Button";
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import Divider from "@material-ui/core/Divider/Divider";
import {translate} from "../../../../config/lang";
import ParentCategoryHolder from "../../../components/fields/ParentCategoryHolder";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";

class MakeFAQ extends React.Component {
    state = {
        fields:{
            question:'',
            answer:'',
        },
        loading: true,
        SubmitLoading:false,
        id: this.props.id,
        errors:[],
        defaultCategory:null,
        categoryId:null,
        categories:[],
    };

    classes = this.props.classes;
    async componentDidMount(){
        let {id} = this.props;
        if(!!id){
            await this.loadData();
        }
        await this.loadParents();

    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {id} = this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/faq/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            question: response.data.question,
                            answer: response.data.answer,
                        },
                        category: response.data.category,
                        categoryId: response.data.category_id,
                    })
                    return true
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    async loadParents(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/faq_categories/getAll/${this.props.locale}`, config)
                .then(response => {
                    let categories = response.data;
                    categories.map((item,index) =>{
                        return item.id === this.state.categoryId ? this.setState({
                            defaultCategory: index,
                        }) : null
                    })
                    this.setState({
                        categories: response.data,
                        loading:false,
                    })
                })
                .catch(error => {
                    this.setState({
                        loading:false,
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false,
                SubmitLoading:false
            });
            this.setState({
                loading:false,
                SubmitLoading:false
            })
        }
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
            PreventingToExit:true
        });

    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //comment
        if(!fields.question) {
            formIsValid = false;
            errors['question'] = translate('emptyField');
        }
        if(!fields.answer) {
            formIsValid = false;
            errors['answer'] = translate('emptyField');
        }
        if(!formIsValid){
            this.setState({
                SubmitLoading:false
            })
        }
        return callback(formIsValid)
    }
    handleParent(value){
        if(value === null){
            this.setState({
                categoryId:''
            })
        }else{
            this.setState({
                categoryId:value.id
            })
        }
    }
    handleRequest(){
        let { fields,categories,categoryId } = this.state;
        let data = new FormData();
        data.append('id', this.state.id);
        data.append('category_id',categoryId);
        data.append('question', fields.question);
        data.append('answer', fields.answer);
        data.append('lang', this.props.locale);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/faq/make`, data, config)
            .then(response => {
                this.setState({
                    fields:{
                        question:'',
                        answer:'',
                    },
                    loading: true,
                    SubmitLoading:false,
                    errors:[],
                });

                this.props.reload()
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('dataEditingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }
    render(){
        let {fields,errors,loading,SubmitLoading,categoryId,categories} = this.state;
        let {dialog} = this.props;
        return (
            <React.Fragment>
                <HeaderTags title={translate('makeField')}/>
                {
                    loading ? <Loader/> :
                        <React.Fragment>
                            <Grid item xs={12}>
                                <Grid container spacing={2} direction="row">
                                    <Grid item xs={12}>
                                        <Autocomplete
                                            options={categories}
                                            defaultValue={categories[this.state.defaultCategory]}
                                            onChange={(event, value) => this.handleParent(value)}
                                            getOptionLabel={option => option.name}
                                            renderInput={params => (
                                                <TextField
                                                    {...params}
                                                    label={translate('FAQCategory')}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                />
                                            )}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="question"
                                            label={translate('question')}
                                            type="text"
                                            value={fields.question}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['question']}
                                            helperText={errors['question']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            fullWidth
                                            name="answer"
                                            label={translate('answer')}
                                            type="text"
                                            multiline
                                            minRows={5}
                                            value={fields.answer}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['answer']}
                                            helperText={errors['answer']}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {translate('save')}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withSnackbar(withRouter(withWidth()(withStyles(Styles)(connect(mstp)(MakeFAQ)))));