import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Hidden from '@material-ui/core/Hidden';
import Box from '@material-ui/core/Box';
import Notifications from './Notifications';
import useStyles from './AppHeader.style';
import Logo from '../../../../files/img/logo-dark.png';
import HorizontalNav from './HorizontalNav';
import {connect, setStore} from "trim-redux";
import MyWallet from "../../elements/MyWallet";
import {defaultVariabls} from "../../../../config";
import {Translate} from "../../../../config/Translate";
import FavoriteBorderRoundedIcon from '@material-ui/icons/FavoriteBorderRounded';
import Feature from "../../elements/Feature";
import LanguageSwitcher from "../../elements/LanguageSwitcher";
import clsx from "clsx";
import {makeSlug} from "../../../../config/lang";
import Badge from "@material-ui/core/Badge/Badge";
const AppHeader = (props) => {
    const classes = useStyles();
    return (
        <>
            <AppBar className={classes.appbar} color='inherit'>
                <Toolbar className={classes.appToolbar}>
                    {
                        !props.hide &&
                        <Hidden lgUp>
                            <IconButton
                                edge='start'
                                className={classes.menuButton}
                                color='inherit'
                                aria-label='open drawer'
                                onClick={() => setStore({navCollapsed:!props.navCollapsed})}>
                                <MenuIcon className={classes.menuIcon} />
                            </IconButton>
                        </Hidden>
                    }

                    <Box style={{
                        cursor: "pointer",
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'center',
                    }}
                         component="a"
                         href={makeSlug('')}
                    >
                        <img
                            className={classes.LayoutLogo}
                            src={`${defaultVariabls.uploads}/FileManager/logo-light.png`}
                            alt={Translate.appName}
                        />
                    </Box>
                    <Hidden mdDown>
                        <Box className={classes.headerNav}>
                            <Box className={classes.headerContainer}>
                                <HorizontalNav />
                            </Box>
                        </Box>
                    </Hidden>

                    <Box className={classes.grow} />
                    {
                        !!props.user &&

                            <IconButton
                                color='inherit'
                                className={clsx(classes.notiBtn, 'notiBtn')}
                                component={"a"}
                                href={makeSlug('wishlist')}
                            >
                                <Badge badgeContent={props.user.wishlist_count} color="secondary">
                                <FavoriteBorderRoundedIcon/>
                                </Badge>
                            </IconButton>

                    }

                    <Box>
                        <Notifications />
                    </Box>

                    <LanguageSwitcher menu/>
                    {/*<Hidden mdUp>
                        <Button
                            size="small"
                            component="a"
                            variant="contained"
                            style={{marginRight:10}}
                            color="primary"
                            startIcon={<HomeRoundedIcon/>}
                            href={`${defaultVariabls.website}`}
                        >
                            {Translate.home}
                        </Button>
                    </Hidden>*/}
                </Toolbar>
            </AppBar>
        </>
    );
};
const mstp = state => ({
    navCollapsed: state.navCollapsed,
    logo: state.logo,
    user: state.user,
});
export default connect(mstp)(AppHeader);
