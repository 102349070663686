import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../../config/Translate";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import LoadingButton from "../../../../components/elements/LoadingButton";
import Grid from "@material-ui/core/Grid/Grid";
import {connect, setStore} from "trim-redux";

import Alert from "@material-ui/lab/Alert/Alert";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import withWidth from "@material-ui/core/withWidth/withWidth";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import Button from "@material-ui/core/Button/Button";
import {translate} from "../../../../../config/lang";
import ChooseAdComment from "../../comments/components/ChooseAdComment";
import SingleImageChooser from "../../../fileManager/Components/SingleImageChooser";
import SubTitle from "../../../../components/elements/SubTitle";
import MetaDescription from "../../../fileManager/Components/MetaDescription";

class MakeHomeBanner extends React.Component {

    state = {
        fields:{
            link:''
        },
        file: null,
        loading: true,
        SubmitLoading: false,
        active: true,
        id:!!this.props.id ? this.props.id : 0,
        editMode:!!this.props.id,
        errors:[],
        tab:"visual"
    };
    classes = this.props.classes;
    async componentDidMount(){
        await this.setState({
            id:this.props.id,
            editMode:!!this.props.id
        })
        if(!!this.props.id) {
            await this.loadData();
        }else{
            this.setState({
                loading:false
            })
        }
    }

    async loadData(){
        let {id} = this.state;
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/home-banner/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            link:response.data.link
                        },
                        editMode:true,
                        loading:false,
                        file: response.data.file,
                        active: response.data.active,
                    })

                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;

        this.setState({
            fields,
            errors
        });    
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;


        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { id,file,editMode,active,fields} = this.state;
        let data = new FormData();

        data.append('file_id', !!file ? parseInt(file.id) : 1);
        data.append('id', !!id ? id : 0);
        data.append('active',  active ? 1 : 0);
        data.append('link',  fields.link);
        data.append('manage',  this.props.manage);
        data.append('lang',  this.props.locale);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/home-banner/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(editMode ? translate('dataEdited') : translate('dataAdded'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                this.props.reload();

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(editMode ?  translate('dataEditingFailed') : translate('dataAddingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                    SubmitDraftLoading: false
                });
            });

    }

    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }

    handleChangeActive(){
        this.setState({
            active: !this.state.active,
        })
    }

    handleChangeField(field,val){
        let {fields}= this.state;
        fields[field] = val;
        this.setState({
            fields
        })
    }
    handleThumbImage(file){
        this.setState({
            file
        })
    }
    handleAd(value){
        this.setState({
            ad_id:!!value ? value.id : null
        })
    }
    render(){
        let {fields, file,SubmitLoading,errors,tab,active,loading} = this.state;
        let {user} = this.props;
        return (
            <div className="modifySinglePricingHolder">
                {
                    loading ?
                        <div style={{paddingTop:50,paddingBottom:50,textAlign:"center"}}>
                            <CircularProgress size={35}/>
                        </div>
                        :
                        <Grid container spacing={2}  justifyContent="center">
                            <Grid item xs={12} md={10}>
                                <Tabs
                                    value={tab}
                                    onChange={(e,val) => this.setState({tab:val})}
                                    indicatorColor="primary"
                                    textColor="primary"
                                    centered
                                >
                                    <Tab label={translate('slideShowImages')} value="visual"/>
                                    <Tab label={translate('SlideShowContent')} value="content" />
                                </Tabs>
                            </Grid>
                            <>
                            {
                                tab === "visual" ?
                                    <>

                                        <Grid item xs={12} md={10}>
                                            <SubTitle align="center">{translate('banner_image')}</SubTitle>
                                            <SingleImageChooser
                                                primary
                                                onChange={(file) => this.handleThumbImage(file)}
                                                initial={file}
                                                defined_width={this.props.defined_width}
                                                defined_height={this.props.defined_height}
                                            />
                                            <MetaDescription style={{marginTop:10}} width={this.props.defined_width} height={this.props.defined_height} size={"10MB"}/>

                                        </Grid>
                                        <Grid item xs={12} md={10} style={{textAlign:"center"}}>
                                            <Button
                                                onClick={() => this.setState({tab:"content"})}
                                                variant="contained"
                                            >
                                                {translate('nextStep')}
                                            </Button>
                                        </Grid>
                                    </>
                                :
                                    <>
                                        <Grid item xs={12} md={10}>
                                            <Alert
                                                severity="info"
                                                action={
                                                    <FormControlLabel
                                                        control={
                                                            <Switch
                                                                checked={active}
                                                                onChange={this.handleChangeActive.bind(this)}
                                                                value={active}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={active ? translate('active') : translate('inactive')}
                                                    />
                                                }
                                            >
                                                {translate('activeStatus')}
                                            </Alert>
                                        </Grid>
                                        <Grid item xs={12} md={10}>
                                            <TextField
                                                variant="outlined"
                                                value={fields.link}
                                                fullWidth
                                                size="small"
                                                label={translate('link')}
                                                name="link"
                                                onChange={this.handleChange.bind(this)}
                                                error = {!!errors['link']}
                                                helperText={errors['link']}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <LoadingButton
                                                startIcon={<SaveRoundedIcon/>}
                                                variant="contained"
                                                color="primary"
                                                onClick = {this.handleSubmit.bind(this)}
                                                loading = {SubmitLoading}
                                            >
                                                {translate('save')}
                                            </LoadingButton>
                                        </Grid>
                                    </>
                            }

                            </>

                        </Grid>
                }

            </div>

        );
    }
}

const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_color: state.primary_color,
    locale: state.locale,
});
export default withWidth()(withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeHomeBanner)))));