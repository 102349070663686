import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import Grid from "@material-ui/core/Grid/Grid";
import AppCardLoaderFields from "../../components/Skeletons/AppCardLoaderFields";
import AppCard from "../../components/layouts/components/AppCard";
import AppAnimate from "../../components/AppAnimate";
import {makeSlug, translate} from "../../../config/lang";
import {defaultVariabls} from "../../../config";
 import SubTitleLight from "../../components/elements/SubTitleLight";
import moment from "moment-timezone";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider/Divider";
import SubTitle from "../../components/elements/SubTitle";
import List from "@material-ui/core/List/List";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import {ListItem} from "@material-ui/core";
import {getMonths, getTimeZone} from "../../../config/values";
import Alert from "@material-ui/lab/Alert/Alert";
import Button from "@material-ui/core/Button/Button";
class MyPlanPage extends React.Component {
    classes = this.props.classes;
    state = {
        loading:true,
    }
    componentDidMount() {
        let {user} = this.props;
        if(!!user.plan_id){
            this.setState({
                loading:false
            })
        }else{
            this.props.history.push('/steps')
        }
    }

    render(){
        let {user} = this.props;
        let {loading} = this.state;

        return (
            <AppAnimate animation='transition.slideUpIn' delay={200} title={translate('myPlan')}>
                <Grid container spacing={2} justifyContent="center" alignItems="center">
                    {
                        loading ? <AppCardLoaderFields/> :
                            <Grid item xs={12} lg={11}>
                                <Grid container spacing={2} justifyContent="center">
                                    <Grid item xs={12}>
                                        <Alert
                                            severity="info"
                                            variant="outlined"
                                            action={
                                                <Button
                                                    size="small"
                                                    color="primary"
                                                    variant="contained"
                                                    component="a"
                                                    href={makeSlug('buy-plan')}
                                                >
                                                    {translate('plansPage')}
                                                </Button>
                                            }
                                        >
                                            {translate('forPurchasingBetterPlansAdsAndReserveGoToPlans')}
                                        </Alert>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <AppCard>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} className="my-plan-container">
                                                    <img
                                                        src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`} />
                                                    <SubTitleLight style={{color:user.plan.parent.color}}>{this.props.locale === "en" ? user.plan.parent.english_name : user.plan.parent.name}</SubTitleLight>
                                                    {
                                                        !!user.plan_usage.finishes_at &&
                                                        <span style={{color:user.plan.parent.color}}>{translate('planValidity')} <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong></span>
                                                    }
                                                </Grid>
                                            </Grid>
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <AppCard style={{height:"100%"}}>
                                            <SubTitle align="left" style={{position:"relative",marginBottom:33}}>
                                                {translate('planInfo')}
                                            </SubTitle>
                                            <Typography className='date-holder'>
                                                <span>{translate('monthlyAdCount')}</span>
                                                <strong className={this.classes.primary_color}>{parseInt(user.ad_count) - parseInt(user.plan_usage.ad_count)}</strong>
                                            </Typography>
                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                            <Typography className='date-holder'>
                                                <span>{translate('monthlyEditCount')}</span>
                                                <strong className={this.classes.primary_color}>{parseInt(user.edit_count) - parseInt(user.plan_usage.edit_count)}</strong>
                                            </Typography>
                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                            <Typography className='date-holder'>
                                                <span>{translate('philmag')}</span>
                                                <strong className={this.classes.primary_color}>{parseInt(user.plan.philmag)}</strong>
                                            </Typography>
                                            <Divider style={{marginTop:10,marginBottom:10}}/>
                                            <Typography className='date-holder'>
                                                <span>{translate('photography')}</span>
                                                <strong className={this.classes.primary_color}>{parseInt(user.plan.photography)}</strong>
                                            </Typography>
                                        </AppCard>
                                    </Grid>
                                    <Grid item xs={12} md={8}>
                                        <AppCard>
                                            <SubTitle align="left">{translate('planFeatures')}</SubTitle>
                                            <List dense>
                                                {
                                                    !!user.plan.purchase_ad &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('purchase_ad')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_discount &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_discount')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_business_page &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_business_page')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_ad &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_ad')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_teaser &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_teaser')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_introducing_video &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_introducing_video')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_banner_design &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_banner_design')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_commercial_banner &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_commercial_banner')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.has_support &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('has_support')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                                {
                                                    !!user.plan.verify_badge &&
                                                    <ListItem>
                                                        <ListItemText>
                                                            {translate('verify_badge')}
                                                        </ListItemText>
                                                        <ListItemSecondaryAction>
                                                            <CheckRoundedIcon />
                                                        </ListItemSecondaryAction>
                                                    </ListItem>
                                                }
                                            </List>
                                        </AppCard>
                                    </Grid>
                                    {
                                        !!user.reserved_plan_id &&
                                        <Grid item xs={12}>
                                            <AppCard>
                                                <SubTitle align="left">
                                                    {translate('reserved_plan')}
                                                </SubTitle>
                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                <Typography style={{color:user.reserved_plan.parent.color,display:"flex",alignItems:"center"}}>
                                                    <img style={{width:24,marginRight:10}} src={`${defaultVariabls.uploads}/FileManager/${user.reserved_plan.parent.thumbnail.name}.${user.reserved_plan.parent.thumbnail.extension}`} />
                                                    <span>{this.props.locale === "en" ? user.reserved_plan.parent.english_name : user.reserved_plan.parent.name} ({getMonths(user.reserved_plan.duration)} Months)</span>
                                                </Typography>
                                                <Divider style={{marginTop:10,marginBottom:10}}/>
                                                <Typography className='date-holder'>
                                                    <span>{translate('staringDate')}</span>
                                                    <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong>
                                                </Typography>
                                            </AppCard>
                                        </Grid>
                                    }
                                </Grid>
                            </Grid>
                    }
                </Grid>
            </AppAnimate>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MyPlanPage))));
