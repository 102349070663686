import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Link, withRouter} from 'react-router-dom';
import Loader from "../../../components/elements/Loader";
import TextField from "@material-ui/core/TextField/TextField";
import validator from "validator";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {connect, setStore} from "trim-redux";
import { withSnackbar } from 'notistack';
import SubTitle from "../../../components/elements/SubTitle";
import LoadingButton from "../../../components/elements/LoadingButton";
import BreadCrumbs from "../../../components/elements/BreadCrumbs";
import Title from "../../../components/elements/Title";
import FormControlLabel from "@material-ui/core/FormControlLabel/FormControlLabel";
import Switch from "@material-ui/core/Switch/Switch";
import Permission from "../../../components/elements/Permission";
import Rating from "@material-ui/lab/Rating/Rating";
import Typography from "@material-ui/core/Typography/Typography";
import HeaderTags from "../../../components/elements/HeaderTags";
import withWidth from "@material-ui/core/withWidth/withWidth";
import Button from "@material-ui/core/Button/Button";
import ChatBubbleOutlineRoundedIcon from '@material-ui/icons/ChatBubbleOutlineRounded';
import Divider from "@material-ui/core/Divider/Divider";
import {Translate} from "../../../../config/Translate";

class EditComment extends React.Component {
    state = {
        fields:{
            name:"",
            email:"",
            comment:'',
        },
        loading: true,
        rating: 4.5,
        SubmitLoading:false,
        confirmed:false,
        user:null,
        shop:null,
        id: null,
        parent: null,
        errors:[],
    };

    classes = this.props.classes;
    async componentDidMount(){
        let {dialog,id} = this.props;
        if(dialog){
            await this.setState({
                id:id
            })
        }else{
            await this.setState({
                id:this.props.match.params.id
            })
        }
        await this.loadData();
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {id} = this.state;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/shop-comment/get/${id}`, config)
                .then(response => {
                    this.setState({
                        fields: {
                            name: response.data.name,
                            email: response.data.email,
                            comment: response.data.comment,
                        },
                        rating: response.data.rate,
                        confirmed: response.data.confirmed,
                        user:response.data.user,
                        shop:response.data.shop,
                        parent:response.data.parent,
                        loading: false

                    })
                    return true
                }).catch(error => {
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    handleChangeConfirmed(){
        this.setState({
            confirmed: !this.state.confirmed,
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
            PreventingToExit:true
        });

    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //name
        if(validator.isEmpty(fields.name)){
            formIsValid = false;
            errors['name'] = Translate.emptyField;
        }else if(!validator.isByteLength(fields.name,{min:0, max:255})){
            formIsValid = false;
            errors['name'] = Translate.maxLength255;
        }
        //comment
        if(fields.comment !== null ) {
            if (fields.comment.length > 1000) {
                formIsValid = false;
                errors['comment'] = Translate.maxLength1000;
            }
        }
        if(!formIsValid){
            this.setState({
                SubmitLoading:false
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        let { fields,confirmed,rating } = this.state;
        let data = new FormData();
        data.append('id', this.state.id);
        data.append('name', fields.name);
        data.append('email', fields.email);
        data.append('comment', fields.comment);
        data.append('rate', rating);
        data.append('confirmed', confirmed ? 1 : 0);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/shop-comment/edit`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.commentEdited, {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                })
                if(this.props.dialog)
                this.props.handleDone()
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.commentEditingFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    loading:true
                });
                this.handleRequest();
            }
        });
    }

    handleRemoveItem(){
        let data = new FormData();
        data.append('id', this.state.id);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/shop-comment/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(Translate.commentRemoved, {
                    variant: 'success',
                });
                this.setState({
                    PreventingToExit:false,
                    SubmitLoading:false
                })
                this.props.handleDone();
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.commentEditingFailed, {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                })
            });

        this.setState({
            loading:false,
            SubmitLoading:false
        });
    }

    render(){
        let {fields, confirmed,errors,loading,rating,SubmitLoading,user,shop,parent} = this.state;
        let {dialog} = this.props;
        return (
            <React.Fragment>
                <HeaderTags title={Translate.editComment}/>
                {
                    loading ? <Loader/> :
                        <React.Fragment>
                            {
                                !dialog ?
                                    <Grid item xs={12}>
                                        <BreadCrumbs  firstText={Translate.comments} firstLink="/setting/comments" secondText={Translate.editComment}/>
                                    </Grid>
                                    : null
                            }

                            <Grid item xs={12}>
                                <Grid container spacing={1} direction="row" className={dialog ? null :this.classes.GridAsaPaper}>
                                    {
                                        !dialog ?
                                            <Grid item xs={12} className="TitleContainer">
                                                <Title>{Translate.editComment} <ChatBubbleOutlineRoundedIcon style={{float:'left'}}/></Title>
                                            </Grid>
                                            : null
                                    }
                                    {
                                        !!parent ?
                                            <Grid item xs={12}>
                                                <Typography variant="body2" color="textSecondary"> {Translate.parentCommentDesc}</Typography>
                                                <Typography>{Translate.user}: {parent.name}</Typography>
                                                <Typography>{Translate.comment}: {parent.comment}</Typography>
                                                <Divider/>
                                            </Grid>
                                            : null
                                    }
                                    <Grid item xs={12}>
                                        <Typography>
                                            {Translate.shop}: <Link className="link" to={`/edit/shop/${shop.id}`}>{shop.name}</Link>
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        {
                                            !!user ?
                                                <Typography>
                                                    {Translate.user}: <Link className="link" to={`/edit/user/${user.id}`}>{user.first_name} {user.last_name}</Link>
                                                </Typography>
                                                : null
                                        }

                                    </Grid>
                                    <Grid item xs={12}>
                                        <Typography>{Translate.shopRating}:</Typography>
                                        <Rating
                                            name="rating"
                                            precision={0.5}
                                            value={rating}
                                            onChange={(event, newValue) => {
                                                this.setState({
                                                    rating:newValue,
                                                })
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12} className="field-sticky">
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    required
                                                    fullWidth
                                                    name="name"
                                                    label={Translate.userName}
                                                    type="text"
                                                    id="name"
                                                    value={fields.name}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['name']}
                                                    helperText={errors['name']}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <TextField
                                                    variant="outlined"
                                                    margin="normal"
                                                    fullWidth
                                                    name="email"
                                                    label={Translate.email}
                                                    type="email"
                                                    id="email"
                                                    value={fields.email}
                                                    onChange={this.handleChange.bind(this)}
                                                    error = {!!errors['email']}
                                                    helperText={errors['email']}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} direction="row">

                                    <Grid item xs={12}>
                                        <TextField
                                            variant="outlined"
                                            margin="normal"
                                            multiline
                                            fullWidth
                                            name="comment"
                                            label={Translate.comment}
                                            type="text"
                                            id="comment"
                                            rows="4"
                                            value={fields.comment}
                                            onChange={this.handleChange.bind(this)}
                                            error = {!!errors['comment']}
                                            helperText={errors['comment']}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <Grid container  direction="row" className={dialog ? null : this.classes.GridAsaPaper}>
                                    <Permission rule="manageSettings">
                                        <Grid item xs={5} md={2}>
                                            <SubTitle>{Translate.confirmStatus} : </SubTitle>
                                        </Grid>
                                        <Grid item xs={7} md={4}>
                                            <FormControlLabel
                                                style={{marginRight:10}}
                                                control={
                                                    <Switch
                                                        checked={confirmed}
                                                        onChange={this.handleChangeConfirmed.bind(this)}
                                                        value={confirmed}
                                                        color="primary"
                                                    />
                                                }
                                                label={confirmed ? Translate.confirmed : Translate.unConfirmed}
                                            />
                                        </Grid>
                                    </Permission>
                                    <Grid item xs={12} md={6}>
                                        <Permission rule="remove">
                                            <Button
                                                style={{color:"#ef6b2c",float:'left'}}
                                                onClick={this.handleRemoveItem.bind(this)}
                                            >
                                                {
                                                    Translate.removeComment
                                                }
                                            </Button>
                                        </Permission>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <LoadingButton
                                            variant="contained"
                                            color="primary"
                                            onClick = {this.handleSubmit.bind(this)}
                                            loading = {SubmitLoading}
                                        >
                                            {Translate.editComment}
                                        </LoadingButton>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </React.Fragment>
                }
            </React.Fragment>

        );
    }
}
const mstp = state => ({
    user: state.user
});
export default withSnackbar(withRouter(withWidth()(withStyles(Styles)(connect(mstp)(EditComment)))));