import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {connect, getStore, setStore} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import {Link} from 'react-router-dom';
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import GetConfirmationButton from "../../../elements/GetConfirmationButton";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {translate} from "../../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import PermContactCalendarTwoToneIcon from '@material-ui/icons/PermContactCalendarTwoTone';
import withTheme from "@material-ui/core/styles/withTheme";
import moment from "moment-timezone";
import Badge from "@material-ui/core/Badge/Badge";
import {getTimeZone, inviteText} from "../../../../../config/values";
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import ShareTwoToneIcon from '@material-ui/icons/ShareTwoTone';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {withSnackbar} from "notistack";
import Popover from "@material-ui/core/Popover/Popover";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import {Telegram} from "@material-ui/icons";
import InvitationArea from "./InvitationArea";
const useStyles = makeStyles((theme) => {
  return {
    crUserInfo: {
      paddingTop: 9,
      paddingBottom: 9,
        minHeight: 110,
      display: 'flex',
        backgroundColor:getStore('mode') === "dark" ? "#424242" : "#c7badf",
      flexDirection: 'column',
      justifyContent: 'center',
      [theme.breakpoints.up('sm')]: {
        paddingTop: 10,
        paddingBottom: 10,
      },
    },
    profilePic: {
      fontSize: 24,
    },
    userInfo: {
      width: 'calc(100% - 75px)',
    },
    userName: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      fontSize: 16,
      color: '#fff',
    },
    designation: {
      marginTop: -2,
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      color: theme.palette.text.secondary,
    },
    pointer: {
      cursor: 'pointer',
    },
      link:{
        cursor:"pointer"
      }
  };
});

const handleCopy = (props,text) =>{
    navigator.clipboard.writeText(text.toUpperCase());
    props.enqueueSnackbar(translate('invite_code_copied'), {
        variant: 'info',
    });
}
const UserInfo = (props) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const open = Boolean(anchorEl);

    const handleLogout = () => {
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        window.location.replace(`${defaultVariabls.website}/logOutFromPanel`);
    };
  const classes = useStyles();
  let {user,primary_color} = props;
  return (
      <Box
          px={{xs: 2, xl: 4}}
          className={clsx(classes.crUserInfo, 'cr-user-info')}>
          <Grid container spacing={2} alignItems="start" justifyContent="between" style={{position:"relative"}}>
              <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="center" justifyContent="center">
                      <Grid item xs={4} style={{textAlign:'center'}}>
                          {
                              (!!user.plan && user.role.type !== "admin") ?
                                  <Link to="/account/details"
                                        onClick={() => setStore({
                                            navCollapsed: false
                                        })}
                                  >
                                      <Badge
                                          overlap="circular"
                                          anchorOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'right',
                                          }}
                                          className="plan_badge"
                                          badgeContent={<img style={{width: 20}}
                                                             src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}.${user.plan.parent.thumbnail.extension}`}/>}
                                      >
                                          <Avatar style={{width: 70, height: 70, border: "2px solid #4d0267"}}
                                                  className={classes.profilePic}
                                                  src={`${defaultVariabls.uploads}/FileManager/${user.avatar.name}_small.${user.avatar.extension}`}
                                                  alt={user.name}/>
                                      </Badge>
                                  </Link> :
                                  <Link to="/account/details"
                                        onClick={() => setStore({
                                            navCollapsed: false
                                        })}
                                  >
                                      <Avatar style={{width: 70, height: 70, border: "2px solid #4d0267"}}
                                              className={classes.profilePic}
                                              src={`${defaultVariabls.uploads}/FileManager/${user.avatar.name}_small.${user.avatar.extension}`}
                                              alt={user.name}/>
                                  </Link>
                          }
                      </Grid>
                      <Grid item xs={8} style={{padding:0,paddingLeft:8}}>
                          <Typography style={{color:props.theme.palette.primary.main,marginRight:10,fontSize:13,lineHeight:1.2}} className="only-one-line" >
                              {user.first_name} {user.last_name}
                          </Typography>
                          <Typography varaint="body2"  style={{fontSize:12,color:"#fff",marginTop:2}}>
                              {
                                  user.role.type === "admin" ?
                                      <Button
                                          color="secondary"
                                          size="small"
                                          startIcon={<PermContactCalendarTwoToneIcon/>}
                                      >
                                          {translate(user.role.name)}
                                      </Button> :
                                      <>
                                          {
                                              !!user.plan ?
                                                  <Link
                                                      to={'/my-plan'}
                                                      style={{display:"flex",flexDirection:"column"}}
                                                      onClick={() => setStore({
                                                          navCollapsed: false
                                                      })}
                                                  >
                                                    <span style={{display:"flex",alignItems:"center"}}>
                                                        <span style={{color:user.plan.parent.color}}>{props.locale === "en" ? user.plan.parent.english_name : user.plan.parent.name}</span>
                                                    </span>
                                                      {
                                                          !!user.plan_usage.finishes_at &&
                                                          <span style={{fontSize:10,color:user.plan.parent.color}}>{translate('planValidity')} <strong>{moment.tz(user.plan_usage.finishes_at,'UTC').tz(getTimeZone()).format( 'YYYY/M/D')}</strong></span>
                                                      }
                                                  </Link>
                                                   :
                                                  <Button
                                                      color="primary"
                                                      size="small"
                                                      style={{marginTop:5,whiteSpace:"nowrap"}}
                                                      variant="contained"
                                                      component={Link}
                                                      to={"/steps"}
                                                      onClick={() => setStore({
                                                          navCollapsed: false
                                                      })}
                                                  >
                                                      {translate('registerBusiness')}
                                                  </Button>
                                          }
                                      </>
                              }

                          </Typography>
                          <Typography
                              varaint="body2"
                              color="primary"
                              style={{
                                  fontSize:11,
                                  border: "1px dashed",
                                  padding:"2px 4px",
                                  borderRadius:4,
                                  marginTop:5,
                                  display:"flex",
                                  justifyContent:"space-between",
                                  alignItems:"center"
                                }}
                          >
                              <span style={{display:"flex",whiteSpace:"nowrap"}}>
                                  <span>{translate('invite_code')} </span>: <strong style={{textTransform:"uppercase",fontSize:12,marginLeft:2}}>{user.invite_code}</strong>
                              </span>
                              <span>
                                  <IconButton
                                      size="small"
                                      color="primary"
                                      onClick={() => handleCopy(props,user.invite_code)}
                                  >
                                      <FileCopyTwoToneIcon  style={{fontSize: "1rem"}}/>
                                  </IconButton>
                                  <IconButton size="small" color="primary" onClick={handleClick}>
                                      <ShareTwoToneIcon style={{fontSize: "1rem"}}/>
                                  </IconButton>
                                  <Popover
                                      open={open}
                                      anchorEl={anchorEl}
                                      onClose={handleClose}
                                  >
                                    <InvitationArea/>
                                  </Popover>
                              </span>
                          </Typography>
                      </Grid>
                  </Grid>
              </Grid>
              <Grid item style={{position:"absolute",top:-8,right:-8}}>
                  <Grid container alignItems="start" justify="end">
                     {/* <Grid item xs={12}>
                          <ToggleDarkMode/>
                      </Grid>*/}
                      <Grid item xs={12}>
                          <GetConfirmationButton
                              handleAction={handleLogout}
                              question={translate('doYouWanaExit')}
                              icon={<ExitToAppRoundedIcon/>}
                              color={props.theme.palette.primary.main}
                              tooltip={translate('logOut')}
                              size="small"
                          >
                              <PowerSettingsNewRoundedIcon style={{color: getStore('mode') === 'dark' ? "#ddd" : "#333"}} fontSize="small"/>
                          </GetConfirmationButton>
                      </Grid>
                  </Grid>
              </Grid>
          </Grid>
      </Box>
  );
};
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    locale: state.locale,
    primary_color: state.primary_color,
});
export default withSnackbar(withTheme(connect(mstp)(UserInfo)));
