import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import Grid from "@material-ui/core/Grid/Grid";
import TextField from "@material-ui/core/TextField/TextField";
import axios from "axios";
import {defaultVariabls} from "../../../../../../config";
import LoadingButton from "../../../../../components/elements/LoadingButton";
import SaveRoundedIcon from '@material-ui/icons/SaveRounded';
import {LoadSetting} from "../../../../../../config/Security";
import {translate} from "../../../../../../config/lang";

class ManageContactInfo extends React.Component {
    classes = this.props.classes;
    state = {
        fields:{
            main_phone: this.props.about.main_phone,
            main_mobile: this.props.about.main_mobile,
            main_email: this.props.about.main_email,
            main_address: this.props.about.main_address,
            facebook: this.props.about.facebook,
            instagram: this.props.about.instagram,
            telegram: this.props.about.telegram,
            whatsapp: this.props.about.whatsapp,
            twitter: this.props.about.twitter,
            linkedin: this.props.about.linkedin,
            pinterest: this.props.about.pinterest,
            youtube: this.props.about.youtube,
            short_description: this.props.about.short_description,
            footer_text: this.props.about.footer_text,
            footer_certificates: this.props.about.footer_certificates,
            map_script: this.props.about.map_script,
        },
        SubmitLoading:false,
        errors:{}
    };
    handleChange(e){
        let {errors,fields} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({fields});
    }

    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;



        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }

    handleRequest(){
        let { fields} = this.state;
        let data = new FormData();
        data.append('main_phone', fields.main_phone);
        data.append('main_address', fields.main_address);
        data.append('main_email', fields.main_email);
        data.append('main_mobile', fields.main_mobile);
        data.append('facebook', fields.facebook);
        data.append('instagram', fields.instagram);
        data.append('twitter', fields.twitter);
        data.append('telegram', fields.telegram);
        data.append('whatsapp', fields.whatsapp);
        data.append('linkedin', fields.linkedin);
        data.append('pinterest', fields.pinterest);
        data.append('youtube', fields.youtube);
        data.append('short_description', fields.short_description);
        data.append('footer_certificates', fields.footer_certificates);
        data.append('footer_text', fields.footer_text);
        data.append('map_script', fields.map_script);
        data.append('lang', this.props.locale);


        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/abouts/save`, data, config)
            .then(async response => {
                await LoadSetting();
                await this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                await this.props.enqueueSnackbar(translate('allChangesSaved'), {
                    variant: 'success',
                });
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('allChangesSavingFailed'), {
                    variant: 'error',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleSubmit(e){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render(){
        let {errors,fields,SubmitLoading} = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.main_phone}
                        fullWidth
                        size="main_phone"
                        label={translate('main_phone')}
                        name="main_phone"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['main_phone']}
                        helperText={errors['main_phone']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.main_email}
                        fullWidth
                        size="main_email"
                        label={translate('main_email')}
                        name="main_email"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['main_email']}
                        helperText={errors['main_email']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.main_mobile}
                        fullWidth
                        size="main_mobile"
                        label={translate('main_mobile')}
                        name="main_mobile"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['main_mobile']}
                        helperText={errors['main_mobile']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.main_address}
                        fullWidth
                        size="main_address"
                        label={translate('main_address')}
                        name="main_address"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['main_address']}
                        helperText={errors['main_address']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.facebook}
                        fullWidth
                        size="facebook"
                        label={translate('facebook')}
                        name="facebook"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['facebook']}
                        helperText={errors['facebook']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.twitter}
                        fullWidth
                        size="twitter"
                        label={translate('twitter')}
                        name="twitter"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['twitter']}
                        helperText={errors['twitter']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.instagram}
                        fullWidth
                        size="instagram"
                        label={translate('instagram')}
                        name="instagram"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['instagram']}
                        helperText={errors['instagram']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.whatsapp}
                        fullWidth
                        size="whatsapp"
                        label={translate('whatsapp')}
                        name="whatsapp"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['whatsapp']}
                        helperText={errors['whatsapp']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.telegram}
                        fullWidth
                        size="telegram"
                        label={translate('telegram')}
                        name="telegram"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['telegram']}
                        helperText={errors['telegram']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.linkedin}
                        fullWidth
                        size="linkedin"
                        label={translate('linkedin')}
                        name="linkedin"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['linkedin']}
                        helperText={errors['linkedin']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.pinterest}
                        fullWidth
                        size="pinterest"
                        label={translate('pinterest')}
                        name="pinterest"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['pinterest']}
                        helperText={errors['pinterest']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.youtube}
                        fullWidth
                        label={translate('youtube')}
                        name="youtube"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['youtube']}
                        helperText={errors['youtube']}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        value={fields.short_description}
                        fullWidth
                        size="short_description"
                        label={translate('short_description')}
                        name="short_description"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['short_description']}
                        helperText={errors['short_description']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        value={fields.footer_text}
                        fullWidth
                        multiline
                        minRows={3}
                        size="footer_text"
                        label={translate('footer_text')}
                        name="footer_text"
                        onChange={this.handleChange.bind(this)}
                        error = {!!errors['footer_text']}
                        helperText={errors['footer_text']}
                    />
                </Grid>
                <Grid item xs={12}>
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        startIcon={<SaveRoundedIcon/>}
                        onClick={this.handleSubmit.bind(this)}
                        loading={SubmitLoading}
                    >
                        {translate('saveChanges')}
                    </LoadingButton>
                </Grid>
            </Grid>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
    about: state.about,
    setting: state.setting,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(ManageContactInfo)))));