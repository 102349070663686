import {Translate} from "../Translate";
import {defaultVariabls} from "../index";

const topMenuRoutesConfig = [
  {
    id: 'homePage',
    title: Translate.homePage,
    type: 'item',
    url: `${defaultVariabls.website}`,
  },
    {
        id: 'shop',
        title: Translate.shopPage,
        type: 'item',
        url: `${defaultVariabls.website}/shop`,
    },
    {
        id: 'contact',
        title: Translate.contactPage,
        type: 'item',
        url: `${defaultVariabls.website}/contact`,
    },
    {
        id: 'rules',
        title: Translate.rulesPage,
        type: 'item',
        url: `${defaultVariabls.website}/terms-and-conditions`,
    },
    {
        id: 'about',
        title: Translate.aboutPage,
        type: 'item',
        url: `${defaultVariabls.website}/about`,
    },
];
export default topMenuRoutesConfig;
