import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import clsx from 'clsx';
import {makeStyles} from '@material-ui/core';
import Box from '@material-ui/core/Box';
import {connect, getStore, setStore} from "trim-redux";
import {Translate} from "../../../../../config/Translate";
import {Link} from 'react-router-dom';
import {defaultVariabls} from "../../../../../config";
import Grid from "@material-ui/core/Grid/Grid";
import Typography from "@material-ui/core/Typography/Typography";
import PowerSettingsNewRoundedIcon from '@material-ui/icons/PowerSettingsNewRounded';
import GetConfirmationButton from "../../../elements/GetConfirmationButton";
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import {translate} from "../../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import PermContactCalendarTwoToneIcon from '@material-ui/icons/PermContactCalendarTwoTone';
import withTheme from "@material-ui/core/styles/withTheme";
import moment from "moment-timezone";
import Badge from "@material-ui/core/Badge/Badge";
import {getTimeZone, inviteText} from "../../../../../config/values";
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import FileCopyTwoToneIcon from '@material-ui/icons/FileCopyTwoTone';
import ShareTwoToneIcon from '@material-ui/icons/ShareTwoTone';
import IconButton from "@material-ui/core/IconButton/IconButton";
import {withSnackbar} from "notistack";
import Popover from "@material-ui/core/Popover/Popover";
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import SmsIcon from '@material-ui/icons/Sms';
import EmailIcon from '@material-ui/icons/Email';
import {Telegram} from "@material-ui/icons";

const InvitationArea = (props) => {

    return (
        <div
        style={{padding:"5px 15px"}}>
            <IconButton
                size="small"
                color="primary"
                component="a"
                href={`https://api.whatsapp.com/send?text=${inviteText()}`}
            >
                <WhatsAppIcon style={{fontSize:"1.1rem"}}/>
            </IconButton>
            <IconButton
                size="small"
                color="primary"
                component="a"
                href={`https://t.me/share/url?url=${defaultVariabls.website}&text=${inviteText()}`}
            >
                <Telegram style={{fontSize:"1.1rem"}}/>
            </IconButton>
            <IconButton
                size="small"
                component="a"
                color="primary"
                href={`sms:?body=${inviteText()}`}
            >
                <SmsIcon style={{fontSize:"1.1rem"}}/>
            </IconButton>
            <IconButton
                size="small"
                color="primary"
                component="a"
                href={`mailto:?subject=${translate('invite_text')}&body=${inviteText()}`}
            >
                <EmailIcon style={{fontSize:"1.1rem"}}/>
            </IconButton>
        </div>
    );
};
const mstp = state => ({
    isAuth: state.isAuth,
});
export default withSnackbar(withTheme(connect(mstp)(InvitationArea)));
