import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import { withSnackbar } from 'notistack';
import ChooseMobile from './components/LoginWithToken/ChooseMobile'
import {defaultVariabls} from "../../../config";
import axios from "axios";
import {connect, getStore, setStore} from "trim-redux";
import LoginUser from "./components/LoginWithToken/LoginUser";
import HeaderTags from "../../components/elements/HeaderTags";
import Box from "@material-ui/core/Box/Box";
import AuthLayout from "../../components/layouts/AuthLayout";
import ScreenLockPortraitTwoToneIcon from '@material-ui/icons/ScreenLockPortraitTwoTone';
import {translate} from "../../../config/lang";
import queryString from "query-string";
import Register from "./Register_old_new";

class LoginWithToken extends React.Component {
    constructor(props){
        super(props);
        const values = queryString.parse(this.props.location.search)
        let redirect = values.r;
        if(this.props.isAuth){
            this.handleLogout();
        }
        let lang = values.lang;
        if(!!lang){
            if(lang !== getStore('locale')){
                setStore({
                    lang
                });
                if(lang === "fa"){
                    localStorage.setItem('locale','fa');
                    window.location.reload(true);
                }else{
                    localStorage.setItem('locale','en');
                    window.location.reload(true);
                }
            }
        }else{
            localStorage.setItem('locale','fa');
        }
        let mode = values.mode;
        if(!!mode){
            localStorage.setItem('mode',mode);
        }
        this.state={
            level:1,
            user:null,
            lang: !!lang ? lang : "fa",
            mode: !!mode ? mode : "light",
            redirect: !!redirect ? redirect : null
        };
    }
    handleLogout(){
        localStorage.removeItem('ACT');
        localStorage.removeItem('RET');
        setStore({
            isAuth : false,
            user : null,
        });
    }
    setNextLevel(){
        this.setState({
            level: this.state.level+1
        })
    }
    async setUser(user){
        await this.setState({
            user
        })

    }
    async setUserWithLevel(user){
        await this.setState({
            level:1
        })
        await this.setState({
            user
        })
        await this.setState({
            level:2
        })
    }
    makeLogin(data){
        localStorage.setItem('ACT',data.accessToken);
        this.checkLogin();
    }
    async checkLogin(){
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/checkLogin/${getStore('locale')}`, config)
                .then(response => {
                    if(response.data.name === null){
                        localStorage.removeItem('ACT');
                        this.setState({
                            loading:false,
                            level:3
                        })
                    }else{
                        setStore({
                            isAuth : true,
                            user : response.data,
                        });
                        this.setState({
                            loading:false
                        })
                    }

                }).catch(error => {
                setStore({
                    isAuth : false,
                    user : null,
                    loading:false
                });
                this.setState({
                    loading:false
                })
            })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
        if (this.props.isAuth){
            let code = this.props.user.login_token;
            let {iframe} = this.props;
            let token = localStorage.getItem('ACT');
            let {lang,mode} = this.state;
            if(iframe){
                await window.parent.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }else{
                await window.location.replace(`${defaultVariabls.website}/loginFromPanel/${this.props.user.email}/${token}/${code}/${lang}/${mode}`);
            }
        }
    }
    render() {
        const {classes,iframe} = this.props;
        const {level,user} = this.state;
        return (
            <AuthLayout iframe={iframe}>
                <HeaderTags title={translate('login')}/>
            <Grid item  md={10} lg={9}>
                <Box mb={{xs: 3, xl: 8}}  fontSize={20}>
                    <strong className={classes.auth_title}>
                        <ScreenLockPortraitTwoToneIcon className={classes.me10} color="secondary"/>
                        {level === 3 ? translate('register') : translate('login')}
                        </strong>
                </Box>
                    {
                        level === 1 ? <ChooseMobile iframe={iframe} hasBack setUser={this.setUser.bind(this)} setNextLevel={this.setNextLevel.bind(this)}/> : null
                    }
                    {
                        level === 2 ? <LoginUser user={user}  makeLogin={this.makeLogin.bind(this)} setUser={this.setUserWithLevel.bind(this)} /> : null
                    }
                {
                    level === 3 &&  <Register iframe={iframe} user_id={user.id}/>
                }
            </Grid>
            </AuthLayout>
        );

    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(LoginWithToken))));
