import queryString from "query-string";
import {getStore, setStore} from "trim-redux";
import {translate} from "./lang";

export const howToSale = ["روش فروش ۵","روش فروش ۴","روش فروش ۳","روش فروش ۲","روش فروش ۱"];

export function toEnglishDigits(str) {

    let  e = '۰'.charCodeAt(0);
    str = str.replace(/[۰-۹]/g, function(t) {
        return t.charCodeAt(0) - e;
    });
    return str;
}

export function price(x) {
    if(!!x){
        let res = Math.round((x + Number.EPSILON) * 100) / 100
        return res.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");

    }else {
        return x
    }
}

export function truncate(str, n){
    if(!!str){
        return (str.length > n) ? str.substr(0, n-1) + '...' : str;
    }else{
        return ''
    }
};
export function changeURL(queryStrong) {
    let url = queryString.stringifyUrl({url: window.location.href, query: queryStrong});
    window.history.replaceState(null, "",url);
}
export const currencySymbol = 'AED';

export const AddressesLimit = 5;

export function makeRound(num){
    return Math.round((num + Number.EPSILON) * 100) / 100;
}
export function changeMode(mode){
    setStore({
        global_loading: true
    })
    if(mode === "dark"){
        mode = 'dark';
    }else{
        mode = 'light';
    }
    setStore({
        mode:mode
    });
    localStorage.setItem('mode',mode);
    setTimeout(function() {
        setStore({
            global_loading: false
        })
    }, 200);
}
export function getMonths(days){
    return Math.round(days / 30);

}
export function cleanText(t) {
    return t.toLowerCase().replace(/[^a-zA-Z0-9]+/g, "");
}
export function toFindDuplicates(arry){
    return arry.filter((item, index) => arry.indexOf(item) !== index)
}
export  function writeOrderType(items){
    let text = '';
    let has_discount = false;

    Object.keys(items).forEach(function (item) {
        if(items[item].type === "discount"){
            if(!has_discount){
                text += translate('buyDiscount')+" / "
            }else{
                has_discount = true;
            }
        }else if(items[item].type === "ad"){
            text += translate('buyAdvertisement')+" / "
        }else{
            text += translate('buyPlan')+" / "
        }
    });

     return  text.slice(0,-2);
}
export const scrollTop = () =>
    window.scrollTo({
        top: 0,
        behavior: "smooth"
    })
export const getTimeZone = () => {
    const {timeZone} = Intl.DateTimeFormat().resolvedOptions();
    return timeZone
}

export const inviteText = () =>{
    let user = getStore('user');
    let text = translate('invite_text_1');
    text += user.first_name+ " " + user.last_name;
    text += translate('invite_text_2');
    text += "%0D%0A";
    text += translate('invite_text_3');
    text += "%0D%0A";
    text += translate('invite_code')+": ";
    text += "%0D%0A";
    text += user.invite_code;
    return text;
}

