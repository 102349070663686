import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BackupIcon from '@material-ui/icons/Backup';
import Button from "@material-ui/core/Button/Button";
import {Translate} from "../../../../config/Translate";
import axios from "axios/index";
import {defaultVariabls} from "../../../../config/index";
import CircularProgress from '@material-ui/core/CircularProgress';
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import IconButton from "@material-ui/core/IconButton/IconButton";
import {translate} from "../../../../config/lang";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText/DialogContentText";
import TextField from "@material-ui/core/TextField/TextField";
import DialogActions from "@material-ui/core/DialogActions/DialogActions";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import LoadingButton from "../../../components/elements/LoadingButton";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import DoneRoundedIcon from '@material-ui/icons/DoneRounded';
import ArrowBackIcon from "../../../components/elements/ArrowBackIcon";
import YouTubeIcon from '@material-ui/icons/YouTube';
class AddFile extends React.Component {
    state = {
        loading:false,
        open:false,
        ready:false,
        SubmitLoading:false,
        url:'',
        error:'',
        data:null

    }
    classes = this.props.classes;
    handleClickOpen(){
        this.setState({
            open:true,
        })
    }
    handleClickClose(){
        this.setState({
            open:false,
        })
    }
    handleChange(e){
        this.setState({
            url: e.target.value,
            error:''
        })
    }
    async checkURL(){
        this.setState({
            loading:true,
        })
        let {url} = this.state;
        if(!!url){
            if(url.length > 20){
                let data = new FormData();
                data.append('url', url);
                let config = {
                    headers: {
                        Accept: 'application/json',
                    }
                };
                await axios.post(`${defaultVariabls.url}/youtube/checkURL`, data, config)
                    .then(response =>{
                        console.log(response.data)
                        this.setState({
                            SubmitLoading:false,
                            loading:false,
                            data:response.data
                        })
                    })
                    .catch(error =>{
                        this.setState({
                            SubmitLoading:false,
                            loading:false,
                            error: translate('wrongURlForYoutube')
                        })
                    });
            }else{
                this.setState({
                    loading:false,
                    error: translate('wrongURlForYoutube')
                })
            }
        }else{
            this.setState({
                loading:false,
                error: translate('wrongURlForYoutube')
            })
        }


    }
    handleSubmit(){
        this.setState({
            SubmitLoading:true
        })
        let {url} = this.state;
        let data = new FormData();

        data.append('url',url);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/file/addYoutube`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('uploadSuccess'), {
                    variant: 'success',
                });
                this.setState({
                    open:false,
                    SubmitLoading:false,
                })
                this.props.Changed();
            })
            .catch(error => {
                console.log(error)
                this.props.enqueueSnackbar(translate('submitErrorFile'), {
                    variant: 'error',
                });
                this.setState({
                    open:false,
                    SubmitLoading:false,
                })
            });
    }
    render(){
        let {SubmitLoading,open,ready,error,url,loading,data} = this.state;
        return(
            <>
                <Tooltip title={translate('addFromYoutube')}>
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => this.handleClickOpen()}
                    startIcon={<YouTubeIcon/>}
                    className={this.classes.addImageBtn}
                >
                    {translate('youtube')}
                </Button>
                </Tooltip>
                <Dialog fullWidth maxWidth="sm" open={open} onClose={() => this.handleClickClose()} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{translate('addFromYoutube')}</DialogTitle>
                    <DialogContent>
                        {
                            !data &&
                                <>
                                    <DialogContentText className={this.classes.condir}>
                                        {translate('addYoutubeLinkHere')}
                                    </DialogContentText>
                                    <TextField
                                        autoFocus
                                        margin="normal"
                                        variant="outlined"
                                        label={translate('youtubeLink')}
                                        type="text"
                                        dir="ltr"
                                        fullWidth
                                        value={url}
                                        onChange={this.handleChange.bind(this)}
                                        onBlur={this.checkURL.bind(this)}
                                        error={!!error}
                                        helperText={error}
                                        InputProps={!!loading ? {
                                            endAdornment: <InputAdornment position="end">
                                                <CircularProgress size={22}/>
                                            </InputAdornment>,
                                        }: {
                                            endAdornment: <InputAdornment position="end">
                                                <IconButton>
                                                    <DoneRoundedIcon color="primary"/>
                                                </IconButton>
                                            </InputAdornment>,
                                        }}
                                    />
                                </>
                        }
                        {
                            !!data &&
                                <div>
                                    <Button
                                        startIcon={<ArrowBackIcon/>}
                                        size="small"
                                        onClick={() => this.setState({data: null,url:null})}
                                    >
                                        {translate('back')}
                                    </Button>
                                    <p>
                                        {data.snippet.title}
                                    </p>
                                    <div dangerouslySetInnerHTML={{__html:data.player.embedHtml}} />
                                </div>
                        }
                    </DialogContent>
                    <DialogActions>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            disabled={!data}
                            onClick={this.handleSubmit.bind(this)}
                            loading={SubmitLoading}
                        >
                            {translate('addVideo')}
                        </LoadingButton>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default withWidth()(withSnackbar(withStyles(Styles)(AddFile)));