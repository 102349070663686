import React from 'react';
import {Styles} from '../../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../../config/index";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import {Translate} from "../../../../../../config/Translate";
import withWidth from "@material-ui/core/withWidth/withWidth";
import AppCardLoaderFields from "../../../../../components/Skeletons/AppCardLoaderFields";
import Grid from "@material-ui/core/Grid/Grid";
import MakeSlideShowButton from "../MakeSlideShowButton";
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteButton from "../../../../../components/elements/DeleteButton";
import Card from "@material-ui/core/Card/Card";
import {translate} from "../../../../../../config/lang";

class SlideShowItem extends React.Component {

    onDelete(id){
        let data = new FormData();
        data.append('id',  id);
        this.setState({
            loading: false
        });
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/slideshow/delete`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('deleteData'), {
                    variant: 'success',
                });
                this.props.reload();

            })
            .catch(error => {
                console.log(error.response);
                this.loadData();
                this.props.enqueueSnackbar(translate('deleteDataFailed'), {
                    variant: 'error',
                });

                return false
            });
    }

    render(){
        let {item} = this.props;
        return (
            <div
                className="slide_show_item"
                style={{backgroundImage: `url("${defaultVariabls.uploads}/FileManager/${item.background.name}.${item.background.extension}")`,}}
            >
                {
                    item.active === 1 &&
                        <span className="active-badge">
                            {translate('active')}
                        </span>
                }
                {
                    !!item.advertisement &&
                    <h3>{item.advertisement.name}</h3>
                }

                <div className="controller">
                    <MakeSlideShowButton
                        id={item.id}
                        reload={() => this.props.reload()}
                        type={this.props.type}
                        defined_width={this.props.defined_width}
                        defined_height={this.props.defined_height}
                    />
                    <DeleteButton
                        size="small"
                        handleDelete={() => this.onDelete(item.id)}
                    />
                </div>
            </div>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SlideShowItem)))));