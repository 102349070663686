import React from 'react';
import Button from '@material-ui/core/Button';
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import {connect} from "trim-redux";
import Dialog from "@material-ui/core/Dialog/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent/DialogContent";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import Typography from "@material-ui/core/Typography/Typography";
import Grid from "@material-ui/core/Grid/Grid";
import {makeSlug, translate} from "../../../../config/lang";
import SubTitle from "../../../components/elements/SubTitle";
import {Link} from "react-router-dom";
import {defaultVariabls} from "../../../../config";

class CheckUserCanMakeAd extends React.Component{
    state= {
        dialogOpen: false,
        dialogDetailsOpen: false,
    };
    classes = this.props.classes;
    componentDidMount(){
        let {user,edit,confirmed} = this.props;
        if(edit && !!confirmed){
            console.log(confirmed);
            if(parseInt(user.edit_count) <= parseInt(user.plan_usage.edit_count)){
                this.setState({
                    dialogOpen:true,
                })
            }
        }else if(!edit){
            if(parseInt(user.ad_count) <=  parseInt(user.plan_usage.ad_count)){
                this.setState({
                    dialogOpen:true,
                })
            }
        }
        if(!user.business_name){
            this.setState({
                dialogDetailsOpen:true,
            })
        }
    }
    render(){
        let {dialogOpen,dialogDetailsOpen} = this.state;
        let {edit} = this.props;
        return (
            <>
                <Dialog
                    open={dialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title" className={this.classes.alertText}>
                        <SubTitle >{edit ? translate('youCantEditAd') : translate('youCantAddAd')}</SubTitle>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography className={this.classes.textFloat}>{translate('planLimitationDescription')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    href={makeSlug('plans')}
                                    component={"a"}
                                >
                                    {translate('viewPlans')}
                                </Button>
                                <Button
                                    size="small"
                                    onClick={() => this.props.history.goBack()}
                                >
                                    {translate("back")}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
                <Dialog
                    open={dialogDetailsOpen}
                    onClose={() => this.setState({dialogDetailsOpen:false})}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogTitle id="alert-dialog-title" className={this.classes.alertText}>
                        <SubTitle >{edit ? translate('youCantEditAd') : translate('youCantAddAd')}</SubTitle>
                    </DialogTitle>
                    <DialogContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography style={{textAlign:"center"}} className={this.classes.textFloat}>{translate('pleaseCompleteYourInfoFirst')}</Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    component={Link}
                                    to={'/account/details'}
                                >
                                    {translate('myAccount')}
                                </Button>
                            </Grid>
                        </Grid>
                    </DialogContent>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
});
export default withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(CheckUserCanMakeAd))));