import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import AppCard from "../../../components/layouts/components/AppCard";
import Grid from "@material-ui/core/Grid/Grid";
import {translate} from "../../../../config/lang";
import SubTitleLight from "../../../components/elements/SubTitleLight";
import Button from "@material-ui/core/Button/Button";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import TextField from "@material-ui/core/TextField/TextField";
import {cleanText, toFindDuplicates} from "../../../../config/values";
import LoadingButton from "../../../components/elements/LoadingButton";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {updateRedux} from "../../../../config/Security";
import {connect} from "trim-redux";
import {withRouter} from "react-router-dom";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import IconButton from "@material-ui/core/IconButton";
import Alert from "@material-ui/lab/Alert/Alert";
import Typography from "@material-ui/core/Typography";
import AlertTitle from "@material-ui/lab/AlertTitle/AlertTitle";
class MakeVouchers extends React.Component {
    classes = this.props.classes;
    state = {
        fields:{
            inventory:'',
        },
        codes: ['','','','','','','',''],
        discount_type:"system",
        errors:{},
        discount:this.props.discount,
        editMode:this.props.editMode,
    }
    componentDidMount() {
        this.setState({
            discount:this.props.discount,
            editMode:this.props.editMode,
        })
        if(!!this.props.editMode){
            this.setState({
                discount_type: this.props.discount.voucher_type,
            })
        }
    }
    handleChange(e){
        let {errors,fields,percent} = this.state;
        let target = e.target;
        fields[target.name] = target.value;
        errors[target.name] = "";
        this.setState({
            fields,
            percent
        });
    }
    handleRequest(){
        let { discount,fields,discount_type,codes} = this.state;
        let data = new FormData();
        data.append('id', !!discount ? discount.id : 0);
        data.append('inventory', fields.inventory);
        data.append('discount_type', discount_type);
        data.append('codes', JSON.stringify(codes));
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        let {user} = this.props;
        axios.post(`${defaultVariabls.url}/discount/make_voucher`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('discountEdited'), {
                    variant: 'success',
                });
                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
                updateRedux();

                this.props.reload();
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('discountEditingFailed'), {
                    variant: 'error',
                });

                this.setState({
                    loading:false,
                    SubmitLoading: false,
                });
            });

    }
    handleValidation(callback){
        let { fields,discount_type,codes, } = this.state;
        let errors = {};
        let formIsValid = true;
        if(discount_type === "system"){
            if(!fields.inventory){
                formIsValid = false;
                errors['inventory'] = translate('emptyField');
            }
        }else{
            if(codes.length === 0){
                formIsValid = false;
                errors['codes'] = translate('pleaseAddSomeCodes');
            }
            let is_empty = true;
            codes.map((code,index) => {
                if(code.length < 5 && code.length !== 0){
                    formIsValid = false;
                    errors[`code_${index}`] = translate('wrongVoucherCode');
                }
                if(code.length !== 0){
                    is_empty = false;
                }
            })
            if(is_empty){
                formIsValid = false;
                errors['codes'] = translate('pleaseAddSomeCodes');
            }
            const duplicateElements = toFindDuplicates(codes);
            duplicateElements.map(item =>{
                if(!!item){
                    formIsValid = false;
                    errors['codes'] = translate('someVoucherCodesAreDuplicated');
                }
            })
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                errors,
                SubmitLoading:false,
            })
        }
        return callback(formIsValid)
    }
    handleSubmit(e,draft = false){
        this.setState({
            SubmitLoading: true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.setState({
                    publish:draft
                })
                this.handleRequest();
            }
        });
    }
    handleChangeCode(i,v){
        let {codes,errors} = this.state;
        codes[i] = cleanText(v);
        errors[`code_${i}`] = '';
        this.setState({
            codes,
            errors,
        })
    }
    handlePlus(){
        let {codes} = this.state;
        if((!!codes[codes.length-1]) || codes.length === 0){
            codes.push("");
            this.setState({
                codes
            })
        }else{
            this.props.enqueueSnackbar(translate('pleaseFillFiledBefore'), {
                variant: 'warning',
            });
        }
    }
    async handleRemoveCode(index){
        let {codes} = this.state;
        if (index > -1) {
            await codes.splice(index, 1);
        }
        await this.setState({
            codes
        })
    }
    render(){
        let {fields,discount_type,SubmitLoading,errors,codes,editMode} = this.state;
        let {discount} = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    {
                        !editMode &&
                        <Grid item xs={12}>
                            <SubTitleLight align="left">{translate('typeOfDiscount')}</SubTitleLight>
                            <Button
                                color="primary"
                                variant={discount_type === "system" ? "contained" : 'text'}
                                startIcon={discount_type === "system" ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                                onClick={() => this.setState({
                                    discount_type:"system"
                                })}
                                size={'small'}
                                style={{marginRight:5}}
                            >
                                {translate('discountBySystem')}
                            </Button>
                            <Button
                                size={'small'}
                                color="primary"
                                variant={discount_type === "manual" ? "contained" : 'text'}
                                startIcon={discount_type === "manual" ? <CheckBoxIcon/> : <CheckBoxOutlineBlankIcon/>}
                                onClick={() => this.setState({
                                    discount_type:"manual"
                                })}
                            >
                                {translate('discountByManual')}
                            </Button>
                        </Grid>
                    }
                    {
                        discount_type === "system" ?
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <AlertTitle>{translate('voucherTypeSystem')}</AlertTitle>
                                        {translate('voucherTypeSystemDesc')}
                                    </Alert>
                                </Grid>
                                <Grid item xs={12}>
                                    <TextField
                                        required
                                        fullWidth
                                        size="small"
                                        name="inventory"
                                        variant="outlined"
                                        label={translate('inventory')}
                                        type="text"
                                        value={fields.inventory}
                                        onChange={this.handleChange.bind(this)}
                                        error={!!errors['inventory']}
                                        helperText={errors['inventory']}
                                    />
                                </Grid>
                            </>
                            :
                            <>
                                <Grid item xs={12}>
                                    <Alert severity="info">
                                        <AlertTitle>{translate('voucherTypeManual')}</AlertTitle>
                                        {translate('voucherTypeManualDesc')}<br/>
                                        <strong>{translate('voucherTypeManualDescStrong')}</strong>
                                    </Alert>
                                </Grid>
                                {
                                    codes.map((code,index) => (
                                        <Grid item xs={12} md={6}>
                                            <TextField
                                                key={index}
                                                fullWidth
                                                size="small"
                                                name={`code_${index}`}
                                                variant="outlined"
                                                label={translate('voucherCode')+" "+(index+1)}
                                                type="text"
                                                value={code}
                                                onChange={(e) => this.handleChangeCode(index,e.target.value)}
                                                error={!!errors[`code_${index}`]}
                                                helperText={errors[`code_${index}`]}
                                                InputProps={!!code && {
                                                    endAdornment: (
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                size="small"
                                                                onClick={() => this.handleRemoveCode(index)}
                                                            >
                                                                <CloseRoundedIcon/>
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    ))
                                }
                                <Grid item xs={12}>
                                    <Button
                                        size="small"
                                        color="primary"
                                        onClick={() => this.handlePlus()}
                                        startIcon={<AddRoundedIcon/>}
                                    >
                                        {translate('addMoreField')}
                                    </Button>
                                </Grid>
                                {
                                    !!errors['codes'] &&
                                    <Alert severity="error">
                                        {errors['codes']}
                                    </Alert>
                                }
                            </>
                    }
                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            style={{minWidth:200}}
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {translate('makeDiscountCodes')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeVouchers))));