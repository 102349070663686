import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization, Translate} from "../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../config/design/TableIcons";
import { withRouter} from "react-router-dom";
import moment from 'jalali-moment';
import { withSnackbar } from 'notistack';
import Container from "@material-ui/core/Container/Container";
import {TableCount, TablePage} from "../../../../config/Permissions";
import EditCommentDialog from "./components/EditCommentDialog";
import Button from "@material-ui/core/Button/Button";
import AddBox from "@material-ui/icons/AddBox";
import AddCommentDialog from "./components/AddCommentDialog";
import AppCardLoaderTable from "../../../components/Skeletons/AppCardLoaderTable";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import Typography from "@material-ui/core/Typography/Typography";

class Comments extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'content',
                title: Translate.content,
            },
            {
                title: Translate.user,
                field: 'user',
            },
            {title: Translate.email, field: 'email',},
            {title: Translate.comment, field: 'comment',},
            {
                title: Translate.confirmationStatus,
                field: 'confirmed',
                lookup: {
                    1 :
                        <Typography style={{color: "#38bf84"}}>{Translate.confirmed}</Typography>
                    , 0:
                        <Typography color="textSecondary">{Translate.unConfirmed}</Typography>
                },            },
            {
                title: Translate.created_at,
                editable: 'never',
                field: 'created_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.created_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.created_at).locale('en').format( 'HH:mm , YYYY/M/D')}`}
                    </Typography>
            },
            { title: Translate.activity,
                editable: 'never',
                field: 'activity',
                render: rowData =>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={() => this.handleRowClick(rowData)}
                        endIcon={<ChevronLeftRoundedIcon/>}
                        size={"small"}
                        style={{marginBottom:5,marginRight:5}}
                    >
                        {Translate.details}
                    </Button>
            },

        ],
        data: [],
        loading:false,
        ChangeLoader:false,
        id:null,
        replay_comment:null,
        replay_content:null,
        openDialog:false,
        openDialogAdd:false,
        replaying:false,

    };
    componentDidMount(){
        this.loadData();
    }
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    async handleReplayClick(data){
        await this.setState({
            replay_comment: data.id,
            replay_content: data.content_id
        })
        await this.toggleDialogAdd();
    }

    onDelete(id,hasNotif = true) {
        let data = new FormData();
        data.append('id', id);
        let loginToken = localStorage.getItem('ACT');

        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/blog-comment/delete`, data, config)
            .then(response => {
                if(hasNotif){
                    this.props.enqueueSnackbar(Translate.commentRemoved, {
                        variant: 'success',
                    });
                }
                return true
            })
            .catch(error => {
                this.props.enqueueSnackbar(Translate.commentRemovingFailed, {
                    variant: 'error',
                });
                return false
            });
        this.loadData();
    }
    async onDeleteSelected(data){
        await data.map((row) =>(
            this.onDelete(row.id,false)

        ));
        await this.loadData();
        await this.props.enqueueSnackbar(Translate.deleteSelectedComments, {
            variant: 'success',
        });
    }
    handleRowClick(data){
        this.setState({
            id:data.id,
            openDialog:true,
        })
    }
    changePageSize(data){
        setTimeout(function() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }, 100);

    }
    async toggleDialog(){
        await this.setState({
            openDialog: !this.state.openDialog,
            id:null,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    async toggleDialogAdd(){
        await this.setState({
            openDialogAdd: !this.state.openDialogAdd,
            ChangeLoader:true,
        })
        await this.loadData();
    }
    render(){
        let{openDialog,id,openDialogAdd}= this.state;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                    {
                        this.state.loading ? <AppCardLoaderTable/> :
                            <Container maxWidth="xl">
                            <MaterialTable
                                    tableRef={this.tableRef}
                                    icons={tableIcons}
                                    onChangeRowsPerPage={(data) => this.changePageSize(data)}
                                    title={
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={this.toggleDialogAdd.bind(this)}
                                            startIcon={<AddBox />}
                                        >
                                            {Translate.addComment}
                                        </Button>
                                    }
                                    localization={DataTableLocalization}
                                    columns={this.state.columns}
                                    data={query =>
                                        new Promise((resolve, reject) => {
                                            axios.get(`${defaultVariabls.url}/blog-comments/quickView?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                                .then(response => {
                                                    let data = [];
                                                    response.data.data.map((row) =>(
                                                        data.push({
                                                            content: row.content.title,
                                                            content_id: row.content.id,
                                                            user: !!row.user ? row.user.first_name+" "+row.user.last_name : null,
                                                            user_id:!!row.user ? row.user.id : null,
                                                            comment: !!row.comment ? row.comment.slice(0,15)+"..." : null,
                                                            name: row.name,
                                                            email: row.email,
                                                            confirmed: row.confirmed,
                                                            id:row.id,
                                                            created_at:row.created_at,
                                                        })
                                                    ));
                                                    resolve({
                                                        data: data,
                                                        page: response.data.current_page - 1,
                                                        totalCount: response.data.total,
                                                    })
                                                })
                                                .catch(error => {
                                                    console.log(error)
                                                    this.props.enqueueSnackbar(Translate.connectionError, {
                                                        variant: 'error',
                                                    });
                                                });
                                        })
                                    }
                                    options={{
                                        selection: true,
                                        pageSize: TablePage,
                                        pageSizeOptions: TableCount,
                                    }}
                                    actions={[
                                        {
                                            tooltip: Translate.delete,
                                            icon: tableIcons.Delete,
                                            onClick: (evt, data) => this.onDeleteSelected(data)
                                        },

                                    ]}
                                    editable={{
                                        onRowDelete: oldData =>
                                            new Promise(resolve => {
                                                setTimeout(() => {
                                                    resolve();
                                                    this.onDelete(oldData.id);
                                                    this.setState(prevState => {
                                                        const data = [...prevState.data];
                                                        data.splice(data.indexOf(oldData), 1);
                                                        return { ...prevState, data };
                                                    });
                                                }, 600);
                                            }),
                                    }}
                                />
                            </Container>

                    }
                {
                    openDialog ? <EditCommentDialog id={id} cancleOperation={() => this.toggleDialog()} handleDone={() => this.toggleDialog()} /> : null
                }
                {
                    openDialogAdd ? <AddCommentDialog replay_comment={this.state.replay_comment} replay_content={this.state.replay_content} id={id} cancleOperation={() => this.toggleDialogAdd()} handleDone={() => this.toggleDialogAdd()} /> : null
                }
            </>

        );
    }
}
export default withSnackbar(withRouter(withStyles(Styles)(Comments)));