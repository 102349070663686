import React from 'react';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import { withSnackbar } from 'notistack';
import {withRouter} from "react-router";
import {connect} from "trim-redux";
import Step1 from '../../../../files/img/steps/1.png';
import Step2 from '../../../../files/img/steps/2.png';
import Step3 from '../../../../files/img/steps/3.png';
import Step4 from '../../../../files/img/steps/4.png';
import Step5 from '../../../../files/img/steps/5.png';
import Step6 from '../../../../files/img/steps/6.png';
import {translate} from "../../../../config/lang";
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import Typography from "@material-ui/core/Typography";
class RegisterStepsPage extends React.Component {
    classes = this.props.classes;

    render(){

        let {step_number} = this.props;
        return (
            <div className="steps_holder">
                <div className={`step ${step_number >= 1 ? "active" : ""}`}>
                    <img src={Step1}/>
                    <Typography color="textSecondary" className="only-one-line">{translate('step1')}</Typography>
                </div>
                <div className={`separator ${step_number >= 2 ? "active" : ""}`}>
                    <ArrowForwardRoundedIcon/>
                </div>
                <div className={`step ${step_number >=2 ? "active" : ""}`}>
                    <img src={Step2}/>
                    <Typography color="textSecondary"  className="only-one-line">{translate('step2')}</Typography>
                </div>
                <div className={`separator ${step_number >= 3 ? "active" : ""}`}>
                    <ArrowForwardRoundedIcon/>
                </div>
                <div className={`step ${step_number >= 3 ? "active" : ""}`}>
                    <img src={Step3}/>
                    <Typography color="textSecondary" className="only-one-line">{translate('step3')}</Typography>
                </div>
                <div className={`separator ${step_number >= 4 ? "active" : ""}`}>
                    <ArrowForwardRoundedIcon/>
                </div>
                <div className={`step ${step_number >=4  ? "active" : ""}`}>
                    <img src={Step4}/>
                    <Typography color="textSecondary" className="only-one-line">{translate('step4')}</Typography>
                </div>
                <div className={`separator ${step_number >= 5 ? "active" : ""}`}>
                    <ArrowForwardRoundedIcon/>
                </div>
                <div className={`step ${step_number >= 5 ? "active" : ""}`}>
                    <img src={Step5}/>
                    <Typography color="textSecondary" className="only-one-line">{translate('step5')}</Typography>
                </div>
                <div className={`separator ${step_number >= 6 ? "active" : ""}`}>
                    <ArrowForwardRoundedIcon/>
                </div>
                <div className={`step ${step_number >= 6  ? "active" : ""}`}>
                    <img src={Step6}/>
                    <Typography color="textSecondary" className="only-one-line">{translate('step6')}</Typography>
                </div>
            </div>
        );
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user,
    primary_shop: state.primary_shop,
    locale: state.locale,
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(RegisterStepsPage))));
