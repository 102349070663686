import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import { withRouter } from "react-router";
import {withSnackbar} from "notistack";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {Translate} from "../../../../config/Translate";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";

class Disadvantages extends React.Component {
    classes = this.props.classes;
    state={
        disadvantages:[
            ''
        ],
        errors:[],
        limit:5,
    };
    componentDidMount(){
        if(this.props.input){
            this.setState({
                disadvantages: this.props.input,
            })
        }
    }
    handleValidation(){
        let { disadvantages } = this.state;
        let errors = {};
        disadvantages.map((disadvantage,index) =>{
            if(validator.isEmpty(disadvantage)){
                errors[`disadvantage${index}`] = Translate.emptyField;
            }
            return true
        })

        this.setState({
            errors
        })

    }
    handleChangeDisadvantage(e){
        let {disadvantages,errors} = this.state;
        let target = e.target;
        errors[`disadvantage${parseInt(target.name)}`] = "";
        let disadvantage = target.value;
        disadvantages[parseInt(target.name)] = disadvantage;
        this.setState({
            disadvantages
        })
        if(disadvantage.length > 0){
            this.props.handleDisadvantages(disadvantages);
        }
    }
    addDisadvantage() {
        let {disadvantages} = this.state;
        if(disadvantages[disadvantages.length-1].length !== 0){
            disadvantages.push("");
            this.setState({
                disadvantages
            })
        }else{
            this.props.enqueueSnackbar(Translate.fillPrevFieldFirst, {
                variant: 'warning',
            });
        }
    }
    async handleRemoveField(index) {
        let {disadvantages} = this.state;
        disadvantages.splice(index, 1)
        await this.setState({
            disadvantages
        })
        await this.props.handleDisadvantages(disadvantages);
        this.handleValidation()
    }
    render() {
        let {disadvantages,errors,limit} = this.state;
        return(
            <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">

                {
                    disadvantages.map((disadvantage,index) =>(
                        <Grid key={index} style={{position:"relative"}} item xs={10}>
                            <TextField
                                variant="outlined"
                                value={disadvantages[index]}
                                fullWidth
                                size="small"
                                id={`disadvantage${index}`}
                                label={Translate.disadvantage}
                                name={index}
                                onChange={this.handleChangeDisadvantage.bind(this)}
                                onBlur={this.handleValidation.bind(this)}
                                error = {!!errors[`disadvantage${index}`]}
                                helperText={errors[`disadvantage${index}`]}
                                InputProps={ index > 0 && {
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => this.handleRemoveField(index)}
                                            >
                                                <CloseRoundedIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    ))
                }
                {
                    disadvantages.length  !== limit &&
                    <Grid item xs={2}>
                        <IconButton
                            onClick ={this.addDisadvantage.bind(this)}
                            color="primary"
                        >
                            <AddRoundedIcon/>
                        </IconButton>
                    </Grid>
                }
            </Grid>
        )
    }
}

export default withRouter(withSnackbar(withStyles(Styles)(Disadvantages)));