import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {DataTableLocalization} from "../../../../../config/Translate";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect} from "trim-redux";
import MaterialTable from "material-table";
import {tableIcons} from "../../../../../config/design/TableIcons";
import {Link, withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import moment from "jalali-moment";
import {TableCount, TablePage} from "../../../../../config/Permissions";
import Container from "@material-ui/core/Container/Container";
import Typography from "@material-ui/core/Typography/Typography";
import {Translate} from "../../../../../config/Translate";
import AppCardLoaderTable from "../../../../components/Skeletons/AppCardLoaderTable";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {currencySymbol, price} from "../../../../../config/values";
import Button from "@material-ui/core/Button/Button";
import Grid from "@material-ui/core/Grid/Grid";
import Tabs from "@material-ui/core/Tabs/Tabs";
import Tab from "@material-ui/core/Tab/Tab";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';

class AgentOrdersTable extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        columns: [
            {
                field: 'buyer',
                editable: 'never',
                filtering: false,
                title: Translate.buyer,
            },
            {
                field: 'factor_number',
                editable: 'never',
                filtering: false,
                title: Translate.factor_number,
            },
            { title: Translate.agent_commission,
                field: 'commission',
                filterPlaceholder: Translate.search,
            },

            { title: Translate.factor_status,
                editable: 'never',
                field: 'status',
                filterPlaceholder: Translate.choose,
                lookup: {
                    "done" :
                        <Typography color="textPrimary">{Translate.done}</Typography>
                    , "rejected":
                        <Typography color="textSecondary">{Translate.rejected}</Typography>
                    , "sending":
                        <Typography color="textPrimary">{Translate.sending}</Typography>
                    , "gathering":
                        <Typography color="textPrimary">{Translate.gathering}</Typography>
                    , "not_payed":
                        <Typography color="textSecondary">{Translate.not_payed}</Typography>
                },
            },
            { title: Translate.commission_status,
                editable: 'never',
                field: 'commission_payed',
                filterPlaceholder: Translate.choose,
                lookup: {
                    true :
                        <Typography style={{color:"#38bf84"}}>{Translate.commission_payed}</Typography>
                    , false:
                        <Typography color="textSecondary">{Translate.commission_not_payed}</Typography>
                },
            },
            { title: Translate.created_at_factor,
                editable: 'never',
                filtering: false,
                field: 'created_at',
                render: rowData =>
                    <Typography className="date_in_table">
                        {`${moment(rowData.created_at).locale('en').fromNow()}`}
                        <br/>
                        {`${moment(rowData.created_at).locale('en').format( 'YYYY/M/D')}`}
                    </Typography>
            },
        ],
        data: [],
        type:"buy",
        loading:false,
        ChangeLoader:false
    };
    loadData(){
        this.tableRef.current && this.tableRef.current.onQueryChange()
    }
    render(){
        let {type} = this.state;
        let {agent_id} = this.props;
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        return (
            <>
                {
                    this.state.loading ? <AppCardLoaderTable/> :
                        <Container maxWidth="xl" className={!!this.props.widget ? "thin-table" : null}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Tabs
                                        value={type}
                                        onChange={
                                            (event, newValue) =>
                                            {
                                                this.setState({type:newValue});
                                                this.loadData();
                                            }

                                        }
                                        indicatorColor="primary"
                                        textColor="primary"
                                        centered
                                    >
                                        <Tab value="sell" label={Translate.sell_commission_amount} />
                                        <Tab value="buy" label={Translate.buy_commission_amount} />
                                    </Tabs>
                                </Grid>
                                <Grid item xs={12}>
                                    <MaterialTable
                                        tableRef={this.tableRef}
                                        icons={tableIcons}
                                        title={Translate.agentOrdersTable}
                                        localization={DataTableLocalization}
                                        columns={this.state.columns}
                                        data={query =>
                                            new Promise((resolve, reject) => {
                                                axios.get(`${defaultVariabls.url}/agentOrders/${agent_id}/${type}?page=${query.page + 1}&per_page=${query.pageSize}&search=${query.search}`, config)
                                                    .then(response => {
                                                        let data = [];
                                                        // eslint-disable-next-line
                                                        response.data.data.map((row) => {
                                                            data.push({
                                                                commission: price(type === "sell" ? row.sell_commission :row.buy_commission ) +" "+currencySymbol,
                                                                id: row.id,
                                                                factor_number: row.factor.number,
                                                                buyer: row.factor.user.first_name +" "+row.factor.user.last_name,
                                                                status: row.factor.status,
                                                                commission_payed: row.status === "payed_to_shop",
                                                                created_at: row.factor.created_at,
                                                            })
                                                        });
                                                        resolve({
                                                            data: data,
                                                            page: response.data.current_page - 1,
                                                            totalCount: response.data.total,
                                                        })
                                                    }).catch(error => {
                                                    console.log(error.response)
                                                })
                                            })
                                        }
                                        options={{
                                            pageSize: TablePage,
                                            pageSizeOptions: TableCount,
                                            /*search:false,*/
                                        }}
                                    />
                                </Grid>
                                {
                                    this.props.widget &&
                                    <Grid item xs={12} style={{textAlign:"center"}}>
                                        <Button
                                            component={Link}
                                            to="/agent"
                                            color="primary"
                                            variant="contained"
                                            endIcon={<ChevronLeftRoundedIcon/>}
                                        >
                                            {Translate.viewAll}
                                        </Button>
                                    </Grid>
                                }

                            </Grid>
                        </Container>
                }
            </>

        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AgentOrdersTable)))));