import React from 'react';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {withRouter} from 'react-router-dom';
import AppAnimate from "../AppAnimate/index"
import withWidth from "@material-ui/core/withWidth/withWidth";
import {connect} from "trim-redux";
import Logo from '../../../files/img/logo-dark.png';
import Grid from "@material-ui/core/Grid/Grid";
import LanguageSwitcher from "../elements/LanguageSwitcher";
import {makeSlug, translate} from "../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import HomeRoundedIcon from '@material-ui/icons/HomeRounded';
import ArrowForwardRoundedIcon from '@material-ui/icons/ArrowForwardRounded';
import queryString from "query-string";
import {changeMode} from "../../../config/values";
import {defaultVariabls} from "../../../config";
import Link from "@material-ui/core/Link/Link";
import {Helmet} from "react-helmet/es/Helmet";

class AuthLayout extends React.Component {
     constructor(props){
      super(props);
         const values = queryString.parse(this.props.location.search)
         let redirect = values.r;
         if(this.props.isAuth){
             this.props.history.push("/");
         }
         if(!!redirect){
             this.state={
                 redirect: redirect
             };
             localStorage.setItem("external_redirect",this.state.redirect);
         }else{
             this.state={
                 redirect: null
             };
         }
         let url_mode = values.mode;
         if(!!url_mode){
             let mode = localStorage.getItem('mode')
             if(mode !== url_mode){

                 changeMode(url_mode)
             }
         }
     }

    render() {
        const children = this.props.children;
        const {classes,app_name,iframe} = this.props;
        let {redirect} = this.state;
        return (
            <div className="authContainer">
                <div className={!!iframe ?  classes.auth_container_no_bg : classes.auth_container}>
                    <AppAnimate animation='transition.slideUpIn' delay={200}>
                        <div className={!iframe ? classes.auth_inner : ""} style={{width:"100%"}}>
                            {
                                !iframe &&
                                <>
                                    <LanguageSwitcher className={classes.authLanguageSwitcher}/>
                                    <Button
                                        className={classes.authBackButton}
                                        size="small"
                                        endIcon={<ArrowForwardRoundedIcon/>}
                                        component={"a"}
                                        href={!!redirect ? redirect : makeSlug('')}
                                    >
                                        {translate('backToHome')}
                                    </Button>
                                    <div style={{zIndex:10}}>
                                        <Link href={makeSlug('')}>
                                            <img src={Logo} alt={app_name} className={classes.auth_logo}/>
                                        </Link>
                                    </div>
                                </>
                            }
                            <div className={classes.auth_container_items}>
                                <Grid container spacing={2} justify='center' alignItems="center">
                                    {children}
                                </Grid>
                            </div>
                        </div>
                    </AppAnimate>
                </div>
                {/*{
                    !iframe &&
                    <Helmet>
                        <script src="//code.tidio.co/xgpjhmvoh5k6oev0ufm7kopd6megqs4b.js" async></script>
                    </Helmet>
                }*/}

            </div>
        );

    }
}
const mstp = state => ({
    primary_color: state.primary_color,
    logo: state.logo,
    app_name: state.app_name,
    about: state.about,
});
export default withWidth()(withStyles(Styles)(withRouter(connect(mstp)(AuthLayout))));