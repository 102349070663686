import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import axios from "axios";
import {defaultVariabls} from "../../../../config/index";
import {Translate} from "../../../../config/Translate";
import SingleProductItem from "./SingleProductItem";
import Title from "../../../components/elements/Title";

class BestSellingProducts extends React.Component {
    state = {
        products: [],
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        this.setState({
            loading:true,
        });
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/best-selling-products`, config)
                .then(response => {
                    this.setState({
                        products: response.data,
                        loading:false,
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {
            products,
            loading
        } = this.state;
        return (
            <Grid container spacing={2} direction="row" alignItems="center" style={{marginBottom:!!products.length ? 10 : 0}}>
                {
                    !!products.length &&
                    <Grid item xs={12}>
                        <Title align="left" style={{marginBottom:0}}>{Translate.bestSellingProducts}</Title>
                    </Grid>
                }

                {
                    !loading &&
                        products.map((product,index)=> (
                            <Grid item xs={6} md={3} lg={2}>
                            <SingleProductItem product={product} key={index}/>
                            </Grid>
                        ))
                }
            </Grid>
        );
    }
}

export default withStyles(Styles)(BestSellingProducts);