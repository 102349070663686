import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from '../../../../../config/Translate';
import validator from 'validator';
import axios from 'axios';
import { defaultVariabls } from '../../../../../config/index';
import { withRouter} from "react-router";
import {withSnackbar} from "notistack";
import LoadingButton from "../../../../components/elements/LoadingButton";
import {connect} from "trim-redux";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import {secret} from "../../../../../config/Security";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import {rolesWithName} from '../../../../../config/Permissions'
import MuiPhoneNumber from "material-ui-phone-number";
import {translate} from "../../../../../config/lang";
class AddUser extends React.Component {
    classes = this.props.classes
    state={
        fields: {
            name: '',
            email:'',
            mobile:'',
            first_name:'',
            last_name:'',
            role:'user',
            password:'',
            password_confirmation: '',
        },
        errors:{},
        loading:false,
        SubmitLoading:false
    };
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,

        });
    }
    handleValidation(callback){
        let { fields } = this.state;
        let errors = {};
        let formIsValid = true;

        //first_name
        if(validator.isEmpty(fields.first_name)){
            formIsValid = false;
            errors['first_name'] = translate('emptyName');
        }else if(!validator.isByteLength(fields.first_name,{min:0, max:255})){
            formIsValid = false;
            errors['first_name'] = translate('maxLength255');
        }
        //last_name
        if(validator.isEmpty(fields.last_name)){
            formIsValid = false;
            errors['last_name'] = translate('emptyLastName');
        }else if(!validator.isByteLength(fields.last_name,{min:0, max:255})){
            formIsValid = false;
            errors['last_name'] = translate('maxLength255');
        }
        //mobile
        if(validator.isEmpty(fields.mobile)){
            formIsValid = false;
            errors['mobile'] = translate('emptyMobile');
        }

        //Email
        if(validator.isEmpty(fields.email)){
            formIsValid = false;
            errors['email'] = translate('emptyEmail');
        }else if(!validator.isEmail(fields.email)){
            formIsValid = false;
            errors['email'] = translate('wrongEmail');
        }


        //Password
        if(validator.isEmpty(fields.password)){

        }else if(fields.password.length < 6){
            formIsValid = false;
            errors['password'] = translate('weakPassword');
        }

        //check Email and Mobile
        let data = new FormData();
        data.append('email', fields.email);
        data.append('mobile', fields.mobile);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        const request = async () => {
            if(errors['email'] === undefined){
                await axios.post(`${defaultVariabls.url}/register/checkEmail`, data, config)
                    .then(response => {}).catch(error => {
                        formIsValid = false;
                        errors['email'] = translate('existEmail');
                    });
            }
            if(errors['mobile'] === undefined) {
                await axios.post(`${defaultVariabls.url}/register/checkMobile`, data, config)
                    .then(response => {
                        if(response.data.errors.mobile !== undefined){
                            formIsValid = false;
                            errors['mobile'] = translate('existMobile');
                        }
                    }).catch(error => {
                    });
            }
            await this.setState({errors},() =>{
                if(!formIsValid){
                    this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                        variant: 'warning',
                    });
                    this.setState({
                        SubmitLoading:false
                    })
                }
                return callback(formIsValid)
            });
        }
        request();
    }
    handleRequest(){
        const {fields} = this.state;
        let data = new FormData();
        data.append('first_name', fields.first_name);
        data.append('last_name', fields.last_name);
        data.append('secret', secret);
        data.append('email', fields.email);
        data.append('mobile', fields.mobile);
        data.append('password', fields.password);
        data.append('password_confirmation', fields.password_confirmation);
        data.append('role', fields.role);
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/user/make`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('detailsSet'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false
                });
                this.props.history.push(`/manage/users/view/all`);
            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        });
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }

        });
    }
    handleChangeMobile(mobile){
        let {fields,errors} = this.state;
        fields.mobile = mobile;
        errors.mobile = '';
        this.setState({
            fields,
            errors
        })
    }
    render() {
        const {errors,fields,SubmitLoading} = this.state;
        if (this.state.loading) {
            return (
                <AppCardLoaderFields/>
            )
        } else {
            return (
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12} md={11} lg={10}>
                    <Grid container  spacing={2} justify="center" alignItems="center">
                        <CssBaseline/>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                required
                                value={fields.first_name}
                                fullWidth
                                id="first_name"
                                label={translate('first_name')}
                                name="first_name"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['first_name']}
                                helperText={errors['first_name']}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                required
                                value={fields.last_name}
                                fullWidth
                                id="last_name"
                                label={translate('last_name')}
                                name="last_name"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['last_name']}
                                helperText={errors['last_name']}
                            />
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <TextField
                                variant="outlined"
                                required
                                value={fields.email}
                                fullWidth
                                id="email"
                                label={translate('email')}
                                name="email"
                                autoComplete="email"
                                onChange={this.handleChange.bind(this)}
                                error = {!!errors['email']}
                                helperText={errors['email']}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <MuiPhoneNumber
                                required
                                fullWidth
                                id="mobile"
                                label={translate('mobile')}
                                name="mobile"
                                margin="normal"
                                dir="ltr"
                                variant="outlined"
                                defaultCountry={'ae'}
                                value={fields.mobile}
                                onChange={this.handleChangeMobile.bind(this)}
                                error = {!!errors['mobile']}
                                helperText={!!errors['mobile'] ? errors['mobile'] : ''}
                            />
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                select
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                required
                                value={fields.role}
                                name="role"
                                id="role"
                                helperText={translate('roleHelper')}
                                onChange={this.handleChange.bind(this)}
                                >
                                    {
                                        rolesWithName().map((row,key) => (
                                            <MenuItem value={row.value} key={key}>
                                                {row.name}
                                            </MenuItem>
                                        ))
                                    }
                            </TextField>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="password"
                                label={translate('password')}
                                type="password"
                                id="password"
                                onChange={this.handleChange.bind(this)}
                                autoComplete="current-password"
                                error={!!errors['password']}
                                helperText={errors['password']}
                            />

                        </Grid>
                        <Grid item xs={12} md={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                name="password_confirmation"
                                label={translate('passwordConfirm')}
                                type="password"
                                id="password_confirmation"
                                onChange={this.handleChange.bind(this)}
                                autoComplete="current-password"
                                error={!!errors['password_confirmation']}
                                helperText={errors['password_confirmation']}
                            />
                        </Grid>
                        <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {translate('setDetails')}
                        </LoadingButton>
                        </Grid>
                    </Grid>
                    </Grid>
                </Grid>
            );
        }
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(AddUser))));