import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Translate} from "../../../../config/Translate";
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import IconButton from "@material-ui/core/IconButton/IconButton";
import MessageRoundedIcon from '@material-ui/icons/MessageRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import AddBox from "@material-ui/icons/AddBox";

class MakeAttributeModal extends React.Component{
    state = {
        open: false,
        loading:true,
        submit_loading:false,
        errors:[],
        name:'',
        type:'string',
    };
    toggleOpen(){
        this.setState({
            open: !this.state.open
        })
    }

    handleSend(){
        this.setState({
            submit_loading:true
        });
        let{name,type,errors} = this.state;
        if(!name){
            errors['name'] = Translate.emptyField;
            this.setState({
                errors
            })
        }else{
            let data = new FormData();
            data.append('name', name);
            data.append('type',type);
            let loginToken = localStorage.getItem('ACT');
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            axios.post(`${defaultVariabls.url}/attribute/make`, data, config)
                .then(response => {
                    this.setState({
                        open: false,
                        loading:true,
                        submit_loading:false,
                        errors:[],
                        name:'',
                        type:'',
                    });
                    this.props.reload();
                })
                .catch(error => {
                    console.log(error.response)
                    this.props.enqueueSnackbar(Translate.dataAddingFailed, {
                        variant: 'error',
                    });
                    this.setState({
                        submit_loading:false,
                    });
                });
        }

    }
    render(){
        let {open,name,type,submit_loading,errors} = this.state;
        return (
            <>
                {
                    this.props.width !== "xs" ?
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => this.toggleOpen()}
                            startIcon={<AddBox />}
                        >
                            {Translate.makeAttribute}
                        </Button> :
                        <IconButton color="primary" onClick={() => this.toggleOpen()}>
                            <AddBox />
                        </IconButton>
                }
                <Dialog
                    open={open}
                    onClose={() => this.toggleOpen()}
                    aria-labelledby="form-dialog-title"
                    fullWidth
                    maxWidth="xs"
                >
                    <DialogTitle id="form-dialog-title">
                        <IconButton
                            onClick={() => this.toggleOpen()}
                            style={{position:"absolute",left:15,top:15}}
                            size="small"
                        >
                            <CloseRoundedIcon fontSize="small"/>
                        </IconButton>
                        {Translate.makeAttribute}
                    </DialogTitle>
                    <DialogContent>
                        <TextField
                            fullWidth
                            variant="outlined"
                            margin="normal"
                            value={name}
                            onChange={(e) => this.setState({name: e.target.value,errors:[]})}
                            label={Translate.attributeName}
                            error={!!errors['name']}
                            helperText={errors['name']}
                        />
                        <TextField
                            select
                            label={Translate.attributeType}
                            value={type}
                            margin="normal"
                            onChange={(e) => this.setState({type: e.target.value})}
                            fullWidth
                            variant="outlined"
                        >
                            <MenuItem  value="color">
                                {Translate.color}
                            </MenuItem>
                            <MenuItem  value="string">
                                {Translate.string}
                            </MenuItem>
                        </TextField>
                    </DialogContent>
                    <DialogActions style={{justifyContent:"space-between"}}>
                        <Button
                            onClick={() => this.handleSend()}
                            color="primary"
                            variant="outlined"
                            startIcon={submit_loading ? <CircularProgress size={20}/> : <MessageRoundedIcon/>}
                        >
                            {Translate.save}
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(MakeAttributeModal)))));