import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button/Button";
import FileManagerDialog from "./FileManagerDialog";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {translate} from "../../../../config/lang";
import PhotoLibraryTwoToneIcon from '@material-ui/icons/PhotoLibraryTwoTone';
import Typography from "@material-ui/core/Typography/Typography";
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';
import OndemandVideoRoundedIcon from '@material-ui/icons/OndemandVideoRounded';
import VideoCoverButton from "./VideoCoverButton";

class ImageGallery extends React.Component {
    classes = this.props.classes;
    state={
        FileManagerPending: false,
        images: {},
    }
    componentDidMount(){
        this.loadData()
    }
    loadData(){
        this.setState({
            images:this.props.initial
        })
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }
    setImage = async (data) => {
        let {images} = this.state;
        let tempImage = images;
        let can_added = true;
        images.map((image,index) =>{
            if(image.id === data.id){
                can_added = false;
            }
        });
        if(can_added){
            await tempImage.push({
                type: data.type,
                name: data.name,
                src: data.thumbSource,
                preview: data.preview,
                link: data.link,
                id: data.id
            }) ;
            if(images.length >= 9){
                await this.props.enqueueSnackbar(translate('maxGallerySizeIs9'), {
                    variant: 'warning',
                });
            }
            await this.setState({
                images: tempImage
            })
            await this.galleryChanges();
            await this.toggleOperation();
        }
    }
    async removeImage(index) {
        let { images } = this.state;
        let tempImage =[];
        images.map(row =>{
            if(row.id !== index){
                tempImage.push({
                    src: row.src,
                    id: row.id,
                    type: row.type,
                    name: row.name,
                    preview: row.preview
                })
            }
            return true
        });
        await this.setState({
            images: tempImage
        });
        this.galleryChanges();
    }
    galleryChanges(){
        let {images} = this.state;
        this.props.onChange(images)
    }
    async handlePreview(index,res){

        let {images} = this.state;
        await images.map((image,key) => {
            if(index === key){
                image['preview'] = res;
            }
        })
        await this.setState({
            images
        })

    }
    render(){
        let {FileManagerPending,images} = this.state;
        let {type} = this.props;
        return(
            <Grid container spacing={2}>
                    {
                        !!images.length && images.map((row,key) =>{
                            return(
                                <Grid item xs={6} md={3} key={key}>
                                    <div className={this.classes.singleImageHolder}>
                                    <a  rel="noopener noreferrer"  className={this.classes.galleryItemHolder} target="_blank" href={`${row.link}`}>
                                        {
                                            row.type === "video" ?
                                                !!row.preview ?
                                                <div className={this.classes.thumbnailWithPreviewChooser}>
                                                    <img src={`${row.preview}`} className="fileChooser-image"/>
                                                    <PlayCircleFilledOutlinedIcon style={{fontSize: 60,color: "#fee800",opacity:0.9}}/>
                                                </div>
                                                    :
                                                    <div className={this.classes.thumbnailWithPreviewChooser}>
                                                        <OndemandVideoRoundedIcon style={{fontSize: 60,color: "#6d6d6d"}}/>
                                                    </div>
                                                    :
                                                    <div className={this.classes.thumbnailWithPreviewChooser}>
                                                        <img src={`${row.src}`} className="fileChooser-image"/>
                                                    </div>
                                        }
                                    </a>
                                        {
                                            row.type === "video" &&
                                            <VideoCoverButton
                                                file={row}
                                                handlePreview={(res) => this.handlePreview(key,res)}
                                                style={{
                                                    bottom: "unset",
                                                    top: -14,
                                                    backgroundColor: "rgb(255, 246, 160)",
                                                    width: "unset",
                                                    left: "unset",
                                                    fontSize: 11,
                                                    padding: "5px 10px",
                                                    position:"absolute",
                                                    right:0
                                                }}
                                        />
                                    }
                                    <Button
                                        startIcon={<CloseRoundedIcon />}
                                        size="small"
                                        variant="contained"
                                        onClick={() => this.removeImage(row.id)}
                                    >
                                        {translate('removeItem')}
                                    </Button>
                                </div>
                            </Grid>
                        )
                    })
                }
                <Grid item xs={6} md={3}>
                    <Button
                        onClick={this.toggleOperation}
                        variant="outlined"
                        color="primary"
                        size="small"
                        className={this.classes.addImageBtn}
                        startIcon={<PhotoLibraryTwoToneIcon />}
                    >
                        {translate('addItem')}
                    </Button>
                </Grid>
            {
                FileManagerPending ? <FileManagerDialog
                                        fourtotree
                                        fileType={!!type ? type : "all"}
                                        selection
                                        multiInsert
                                        cancleOperation={this.toggleOperation}
                                        setImage={this.setImage}
                                        defined_width={this.props.defined_width}
                                        defined_height={this.props.defined_height}
                                    /> : null
            }
        </Grid>
    )
}
}
export default withRouter(withSnackbar(withStyles(Styles)(ImageGallery)));