import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import BreadCrumbs from "../../components/elements/BreadCrumbs";
import {Translate} from "../../../config/Translate";
import AppCard from "../../components/layouts/components/AppCard";
import AppAnimate from "../../components/AppAnimate";
import {withRouter} from "react-router";
import {withSnackbar} from "notistack";
import {connect} from "trim-redux";
import MakeShop from "./parts/MakeShop";

class MakeShopPage extends React.Component {
    id = this.props.match.params.id;
    render() {
        return(
            <AppAnimate animation='transition.slideUpIn' delay={200} title={this.props.edit ? Translate.editShop : Translate.makeShop}>
                <Grid container spacing={2} justify="center">
                    <Grid item xs={12}>
                        <AppCard
                            title={this.props.edit ? Translate.editShop : Translate.makeShop}
                            action={
                                <BreadCrumbs singleLevel firstText={this.props.edit ? Translate.editShop : Translate.makeShop}/>
                            }>
                            <MakeShop edit={this.props.edit} shop_id={!!this.id ? this.id : 0 }/>
                        </AppCard>
                    </Grid>
                </Grid>
            </AppAnimate>
        )
    }
}
const mstp = state => ({
    isAuth: state.isAuth,
    user: state.user
});
export default withRouter(withSnackbar(withStyles(Styles)(connect(mstp)(MakeShopPage))));