import React from 'react';
import Grid from "@material-ui/core/Grid/Grid";
import {connect, getStore, setStore} from "trim-redux";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../../config/design/styles";
import SubTitle from "../../../../components/elements/SubTitle";
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from '@material-ui/icons/ChevronRightRounded';
import {Link, withRouter} from "react-router-dom";
import List from "@material-ui/core/List/List";
import ListItem from "@material-ui/core/ListItem/ListItem";
import ListItemText from "@material-ui/core/ListItemText/ListItemText";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction/ListItemSecondaryAction";
import {makeSlug, translate} from "../../../../../config/lang";
import Typography from "@material-ui/core/Typography/Typography";
import {hasAccess} from "../../../../../config/Security";
import withWidth from "@material-ui/core/withWidth/withWidth";
import AppCard from "../../../../components/layouts/components/AppCard";

class OrderAnalyticsShort extends React.Component {
    state = {
        total_plans: null,
        month_plans: null,
        total_discounts: null,
        month_discounts: null,
        total_ads: null,
        month_ads: null,
        loading:true,
    };
    componentDidMount(){
        this.loadData();
    }
    async loadData(){
        this.setState({
            loading:true,
        });
        let loginToken = localStorage.getItem('ACT');
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/analytics/admin-analytics`, config)
                .then(response => {
                    this.setState({
                        total_plans: response.data.total_plans,
                        month_plans: response.data.month_plans,
                        total_discounts: response.data.total_discounts,
                        month_discounts: response.data.month_discounts,
                        total_ads: response.data.total_ads,
                        month_ads: response.data.month_ads,
                        loading: false,
                    })
                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
                loading:false
            });
            this.setState({
                loading:false
            })
        }
    }
    classes = this.props.classes;
    render(){
        let {
            total_plans,
            month_plans,
            total_discounts,
            month_discounts,
            total_ads,
            month_ads,
            loading
        } = this.state;
        let mode = getStore('mode');
        return (
            <Grid container spacing={2} direction="row">
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative"}} direction="row" className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11}>
                                <SubTitle  align="left">
                                    {translate('plansSales')}

                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                <List dense>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('monthlySales')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{month_plans}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('totalSale')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{total_plans}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>

                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative"}} direction="row"  className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11}>
                                <SubTitle  align="left">
                                    {translate('adsSales')}
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                <List dense>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('monthlySales')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{month_ads}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('totalSale')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{total_ads}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>

                        </Grid>
                    </AppCard>
                </Grid>
                <Grid item xs={12} md={4}>
                    <AppCard style={{height:"100%"}}>
                        <Grid container  style={{position:"relative"}} direction="row"  className={this.classes.GridAsaPaperBaby}>
                            <Grid item xs={11}>
                                <SubTitle align="left">
                                    {translate('discountsSales')}
                                    {/*<Button
                                        size="small"
                                        style={{position:"absolute",right:0}}
                                        endIcon={<ChevronRightRoundedIcon/>}
                                        component={Link}
                                        to='/manage/orders'
                                    >
                                        {translate('view')}
                                    </Button>*/}
                                </SubTitle>
                            </Grid>
                            <Grid item xs={12}>
                                <List dense>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('monthlySales')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{month_discounts}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                    <ListItem>
                                        <ListItemText
                                            primary={translate('totalSale')}
                                        />
                                        <ListItemSecondaryAction>
                                            <strong style={{fontSize:11}}>{total_discounts}</strong>
                                        </ListItemSecondaryAction>
                                    </ListItem>
                                </List>
                            </Grid>
                        </Grid>
                    </AppCard>
                </Grid>
            </Grid>
        );
    }
}
const mstp = state => ({
    user: state.user,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(OrderAnalyticsShort))));
