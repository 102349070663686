import React from 'react';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import {Translate} from "../../../../config/Translate";
import Button from "@material-ui/core/Button/Button";
import FileManagerDialog from "./FileManagerDialog";
import AddBoxRoundedIcon from '@material-ui/icons/AddBoxRounded';
import {defaultVariabls} from "../../../../config";
import IconButton from "@material-ui/core/IconButton/IconButton";
import Typography from "@material-ui/core/Typography/Typography";
import DescriptionTwoToneIcon from '@material-ui/icons/DescriptionTwoTone';
import SubTitle from "../../../components/elements/SubTitle";
import Card from "@material-ui/core/Card/Card";
import CardContent from "@material-ui/core/CardContent/CardContent";
import CancelIcon from '@material-ui/icons/Cancel';
import {truncate} from "../../../../config/values";
import SubTitleLight from "../../../components/elements/SubTitleLight";

class FileChooser extends React.Component {
    classes = this.props.classes;
    state={

        FileManagerPending: false,
        files: [],
    }
    componentDidMount(){
        this.loadData()
    }
    async loadData(){
        await this.setState({
            files:this.props.initial
        })
        await this.fileChanges();
    }
    toggleOperation= () => {
        this.setState({
            FileManagerPending:!this.state.FileManagerPending,
        })
    }

    setData = (data) => {
        let {files} = this.state;
        let tempFile = files;
        let check = true;
        files.map(row => {
            if(row.id === data.id ){
                check = false
            }
            return true;
        })
        if(check){
            tempFile.push(data);
            this.setState({
                files: tempFile,
            });
        }
        this.fileChanges();
        this.toggleOperation();
    }
    async removeDataSheet(index) {
        let { files } = this.state;
        let temp =[];
        files.map(row =>{
            if(row.id !== index){
                temp.push(row)
            }
            return true
        });
        await this.setState({
            files: temp
        });
        this.fileChanges();
    }
    async fileChanges(){
        let {files} = this.state;
        let temp = []
        if(!!files){
            await files.map(row=>{
                temp.push(row)
                return true
            })
        }
        await this.props.onChange(temp)
    }
    render(){
        let {FileManagerPending,files} = this.state;
        let{title,required,max,disabled,print} = this.props;

        return(
            <React.Fragment>
                <Grid container spacing={2} direction="row" alignItems="center"  style={{minHeight:80}} >
                    <Grid item xs={12} className="mt-20">
                        <SubTitleLight align="left" style={{paddingTop:20,fontWeight:700}}>{title} {!!required &&
                        <span className={this.classes.requiredSign}>*</span>}</SubTitleLight>
                    </Grid>
                    {
                        !!files ? files.map((row,key) =>{
                            return(
                                <Grid item xs={6} md={4} lg={3} style={{marginBottom:15,textAlign:'center'}}>
                                    <Card style={{position:"relative"}}>
                                        <CardContent>
                                            <a  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={`${defaultVariabls.uploads}/FileManager/${row.name}.${row.extension}`}>
                                                {
                                                    row.type === 'image' ? <img src={`${defaultVariabls.uploads}/FileManager/${row.name}_small.${row.extension}`} className="fileChooser-image"/> : null
                                                }
                                                {
                                                    row.type === 'document' ? <DescriptionTwoToneIcon style={{fontSize:70}} /> : null
                                                }
                                            </a>
                                            <br />
                                            <Typography>
                                            <a  rel="noopener noreferrer"  className={this.classes.primaryLink} target="_blank" href={`${defaultVariabls.uploads}/FileManager/${row.name}.${row.extension}`}>
                                            {truncate(row.name,15)}
                                            </a>
                                                {
                                                    !print &&
                                                    <IconButton style={{position:"absolute",top:5,right:5}} size="small" onClick={() => this.removeDataSheet(row.id)} aria-label={Translate.removeImage}>
                                                        <CancelIcon />
                                                    </IconButton>
                                                }

                                            </Typography>
                                        </CardContent>
                                    </Card>
                                </Grid>
                            )
                        }) : null
                    }
                    {
                        !disabled &&
                        files.length <= max &&
                        <Grid item xs={6} md={4} lg={3}>
                            <Button
                                startIcon={<AddBoxRoundedIcon />}
                                color="secondary"
                                variant="outlined"
                                size="small"
                                onClick={this.toggleOperation.bind(this)}
                            >
                                {Translate.chooseFile}
                            </Button>
                        </Grid>
                    }

                </Grid>

                {
                    FileManagerPending ? <FileManagerDialog fileType="all"  selection cancleOperation={this.toggleOperation} setData={this.setData}/> : null
                }
            </React.Fragment>
        )

    }
}
export default withStyles(Styles)(FileChooser);