import React from 'react';
import Button from '@material-ui/core/Button';
import {Translate} from "../../../config/Translate";
import {bindPopover, bindTrigger} from "material-ui-popup-state";
import Popover from "@material-ui/core/Popover/Popover";
import Box from "@material-ui/core/Box/Box";
import Typography from "@material-ui/core/Typography/Typography";
import PopupState from "material-ui-popup-state";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import DeleteRoundedIcon from '@material-ui/icons/DeleteRounded';
import Tooltip from "@material-ui/core/Tooltip/Tooltip";
import {translate} from "../../../config/lang";

class GetConfirmationButton extends React.Component{
    render(){
        let{children,question,size,icon,color,tooltip,className,style,variant} = this.props
        return (

            <PopupState variant="popover" popupId="demo-popup-popover">
                {(popupState) => (
                    <div className="floated-delete-button">
                        <Tooltip title={tooltip} arrow>
                            <Button
                                variant={variant}
                                size={size}
                                style={style}
                                className={className}
                                {...bindTrigger(popupState)}
                            >
                                {children}
                            </Button>
                        </Tooltip>
                        <Popover
                            elevation={1}
                            {...bindPopover(popupState)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Box p={1}>
                                <Typography>
                                    {!!question ? question : translate('areYouSure')}
                                    <Button
                                        style={{color:!!color ? color: "#f44336",marginRight:5}}
                                        startIcon={icon ? icon : <DeleteRoundedIcon/>}
                                        onClick={() => this.props.handleAction()}
                                    >
                                        {translate('yes')}
                                    </Button>
                                </Typography>
                            </Box>
                        </Popover>
                    </div>
                )}
            </PopupState>
        );
    }
}
export  default GetConfirmationButton;
