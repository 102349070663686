import React from 'react';
import {withStyles} from '@material-ui/core/styles';
import withWidth from "@material-ui/core/withWidth/withWidth";
import {Styles} from "../../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import {withRouter} from "react-router-dom";
import {connect, getStore} from "trim-redux";
import {Translate} from "../../../../config/Translate";
import AppCard from "../../../components/layouts/components/AppCard";
import Typography from "@material-ui/core/Typography/Typography";
import Title from "../../../components/elements/Title";
import {Link} from 'react-router-dom';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import ChevronLeftRoundedIcon from '@material-ui/icons/ChevronLeftRounded';
import {defaultVariabls} from "../../../../config";
import {hasAccess, updateRedux} from "../../../../config/Security";
import SubTitle from "../../../components/elements/SubTitle";
import {makeSlug, translate} from "../../../../config/lang";
import Button from "@material-ui/core/Button/Button";
import ChevronRightRoundedIcon from "@material-ui/icons/ChevronRightRounded";
import {Icon} from "@material-ui/core";
class UserGuidLine extends React.Component{
    classes = this.props.classes;
    state={

    };
    componentDidMount(){
        updateRedux();
    }
    render(){
        let {user} = this.props;
        let mode = getStore("mode");
        return (

            <AppCard>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <SubTitle align="left">{translate('sellerGuidLine')}</SubTitle>
                    </Grid>
                    {
                        !!user &&
                        <Grid item xs={12} md={6} lg={3}>
                            <div className="guidline-single" style={user.step === 6 ? {backgroundColor:mode === "light" ? "#d7f1d7" : "rgb(54,82,54)"} : null}>
                                <img width={50} src="../assets/images/guid1.png" alt="guid1" loading="lazy"/>
                                <SubTitle noHR style={{marginBottom:10,whiteSpace:"nowrap"}} >{translate('submitCompanyInfo')}</SubTitle>
                                {
                                    user.step === 6 ?
                                        <Button size="small" disabled style={{color: getStore('mode') === "dark" ? "#ad55ca" : "#4d0267" }}>{translate('registrationCompleted')}</Button>
                                    :
                                        <Button
                                            color="primary"
                                            endIcon={<ChevronRightRoundedIcon/>}
                                            variant="outlined"
                                            size="small"
                                            component={Link}
                                            to={'/steps'}
                                        >
                                            {translate('letsGo')}
                                        </Button>
                                }

                            </div>
                        </Grid>
                    }

                    <Grid item xs={12} md={6} lg={3}>
                        <div className="guidline-single" style={{textAlign:"center"}}>
                            <img width={50} src="../assets/images/guid2.png" alt="guid1" loading="lazy"/>
                            <SubTitle noHR style={{marginBottom:10}} >{translate('enjoyPremiumFeatures')}</SubTitle>
                            {
                                !!user.plan ?

                                user.plan.parent.type === "premium" ?
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component={Link}
                                        to={'/my-plan'}
                                        startIcon={<img style={{width:20}} src={`${defaultVariabls.uploads}/FileManager/${user.plan.parent.thumbnail.name}_small.${user.plan.parent.thumbnail.extension}`}  />}
                                    >
                                        {this.props.locale === "en" ? user.plan.parent.english_name : user.plan.parent.name}
                                    </Button> :
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="a"
                                        href={makeSlug('plans')}
                                    >
                                        {translate('becomePremium')}
                                    </Button> :
                                    <Button
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        component="a"
                                        href={makeSlug('plans')}
                                    >
                                        {translate('becomePremium')}
                                    </Button>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <div className={hasAccess('has_discount') ? "guidline-single" : mode === "dark" ? "guidline-single disabled-appCard disabled-appCard-dark" : "guidline-single disabled-appCard"} style={{textAlign:"center"}}>
                            <img width={50} src="../assets/images/guid3.png" alt="guid1" loading="lazy"/>
                            <SubTitle noHR style={{marginBottom:10}} >{translate('makeDiscount')}</SubTitle>
                            <Button
                                color="primary"
                                endIcon={<ChevronRightRoundedIcon/>}
                                variant="outlined"
                                size="small"
                                component={Link}
                                disabled={!hasAccess('has_discount')}
                                to={hasAccess('has_discount') ? "/my-discounts" : "/dashboard"}
                            >
                                {translate('myDiscounts')}
                            </Button>
                        </div>
                        {
                            !hasAccess('has_discount') &&
                            <Typography style={{marginTop:10, textAlign:"center"}}>{translate('upgradeYourPlan')}</Typography>
                        }
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <div className={hasAccess('ad_count') ? "guidline-single" : mode === "dark" ? "guidline-single disabled-appCard disabled-appCard-dark" : "guidline-single disabled-appCard"} style={{textAlign:"center"}}>
                            <img width={50} src="../assets/images/guid4.png" alt="guid1" loading="lazy"/>
                            <SubTitle noHR style={{marginBottom:10}} >{translate('makeAdvertisement')}</SubTitle>
                            <Button
                                color="primary"
                                endIcon={<ChevronRightRoundedIcon/>}
                                variant="outlined"
                                size="small"
                                component={Link}
                                disabled={!hasAccess('ad_count')}
                                to={hasAccess('ad_count') ? "/my-advertisements" : "/dashboard"}
                            >
                                {translate('myAdvertisements')}
                            </Button>
                        </div>
                        {
                            !hasAccess('ad_count') &&
                            <Typography style={{marginTop:10, textAlign:"center"}}>{translate('upgradeYourPlan')}</Typography>
                        }
                    </Grid>
                </Grid>
            </AppCard>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default  withRouter(withWidth()(withStyles(Styles)(connect(mstp)(UserGuidLine))));