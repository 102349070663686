import React from 'react';
import {Styles} from '../../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import axios from "axios";
import {defaultVariabls} from "../../../../../config/index";
import {connect, setStore} from "trim-redux";
import {withRouter} from "react-router-dom";
import { withSnackbar } from 'notistack';
import withWidth from "@material-ui/core/withWidth/withWidth";
import MakeHomeBannerButton from "./MakeHomeBannerButton";
import AppCardLoaderFields from "../../../../components/Skeletons/AppCardLoaderFields";
import Grid from "@material-ui/core/Grid/Grid";
import HomeBannerItem from "./components/HomeBannerItem";

class SlideShows extends React.Component {
    constructor(props) {
        super(props)
        this.tableRef = React.createRef()
    }
    classes = this.props.classes;
    state = {
        loading:true,
        items:[],
    };
    componentDidMount(){
        this.loadData()
    }
    async loadData(){
        let loginToken = localStorage.getItem('ACT');
        let {locale} = this.props;
        if(loginToken !== null){
            let config = {
                headers: {
                    Accept: 'application/json',
                    Authorization: `Bearer ${loginToken}`
                }
            };
            await axios.get(`${defaultVariabls.url}/home-banners/getAll/${locale}`, config)
                .then(response => {
                    this.setState({
                        items:response.data,
                        loading:false
                    })

                }).catch(error => {
                    console.log(error.response)
                    this.setState({
                        loading:false
                    })
                })
        }else {
            setStore({
                isAuth : false,
                user : null,
            });
        }
    }

    render(){
        let {loading,items} = this.state;
        return (
            <>
                {
                    loading ? <AppCardLoaderFields/> :
                        <Grid container spacing={2} alignItems="center">
                            {
                                items.map((item,index) => (
                                    <Grid key={index} item xs={12} md={4} lg={3}>
                                        <HomeBannerItem
                                            item={item}
                                            reload={() => this.loadData()}
                                            defined_width={this.props.defined_width}
                                            defined_height={this.props.defined_height}
                                        />
                                    </Grid>
                                ))
                            }
                            <Grid item xs={12} md={4} lg={3}>
                                <MakeHomeBannerButton
                                    reload={() => this.loadData()}
                                    defined_width={this.props.defined_width}
                                    defined_height={this.props.defined_height}
                                />
                            </Grid>
                        </Grid>
                }
            </>
        );
    }
}

const mstp = state => ({
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(SlideShows)))));