import React from 'react';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import {Styles} from '../../../../config/design/styles';
import { withStyles } from '@material-ui/core/styles';
import validator from 'validator';
import { withRouter } from "react-router";
import {withSnackbar} from "notistack";
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import IconButton from "@material-ui/core/IconButton/IconButton";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import {Translate} from "../../../../config/Translate";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import {Visibility, VisibilityOff} from "@material-ui/icons";

class Advantages extends React.Component {
    classes = this.props.classes;
    state={
        advantages:[
            ''
        ],
        errors:[],
        limit:5,
    };
    componentDidMount(){
        if(this.props.input){
            this.setState({
                advantages: this.props.input,
            })
        }
    }
    handleValidation(){
        let { advantages } = this.state;
        let errors = {};
        advantages.map((advantage,index) =>{
            if(validator.isEmpty(advantage)){
                errors[`advantage${index}`] = Translate.emptyField;
            }
            return true
        })

        this.setState({
            errors
        })

    }
    handleChangeAdvantage(e){
        let {advantages,errors} = this.state;
        let target = e.target;
        errors[`advantage${parseInt(target.name)}`] = "";
        let advantage = target.value;
        advantages[parseInt(target.name)] = advantage;
        this.setState({
            advantages
        })
        if(advantage.length > 0){
            this.props.handleAdvantages(advantages);
        }
    }
    addAdvantage() {
        let {advantages} = this.state;
        if(advantages[advantages.length-1].length !== 0){
            advantages.push("");
            this.setState({
                advantages
            })
        }else{
            this.props.enqueueSnackbar(Translate.fillPrevFieldFirst, {
                variant: 'warning',
            });
        }
    }
    async handleRemoveField(index) {
        let {advantages} = this.state;
        advantages.splice(index, 1)
        await this.setState({
            advantages
        })
        await this.props.handleAdvantages(advantages);
        this.handleValidation()
    }
    render() {
        let {advantages,errors,limit} = this.state;
        return(
            <Grid container spacing={2} direction="row" justify="flex-start" alignItems="center">

                {
                    advantages.map((advantage,index) =>(
                        <Grid key={index} style={{position:"relative"}} item xs={10}>
                            <TextField
                                variant="outlined"
                                value={advantages[index]}
                                fullWidth
                                size="small"
                                id={`advantage${index}`}
                                label={Translate.advantage}
                                name={index}
                                onChange={this.handleChangeAdvantage.bind(this)}
                                onBlur={this.handleValidation.bind(this)}
                                error = {!!errors[`advantage${index}`]}
                                helperText={errors[`advantage${index}`]}
                                InputProps={ index > 0 && {
                                    endAdornment:(
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={() => this.handleRemoveField(index)}
                                            >
                                                <CloseRoundedIcon/>
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                    ))
                }
                {
                    advantages.length !== limit &&
                    <Grid item xs={2}>
                        <IconButton
                            onClick ={this.addAdvantage.bind(this)}
                            color="primary"
                        >
                            <AddRoundedIcon/>
                        </IconButton>
                    </Grid>
                }

            </Grid>
        )
    }
}

export default withRouter(withSnackbar(withStyles(Styles)(Advantages)));