import React from 'react';
import MenuItem from "@material-ui/core/MenuItem/MenuItem";
import axios from "axios";
import {defaultVariabls} from "../../../../config";
import {connect} from "trim-redux";
import TextField from "@material-ui/core/TextField/TextField";
import CircularProgress from "@material-ui/core/CircularProgress/CircularProgress";
import withWidth from "@material-ui/core/withWidth/withWidth";
import {withSnackbar} from "notistack";
import {withRouter} from "react-router";
import {withStyles} from "@material-ui/core";
import {Styles} from "../../../../config/design/styles";
import Grid from "@material-ui/core/Grid/Grid";
import InputAdornment from "@material-ui/core/InputAdornment/InputAdornment";
import LoadingButton from "../../../components/elements/LoadingButton";
import {translate} from "../../../../config/lang";
import SingleFileChooser from "../../fileManager/Components/SingleFileChooser";

class AddTicketReport extends React.Component{
    classes = this.props.classes

    state = {
        fields:{
            subject:"",
            message:"",
            priority:"medium",
            department:"",
            order_item:""
        },
        type:"system",
        SubmitLoading:false,
        departments:[],
        orderItems:[],
        loading:true,
        open:false,
        errors:{},
        anchorEl:null
    };
    handleClickMenu = (event) => {
        this.setState({
            anchorEl: event.currentTarget
        })
    };

    handleCloseMenu = () => {
        this.setState({
            anchorEl: null
        })
    };

    componentDidMount(){
        this.loadData()
    }
    loadData(){
        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.get(`${defaultVariabls.url}/departments/getAllWithOrders?lang=${this.props.locale}`, config)
            .then(response => {
                this.setState({
                    departments: response.data.departments,
                    loading:false,
                })
            })
            .catch(error => {
                console.log(error.response)
                this.setState({
                    loading:false
                })
            });
    }
    toggleOpen(type){
        this.setState({
            open: !this.state.open,
            type:type
        })
    }
    handleChange(e){
        let {fields,errors} = this.state;
        let target = e.target;
        errors[target.name] = "";
        fields[target.name] = target.value;
        this.setState({
            fields,
            errors
        });
    }

    handleValidation(callback){
        let { fields,type } = this.state;
        let errors = {};
        let formIsValid = true;

        //subject
        if(!fields.subject) {
            formIsValid = false;
            errors['subject'] = translate('emptyField');
        }
        //subject
        if(!fields.message) {
            formIsValid = false;
            errors['message'] = translate('message');
        }

        if(!formIsValid){
            this.props.enqueueSnackbar(translate('someFieldsHasError'), {
                variant: 'warning',
            });
            this.setState({
                SubmitLoading:false,
                errors
            })
        }
        return callback(formIsValid)
    }
    handleRequest(){
        const {fields} = this.state;
        let data = new FormData();
        data.append('lang', this.props.locale);
        data.append('subject', fields.subject);
        data.append('message', fields.message);

        let loginToken = localStorage.getItem('ACT');
        let config = {
            headers: {
                Accept: 'application/json',
                Authorization: `Bearer ${loginToken}`
            }
        };
        axios.post(`${defaultVariabls.url}/ticket/add-report`, data, config)
            .then(response => {
                this.props.enqueueSnackbar(translate('ticket_sent'), {
                    variant: 'success',
                });
                this.setState({
                    SubmitLoading:false,
                });
                this.props.history.push('/tickets')

            })
            .catch(error => {
                console.log(error.response)
                this.props.enqueueSnackbar(translate('detailsSetFailed'), {
                    variant: 'error',
                });
                this.setState({
                    SubmitLoading:false
                });
            });
    }
    handleSubmit(e){
        this.setState({
            SubmitLoading:true
        })
        e.preventDefault();
        this.handleValidation((valid) =>{
            if(valid) {
                this.handleRequest();
            }
        });
    }
    render(){
        let {SubmitLoading,errors,fields} = this.state;
        let {user} = this.props;
        return (
            <>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={fields.subject}
                            fullWidth
                            label={translate('subject')}
                            name="subject"
                            onChange={this.handleChange.bind(this)}
                            error = {!!errors['subject']}
                            helperText={errors['subject']}
                            className={this.classes.inputCtl}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            value={fields.message}
                            fullWidth
                            label={translate('message')}
                            name="message"
                            minRows={5}
                            multiline
                            onChange={this.handleChange.bind(this)}
                            error = {!!errors['message']}
                            helperText={errors['message']}
                            className={this.classes.inputCtl}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <LoadingButton
                            variant="contained"
                            color="primary"
                            onClick = {this.handleSubmit.bind(this)}
                            loading = {SubmitLoading}
                        >
                            {translate('addReport')}
                        </LoadingButton>
                    </Grid>
                </Grid>
            </>
        );
    }
}
const mstp = state => ({
    user: state.user,
    locale: state.locale,
});
export default withWidth()(withSnackbar(withRouter(withStyles(Styles)(connect(mstp)(AddTicketReport)))));